import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Icon from '../../Icon';
import Title from '../../Title';
import {
  navRoutes as R,
  userRoles,
  coachStatusesQuery,
} from '../../../constants';
import ProgressHeader from './ProgressHeader';
import { UseExerciseWithSelfLearning } from './../../../Hooks';
import { useAuth } from '../../../context/auth';
import * as S from './style';
import theme from '../../../theme';
import TextWithIcon from '../../../components/TextWithIcon';
import { Button } from '../../../components';
import { navRoutes as NR } from '../../../constants';
import { useProgressCheck } from '../../../context/progress-check';

const Header = ({
  isLoggedIn,
  bgColor = 'primaryMain',
  type,
  showProfile,
  showParking,
  showNotes,
  learnerDashboard,
  exercise,
  progressPercent: _progressPercent,
  ...props
}) => {
  const {
    progressPercent: exerciseProgressPercent,
    withCoach,
    handleSaveStep,
    selfLearning,
  } = UseExerciseWithSelfLearning();
  const { user, logout } = useAuth();
  const { progressPercent: checkProgressPercent } = useProgressCheck();
  const location = useLocation();
  const navigate = useNavigate();
  const isMenu = location.pathname?.includes('menu');
  const isPreview = location?.pathname?.includes('preview');
  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.tablet})`,
  });

  let profileRoute = R.FACILITATOR.DASHBOARD;
  if (user.role === userRoles.LEARNER) {
    profileRoute = R.LEARNER.PROFILE;
  } else if (user.role === userRoles.COACH) {
    if (learnerDashboard) {
      profileRoute = R.LEARNER.PROFILE;
    } else {
      profileRoute = R.COACH.PROFILE;
    }
  }
  if (type === 'progress') {
    return (
      <ProgressHeader
        showNotes={!isPreview && (withCoach || user.role === userRoles.COACH)}
        showParking={
          !isPreview && !selfLearning && user.role === userRoles.LEARNER
        }
        handleSaveStep={handleSaveStep}
        progressPercent={
          _progressPercent || exerciseProgressPercent || checkProgressPercent
        }
      />
    );
  }

  if (!isTablet && !exercise && user.role === userRoles.FACILITATOR) {
    return (
      <S.Header bgColor={bgColor} {...props}>
        <Title
          color="white"
          lightText="Turning"
          boldText="Pages"
          icon="openedBook"
          iconProps={{}}
        />
        <S.MenuWrapper>
          <Button.WithText
            color="white"
            text="Dashboard"
            weight="700"
            to={NR.FACILITATOR.DASHBOARD}
            header
          />
          <Button.WithText
            color="white"
            text="My learners"
            weight="700"
            to={NR.FACILITATOR.LEARNERS}
            header
          />
          <Button.WithText
            color="white"
            text="My coaches"
            weight="700"
            to={NR.FACILITATOR.COACHES.replace(
              ':statusType',
              coachStatusesQuery.ALL
            )}
            header
          />
          <TextWithIcon
            color="white"
            icon="logout"
            text="Log out"
            weight="bold"
            handleClick={logout}
            iconProps={{ style: { marginRight: 4 } }}
            isButton
          />
        </S.MenuWrapper>
      </S.Header>
    );
  }

  return (
    <S.Header
      learner={user?.role === userRoles.LEARNER}
      bgColor={bgColor}
      {...props}
    >
      <Title
        color="white"
        lightText="Turning"
        boldText="Pages"
        icon="openedBook"
        iconProps={{}}
      />
      {(showParking || showProfile) && (
        <S.IconsContainer>
          {showParking && (
            <S.IconButton
              onClick={() => {
                // should open the modal component
              }}
            >
              <Icon
                icon="questionMark"
                color="white"
                mr={3}
                style={{ cursor: 'pointer' }}
              />
            </S.IconButton>
          )}
          {userRoles.LEARNER !== user?.role && showProfile && (
            <Link
              to={profileRoute}
              aria-label={`Visit your ${user.role.toLowerCase()} profile page`}
            >
              <Icon
                icon="profile"
                color="white"
                style={{ cursor: 'pointer' }}
                width="32"
                height="32"
              />
            </Link>
          )}
        </S.IconsContainer>
      )}
      {userRoles.LEARNER === user?.role ? (
        isMenu ? (
          <Button.IconButton
            handleClick={() => navigate(-1)}
            aria-label={`Go back to your previous page`}
          >
            <Icon icon="cross" color="white" width="24" height="24" />
          </Button.IconButton>
        ) : (
          <Link
            to={R.LEARNER.MENU}
            aria-label={`Visit your ${user.role.toLowerCase()} menu page`}
          >
            <Icon
              icon="hamburger"
              color="white"
              style={{ cursor: 'pointer' }}
              width="36"
              height="32"
            />
          </Link>
        )
      ) : null}
    </S.Header>
  );
};

export default Header;
