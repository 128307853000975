import { useState } from 'react';
import { Button } from '../../../components';
import * as S from './style';
import Task from './Task';
import { useUpdateLearnerGoal } from '../../../api-calls/learnersGoals.queries';

const CardButtonsSection = ({
  goalId,
  setGoals,
  title,
  completed,
  setOpenModal,
  tasks,
  refetch,
}) => {
  const [showNewTask, setShowNewTask] = useState(false);

  const { mutate: updateGoal } = useUpdateLearnerGoal({
    personalGoalId: goalId,
    title,
    completed,
  });

  const markGoalAsCompleted = () => {
    updateGoal({
      title,
      completed: true,
    });
    setGoals((goals) => {
      const currentGoal = goals.unCompleted.find((g) => g.id === goalId);
      const _unCompleted = goals.unCompleted.filter((g) => g.id !== goalId);
      const _completed = [
        ...goals.completed,
        { ...currentGoal, status: 'COMPLETED' },
      ];
      return { completed: _completed, unCompleted: _unCompleted };
    });
    setOpenModal(true);
  };

  return (
    <>
      {showNewTask && (
        <Task
          mt="2"
          title=""
          setGoals={setGoals}
          goalId={goalId}
          newTask
          handleTaskCheck={() => {}}
          setShowNewTask={setShowNewTask}
          refetch={refetch}
        />
      )}
      <S.CardButtonsWrapper>
        <S.CardButtons mt="6">
          <S.SmallBtn>
            <Button.BasicButton
              disabled={showNewTask || !tasks?.length}
              handleClick={() => {
                setShowNewTask(true);
              }}
              icon="plus"
              iconProps={{ color: 'secondaryMain' }}
              bgColor="neutralSurface"
              variant="tertiary"
            />
          </S.SmallBtn>
          <S.BigBtn>
            <Button.BasicButton
              handleClick={markGoalAsCompleted}
              icon="tick"
              variant="primary"
              iconProps={{ width: '13', height: '11.5' }}
            />
          </S.BigBtn>
        </S.CardButtons>
      </S.CardButtonsWrapper>
    </>
  );
};

export default CardButtonsSection;
