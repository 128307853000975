import * as S from './style';
import { PlayButton } from '../Button';
import * as T from '../Typography';
import TextWithIcon from '../TextWithIcon';
import MilestoneCard from './MilestoneCard';
import { getMediaUrl } from '../../helpers';
import { useGeneralState } from './../../context/general-state';

const handleClick = () => {};
const Exercises = ({
  image = 'exerciseCompletedBackground',
  backgroundColor = 'neutralLight',
  to,
  title,
  exerciseComplete,
  savedExercise,
  disabled,
  titleAudioKey,
  ...props
}) => {
  const { state: generalState } = useGeneralState();
  // used when button is disabled
  const preventClick = (event) => {
    event.preventDefault();
  };

  return (
    <S.StyledLink
      backgroundColor={backgroundColor}
      to={to}
      onClick={disabled ? preventClick : null}
      exerciseComplete={exerciseComplete}
      savedExercise={savedExercise}
      disabled={disabled}
      aria-disabled={disabled ? 'true' : 'false'}
      {...props}
    >
      {!exerciseComplete && !disabled && <S.StyledImage image={image} />}
      {savedExercise && (
        <S.StyledIcon
          icon="bookmarkSaved"
          width="32px"
          height="32px"
          color="neutralMain"
        />
      )}
      <S.Content>
        <PlayButton
          handleClick={handleClick}
          backgroundColor="white"
          iconProps={{ width: '32px', height: '32px', color: 'neutralMain' }}
          padding="12px"
          icon={disabled ? 'lock' : 'speaker'}
          disabled={disabled}
          audioUrl={getMediaUrl(
            titleAudioKey,
            true,
            generalState?.preferredVoice
          )}
        />
        <T.P
          weight="bold"
          ta="center"
          mt="12px"
          lineHeight="medLarge"
          size="medLarge"
          color="neutralMain"
        >
          {title}
        </T.P>
        {exerciseComplete && (
          <TextWithIcon
            text="Complete"
            icon="tick"
            jc="center"
            ai="center"
            mt="12px"
            isText={true}
            iconProps={{
              color: 'neutralMain',
              width: '13.5px',
              height: '11.5px',
            }}
          />
        )}
      </S.Content>
    </S.StyledLink>
  );
};

export { MilestoneCard };
export default Exercises;
