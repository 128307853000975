import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BasicButton } from '../../../../components/Button';
import * as T from '../../../../components/Typography';
import { navRoutes } from '../../../../constants';
import * as S from './style';

const StepFooterButtons = ({
  stuckAtReview,
  handleNext,
  preview,
  disabled,
  enableNext = false,
  selfLearning,
  title,
}) => {
  const { exerciseId } = useParams();

  const { state } = useLocation();

  let backToExerciseLink = (
    selfLearning
      ? navRoutes.EXERCISES.EXERCISE_EXPLAINER_SL
      : navRoutes.EXERCISES.EXERCISE_EXPLAINER
  ).replace(':exerciseId', exerciseId);

  if (state?.canGoBack) {
    backToExerciseLink = -1;
  }

  if (stuckAtReview) {
    return (
      <S.ButtonWrapper>
        <BasicButton handleClick={handleNext} variant="primary">
          <T.P color="white" weight="semi">
            Complete review
          </T.P>
        </BasicButton>
      </S.ButtonWrapper>
    );
  }

  if (preview) {
    return (
      <S.ButtonWrapper>
        <BasicButton
          disabled
          handleClick={handleNext}
          icon="next"
          variant="primary"
        >
          <T.P color="white" weight="semi">
            {title || 'Next'}
          </T.P>
        </BasicButton>
        <BasicButton
          icon="back"
          variant="primary"
          mt={5}
          to={backToExerciseLink}
        >
          <T.P color="white" weight="semi">
            Back to exercise
          </T.P>
        </BasicButton>
      </S.ButtonWrapper>
    );
  }

  return (
    <S.ButtonWrapper>
      <BasicButton
        disabled={disabled || !enableNext}
        handleClick={handleNext}
        icon="next"
        variant="primary"
      >
        <T.P color="white" weight="semi">
          {title || 'Next'}
        </T.P>
      </BasicButton>
    </S.ButtonWrapper>
  );
};

StepFooterButtons.propTypes = {
  stuckAtReview: PropTypes.bool,
  handleNext: PropTypes.func,
  preview: PropTypes.bool,
  disabled: PropTypes.bool,
  enableNext: PropTypes.bool,
  selfLearning: PropTypes.bool,
  title: PropTypes.string,
};

export default StepFooterButtons;
