import { Col, Row } from '../../components/Grid';
import { Typography as T } from '../../components';
import {
  BasicInput,
  Dropdown,
  Checkbox,
  Textarea,
  Radio,
} from '../../components/Inputs';
import { BasicButton } from '../../components/Button';
import {
  probationAreaTypes,
  // genderTypes,
  // ageGroups,
  // ethnicityTypes,
} from '../../constants/data-types';
import styled from '@emotion/styled';
import { navRoutes as NR } from '../../constants';
import { useNavigate } from 'react-router-dom';
import theme from '../../theme';

const StyledP = styled(T.P)`
  line-height: 28px !important;
  font-size: 1 !important;
  color: ${theme.colors.neutral90} !important;
  padding: 4.5px 0 !important;
  padding-top: 0 !important;
  padding-left: ${({ theme }) => theme.spacings[2]} !important;
`;

const InterestForm = ({
  setFormData,
  form,
  validationErrs,
  httpError,
  loading,
  handleSubmit,
  userRole,
}) => {
  const navigate = useNavigate();
  return (
    <div style={{ maxWidth: '636px' }}>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H2 weight="bold" color="neutralMain">
            Application form
          </T.H2>
          <T.P weight="regular" size="regular" mt={3}>
            Thank you for your interest! To start with, please add your name and
            email so we can contact you. We’ll then get into the application
            questions
          </T.P>
        </Col>
        <Col w={[4, 11, 6]} mt="5">
          <Dropdown
            label="In which location(s) could you coach?"
            selected={
              form.coachingLocations !== null &&
              form.coachingLocations.map((location) => {
                return probationAreaTypes.find(
                  (type) => type.value === location
                );
              })
            }
            handleChange={(coachingLocations) => {
              setFormData({ coachingLocations });
            }}
            options={probationAreaTypes}
            multi
            error={validationErrs.coachingLocations}
          />
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <T.P weight="bold" mt={3}>
            Please confirm you are eligible for this role by ticking the boxes
            below
          </T.P>
        </Col>
        <Col w={[4, 11, 12]} mt="12px">
          <Checkbox
            ml={2}
            font="regular"
            label={<StyledP>I have read the role description</StyledP>}
            checked={form.readRoleDescription}
            handleChange={(readRoleDescription) => {
              setFormData({ readRoleDescription });
            }}
            plain
            error={validationErrs.readRoleDescription}
          />
          <Checkbox
            ml={2}
            label={
              <StyledP>
                I can offer a non-judgmental approach to those accessing support
                through Shannon Trust to improve their reading
              </StyledP>
            }
            checked={form.canOfferNonJudgementalApproach}
            handleChange={(canOfferNonJudgementalApproach) =>
              setFormData({ canOfferNonJudgementalApproach })
            }
            plain
            error={validationErrs.canOfferNonJudgementalApproach}
          />
          <Checkbox
            ml={2}
            label={
              <StyledP>
                I am able to give a regular, reliable commitment to volunteering
                and am able to offer some flexibility when/where needed
              </StyledP>
            }
            checked={form.ableToGiveRegularCommitment}
            handleChange={(ableToGiveRegularCommitment) =>
              setFormData({ ableToGiveRegularCommitment })
            }
            plain
            error={validationErrs.ableToGiveRegularCommitment}
          />
          <Checkbox
            ml={2}
            label={
              <StyledP>
                I am in the right location for the role I am applying for (less
                than an hour’s traveling distance)
              </StyledP>
            }
            checked={form.inTheRightLocation}
            handleChange={(inTheRightLocation) =>
              setFormData({ inTheRightLocation })
            }
            plain
            error={validationErrs.inTheRightLocation}
          />
          <Checkbox
            ml={2}
            label={
              <StyledP>
                I have strong communication skills and am able to use these to
                inspire others
              </StyledP>
            }
            checked={form.hasStrongCommunicationSkills}
            handleChange={(hasStrongCommunicationSkills) =>
              setFormData({ hasStrongCommunicationSkills })
            }
            plain
            error={validationErrs.hasStrongCommunicationSkills}
          />
          <Checkbox
            ml={2}
            label={
              <StyledP>
                I am able to effectively plan, manage my own time and prioritise
                key tasks
              </StyledP>
            }
            checked={form.ableToPlanAndManageTime}
            handleChange={(ableToPlanAndManageTime) =>
              setFormData({ ableToPlanAndManageTime })
            }
            plain
            error={validationErrs.ableToPlanAndManageTime}
          />
          <Checkbox
            ml={2}
            label={
              <StyledP>
                I am able to keep up to date with Shannon Trust news and
                communications and share relevant information with the wider
                team
              </StyledP>
            }
            checked={form.ableToKeepUpToDate}
            handleChange={(ableToKeepUpToDate) =>
              setFormData({ ableToKeepUpToDate })
            }
            plain
            error={validationErrs.ableToKeepUpToDate}
          />
          <Checkbox
            ml={2}
            label={
              <StyledP>
                I have access to the internet and a computer or other suitable
                device. I am able to use Microsoft Outlook to regularly check my
                Shannon Trust emails. I am comfortable using video-conferencing
                applications such as Zoom or Teams, as well as other
                commonly-used Microsoft software packages.
              </StyledP>
            }
            checked={form.hasInternetAccess}
            handleChange={(hasInternetAccess) =>
              setFormData({ hasInternetAccess })
            }
            plain
            error={validationErrs.hasInternetAccess}
          />
        </Col>

        <Col w={[4, 11, 6]} mt="5">
          <BasicInput
            label="What is your first name?"
            value={form.firstName}
            name="firstName"
            handleChange={(firstName) => setFormData({ firstName })}
            error={validationErrs.firstName}
          />
        </Col>
        <Col w={[4, 11, 6]} mt="5">
          <BasicInput
            label="What is your last name?"
            value={form.lastName}
            name="lastName"
            handleChange={(lastName) => setFormData({ lastName })}
            error={validationErrs.lastName}
          />
        </Col>
        <Col w={[4, 11, 6]} mt="5">
          <BasicInput
            label="What is your email?"
            value={form.email}
            name="email"
            handleChange={(email) => setFormData({ email })}
            error={validationErrs.email || httpError?.response?.data?.message}
          />
        </Col>
        <Col w={[4, 11, 6]} mt="5">
          <BasicInput
            label="What is your phone number?"
            value={form.phoneNumber}
            name="phoneNumber"
            handleChange={(phoneNumber) => setFormData({ phoneNumber })}
            error={validationErrs.phoneNumber}
          />
        </Col>
        <Col w={[4, 11, 6]} mt="5">
          <BasicInput
            label="Address Line 1"
            value={form.addressLine1}
            name="addressLine1"
            handleChange={(addressLine1) => setFormData({ addressLine1 })}
            error={validationErrs.addressLine1}
          />
        </Col>
        <Col w={[4, 11, 6]} mt="5">
          <BasicInput
            label="Address Line 2"
            optional
            value={form.addressLine2}
            name="addressLine2"
            handleChange={(addressLine2) => setFormData({ addressLine2 })}
            error={validationErrs.addressLine2}
          />
        </Col>
        <Col w={[4, 11, 6]} mt="5">
          <BasicInput
            label="City"
            value={form.city}
            name="city"
            handleChange={(city) => setFormData({ city })}
            error={validationErrs.city}
          />
        </Col>
        <Col w={[4, 11, 6]} mt="5">
          <BasicInput
            label="Postcode"
            value={form.postcode}
            name="postcode"
            handleChange={(postcode) => setFormData({ postcode })}
            error={validationErrs.postcode}
          />
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <Textarea
            label="Why do you wish to volunteer with the Shannon Trust?"
            value={form.volunteeringReason}
            handleChange={(volunteeringReason) => {
              setFormData({ volunteeringReason });
            }}
            error={validationErrs.volunteeringReason}
          />
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <BasicInput
            label="How many hours per month could you offer the Shannon Trust?"
            value={form.availabilityHoursPerMonth}
            handleChange={(availabilityHoursPerMonth) => {
              setFormData({ availabilityHoursPerMonth });
            }}
            type="number"
            error={validationErrs.availabilityHoursPerMonth}
          />
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <Textarea
            label="Briefly describe the skills and personal qualities that you can bring to the role? (please refer to the job description)"
            value={form.skillsDescription}
            handleChange={(skillsDescription) => {
              setFormData({ skillsDescription });
            }}
            error={validationErrs.skillsDescription}
          />
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <Radio
            label="Do you have a full driving licence?"
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            value={form.hasDrivingLicence}
            onChange={(hasDrivingLicence) => {
              setFormData({ hasDrivingLicence });
            }}
            error={validationErrs.hasDrivingLicence}
            row
          />
        </Col>

        <Col w={[4, 11, 12]} mt="5">
          <Radio
            label="Do you have use of a car?"
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            value={form.hasCar}
            onChange={(hasCar) => {
              setFormData({ hasCar });
            }}
            error={validationErrs.hasCar}
            row
          />
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <Radio
            label="Do you have access to a computer?"
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            value={form.hasComputerAccess}
            onChange={(hasComputerAccess) => {
              setFormData({ hasComputerAccess });
            }}
            error={validationErrs.hasComputerAccess}
            row
          />
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <Textarea
            label="If you have any access requirements please specify below"
            value={form.accessRequirements}
            handleChange={(accessRequirements) => {
              setFormData({ accessRequirements });
            }}
            error={validationErrs.accessRequirements}
          />
        </Col>

        <Col w={[4, 12, 12]} mt="6">
          <T.H2 weight="bold" color="neutralMain">
            Criminal Record Declaration
          </T.H2>
          <T.P weight="regular" size="regular" mt={1} color="neutralMain">
            As a Shannon Trust volunteer, you may be visiting prisons and have
            contact with serving prisoners. The Prison Service requires
            information about visitors’ previous criminal convictions. Please
            therefore declare below ANY previous criminal convictions (excluding
            speeding endorsements and parking offenses). Having a criminal
            conviction does not necessarily exclude you from volunteering with
            us.
          </T.P>
        </Col>

        <Col w={[4, 11, 12]} mt="5">
          <Radio
            label="Do you have any previous convictions?"
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            value={form.hasPreviousConvictions}
            onChange={(hasPreviousConvictions) => {
              setFormData({ hasPreviousConvictions });
            }}
            error={validationErrs.hasPreviousConvictions}
            row
          />
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <Radio
            label="Do you have any court appearances pending?"
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            value={form.hasCourtAppearances}
            onChange={(hasCourtAppearances) => {
              setFormData({ hasCourtAppearances });
            }}
            error={validationErrs.hasCourtAppearances}
            row
          />
        </Col>
        <Col w={[4, 11, 12]} mt="5">
          <Textarea
            label="If yes to either of the above questions, please provide details"
            value={form.criminalRecordDetails}
            handleChange={(criminalRecordDetails) => {
              setFormData({ criminalRecordDetails });
            }}
            error={validationErrs.criminalRecordDetails}
          />
        </Col>
        {/* 
        <Col w={[4, 12, 12]} mt="8">
          <T.H2 weight="bold" color="neutralMain">
            Equal Opportunities
          </T.H2>
          <T.P weight="regular" size="regular" mt={1} color="neutral90">
            Shannon Trust is an equal opportunities employer. To help us make
            sure that it works effectively, we would appreciate your completion
            of this form. The information will be used solely for monitoring
            purposes and will be treated in strictest confidence. It will be
            separated from your application form on receipt.
          </T.P>
        </Col> 
         <Col w={[4, 12, 12]} inner>
          <Row inner>
            <Col w={[4, 11, 12]} mt="5">
              <Dropdown
                label="Which gender do you identify with? Please select all that applies"
                selected={
                  form.genders !== null &&
                  form.genders.map((e) => ({
                    label: e,
                    value: e,
                  }))
                }
                handleChange={(genders) => {
                  setFormData({
                    genders,
                  });
                }}
                options={Object.values(genderTypes)
                  .filter((e) => e !== genderTypes.LET_ME_TYPE)
                  .map((e) => ({
                    label: e,
                    value: e,
                  }))}
                multi
                error={validationErrs.genders}
              />

              <BasicInput
                label="If you would prefer to type the gender you identify with, please do that here"
                value={form.gendersOther}
                name="gendersOther"
                handleChange={(gendersOther) => setFormData({ gendersOther })}
                error={validationErrs.gendersOther}
                m={{ mt: 4 }}
              />
            </Col>

            <Col w={[4, 11, 12]} mt="5">
              <Dropdown
                label="Please select your age group"
                selected={form.ageGroup !== null && form.ageGroup}
                handleChange={(ageGroup) => {
                  setFormData({
                    ageGroup,
                  });
                }}
                options={ageGroups.map((e) => ({
                  label: e,
                  value: e,
                }))}
                error={validationErrs.ageGroup}
              />
            </Col>
            <Col w={[4, 11, 12]} mt="5">
              <Dropdown
                label="How would you describe your ethnic origin?"
                selected={form.ethnicOrigin !== null && form.ethnicOrigin}
                handleChange={(ethnicOrigin) => {
                  setFormData({
                    ethnicOrigin,
                  });
                }}
                options={Object.values(ethnicityTypes).map((e) => ({
                  label: e,
                  value: e,
                }))}
                error={validationErrs.ethnicOrigin}
              />

              <BasicInput
                label="If any other ethnic background not listed, please state"
                value={form.ethnicOriginOther}
                name="ethnicOriginOther"
                handleChange={(ethnicOriginOther) =>
                  setFormData({ ethnicOriginOther, ethnicOrigin: [] })
                }
                error={validationErrs.ethnicOriginOther}
                m={{ mt: 4 }}
              />
            </Col>
            <Col w={[4, 11, 12]} mt="5">
              <Dropdown
                label="In order to improve recruitment, please tell us how you heard of Shannon Trust"
                selected={form.heardOfBy !== null && form.heardOfBy}
                handleChange={(heardOfBy) => {
                  setFormData({
                    heardOfBy,
                  });
                }}
                options={['Website', 'Friend'].map((e) => ({
                  label: e,
                  value: e,
                }))}
                error={validationErrs.heardOfBy}
              />
            </Col> */}

        <Col w={[4, 11, 12]} mt="6">
          <Checkbox
            ml={2}
            ai="start"
            label={
              <StyledP>
                I declare that the information on this form is true and complete
              </StyledP>
            }
            checked={form.trueAndCompleteInfo}
            handleChange={(trueAndCompleteInfo) =>
              setFormData({ trueAndCompleteInfo })
            }
            plain
            error={validationErrs.trueAndCompleteInfo}
          />

          <Checkbox
            ml={2}
            ai="start"
            label={
              <StyledP>
                By applying I give consent to Turning Pages using and keeping
                the information I provide as described in our{' '}
                <T.Link
                  to={NR.EXTERNAL.PRIVACY_POLICY}
                  color="neutralMain"
                  external
                  underline
                >
                  Privacy Policy
                </T.Link>
              </StyledP>
            }
            checked={form.giveConsent}
            handleChange={(giveConsent) => setFormData({ giveConsent })}
            plain
            error={validationErrs.giveConsent}
          />
        </Col>
        <Col w={[4, 11, 10]} mt="2">
          {httpError && (
            <T.P mb="2" color="error">
              {httpError}
            </T.P>
          )}

          {validationErrs.hasError && (
            <Row>
              <Col w={[4, 10, 12]}>
                <T.P color="error" mb={3}>
                  There is an error in your form. Please correct before
                  submitting.
                </T.P>
              </Col>
            </Row>
          )}
        </Col>
        {userRole !== 'FACILITATOR' && (
          <Col w={[4, 11, 9]}>
            <BasicButton
              variant="primary"
              disabled={loading}
              loading={loading}
              type="submit"
              handleClick={handleSubmit}
            >
              <T.P color="white" weight="semi">
                Submit
              </T.P>
            </BasicButton>
          </Col>
        )}
        {userRole === 'FACILITATOR' && (
          <Col w={[4, 11, 9]}>
            <BasicButton
              variant="primary"
              disabled={validationErrs.hasError}
              handleClick={() => navigate(-1)}
            >
              <T.P color="white" weight="bold">
                Go back
              </T.P>
            </BasicButton>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default InterestForm;
