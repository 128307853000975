import { useState, useEffect } from 'react';
import TextFinderMdContent from './TextFinderMdContent';
import StepFooterButtons from '../StepFooterButtons';
import { useGeneralState } from '../../../../context/general-state';
import * as S from './style';
import { PlayButton } from '../../../../components/Button';
import Markdown from '../../../../components/Markdown';
import { createH1 } from '../../../../utils/markdown';
import { UseExerciseWithSelfLearning } from '../../../../Hooks';
const regex = /%(.*?)%/g;

const Title = ({ textAudio, textAudioUrl }) => {
  if (textAudio && textAudioUrl) {
    return (
      <S.TextAudioWrapper mt={1} mb={5}>
        <PlayButton
          audioUrl={textAudioUrl}
          width="56px"
          height="56px"
          iconProps={{ width: '32px', height: '32px' }}
        />

        <Markdown
          text={textAudio}
          customStyles={{
            p: {
              ml: 4,
              weight: 'regular',
              size: 'med',
            },
          }}
        />
      </S.TextAudioWrapper>
    );
  } else if (textAudio && !textAudioUrl) {
    return (
      <Markdown
        text={createH1(textAudio)}
        customStyles={{
          h1: {
            size: 'Larger',
            ta: 'center',
            weight: 'bold',
            mb: 6,
          },
        }}
      />
    );
  } else {
    return;
  }
};
const TextFinder = ({
  data: { text = '', textAudio, textAudioUrl } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  selfLearning,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { setState } = useGeneralState();
  const { setSubProgress } = UseExerciseWithSelfLearning();

  const matches = text?.match(regex);

  useEffect(() => {
    setState({
      headerSteps: {
        show: true,
        requiredSteps: matches?.length,
        completedSteps: selectedOptions.length,
      },
    });

    setSubProgress(selectedOptions.length / matches?.length);

    return () => {
      setState({
        headerSteps: {
          show: false,
          requiredSteps: null,
          completedSteps: null,
        },
      });
    };
  }, [matches?.length, selectedOptions.length, setState, setSubProgress]);

  return (
    <S.Wrapper>
      <Title textAudio={textAudio} textAudioUrl={textAudioUrl} />
      <S.ContentWrapper>
        {text ? (
          <S.MdContentWrapper>
            <TextFinderMdContent
              text={text}
              matches={matches}
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions}
            />
          </S.MdContentWrapper>
        ) : null}
      </S.ContentWrapper>
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={selectedOptions.length === matches?.length}
        selfLearning={selfLearning}
      />
    </S.Wrapper>
  );
};

export default TextFinder;
