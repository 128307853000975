import styled from '@emotion/styled';
// import theme from './../../theme';
import setMargin from '../../helpers/set-margin';
import * as T from '../Typography';

import { useTheme } from '@emotion/react';

// ICONS

import Tick from './icons/Tick';
import Next from './icons/Next';
import Back from './icons/Back';
import QuestionMark from './icons/QuestionMark';
import BookmarkSaved from './icons/BookmarkSaved';
import Eye from './icons/Eye';
import Profile from './icons/Profile';
import Lock from './icons/Lock';
import Note from './icons/Note';
import Like from './icons/Like';
import Cup from './icons/Cup';
import TryAgain from './icons/TryAgain';
import Milestone from './icons/Milestone';
import Book from './icons/Book';
import LearningAndCoachingTime from './icons/LearningAndCoachingTime';
import Report from './icons/Report';
import Device from './icons/Device';
import Speaker from './icons/Speaker';
import ByYourSelf from './icons/ByYourSelf';
import WithCoach from './icons/WithCoach';
import Search from './icons/Search';
import ChevronDown from './icons/ChevronDown';
import OpenedBook from './icons/OpenedBook';
import ArrowIncrease from './icons/ArrowIncrease';
import Smile from './icons/Smile';
import Accolade from './icons/Accolade';
import Copy from './icons/Copy';
import UserWithArrow from './icons/UserWithArrow';
import Calendar from './icons/Calendar';
import Check from './icons/Check';
import Cross from './icons/Cross';
import SmallTick from './icons/SmallTick';
import ProfileEye from './icons/ProfileEye';
import Logout from './icons/Logout';
import Alert from './icons/Alert';
import Plus from './icons/Plus';
import ArrowUp from './icons/ArrowUp';
import ArrowDown from './icons/ArrowDown';
import DashedLine from './icons/DashedLine';
import CurvedDashedLine from './icons/CurvedDashedLine';
import CurvedDashedLineBackground from './icons/CurvedDashedLineBackground';
import ArrowLine from './icons/ArrowLine';
import ArrowLineUp from './icons/ArrowLineUp';
import Phone from './icons/Phone';
import AchievePersonalGoal from './icons/AchievedPersonalGoal';
import AttendFiveSessions from './icons/AttendedFiveSessions';
import Bullseye from './icons/Bullseye';
import CompletedFirstReadingBook from './icons/CompletedFirstReadingBook';
import DropTheMic from './icons/DropTheMic';
import EndOfM1 from './icons/EndOfM1';
import EndOfM2 from './icons/EndOfM2';
import EndOfM3 from './icons/EndOfM3';
import EndOfM4 from './icons/EndOfM4';
import EndOfM5 from './icons/EndOfM5';
import FirstStep from './icons/FirstStep';
import ListenAndRepeat from './icons/ListenAndRepeat';
import MakingProgress from './icons/MakingProgress';
import MasterInTheMaking from './icons/MasterInTheMaking';
import SpoiltForChoice from './icons/SpoiltForChoice';
import StreakMaster from './icons/StreakMaster';
import StreakProdigy from './icons/StreakProdigy';
import WordBuilder from './icons/WordBuilder';
import WordCollector from './icons/WordCollector';
import WordSmith from './icons/WordSmith';
import Hamburger from './icons/Hamburger';
import Exercises from './icons/Exercises';
import Goals from './icons/Goals';
import MilestoneNew from './icons/MilestoneNew';
import FeedbackLow from './icons/FeedbackLow';
import FeedbackMedium from './icons/FeedbackMedium';
import FeedbackHigh from './icons/FeedbackHigh';
import Edit from './icons/Edit';
import Trash from './icons/Trash';
import Lens from './icons/Lens';

export const IconMap = {
  tick: Tick,
  next: Next,
  back: Back,
  questionMark: QuestionMark,
  bookmarkSaved: BookmarkSaved,
  eye: Eye,
  profile: Profile,
  lock: Lock,
  note: Note,
  like: Like,
  cup: Cup,
  tryAgain: TryAgain,
  milestone: Milestone,
  book: Book,
  learningAndCoachingTime: LearningAndCoachingTime,
  report: Report,
  device: Device,
  search: Search,
  chevronDown: ChevronDown,
  speaker: Speaker,
  byYourSelf: ByYourSelf,
  withCoach: WithCoach,
  openedBook: OpenedBook,
  arrowIncrease: ArrowIncrease,
  smile: Smile,
  accolade: Accolade,
  copy: Copy,
  userWithArrow: UserWithArrow,
  calendar: Calendar,
  check: Check,
  cross: Cross,
  smallTick: SmallTick,
  profileEye: ProfileEye,
  logout: Logout,
  alert: Alert,
  plus: Plus,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  dashedLine: DashedLine,
  curvedDashedLine: CurvedDashedLine,
  curvedDashedLineBackground: CurvedDashedLineBackground,
  arrowLine: ArrowLine,
  arrowLineUp: ArrowLineUp,
  phone: Phone,
  achievedPersonalGoal: AchievePersonalGoal,
  attendedFiveSessions: AttendFiveSessions,
  bullseye: Bullseye,
  completedFirstReadingBook: CompletedFirstReadingBook,
  dropTheMic: DropTheMic,
  endOfM1: EndOfM1,
  endOfM2: EndOfM2,
  endOfM3: EndOfM3,
  endOfM4: EndOfM4,
  endOfM5: EndOfM5,
  firstStep: FirstStep,
  listenAndRepeat: ListenAndRepeat,
  makingProgress: MakingProgress,
  masterInTheMaking: MasterInTheMaking,
  spoiltForChoice: SpoiltForChoice,
  streakMaster: StreakMaster,
  streakProdigy: StreakProdigy,
  wordBuilder: WordBuilder,
  WORD_COLLECTOR: WordCollector,
  wordSmith: WordSmith,
  hamburger: Hamburger,
  exercises: Exercises,
  goals: Goals,
  milestoneNew: MilestoneNew,
  feedbackLow: FeedbackLow,
  feedbackMedium: FeedbackMedium,
  feedbackHigh: FeedbackHigh,
  edit: Edit,
  trash: Trash,
  lens: Lens,
};

const Parent = styled.div`
  ${setMargin}
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'flex-start'};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'unset')};
`;

const Icon = (props) => {
  const theme = useTheme();
  const { icon, color, text, weight = 'bold', pointer, disabled } = props;

  if (!IconMap[icon]) {
    // eslint-disable-next-line no-console
    console.warn(`<Icon /> called with invalid icon prop "${icon}"`);
    return null;
  }

  const StyledIcon = IconMap[icon];

  return (
    <Parent
      {...props}
      onClick={props.onClick}
      pointer={pointer || props.onClick}
    >
      <StyledIcon
        {...props}
        color={
          disabled
            ? theme.colors.neutralMain
            : theme.colors[color] || color || 'currentColor'
        }
        aria-disabled={disabled ? 'true' : 'false'}
      />
      {text && (
        <T.P weight={weight} ml="1" color={color}>
          {text}
        </T.P>
      )}
    </Parent>
  );
};

export default Icon;
