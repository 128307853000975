import Recipes from './Recipes';
import Getbettamol1 from './Getbettamol1';
import Getbettamol2 from './Getbettamol2';
import Phobia1 from './Phobia1';
import Phobia2 from './Phobia2';
import Letter1 from './Letter1';
import Letter2 from './Letter2';
import SimpleRisotto1 from './SimpleRisotto1';
import SimpleRisotto2 from './SimpleRisotto2';
import JobProfile from './JobProfile';
import DogsOfWar from './DogsOfWar';
import ProgressCheck15 from './ProgressCheck15';
import { exerciseTypes } from './../../../../constants';

import Question from './Question';
import { PlayButton } from '../../../../components/Button';

import * as S from './style';

const decideBgColor = (exKey) => {
  if (exKey === 'M5_P170') {
    return 'neutralLight';
  }
  return ['M5_P164&166', 'M5_P174'].includes(exKey) && 'white';
};

const handleBottomPadding = ({ type, exKey }) => {
  if (exKey === 'M5_P174') {
    return '4';
  }

  if (type === exerciseTypes.SELECT_RECIPES) {
    return '0';
  }
};

const Content = ({ exKey, ...props }) => {
  switch (exKey) {
    case 'M5_P164&166':
      return <Recipes {...props} />;

    case 'M5_P168':
      return <Getbettamol1 {...props} />;

    case 'M5_P170':
      return <Getbettamol2 {...props} />;

    case 'M5_P172':
      return <Phobia1 {...props} />;

    case 'M5_P174':
      return <Phobia2 {...props} />;

    case 'M5_P176':
      return <Letter1 {...props} />;

    case 'M5_P178':
      return <Letter2 {...props} />;

    case 'M5_P180':
      return <SimpleRisotto1 {...props} />;

    case 'M5_P182':
      return <SimpleRisotto2 {...props} />;

    case 'M5_P184':
      return <JobProfile {...props} />;

    case 'M5_P188&190':
      return <DogsOfWar {...props} />;

    default:
      break;
  }
};

const Comprehensions = ({ exKey, data, type, isCheck, ...props }) => {
  const bgColor = decideBgColor(exKey);
  return (
    <S.Container>
      {data.textAudio && (
        <PlayButton
          weight="regular"
          audioUrl={data.textAudioUrl}
          iconProps={{ width: '32px', height: '32px' }}
          mx={5}
          mb={5}
          mt={1}
        >
          {data.textAudio}
        </PlayButton>
      )}
      <S.ComprehensionWrapper
        bgColor={bgColor}
        pb={handleBottomPadding({ exKey, type })}
      >
        {isCheck ? (
          <ProgressCheck15 {...props} />
        ) : (
          <Content exKey={exKey} data={data} type={type} {...props} />
        )}
      </S.ComprehensionWrapper>
      <Question
        type={type}
        data={data}
        bgColor={bgColor}
        exKey={exKey}
        isCheck={isCheck}
        {...props}
      />
    </S.Container>
  );
};

export default Comprehensions;
