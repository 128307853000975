import { fields, createSchema, validate as _validate } from '..';

const { firstName, lastName, email, requiredText } = fields;

const schema = createSchema({
  firstName,
  lastName,
  email,
  availabilityHoursPerWeek: requiredText,
  bestDayTime: requiredText,
});

const validate = (data) => _validate(schema, data);

export default validate;
