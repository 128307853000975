import { useNavigate } from 'react-router-dom';
import { GroupCheckbox } from '../../../components/Inputs';
import { Col, Row } from '../../../components/Grid';
import { COACH } from '../../../constants/nav-routes';
import { BasicButton } from '../../../components/Button';
import * as T from '../../../components/Typography';

const checklist = [
  {
    label: 'Watch video on how to use the tool',
    checked: true,
    font: 'regular',
  },
  {
    label:
      'Watch the onboarding demo to understand how to onboard a learner and familarise yourself with the app',
    checked: true,
    font: 'regular',
  },
  {
    label:
      'Complete your online training session (you will need your Shannon Trust Moodle account to do this).',
    checked: true,
    font: 'regular',
  },
];

const Onboarding2 = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(COACH.ONBOARDING_3);
  };
  return (
    <>
      <Row>
        <Col w={[4, 8, 8]}>
          <T.H2 color="neutralMain">Your Training</T.H2>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} mt="3">
          <T.P color="neutralMain">
            Before we can give you a learner there is some initial training you
            need to do. Check your dashboard and tick it off as you go.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 8, 8]} mt="4" jc="center">
          <GroupCheckbox
            noCheckbox
            onClickToRedirect
            state={checklist}
            font="regular"
          />
        </Col>
      </Row>
      <Row mt="6" ai="end" style={{ flex: 1 }}>
        <Col w={[4, 8, 8]}>
          <BasicButton
            maxWidth="426px"
            variant="primary"
            handleClick={handleClick}
          >
            Continue
          </BasicButton>
        </Col>
      </Row>
    </>
  );
};

export default Onboarding2;
