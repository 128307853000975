import styled from '@emotion/styled';
import Image from '../Image';
import Icon from '../Icon';
import { Link } from 'react-router-dom';

export const StyledImage = styled(Image)`
  position: absolute;
  pointer-events: none;
  width: 100%;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${({ theme }) => `${theme.spacings[6]} 46px`};
  width: 100%;
  white-space: normal;
  z-index: 1;
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  right: 6px;
  top: 8px;
`;

const getStyledLinkBg = (exerciseComplete, disabled, theme) => {
  if (exerciseComplete) return theme.colors.neutralLight;
  if (disabled) return theme.colors.neutralLight90;
  return 'white';
};

export const StyledLink = styled(Link)`
  background-color: ${({ theme, exerciseComplete, disabled }) =>
    getStyledLinkBg(exerciseComplete, disabled, theme)};
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
  /* for disabled style */
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 184px;
  white-space: nowrap;
  width: 300px;
`;

export const MilestoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.primaryMain};
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
  padding: ${({ theme }) => ` ${theme.spacings[6]} ${theme.spacings[5]} 36px`};
  white-space: nowrap;
  min-width: 300px;
  height: 150px;
`;
