import { useEffect, useState } from 'react';
import { AudioOut } from '../../../../components/WordButton';
import { Row, Col } from '../../../../components/Grid';
import { UseExerciseWithSelfLearning } from '../../../../Hooks';

const DisplayMultipleWords = ({
  wordsArray,
  setEnableNext,
  needsRowFormat,
}) => {
  const { setSubProgress } = UseExerciseWithSelfLearning();
  const formattedWordsArray = wordsArray.map((el, idx) => ({ ...el, id: idx }));

  const [itemsPlayed, setItemsPlayed] = useState({});

  const handleClick = (el) => {
    if (!el.isPlayed) {
      el.isPlayed = true;
      setItemsPlayed((itemsPlayed) => ({ ...itemsPlayed, [el.id]: true }));
    }

    return;
  };
  const _itemsPlayed = Object.keys(itemsPlayed);

  useEffect(() => {
    const allSubStepsPlayed = _itemsPlayed.length === wordsArray.length;
    if (allSubStepsPlayed) {
      setEnableNext(true);
    }
    setSubProgress(_itemsPlayed.length / wordsArray.length);
  }, [_itemsPlayed.length, setEnableNext, setSubProgress, wordsArray.length]);
  const colWidths = needsRowFormat ? [4, 12, 12] : [4, 5, 5];
  return (
    <Row mt="3" jc={'center'}>
      {formattedWordsArray.map((el, i) => (
        <Col jc="center" w={colWidths} key={`col-${el?.option}-${i}`}>
          <AudioOut
            audioUrl={el?.audioUrl}
            handleClick={() => handleClick(el)}
            text={el?.option}
            mb={2}
            key={`div-${el?.option}-${i}`}
            needsRowFormat={needsRowFormat}
          />
        </Col>
      ))}
    </Row>
  );
};

export default DisplayMultipleWords;
