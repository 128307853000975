import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacings[6]};
  @media (max-width: 345px) {
    padding-right: 32px;
  }
`;

export const StatsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${({ theme }) => theme.media.mobile} {
    width: 102vw;
    margin-left: -9.5%;
  }
`;

export const PlayButtonWrapper = styled.div`
  ${setMargin}
  display: flex;
  align-items: center;
`;
