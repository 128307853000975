import { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography as T, Button } from '../../../components';
import { Row, Col } from '../../../components/Grid';
import { audio } from '../../../constants';
import { BasicButton } from '../../../components/Button';
import { LEARNER } from '../../../constants/nav-routes';
import { keyMilestones } from '../../../constants/data-types';
import { useGetStepsByMilestoneId } from '../../../api-calls/progressChecks.queries';
import { getMediaUrl } from '../../../helpers';
import { useGeneralState } from '../../../context/general-state';

import { BasicInput } from '../../../components/Inputs';
import { coachCode as validate } from '../../../validation/schemas';
import * as S from './style';

const CoachCode = () => {
  const {
    state: { milestoneId, code: coachCode },
  } = useLocation();
  const { state: generalState } = useGeneralState();

  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [validationErrs, setValidationErrs] = useState({});
  const [isCodeIncorrect, setIsCodeIncorrect] = useState(false);
  const submitAttempt = useRef(false);
  const {
    steps,
    error: getStepsError,
    isLoading: getStepsLoading,
  } = useGetStepsByMilestoneId({ milestoneId });
  const validateCode = () => {
    try {
      validate({ code });

      setValidationErrs({ hasError: false });
      if (coachCode !== Number(code)) {
        setIsCodeIncorrect(true);
        return false;
      } else {
        setIsCodeIncorrect(false);
        return true;
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        setValidationErrs({ ...error.inner, hasError: true });
      }

      return false;
    }
  };
  const handleNext = () => {
    submitAttempt.current = true;
    const isValid = validateCode();
    if (isValid) {
      if (keyMilestones.includes(milestoneId)) {
        navigate(
          LEARNER.PROGRESS_PROFILE_QUESTIONS.replace(':id', 1).replace(
            ':milestoneId',
            milestoneId
          )
        );
      } else {
        navigate(
          LEARNER.PROGRESS_CHECK_QUESTIONS.replace(':stepId', 1).replace(
            ':milestoneId',
            milestoneId
          ),
          { state: { steps } }
        );
      }
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <>
      <S.Wrapper>
        <Row jc="center">
          <Col w={[4, 10, 9]}>
            <S.PlayButtonWrapper>
              <Button.PlayButton
                width="56px"
                height="56px"
                audioUrl={getMediaUrl(
                  keyMilestones.includes(milestoneId)
                    ? audio.letsSeeHowYoureCurrentlyFeelingAndHowMuchProgressYouveMade
                    : audio.letSDoAQuickRefresherToSeeHowMuchProgressWeveMade,
                  true,
                  generalState?.preferredVoice
                )}
                iconProps={{ width: 27.2 }}
              />

              <T.H2 ml="3" weight="regular">
                {keyMilestones.includes(milestoneId)
                  ? 'Let’s see how you’re currently feeling and how much progress you’ve made. You will need to be with your coach to do this. Please start by getting your coach to input their code below.'
                  : 'Let’s do a quick refresher to see how much progress we’ve made. You will need to be with your coach to do this. Please start by getting your coach to input their code below.'}
              </T.H2>
            </S.PlayButtonWrapper>
          </Col>
        </Row>
        <Row style={{ maxWidth: '600px' }} jc="center">
          <Col w={[4, 10, 9]} mt="6">
            <BasicInput
              label="Input code"
              placeholder="e.g 85"
              value={code}
              name="coach-code"
              handleChange={setCode}
              error={validationErrs.code}
            />
          </Col>
        </Row>
        {(isCodeIncorrect || getStepsError) && (
          <Row jc="center">
            <Col w={[4, 10, 9]} mt="6">
              <T.P color="error">
                {getStepsError.message || 'Incorrect code,please try again'}
              </T.P>
            </Col>
          </Row>
        )}
      </S.Wrapper>
      <S.NextButtonWrapper mt="6" ai="end">
        <Col w={[4, 10, 9]}>
          <BasicButton
            variant="primary"
            icon="next"
            iconProps={{ width: '24px', height: '24px' }}
            handleClick={handleNext}
            disabled={validationErrs.hasError || isCodeIncorrect}
            loading={getStepsLoading}
          />
        </Col>
      </S.NextButtonWrapper>
    </>
  );
};

export default CoachCode;
