import styled from '@emotion/styled';
import * as T from '../../../components/Typography';

export const StyledFindOut = styled(T.P)`
  flex: 1;
  align-items: flex-end;
  display: flex;
  justify-content: center;
`;

export const VideoCard = styled.div`
  margin-top: 8px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

export const ImageCard = styled.div`
  margin-top: 8px;
  width: 100%;
  overflow: hidden;
  background-image: ${({ bg }) => `url(${bg})`};
  min-height: 156px;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
`;

export const ResponsiveImg = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 100%;
  border-radius: 10px;
`;
