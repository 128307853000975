import { fields, createSchema, validate as _validate } from '..';

const {
  firstName,
  lastName,
  optionalEmail,
  requiredText,
  birthDate,
  requiredBoolean,
  optionalText,
  email,
} = fields;

const preSchema = createSchema({
  firstName,
  lastName,
  involvedBefore: requiredText,
  hasDeviceInternetAccess: requiredText,
  preReleased: requiredBoolean,
  nomisId: optionalText,
  delius: optionalText,
  probationOfficerName: requiredText,
  probationOfficerEmail: email,
  email: optionalEmail,
  availability: optionalText,
  additionalInfo: requiredText,
  dateOfBirth: birthDate,
  requiredCoachGender: optionalText,
  additionalNotes: optionalText,
  referralType: requiredText,
});
const postSchema = createSchema({
  firstName,
  lastName,
  involvedBefore: requiredText,
  hasDeviceInternetAccess: requiredText,
  preReleased: requiredBoolean,
  nomisId: optionalText,
  delius: optionalText,
  probationOfficerName: requiredText,
  probationOfficerEmail: email,
  email: optionalEmail,
  availability: optionalText,
  additionalInfo: requiredText,
  dateOfBirth: birthDate,
  //
  requiredCoachGender: optionalText,
  additionalNotes: optionalText,
  referralType: requiredText,
});

const validate = (data) => {
  return _validate(data.preReleased ? preSchema : postSchema, data);
};

export default validate;
