import Autocomplete from '@mui/material/Autocomplete';
import * as S from './style.js';
import * as CS from './../style';
import * as T from '../../Typography';
import Icon from '../../Icon';

const NewDropDown = ({
  handleChange,
  label,
  color,
  placeholder = 'Select...',
  error,
  helper,
  w,
  disabled,
  options = [],
  selected,
  multi,
  m,
  search,
  optional,
}) => {
  const open = false;
  const focus = false;
  const decideColor = () => {
    if (error) return 'error';
    return color;
  };
  return (
    <S.Field
      w={w}
      disabled={disabled}
      open={open}
      focus={focus}
      multi={multi}
      color={decideColor()}
      error={error}
      search={search}
      {...m}
    >
      {' '}
      {label && (
        <CS.Label htmlFor={label} mb={helper ? '1' : '2'}>
          <T.P weight="bold" mb={helper ? '0' : '4'}>
            {label}
          </T.P>
          {optional && <CS.OptionalTag ml="1">(optional)</CS.OptionalTag>}
        </CS.Label>
      )}
      {helper && (
        <T.P color="neutralMain" mb="4" ml={2}>
          {helper}
        </T.P>
      )}
      <S.Answer>
        <Autocomplete
          getOptionLabel={(option) => option?.label || option}
          options={options}
          value={selected || []}
          disabled={disabled}
          onChange={(op, val) => {
            handleChange(multi ? val.map((e) => e?.value) : val?.value);
          }}
          search={search}
          fullWidth
          disablePortal
          id={`combo-box-demo${label}`}
          groupBy={(option) => option.group}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <S.StyledField
              {...params}
              multiline={true}
              rows={1.22}
              fullWidth
              hiddenLabel
              placeholder={
                (multi && selected?.length === 0) || !selected
                  ? placeholder
                  : ''
              }
              label={`hidden-label ${label}`}
              focused
              InputLabelProps={{
                style: {
                  clip: 'rect(0 0 0 0)',
                  clipPath: 'inset(50%)',
                  height: 1,
                  overflow: 'hidden',
                  position: 'absolute',
                  whiteSpace: 'nowrap',
                  width: 1,
                },
              }}
            />
          )}
          multiple={multi}
          popupIcon={
            search ? (
              <S.StyledIcon
                icon="search"
                color="black"
                width="20"
                height="22"
              />
            ) : (
              <Icon
                icon="chevronDown"
                color="neutralMain"
                width="20"
                height="22"
              />
            )
          }
        />{' '}
      </S.Answer>
      {error && (
        <T.P color="error" m="0" mt="1">
          {error}
        </T.P>
      )}
    </S.Field>
  );
};

export default NewDropDown;
