import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: -58px;
`;

export const Circle = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: ${({ theme, overlayColor }) =>
    !overlayColor || overlayColor === 'default'
      ? 'white'
      : theme.colors[overlayColor]};
  border: ${({ theme }) => `4px solid ${theme.colors.neutralMain}`};
  position: absolute;
  top: 190px;
  z-index: 5;
`;
export const Line = styled.svg`
  border: ${({ theme }) => `3px solid ${theme.colors.neutralMain}`};
  height: 56px;
  width: 0;
`;
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 70%;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  span {
    color: ${({ theme }) => theme.colors['neutralMain']};
    font-weight: bold;
    font-size: 1rem;
  }
`;

export const CurvedLineWrapper = styled.div`
  transform: translateY(-10px);
  ${({ theme }) => theme.media.mobile} {
    transform: translateY(0px);
  }
`;
