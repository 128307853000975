import styled from '@emotion/styled';
import * as T from '../../../components/Typography';

export const Wrapper = styled.div`
  max-width: 635px;
`;

export const P = styled(T.P)`
  line-height: 28px !important;
`;

export const StartCoachingWrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.neutralMain};
  background: ${({ theme }) => theme.colors.neutralLight};
  border: none;
  border-radius: 16px;

  padding: ${({ theme }) => `${theme.spacings[3]}`};

  box-shadow: 0px 2px 5px -1px rgba(4, 13, 38, 0.11);

  /* clicking style */
  :active {
    opacity: 0.9;
    transform: translateY(1px) scale(0.99);
  }
`;

export const OpacityDiv = styled.div`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
`;

export const Circle = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ theme }) => `10px solid ${theme.colors.tertiaryMain}`};
  background-color: ${({ theme }) => theme.colors.neutralMain};
`;

export const SideDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 16.5%;
  position: absolute;
  top: 100px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ActiveStatusSpan = styled.span`
  background-color: ${({ theme }) => theme.colors.neutralMain};
  color: ${({ theme, activeStatus }) =>
    activeStatus ? theme.colors.irlenGreen : theme.colors.irlenRed};
  padding: 0.2rem;
`;
