import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/auth';
import { navRoutes } from '../../../constants';
import { Col, Row } from '../../../components/Grid';
import { Coaches } from '../../../api-calls';
import { COACH } from '../../../constants/nav-routes';
import { BasicButton } from '../../../components/Button';
import * as T from '../../../components/Typography';
import { Facilitators } from '../../../api-calls';

const Onboarding6 = () => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const [facilitator, setFacilitator] = useState({});
  const [loading, setLoading] = useState(false);
  const coachId = user.id;
  const completedTrainingAt = new Date();

  if (user.id && (!user.bestDayTime || !user.availabilityHoursPerWeek)) {
    navigate(navRoutes.COACH.ONBOARDING_5, { replace: true });
  }

  useEffect(() => {
    const getFacilitator = async () => {
      const { data } = await Facilitators.getFacilitatorById({
        facilitatorId: user.facilitatorId,
      });

      if (data) setFacilitator(data);
    };

    getFacilitator();
  }, [user.facilitatorId]);

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    await Coaches.updateCoach({
      coachId,
      completedTrainingAt,
    });
    setUser({ ...user, completedTrainingAt });
    setLoading(false);
    navigate(COACH.DASHBOARD);
  };

  return (
    <>
      <Row>
        <Col w={[4, 7, 7]}>
          <T.H2 color="neutralMain">Getting support</T.H2>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 7, 7]} mt="3">
          <T.P color="neutralMain">
            If you need help at any time, you can reach out to your Shannon
            Trust manager
          </T.P>
        </Col>
      </Row>

      <Row>
        <Col w={[4, 7, 7]} mt="5">
          <T.H3 color="neutralMain">Manager details</T.H3>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 7, 7]} mt="3">
          <T.P color="neutralMain">
            {facilitator.firstName} {facilitator.lastName}
          </T.P>
        </Col>
        <Col w={[4, 7, 7]}>
          <T.Link
            color="neutral90"
            mt={1}
            to={`mailto:${facilitator.email}`}
            external
          >
            {facilitator.email}
          </T.Link>
        </Col>
      </Row>
      <Row mt="6" ai="end" style={{ flex: 1 }}>
        <Col w={[4, 7, 7]}>
          <BasicButton
            variant="primary"
            maxWidth="426px"
            handleClick={handleClick}
            loading={loading}
          >
            Continue
          </BasicButton>
        </Col>
      </Row>
    </>
  );
};

export default Onboarding6;
