import styled from '@emotion/styled';

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  flex: ${({ flex }) => flex || 0};
  display: flex;
  flex-direction: column;
  align-items: end;
  flex: 1;
  padding: 0;
  ${({ theme }) => theme.media.mobile} {
    padding: ${({ theme }) => `0 ${theme.spacings[6]}`};
  }
`;
