import theme from '../../../theme';

const Eye = ({ width, height, color, ...props }) => (
  <svg
    width={width || '25'}
    height={height || '25'}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 8C8.86765 8 5.62331 10.4831 4.03809 14.3086C3.9873 14.4311 3.9873 14.5689 4.03809 14.6914C5.62331 18.5169 8.86765 21 12.5 21C16.1324 21 19.3767 18.5169 20.9619 14.6914C21.0127 14.5689 21.0127 14.4311 20.9619 14.3086C19.3767 10.4831 16.1324 8 12.5 8ZM10 14.5C10 15.8807 11.1193 17 12.5 17C13.8807 17 15 15.8807 15 14.5C15 13.1193 13.8807 12 12.5 12C11.1193 12 10 13.1193 10 14.5Z"
      fill={color || theme.colors.neutralMain}
    />
    <path
      d="M12.5 16C13.3284 16 14 15.3284 14 14.5C14 13.6716 13.3284 13 12.5 13C11.6716 13 11 13.6716 11 14.5C11 15.3284 11.6716 16 12.5 16Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Eye;
