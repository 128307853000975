import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  ${setMargin};
  background: ${({ theme, color }) => theme.colors[color] || color};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacings[1]} ${theme.spacings[4]}`};
  border-radius: ${({ theme }) => theme.borders.tagRadius};
  width: fit-content;
`;

export const TitleContainer = styled(Link)`
  ${setMargin};
  display: flex;
`;
