import React from 'react';
import PropTypes from 'prop-types';

import General from './General';
import Side from './Side';
import Full from './Full';
import FullScreenEx from './FullScreenEx';
import FullScreenComprehension from './FullScreenComprehension';

const CMSLayout = ({ children, ...props }) => {
  return <div>{children}</div>;
};

const Layout = ({ layout, ...props }) => {
  switch (layout) {
    case 'CMS':
      return <CMSLayout {...props} />;
    case 'side':
      return <Side {...props} />;
    case 'full':
      return <Full {...props} />;
    case 'fullScreenEx':
      return <FullScreenEx {...props} />;
    case 'fullScreenComprehension':
      return <FullScreenComprehension {...props} />;
    case 'general':
    default:
      return <General {...props} />;
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export { General, Side, Full, FullScreenEx, FullScreenComprehension };
export default Layout;
