import { Route, Routes } from 'react-router-dom';
import { Route as CustomRoute } from './../../components';

import AddCoach from './AddCoach';
import CoachProfile from './CoachProfile';
import Dashboard from './Dashboard';
import Coaches from './Coaches';
import AssignCoach from './AssignCoach';
import InterestForm from '../../pages/InterestForm';
import {
  ManageInterviewOrReject,
  ApproveCoach,
  CoachInviteLink,
} from './ManageCoachStatus';

import LearnerReferral from './LearnerReferral';
import LearnerProfile from './LearnerProfile';
import Learners from './Learners';

import ViewAllNotes from './CoachProfile/ViewAllNotes';
import EditNote from './CoachProfile/EditNote';
import LearnerMilestonesReached from '../Coach/LearnerMilestonesReached';
import { navRoutes, userRoles } from './../../constants';

const { BASE } = navRoutes.FACILITATOR;
function FacilitatorRouter() {
  return (
    <Routes>
      <Route
        path={navRoutes.FACILITATOR.LEARNER_REFERRAL.replace(BASE, '')}
        element={
          <CustomRoute
            Component={LearnerReferral}
            layout="side"
            sideColor="neutralLight"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR]}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.EDIT_NOTE.replace(BASE, '')}
        element={
          <CustomRoute
            Component={EditNote}
            layout="full"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR]}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.COACH_NOTES.replace(BASE, '')}
        element={
          <CustomRoute
            Component={ViewAllNotes}
            layout="full"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR]}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.COACH_PROFILE.replace(BASE, '')}
        element={
          <CustomRoute
            Component={CoachProfile}
            layout="side"
            sideColor="neutralLight"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR]}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.ADD_COACH.replace(BASE, '')}
        element={
          <CustomRoute
            Component={AddCoach}
            layout="side"
            sideColor="neutralLight"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR]}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.APPROVE_COACH.replace(BASE, '')}
        element={
          <CustomRoute
            Component={ApproveCoach}
            layout="side"
            sideColor="neutralLight"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR]}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.COACH_INVITE_LINK.replace(BASE, '')}
        element={
          <CustomRoute
            Component={CoachInviteLink}
            layout="side"
            sideColor="neutralLight"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR]}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.MANAGE_COACH_APPLICATION.replace(BASE, '')}
        element={
          <CustomRoute
            Component={ManageInterviewOrReject}
            layout="side"
            sideColor="neutralLight"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR]}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.ADD_LEARNER.replace(BASE, '')}
        element={
          <CustomRoute
            Component={LearnerReferral}
            layout="side"
            sideColor="neutralLight"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR]}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.LEARNER.replace(BASE, '')}
        element={
          <CustomRoute
            Component={LearnerProfile}
            layout="side"
            sideColor="neutralLight"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR]}
            sideChildren={<LearnerProfile.LearnerId />}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.DASHBOARD.replace(BASE, '')}
        element={
          <CustomRoute
            Component={Dashboard}
            layout="full"
            overlayColor
            isPrivate
            allowedRoles={[userRoles.FACILITATOR]}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.COACHES.replace(BASE, '')}
        element={
          <CustomRoute
            Component={Coaches}
            isPrivate
            layout="full"
            overlayColor
            allowedRoles={[userRoles.FACILITATOR]}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.LEARNERS.replace(BASE, '')}
        element={
          <CustomRoute
            Component={Learners}
            isPrivate
            layout="full"
            overlayColor
            allowedRoles={[userRoles.FACILITATOR]}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.ASSIGN_COACH.replace(BASE, '')}
        element={
          <CustomRoute
            Component={AssignCoach}
            isPrivate
            sideColor="neutralMain"
            layout="full"
            overlayColor
            allowedRoles={[userRoles.FACILITATOR]}
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.COACH_DETAILS.replace(BASE, '')}
        element={
          <CustomRoute
            Component={InterestForm}
            isPrivate
            layout="side"
            overlayColor
            allowedRoles={[userRoles.FACILITATOR]}
            facilitatorView
          />
        }
      />
      <Route
        path={navRoutes.FACILITATOR.LEARNER_REACHED_MILESTONES.replace(
          BASE,
          ''
        )}
        element={
          <CustomRoute
            Component={LearnerMilestonesReached}
            layout="side"
            sideColor="neutralLight"
            isPrivate
            allowedRoles={[userRoles.FACILITATOR, userRoles.COACH]}
          />
        }
      />
    </Routes>
  );
}

export default FacilitatorRouter;
