import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const ResponsiveYoutube = styled.div`
  ${setMargin};
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 100%;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;
