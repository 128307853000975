import { useRef, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router';
import validate from '../../validation/schemas/coach-interest';
import { GENERAL } from '../../constants/nav-routes';
import Form from './Form';
import { useAddCoaches } from '../../api-calls/coaches.queries';
import { Coaches } from '../../api-calls';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import { cleanEmail } from '../../helpers';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }
  return { ...state, ...value };
}

const InterestForm = ({ facilitatorView }) => {
  const initialState = {
    form: {
      coachingLocations: [],
      readRoleDescription: '',
      canOfferNonJudgementalApproach: '',
      ableToGiveRegularCommitment: '',
      inTheRightLocation: '',
      hasStrongCommunicationSkills: '',
      ableToPlanAndManageTime: '',
      ableToKeepUpToDate: '',
      hasInternetAccess: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postcode: '',
      volunteeringReason: '',
      availabilityHoursPerMonth: '',
      skillsDescription: '',
      hasDrivingLicence: '',
      hasCar: '',
      hasComputerAccess: '',
      accessRequirements: '',
      hasPreviousConvictions: '',
      hasCourtAppearances: '',
      criminalRecordDetails: '',
      // genders: [],
      // ageGroup: [],
      // ethnicOrigin: '',
      // heardOfBy: '',
      trueAndCompleteInfo: facilitatorView ? true : '',
      giveConsent: facilitatorView ? true : '',
      // gendersOther: '',
      // ethnicOriginOther: '',
    },
    httpError: '',
    validationErrs: {},
  };
  const { user } = useAuth();
  const [state, setState] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { coachId } = useParams();
  const submitAttempt = useRef(false);
  const { mutateAsync, error: httpError, isLoading } = useAddCoaches();
  const { validationErrs, form } = state;

  const setFormData = (data) =>
    setState((prevState) => ({
      form: { ...prevState.form, ...data },
    }));

  const validateForm = () => {
    try {
      validate({
        ...state.form,
        // genders: form.gendersOther
        //   ? [...form.genders, form.gendersOther]
        //   : form.genders,
        // ethnicOrigin: form.ethnicOriginOther || form.ethnicOrigin,
      });
      setState({ validationErrs: { hasError: false }, httpError: false });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();

    if (isValid) {
      try {
        await mutateAsync({
          ...state.form,
          email: cleanEmail(state.form.email),
          // genders: form.gendersOther
          //   ? [...form.genders, form.gendersOther]
          //   : form.genders,
          // ethnicOrigin: form.ethnicOriginOther || form.ethnicOrigin,
        });
        navigate(GENERAL.INTEREST_THANK_YOU);
      } catch (error) {
        setState({
          httpError: error?.response?.data?.message,
          validationErrs:
            error?.response?.data?.data?.field === 'email'
              ? {
                  ...validationErrs,
                  email: error?.response?.data?.message,
                }
              : { ...validationErrs },
        });
      }
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const getCoach = async () => {
    const { data } = await Coaches.getCoachDetailsById({
      coachId,
    });
    setFormData(data);
  };

  useEffect(() => {
    if (user.role !== 'FACILITATOR') return;
    getCoach();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachId]);

  return (
    <Form
      setFormData={setFormData}
      form={state.form}
      validationErrs={validationErrs}
      httpError={httpError?.response?.data?.message}
      loading={isLoading}
      handleSubmit={handleSubmit}
      userRole={user.role}
    />
  );
};

export default InterestForm;
