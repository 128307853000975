import { EyeOutlined } from '@ant-design/icons';
import { navRoutes as NR, coachApprovalStatuses } from '../../../constants';
import * as S from './style';

import * as T from '../../../components/Typography';
const NameColumn = ({ name, id, statusType, approvalStatus }) => (
  <S.NameColumnWrapper>
    <T.P>{name}</T.P>
    <T.Link
      to={NR.FACILITATOR.COACH_PROFILE.replace(
        ':statusType',
        statusType
      ).replace(':coachId', id)}
    >
      <S.Button type="text" icon={<EyeOutlined />}>
        {approvalStatus === coachApprovalStatuses.APPROVED
          ? 'View more'
          : 'Review'}
      </S.Button>
    </T.Link>
  </S.NameColumnWrapper>
);

export default NameColumn;
