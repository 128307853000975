export const EXERCISES_BASE = '/exercises';
export const LEARNERS_BASE = '/learners';
export const LEARNERS_GOALS_BASE = '/learner-personal-goals';
export const BADGES_BASE = '/badges';
export const COACHES_BASE = '/coaches';
export const MEDIA_BASE = '/media';
export const FACILITATORS_BASE = '/facilitators';
export const LEARNING_SESSIONS_BASE = '/learning-sessions';
export const PROGRESS_CHECKS_BASE = '/progress-checks';
export const MILESTONES_BASE = '/milestones';
export const USERS_BASE = '/users';
