import { useState } from 'react';
import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import Markdown from '../../../../components/Markdown';
import { createH1 } from '../../../../utils/markdown';
import DisplayMultipleWords from './DisplayMultipleWords';
import StepFooterButtons from '../StepFooterButtons';

import * as S from './style';

const Step = ({
  data: {
    title,
    subtitle,
    sentence,
    textAudio,
    textAudioUrl,
    options = [],
  } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  selfLearning,
}) => {
  const [enableNext, setEnableNext] = useState(false);

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {textAudio && textAudioUrl ? (
          <S.TextAudioWrapper mt={1} mb={5}>
            <PlayButton
              audioUrl={textAudioUrl}
              width="56px"
              height="56px"
              iconProps={{ width: '32px', height: '32px' }}
            />
            <T.P ml={4} weight="regular" size="med">
              {textAudio}
            </T.P>
          </S.TextAudioWrapper>
        ) : null}
        {title ? (
          <Markdown
            text={createH1(title)}
            customStyles={{
              h1: {
                size: 'extraLarge',
                ta: 'center',
                weight: 'regular',
                mb: 2,
              },
            }}
          />
        ) : null}

        {sentence ? (
          <div style={{ alignSelf: 'center' }}>
            <Markdown
              text={sentence.length < 20 ? createH1(sentence) : sentence}
              customStyles={{
                pre: {
                  size: 'med',
                  weight: 'regular',
                  mt: 5,
                },
                p: {
                  size: 'large',
                  weight: 'regular',
                  mt: 5,
                  lh: '150%',
                },
                h1: {
                  size: 'large',
                  ta: 'center',
                  weight: 'regular',
                  mt: 5,
                },
              }}
            />
          </div>
        ) : null}
        {subtitle ? (
          <Markdown
            text={subtitle}
            customStyles={{
              p: { size: 'med', ta: 'center', mb: 4 },
            }}
          />
        ) : null}

        <DisplayMultipleWords
          hidden
          wordsArray={options}
          setEnableNext={setEnableNext}
        />
      </S.ContentWrapper>
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={enableNext}
        selfLearning={selfLearning}
      />
    </S.Wrapper>
  );
};

export default Step;
