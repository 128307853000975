import { useRef, useReducer, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as S from './style';
import Icon from '../../../components/Icon';
import LearnerStats from '../../../components/LearnerStats';
import { coachStatusesQuery } from '../../../constants';

import { Col, Row } from '../../../components/Grid';
import { Dropdown, DatePicker } from '../../../components/Inputs';
import * as T from '../../../components/Typography';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import useCsvDownload from './../../../Hooks/useCsvDownload';
import { BasicButton } from '../../../components/Button';

import { Learners } from '../../../api-calls';
import { useDeleteUser } from '../../../api-calls/users.queries';
import { useUpdateActiveStatus } from '../../../api-calls/learners.queries';
import InfoCard from '../../../components/InfoCard';

import { FACILITATOR } from '../../../constants/nav-routes';
import {
  preferredContactWaysDropDown,
  sessionPreferencesDropDown,
  userRoles,
} from '../../../constants';
import LearnerId from './LearnerId';
import { useGeneralState } from '../../../context/general-state';

import moment from 'moment';

const initialState = {
  form: {
    controlTreatmentType: '',
  },
  learner: {},
  stats: {},
  coach: {},
  latestProgressReport: {},
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
  deleteUserModalVisible: false,
  successModalVisible: false,
  activeStatusModalVisible: false,
  activeStatusDate: moment(Date.now()).format('YYYY-MM-DD'),
};

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const LearnerProfile = () => {
  const { userId } = useParams();
  const [data, handleClick] = useCsvDownload(
    `/csv/learners/${userId}/progress-reports`
  );

  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const { setState: setGeneralState } = useGeneralState();

  const {
    form: { controlTreatmentType },
    learner = {},
    stats = {},
    latestProgressReport = {},
    activeStatusDate,
    coach = {},
    loading,
    submitLoading,
    validationErrs,
    httpError,
    deleteUserModalVisible,
    successModalVisible,
    activeStatusModalVisible,
  } = state;

  const {
    mutateAsync: deleteUser,
    isLoading: deleteUserLoading,
    error: deleteUserError,
  } = useDeleteUser({
    id: userId,
    role: userRoles.LEARNER,
  });

  const {
    mutateAsync: updateActiveStatus,
    isLoading: updateActiveStatusLoading,
    error: updateActiveStatusError,
  } = useUpdateActiveStatus({
    learnerId: userId,
    activeStatus: learner.active,
    activeStatusDate,
  });

  const handleDeleteUser = () => {
    deleteUser(
      {
        id: userId,
        role: userRoles.LEARNER,
      },
      {
        onSuccess: () => {
          setState({ successModalVisible: true });
        },
      }
    );
  };

  const handleUpdateActiveStatus = () => {
    updateActiveStatus(
      {
        learnerId: userId,
        activeStatus: !learner.active,
        activeStatusDate,
      },
      {
        onSuccess: () => {
          setState({ successModalVisible: true });
        },
      }
    );
  };

  useEffect(() => {
    const getReferral = async () => {
      const { data, error } = await Learners.getLearnerProfileByLearner({
        learnerUserId: userId,
      });

      if (error) {
        setState({
          httpError: error.message,
        });
      } else if (data) {
        setGeneralState({
          learnerUniqueId: data?.learner?.learnerUniqueId,
        });
        setState({
          ...data,
          form: { controlTreatmentType: data?.learner?.controlTreatmentType },
        });
      }
    };

    getReferral();

    return () => {
      setGeneralState({
        learnerUniqueId: '',
      });
    };
  }, [setGeneralState, userId]);

  const navigate = useNavigate();

  const setFormData = (data) =>
    setState((prevState) => ({ form: { ...prevState.form, ...data } }));

  const updateReferral = async (_controlTreatmentType) => {
    setState({ submitLoading: true });

    const { error } = await Learners.updateLearnerControlTreatmentType({
      learnerUserId: userId,
      form: {
        learnerUserId: userId,
        controlTreatmentType: _controlTreatmentType,
      },
    });
    setState({ submitLoading: false });
    if (error) {
      setState({
        httpError: error.message,
      });
    }
  };
  const handleSubmit = (controlTreatmentType) => {
    submitAttempt.current = true;

    updateReferral(controlTreatmentType);
  };

  if (loading) return <Loading />;

  if (learner?.deleted) {
    return (
      <T.H1 color="neutralMain" weight="bold" mt={1}>
        This user was deleted.
      </T.H1>
    );
  }

  return (
    <S.Wrapper>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H1 color="neutralMain" weight="bold" mt={1}>
            {learner?.firstName}’s profile
          </T.H1>
        </Col>

        <Col w={[4, 6, 6]} mt="6">
          <Dropdown
            label="Treatment or Control?"
            placeholder="Select..."
            options={[
              { value: 'CONTROL', label: 'Control' },
              { value: 'TREATMENT', label: 'Treatment' },
            ]}
            selected={
              controlTreatmentType && {
                value: controlTreatmentType,
                label:
                  controlTreatmentType === 'CONTROL' ? 'Control' : 'Treatment',
              }
            }
            handleChange={(controlTreatmentType) => {
              setFormData({ controlTreatmentType });
              handleSubmit(controlTreatmentType);
            }}
            error={validationErrs.controlTreatmentType}
          />
        </Col>
      </Row>
      <S.OpacityDiv disabled={!controlTreatmentType}>
        <Row>
          <Col w={[4, 12, 12]} mt={7}>
            <T.H2 color="neutralMain" weight="bold">
              Assigned coach
            </T.H2>
          </Col>
          {coach?.id && (
            <Col w={[4, 6, 6]}>
              <InfoCard
                firstColumnLabel="Name"
                firstColumnValue={coach.firstName}
                mt="3"
                mb="2"
                to={
                  controlTreatmentType &&
                  FACILITATOR.COACH_PROFILE.replace(
                    ':statusType',
                    coachStatusesQuery.ALL
                  ).replace(':coachId', coach.id)
                }
              />
            </Col>
          )}

          <Col w={[4, 12, 12]} mt="3">
            <S.StartCoachingWrapper
              onClick={
                controlTreatmentType
                  ? () =>
                      navigate(FACILITATOR.ASSIGN_COACH.replace(':id', userId))
                  : null
              }
            >
              <S.IconWrapper>
                <Icon icon="userWithArrow" />
              </S.IconWrapper>
              <T.P weight="bold" ml={3} color="neutralMain">
                {coach.id ? 'Reassign coach' : 'Find learner a coach'}
              </T.P>
            </S.StartCoachingWrapper>
          </Col>

          <Col w={[4, 12, 12]} mt={7} display="block">
            <T.H2 color="neutralMain" weight="bold" mb={3} mbM={7}>
              {learner?.firstName}’s stats
            </T.H2>
            <LearnerStats stats={stats} learnerId={userId} />
          </Col>

          <Col w={[4, 12, 12]} mt={7} mb="6" display="block">
            <T.H2 color="neutralMain" weight="bold">
              Progress reports
            </T.H2>
            {latestProgressReport?.createdAt ? (
              <T.P color="neutral90" mt={3}>
                Last report:{' '}
                {moment(latestProgressReport.createdAt).format('DD/MM/YYYY')}
              </T.P>
            ) : (
              <T.P color="neutral90" mt={3}>
                No progress reports yet
              </T.P>
            )}
          </Col>

          {httpError && (
            <Col w={[4, 12, 12]}>
              <T.P color="error" mb={3}>
                {httpError}
              </T.P>
            </Col>
          )}
        </Row>
        <Row mb={3}>
          <Col w={[4, 6, 6]}>
            <BasicButton
              variant="primary"
              disabled={
                !stats.reachedMilestonesTotalNumber || validationErrs.hasError
              }
              loading={submitLoading}
              to={FACILITATOR.LEARNER_REACHED_MILESTONES.replace(
                ':learnerId',
                userId
              )}
            >
              <T.P color="white" weight="bold">
                View progress reports
              </T.P>
            </BasicButton>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 6, 6]}>
            <BasicButton
              variant="secondary"
              disabled={
                !stats.reachedMilestonesTotalNumber || validationErrs.hasError
              }
              loading={data.loading}
              onClick={handleClick}
            >
              <T.P color="white" weight="bold">
                Export progress reports
              </T.P>
            </BasicButton>
          </Col>
        </Row>
      </S.OpacityDiv>

      <Row>
        <Col w={[4, 12, 12]} mt={7} display="block">
          <T.H2 color="neutralMain" weight="bold">
            Details
          </T.H2>
          <T.P color="neutralMain" mt={3}>
            Here you can view and update all relevant details for the learner,
            including their NOMIS ID and Probation Officer
          </T.P>
        </Col>
        <Col w={[4, 6, 6]} mt={3}>
          <BasicButton
            variant="tertiary"
            disabled={validationErrs.hasError}
            loading={submitLoading}
            to={FACILITATOR.LEARNER_REFERRAL.replace(':id', userId)}
            customColor="primaryMain"
          >
            <T.P color="primaryMain" weight="bold">
              View details
            </T.P>
          </BasicButton>
        </Col>
      </Row>
      {learner?.onboarded && (
        <Row>
          <Col w={[4, 12, 12]} mt={7} display="block">
            <T.H2 color="neutralMain" weight="bold">
              Contact Details
            </T.H2>
            <T.P color="neutralMain" mt={3}>
              These are the details they have shared with their coach as part of
              onboarding
            </T.P>
            <T.P color="neutralMain" mt={6} weight="bold">
              How to meet and contact
            </T.P>
            {learner?.sessionPreferences?.map((e) => {
              return (
                <T.P color="neutralMain" mt={3} key={e}>
                  &#x25CF;{' '}
                  {
                    sessionPreferencesDropDown?.find((item) => item.value === e)
                      ?.label
                  }
                </T.P>
              );
            })}
            <T.P color="neutralMain" mt={6} weight="bold">
              Contact number
            </T.P>
            <T.P color="neutralMain" mt={3}>
              {learner?.phoneNumber || 'N/A'}
            </T.P>
            <T.P color="neutralMain" mt={6} weight="bold">
              Contact email
            </T.P>
            <T.P color="neutralMain" mt={3}>
              {learner?.email || 'N/A'}
            </T.P>

            <T.P color="neutralMain" mt={6} weight="bold">
              Preferred ways of being contacted
            </T.P>
            {learner?.preferredContactWays?.map((e) => {
              return (
                <T.P color="neutralMain" mt={3} key={e}>
                  &#x25CF;{' '}
                  {
                    preferredContactWaysDropDown?.find(
                      (item) => item.value === e
                    )?.label
                  }
                </T.P>
              );
            })}
          </Col>
        </Row>
      )}
      {/* DELETE ACCOUNT */}
      <Row>
        <Col w={[4, 12, 12]} mt={7} display="block">
          <T.H2 color="neutralMain" weight="bold">
            Delete account
          </T.H2>
          <T.P color="neutralMain" mt={3}>
            This will delete all personal data related to this user. Please note
            this cannot be undone.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 6]} mt={5}>
          <BasicButton
            variant="primary"
            onClick={() => setState({ deleteUserModalVisible: true })}
          >
            <T.P color="white" weight="bold">
              Delete
            </T.P>
          </BasicButton>
        </Col>
      </Row>
      <Modal visible={deleteUserModalVisible}>
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            {!deleteUserError ? (
              <T.H3 color="neutralSurface">
                Are you sure you want to delete all personal data related to
                this user?
              </T.H3>
            ) : (
              <T.H3 color="error">
                There was an error deleting this account. Please try again
                later.
              </T.H3>
            )}
          </Col>
        </Row>
        <BasicButton
          variant="secondary"
          loading={deleteUserLoading}
          disabled={deleteUserLoading || deleteUserError}
          handleClick={handleDeleteUser}
        >
          <T.P color="white" weight="bold">
            Yes, I'm sure
          </T.P>
        </BasicButton>
        <BasicButton
          mt={3}
          to={'#'}
          variant="tertiary"
          loading={deleteUserLoading}
          handleClick={() => setState({ deleteUserModalVisible: false })}
        >
          No, go back
        </BasicButton>
      </Modal>
      {/* SUCCESS MODAL */}
      <Modal visible={successModalVisible}>
        <S.ModalContent>
          <T.H1 mb={3} color="neutralSurface">
            Done! 🎉
          </T.H1>

          <BasicButton
            to={FACILITATOR.DASHBOARD}
            linkState={{ refetch: userRoles.LEARNER }}
            variant="secondary"
          >
            <T.P color="white" weight="bold">
              Return to dashboard
            </T.P>
          </BasicButton>
        </S.ModalContent>
      </Modal>

      {/* ACTIVE STATUS */}
      <Row>
        <Col w={[4, 12, 12]} mt={7} display="block">
          <T.H2 color="neutralMain" weight="bold">
            Manage active status
          </T.H2>
          <T.P color="neutralMain" mt={3}>
            Current status:
            <S.ActiveStatusSpan activeStatus={learner.active}>
              {learner.active ? 'active' : 'inactive'}
            </S.ActiveStatusSpan>{' '}
            <br></br>
            Latest update:{' '}
            {moment(learner.updateActiveStatusDate).format('YYYY-MM-DD')}
          </T.P>
          <T.P color="neutralMain" mt={3}>
            Here you can mark this learner as active / inactive. Inactive
            learners will not be included in any activity stats and will not be
            visible to their assigned coaches.
          </T.P>
        </Col>
        <Row>
          <Col w={[4, 6, 6]} mt={5}>
            <BasicButton
              variant="primary"
              loading={updateActiveStatusLoading}
              handleClick={() => setState({ activeStatusModalVisible: true })}
            >
              Mark learner as {!learner.active ? 'active' : 'inactive'}
            </BasicButton>
          </Col>
        </Row>
      </Row>
      {/* Activity status modal */}
      <Modal visible={activeStatusModalVisible} bgColor="white">
        {!updateActiveStatusError ? (
          <S.ModalContent>
            <T.H2 ta="center" mb={3}>
              This learner is currently marked as{' '}
              <S.ActiveStatusSpan activeStatus={learner.active}>
                {learner.active ? 'active' : 'inactive'}
              </S.ActiveStatusSpan>
            </T.H2>

            <DatePicker
              label={`Please enter the date they became ${
                !learner.active ? 'active' : 'inactive'
              }`}
              placeholder="DD/MM/YYYY"
              validDateLimit={moment(Date.now()).format('YYYY-MM-DD')}
              value={activeStatusDate}
              name="activeStatusDate"
              handleChange={(date) =>
                setState({
                  activeStatusDate: date,
                })
              }
              error={activeStatusDate === 'Invalid date'}
            />
          </S.ModalContent>
        ) : (
          <T.H3 color="error">
            There was an error managing this learner's activity status. Please
            try again later.
          </T.H3>
        )}
        <BasicButton
          mt={3}
          loading={updateActiveStatusLoading}
          disabled={
            updateActiveStatusLoading ||
            updateActiveStatusError ||
            activeStatusDate === 'Invalid date'
          }
          handleClick={handleUpdateActiveStatus}
        >
          <T.P color="white" weight="bold">
            Submit
          </T.P>
        </BasicButton>
        <BasicButton
          mt={3}
          variant="secondary"
          loading={updateActiveStatusLoading}
          handleClick={() => setState({ activeStatusModalVisible: false })}
        >
          Return to profile
        </BasicButton>
      </Modal>
      <Row>
        <Col w={[4, 6, 6]} mt={7}>
          <BasicButton
            variant="secondary"
            disabled={validationErrs.hasError}
            loading={submitLoading}
            to={FACILITATOR.DASHBOARD}
          >
            <T.P color="white" weight="bold">
              Back to dashboard
            </T.P>
          </BasicButton>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

LearnerProfile.LearnerId = LearnerId;
export default LearnerProfile;
