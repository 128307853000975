import { Spin } from 'antd';
import styled from '@emotion/styled';
import * as T from '../../Typography';
import setMargin from './../../../helpers/set-margin';

export const Container = styled.div`
  ${setMargin};
  background-color: ${({ bubble, theme }) =>
    bubble ? theme.colors.neutralLight : 'transparent'};
  padding: ${({ bubble }) => (bubble ? '18px' : '0px')};
  border-radius: ${({ bubble }) => (bubble ? '16px' : '0px')};
  position: relative;
  border-right: ${({ bubble, theme }) =>
    bubble ? `16px solid ${theme.colors.primaryMain}` : 'none'};
  box-sizing: border-box;
  display: ${({ text }) => {
    if (text) return 'flex';
    else return 'inline-block';
  }};
  align-items: center;

  ${({ bubble, theme }) =>
    bubble &&
    `:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 25px;
      border-top: 20px solid black;
      border-top-color: ${theme.colors.neutralLight};  
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;`}
`;

export const PlayText = styled(T.H2)`
  ${setMargin}
  font-weight: ${({ weight }) => (weight ? weight : 'regular')} !important;
  color: ${({ theme, loading, color }) =>
    loading ? theme.colors.neutral70 : theme.colors[color] || color};
`;

export const PlayTextLink = styled(T.Link)`
  ${setMargin}
  font-weight: ${({ weight }) => (weight ? weight : 'regular')} !important;
  color: ${({ theme, loading, color }) =>
    loading ? theme.colors.neutral70 : theme.colors[color] || color};
`;

export const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  min-width: ${({ innerText, width }) =>
    innerText ? '' : width ? width : '56px'};
  min-height: ${({ height }) => (height ? height + 'px' : '56px')};
  color: ${({ theme }) => theme.colors.neutralMain};
  background: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.neutralLight};
  position: relative;
  border: none;
  border-radius: ${({ radius }) => (radius ? radius + 'px' : '16px')};
  font-size: 1rem;
  font-weight: bold;
  padding: ${({ theme, padding, withoutButtonStyle }) =>
    withoutButtonStyle
      ? 0
      : padding || `${theme.spacings[3]} ${theme.spacings[2]}`};
  box-shadow: ${({ withoutButtonStyle, theme }) =>
    withoutButtonStyle ? 'none' : theme.shadows.elevation1};
  transition: background-color 300ms linear, color 300ms linear;
  cursor: ${({ disabled, loading }) =>
    disabled || loading ? 'not-allowed' : 'pointer'};

  :hover {
    color: ${({ theme }) => theme.colors.primaryMain};
  }

  :active {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.9;
    background-color: ${({ theme }) => theme.colors.neutralMain};
  }

  :focus {
    box-shadow: inset 0px 0px 0px 2px rgba(5, 23, 48, 1);
    outline-style: solid;
    outline-width: 2px;
    outline-color: ${({ theme }) => theme.colors.primaryMain};
    outline-offset: 2px;
  }

  :disabled {
    color: ${({ theme }) => theme.colors.neutralMain};
    background: ${({ theme, backgroundColor }) =>
      backgroundColor || theme.colors.neutralLight};
  }
`;

export const CenterLoading = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  span {
    line-height: 24px;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.quaternaryMain};
  }
`;

export const InnerText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  margin-left: 12px;
`;
