import axios from 'axios';
import handleError from './format-error';

const USERS_BASE = '/users';

const getUserById = async ({ id, options }) => {
  try {
    const { data } = await axios.get(`${USERS_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getLoggedInUserInfo = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${USERS_BASE}/my-info`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const login = async (form, { options } = {}) => {
  try {
    const { data } = await axios.post(`${USERS_BASE}/login`, form);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateOverlayColor = async (form, { options } = {}) => {
  try {
    const { data } = await axios.patch(`${USERS_BASE}/overlay-color`, form);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const logout = async ({ options } = {}) => {
  try {
    await axios.post(`${USERS_BASE}/logout`);
    return {};
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getCSRFToken = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${USERS_BASE}/get-csrf-token`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const sendResetPasswordLink = async (form, { options } = {}) => {
  try {
    await axios.post(`${USERS_BASE}/reset-password-link`, form);
    return {};
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updatePassword = async (form, { options } = {}) => {
  try {
    await axios.post(`${USERS_BASE}/update-password`, form);
    return {};
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getSelectedLearner = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${USERS_BASE}/selected-learner`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getUserById,
  getLoggedInUserInfo,
  login,
  logout,
  getCSRFToken,
  updateOverlayColor,
  sendResetPasswordLink,
  updatePassword,
  getSelectedLearner,
};
