import Icon from '../../Icon';
import * as S from './style';

import { antIcon } from '../Basic';
import { UseAudio } from 'Hooks';

/**
 * Primary UI component for user interaction
 */
const PlaySound = ({
  loading,
  handleClick = () => {},
  disabled,
  audioUrl,
  label,
  ...props
}) => {
  const { canPlay, handleAudioClick } = UseAudio({ audioUrl, handleClick });

  return (
    <S.PlayButton
      type="button"
      disabled={disabled || !canPlay}
      onClick={handleAudioClick}
      onTouchEnd={handleAudioClick}
      as="button"
      loading={loading}
      aria-disabled={disabled ? 'true' : 'false'}
      {...props}
      aria-label="Play sound"
    >
      <Icon
        icon={'speaker'}
        disabled={disabled || !canPlay}
        width="32px"
        height="32px"
      />
      {loading && <S.CenterLoading indicator={antIcon} />}
      <S.InnerText>{label || 'Play sound'}</S.InnerText>
    </S.PlayButton>
  );
};

export default PlaySound;
