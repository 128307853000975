import styled from '@emotion/styled';
import setMargin from '../../../helpers/set-margin';
import Icon from '../../Icon';
import TextField from '@mui/material/TextField';
import theme from '../../../theme';

export const Answer = styled.div`
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: -16px;
`;

export const StyledField = styled(TextField)`
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
  width: 100%;
  height: auto !important;
  border: none;
`;

export const Field = styled.div`
  ${setMargin};
  width: ${({ w }) => w || '100%'};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  .MuiAutocomplete-root {
    width: 100%;
    background-color: transparent;

    box-shadow: ${({ error, theme }) =>
      error
        ? `inset 0px 0px 0px 2px ${theme.colors.error} `
        : `inset 0px 0px 0px 2px ${theme.colors.neutralMain} `};
    border-radius: ${({ theme }) => theme.borders.radiusLarge};
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    width: 100% !important;
    min-height: 54px !important;
    border: 1px solid white;
    border: none;
    padding-top: 0px !important ;
    padding-bottom: 0px !important ;
  }

  .MuiAutocomplete-root:hover {
    box-shadow: ${({ error, theme }) =>
      error
        ? `inset 0px 0px 0px 2px ${theme.colors.error} !important`
        : `inset 0px 0px 0px 2px ${theme.colors.primaryMain} !important`};
    border-radius: ${({ theme }) => theme.borders.radiusLarge};
  }

  .MuiAutocomplete-root:focus-within {
    box-shadow: inset 0px 0px 0px 2px rgba(5, 23, 48, 1) !important;
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${({ theme }) => theme.colors.primaryMain + '!important'};
    outline-offset: 0.2em;
  }
  .MuiInputBase-input .MuiOutlinedInput-input:hover {
    background-color: ${({ theme }) => theme.colors.primaryMain + '!important'};
  }
  & ::placeholder {
    opacity: 0.75 !important;
    color: ${({ theme }) => theme.colors.neutralMain + '!important'};
    padding-top: 3px !important ;
  }
  .MuiAutocomplete-tag {
    background-color: ${theme.colors.neutralLight};
    border-radius: 0px !important ;
  }
  .MuiChip-deleteIconColorDefault {
    color: ${({ theme }) => `${theme.colors.secondaryMain} !important`};
  }
  .MuiAutocomplete-clearIndicator {
    color: ${({ theme }) => theme.colors.secondaryMain};
  }
  .MuiAutocomplete-popupIndicator {
    transform: ${({ search }) => (search ? 'rotate(360deg)' : '')};
  }
`;

export const StyledIcon = styled(Icon)`
  width: 100%;
  transform: rotate(180deg);
`;
