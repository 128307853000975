import { useReducer } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import { Checkbox } from 'antd';
import * as S from './style';
import MDEditor from '../../../../components/MDEditor';
import { Icon } from '../../../../components';
import { string, number, boolean, array, object } from 'yup';
import { MediaKeyInput } from '../../../Components';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data
    ? {
        ...data,
        options: data?.options?.map((e, i) => ({
          ...e,
          id: i,
        })) || [{ id: 0, option: '', isCorrect: false }],
      }
    : {
        options: [{ id: 0, option: '', isCorrect: false }],
        textAudio: '',
        textAudioKey: '',
      },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const MultipleChoiceGrid = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const form = {
        ...prevState.form,
        ...data,
      };
      onChange({
        ...form,
      });
      return { form };
    });

  const addOption = () => {
    const maxId = Math.max(...state.form.options.map((e) => e.id), 0);
    setFormData({
      options: [
        ...state.form.options,
        { id: maxId + 1, option: '', hide: false },
      ],
    });
  };
  const removeOption = (id) => {
    setFormData({
      options: state.form.options.filter((e) => e.id !== id),
    });
  };

  const onOptionChange = (value, id) => {
    setFormData({
      options: state.form.options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const onIsCorrectChange = (value, id) => {
    setFormData({
      options: state.form.options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, isCorrect: value };
      }),
    });
  };

  return (
    <div>
      <BasicInput
        label="Text Audio"
        value={state.form.textAudio}
        handleChange={(textAudio) => setFormData({ textAudio })}
        m={{ mt: 5 }}
        error={errors?.textAudio}
      />

      <MediaKeyInput
        label={`Audio Text file Key`}
        value={state.form.textAudioKey}
        handleChange={(textAudioKey) => {
          setFormData({ textAudioKey });
        }}
        m={{ mt: 5 }}
        error={errors?.textAudioKey}
      />
      <T.P mt={8} size="large" weight="bold">
        Words
      </T.P>
      {errors?.options && typeof errors.options === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.options}
        </T.P>
      )}

      {state.form.options.map((e, i) => {
        return (
          <>
            <MDEditor
              label={`Word ${i + 1}`}
              value={e.option}
              onChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mt: 5 }}
              error={errors?.options && errors?.options[i]?.option}
              height={100}
            />

            <Checkbox
              checked={e.isCorrect}
              onChange={(value) =>
                onIsCorrectChange(value.target.checked, e.id)
              }
              disabled={!e.option}
            >
              correct option?
            </Checkbox>
            {state.form.options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={state.form.options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string().required('required field'),
          isCorrect: boolean(),
        })
        .required()
    )
    .required('you must provide options')
    .min(1, 'you must provide options')
    .test('oneCorrect', 'should select one correct answer at least', (val) => {
      return val?.filter((e) => e.isCorrect)?.length > 0;
    }),

  textAudio: string().required('required field'),
  textAudioKey: string(),
};
export { validationSchema };

export default MultipleChoiceGrid;
