import * as S from './style';

import Card1 from '../../components/assets/card1-min.jpeg';
import Card2 from '../../components/assets/card2.jpeg';
import Card3 from '../../components/assets/card3.jpeg';
import Card4 from '../../components/assets/card4-min.jpeg';

import * as T from '../../components/Typography';
import { navRoutes } from '../../constants';

const TipsCard = ({ img, text, to, ...props }) => {
  return (
    <S.Container img={img} {...props}>
      <T.P color="white" weight="bold" mb="6">
        {text}
      </T.P>
      <S.StyledFindOut
        color="white"
        underline
        weight="bold"
        to={to}
        ta="center"
      >
        Find out
      </S.StyledFindOut>
    </S.Container>
  );
};

const TrainingChecklist = (props) => {
  return (
    <TipsCard
      img={Card1}
      color="lightBlue"
      text="Training checklist - the essentials"
      to={navRoutes.COACH.DASHBOARD_TRAINING_CHECKLIST}
      {...props}
    />
  );
};

const Safeguarding = (props) => {
  return (
    <TipsCard
      img={Card2}
      color="blue"
      text="Safeguarding - how to keep you and your learner safe"
      to={navRoutes.COACH.DASHBOARD_SAFEGUARDING}
      {...props}
    />
  );
};

const Advice = (props) => {
  return (
    <TipsCard
      img={Card3}
      color="black"
      position="bottom"
      text="Advice on how to be a good coach"
      to={navRoutes.COACH.DASHBOARD_ADVICE}
      {...props}
    />
  );
};

const Qualities = (props) => {
  return (
    <TipsCard
      img={Card4}
      color="pink"
      position="bottom"
      text="What sort qualities do I need to be a good coach?"
      to={navRoutes.COACH.DASHBOARD_QUALITIES}
      {...props}
    />
  );
};

export { Advice, Safeguarding, TrainingChecklist, Qualities };
export default TipsCard;
