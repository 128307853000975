import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga';
import { BrowserTracing } from '@sentry/tracing';
// import ReactGA from 'react-ga';
import ErrorBoundary from './ErrorBoundary';

if (process.env.NODE_ENV === 'production') {
  // SENTRY
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: window.location.host.includes('turningpages.co.uk')
      ? 'production'
      : 'staging',

    tracesSampleRate: 0.5,
  });

  // GOOGLE ANALYTICS
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const queryConfig = {
  queries: {
    // only retry queries and refetch when when window is focussed when in prod
    retry: process.env.NODE_ENV === 'production',
    refetchOnWindowFocus: process.env.NODE_ENV === 'production',
    // stale time 20 sec
    staleTime: 1000 * 20,
  },
};

const queryClient = new QueryClient({ defaultOptions: queryConfig });
const container = document.getElementById('root');

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
      {process.env.NODE_ENV === 'development' ? (
        <ReactQueryDevtools initialIsOpen={false} />
      ) : null}
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
