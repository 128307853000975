import * as S from './style';
import * as T from '../Typography';

import { BasicButton } from '../Button';

const InfoCardWithButton = ({
  firstColumnLabel,
  firstColumnValue,
  secondColumnLabel,
  secondColumnValue,
  handleClick = () => {},
}) => {
  return firstColumnValue ? (
    <S.WithButtonWrapper>
      <S.Content>
        <S.Item fullWidth={!secondColumnValue}>
          <T.P color="neutral90">{firstColumnLabel}</T.P>
          <T.P color="neutralMain" weight="bold">
            {firstColumnValue}
          </T.P>
        </S.Item>
        {secondColumnValue && (
          <S.Item>
            <T.P color="neutral90">{secondColumnLabel}</T.P>
            <T.P color="neutralMain" weight="bold">
              {secondColumnValue}
            </T.P>
          </S.Item>
        )}
      </S.Content>
      <BasicButton
        variant="secondary"
        icon="next"
        iconProps={{ color: 'white' }}
        mt="16px"
        handleClick={handleClick}
      >
        <T.P color="white" weight="bold">
          Check exercise
        </T.P>
      </BasicButton>
    </S.WithButtonWrapper>
  ) : (
    <S.EmptyWrapper>
      <T.P color="neutralMain" ta="center">
        None currently
      </T.P>
    </S.EmptyWrapper>
  );
};

export default InfoCardWithButton;
