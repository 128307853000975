import { ProfileStats } from '../';
import * as T from '../Typography';
import * as S from './style';

const LearnerStats = ({ stats, learnerId }) => {
  if (!stats || Object.keys(stats).length === 0) {
    return <T.P>No stats yet!</T.P>;
  }

  return (
    <S.StatsWrapper>
      <ProfileStats
        type="exercisesCompleted"
        size={stats.completedExercisesSize}
        percent={stats.completedExercisesPerc}
      />
      <ProfileStats
        type="learningTime"
        days={stats.totalSessionTimes.days}
        hours={stats.totalSessionTimes.hours}
        months={stats.totalSessionTimes.months}
      />{' '}
      <ProfileStats
        type="milestoneReached"
        milestonesNum={stats.reachedMilestonesTotalNumber}
        learnerId={learnerId}
      />
      {/* <ProfileStats type="newWords" words={stats.newWordsLearned} /> */}
      <ProfileStats
        type="dateOfLastTaskCompletion"
        date={stats.dateOfLastCompletion}
      />
      <ProfileStats
        type="dateOfLastSessionWithCoach"
        date={stats.dateOfLastCoachingSession}
      />
    </S.StatsWrapper>
  );
};

export default LearnerStats;
