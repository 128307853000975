import { useEffect, useState, useReducer, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { string, number, object } from 'yup';
import { createSchema, validate } from '../../../validation';

import { Exercises, Milestones } from '../../../api-calls';
import * as T from '../../../components/Typography';
import { navRoutes } from '../../../constants';

import { BasicInput, Dropdown } from '../../../components/Inputs';
import { BasicButton } from '../../../components/Button';
import MDEditor from '../../../components/MDEditor';
import { MediaKeyInput } from '../../Components';
import * as S from '../style';
import SuccessModal from './SuccessModal';
import ExercisesCard from './../../../components/Exercises';
import VoiceToggle from '../../VoiceToggle';

import { Content as CoachNotes } from '../../../pages/Exercises/CoachNotes';
import { Content as ExerciseExplainer } from '../../../pages/Exercises/ExerciseExplainer';
const initialState = {
  form: {
    milestoneId: null,
    title: '',
    titleAudioKey: '',
    key: '',
    order: '',
    newWords: '',
    // coachNotes: '',
    coachNotesIntro: '',
    coachNotesSteps: '',
    // beforeExercise
    beforeExerciseText: '',
    beforeExerciseTextAudioKey: '',
    beforeExerciseTip: '',
    beforeExerciseTipAudioKey: '',
  },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
};

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const _validateExercise = (data) => {
  const REQUIRED_FIELD = 'required field';
  const exerciseSchema = createSchema({
    milestoneId: number()
      .typeError('Select a milestone')
      .required(REQUIRED_FIELD),
    title: string().required(REQUIRED_FIELD),
    titleAudioKey: string().required(REQUIRED_FIELD),
    key: string().required(REQUIRED_FIELD),
    order: number().required(REQUIRED_FIELD),
    newWords: number().required(REQUIRED_FIELD),
    coachNotes: object()
      .shape({
        intro: string().required(REQUIRED_FIELD),
        steps: string().required(REQUIRED_FIELD),
      })
      .required(),
    beforeExercise: object().shape({
      text: string().required(REQUIRED_FIELD),
      textAudioKey: string().required(REQUIRED_FIELD),
    }),
  });

  return validate(exerciseSchema, data);
};

const ViewExercise = ({ createNew }) => {
  const { id } = useParams();

  const createdExerciseId = useRef();
  const [milestones, setMilestones] = useState([]);
  const [state, setState] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const setFormData = (data) =>
    setState((prevState) => ({ form: { ...prevState.form, ...data } }));

  const getMilestones = async () => {
    const { data } = await Milestones.getMilestones();

    if (data) {
      setMilestones(data);
    }
  };

  useEffect(() => {
    setFormData(initialState.form);
  }, [id]);

  useEffect(() => {
    const getExercise = async () => {
      const { data } = await Exercises.getExerciseById({ id });

      if (data) {
        setFormData({
          milestoneId: data.milestoneId,
          title: data.title,
          titleAudioKey: data.titleAudioKey,
          order: data.order,
          newWords: data.newWords,
          key: data.key,
          // coachNotes
          coachNotesIntro: data.coachNotes?.intro?.trim(),
          coachNotesSteps: data.coachNotes?.steps?.trim(),
          // beforeExercise
          beforeExerciseText: data.beforeExercise?.text?.trim(),
          beforeExerciseTextAudioKey: data.beforeExercise?.textAudioKey,
          beforeExerciseTip: data.beforeExercise?.tip?.trim(),
          beforeExerciseTipAudioKey: data.beforeExercise?.tipAudioKey,
        });
      }

      setLoading(false);
    };

    if (!createNew) {
      getExercise();
    }
    getMilestones();
  }, [createNew, id]);

  const validateExercise = (data) => {
    try {
      _validateExercise(data);
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      setState({ validationErrs: error.inner });
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      validateExercise({
        milestoneId: state.form.milestoneId,
        title: state.form.title,
        titleAudioKey: state.form.titleAudioKey,
        order: state.form.order,
        newWords: state.form.newWords,
        key: state.form.key,
        coachNotes: {
          intro: state.form.coachNotesIntro,
          steps: state.form.coachNotesSteps,
        },
        beforeExercise: {
          text: state.form.beforeExerciseText,
          textAudioKey: state.form.beforeExerciseTextAudioKey,
          tip: state.form.beforeExerciseTip,
          tipAudioKey: state.form.beforeExerciseTipAudioKey,
        },
      });
    }
  }, [
    isSubmitted,
    state.form.beforeExerciseText,
    state.form.beforeExerciseTextAudioKey,
    state.form.beforeExerciseTip,
    state.form.beforeExerciseTipAudioKey,
    state.form.coachNotesIntro,
    state.form.coachNotesSteps,
    state.form.key,
    state.form.milestoneId,
    state.form.order,
    state.form.newWords,
    state.form.title,
    state.form.titleAudioKey,
  ]);

  const createExercise = async () => {
    setLoading(true);
    setIsSubmitted(true);
    const exerciseData = {
      milestoneId: state.form.milestoneId,
      title: state.form.title,
      titleAudioKey: state.form.titleAudioKey,
      order: state.form.order,
      newWords: state.form.newWords,
      key: state.form.key,
      coachNotes: {
        intro: state.form.coachNotesIntro,
        steps: state.form.coachNotesSteps,
      },
      beforeExercise: {
        text: state.form.beforeExerciseText,
        textAudioKey: state.form.beforeExerciseTextAudioKey,
        tip: state.form.beforeExerciseTip,
        tipAudioKey: state.form.beforeExerciseTipAudioKey,
      },
    };
    const isValid = validateExercise(exerciseData);
    if (isValid) {
      let res;
      let update;
      if (Number.isInteger(Number(id))) {
        res = await Exercises.updateExercise({
          data: exerciseData,
          id,
        });
        update = true;
      } else {
        res = await Exercises.createExercise({
          data: exerciseData,
        });
      }

      const { error, data } = res;

      if (!error) {
        if (!update) {
          setFormData(initialState.form);
        }
        setIsUpdate(update);
        setState({ httpError: '' });
        createdExerciseId.current = data.id;
        setIsModalVisible(true);
        navigate(navRoutes.CMS.VIEW_EXERCISE.replace(':id', data.id), {
          replace: true,
        });
      } else {
        setState({
          httpError: error.message,
          validationErrs: {
            key: error?.data?.field === 'key' ? error.message : '',
          },
        });
      }
    }
    setLoading(false);
  };

  const milestonesOptions = milestones.map((e) => ({
    label: e.title,
    value: e.id,
  }));

  return (
    <S.Wrapper>
      <VoiceToggle />
      <S.Form>
        <Dropdown
          label="Milestone"
          options={milestonesOptions}
          selected={
            state.form.milestoneId && {
              value: state.form.milestoneId,
              label: milestones?.find((e) => state.form.milestoneId === e.id)
                ?.title,
            }
          }
          search
          handleChange={(milestoneId, a) => {
            setFormData({
              milestoneId,
              order: milestones.find((e) => e.id === milestoneId)?.maxOrder + 1,
            });
          }}
          error={state.validationErrs.milestoneId}
          m={{ mb: 5 }}
        />

        <BasicInput
          label="Exercise Order in milestone"
          value={state.form.order}
          handleChange={(order) => setFormData({ order })}
          m={{ mt: 5 }}
          type="number"
          disabled
          error={state.validationErrs.order}
        />

        <BasicInput
          label="Number of new words"
          value={state.form.newWords}
          handleChange={(newWords) => setFormData({ newWords })}
          m={{ mt: 5 }}
          type="number"
          error={state.validationErrs.newWords}
        />
        <BasicInput
          label="Key"
          value={state.form.key}
          handleChange={(key) => setFormData({ key })}
          m={{ mt: 5 }}
          helper="Get from the exercises spreadsheet, e.g. 'M1_P12'"
          error={state.validationErrs.key}
        />

        <BasicInput
          label="Title"
          value={state.form.title}
          handleChange={(title) => setFormData({ title })}
          m={{ mt: 7 }}
          error={state.validationErrs.title}
        />
        <MediaKeyInput
          label="Title audio key"
          value={state.form.titleAudioKey}
          handleChange={(titleAudioKey) => setFormData({ titleAudioKey })}
          m={{ mt: 4 }}
          error={state.validationErrs.titleAudioKey}
        />

        <T.P mb={2} mt={8} size="large" weight="bold">
          Coach Notes
        </T.P>

        <MDEditor
          value={state.form.coachNotesIntro}
          onChange={(coachNotesIntro) => setFormData({ coachNotesIntro })}
          label="Introduction"
          m={{ mt: 5 }}
          error={state.validationErrs.coachNotes?.intro}
        />
        <MDEditor
          value={state.form.coachNotesSteps}
          onChange={(coachNotesSteps) => setFormData({ coachNotesSteps })}
          label="Steps"
          m={{ mt: 4 }}
          error={state.validationErrs.coachNotes?.steps}
        />

        <T.P mb={2} mt={5}>
          <T.P mt={8} size="large" weight="bold">
            Before Exercise Page
          </T.P>
          (Explainer page)
        </T.P>
        <MDEditor
          value={state.form.beforeExerciseText}
          onChange={(beforeExerciseText) => setFormData({ beforeExerciseText })}
          label="Text"
          m={{ mt: 4 }}
          error={state.validationErrs.beforeExercise?.text}
        />
        <MediaKeyInput
          label="Text audio key"
          value={state.form.beforeExerciseTextAudioKey}
          handleChange={(beforeExerciseTextAudioKey) =>
            setFormData({ beforeExerciseTextAudioKey })
          }
          m={{ mt: 4 }}
          error={state.validationErrs.beforeExercise?.textAudioKey}
        />
        <MDEditor
          value={state.form.beforeExerciseTip}
          onChange={(beforeExerciseTip) => setFormData({ beforeExerciseTip })}
          label="Tip"
          m={{ mt: 7 }}
        />
        <MediaKeyInput
          label="Tip audio key"
          value={state.form.beforeExerciseTipAudioKey}
          handleChange={(beforeExerciseTipAudioKey) =>
            setFormData({ beforeExerciseTipAudioKey })
          }
          m={{ mt: 4 }}
        />

        <div>
          {
            <T.P mt={5} color="error">
              {state.httpError}
              <T.P mt={5} color="error">
                {!!Object.keys(state.validationErrs)[0] &&
                  'error in one of the fields above'}
              </T.P>
            </T.P>
          }
          {createNew ? (
            <BasicButton onClick={createExercise} loading={loading}>
              Submit
            </BasicButton>
          ) : (
            <>
              <BasicButton onClick={createExercise} loading={loading}>
                Update
              </BasicButton>
              <BasicButton
                to={navRoutes.CMS.VIEW_EXERCISE_STEPS.replace(':id', id)}
                mt={5}
                loading={loading}
              >
                View steps
              </BasicButton>
            </>
          )}
        </div>
      </S.Form>
      <S.Preview>
        <T.P weight="bold" color={'neutralMain'} mt="50px">
          Exercise card
        </T.P>
        <S.PreviewItem style={{ display: 'flex', justifyContent: 'center' }}>
          <ExercisesCard
            title={state.form.title}
            titleAudioKey={state.form.titleAudioKey}
          />
        </S.PreviewItem>
        <T.P weight="bold" color={'neutralMain'} mt="50px">
          Coach Notes
        </T.P>
        <S.PreviewItem>
          <CoachNotes
            steps={state.form.coachNotesSteps}
            intro={state.form.coachNotesIntro}
            exKey={state.form.key}
          />
        </S.PreviewItem>

        <T.P weight="bold" color={'neutralMain'} mt="50px">
          Before Exercise (Explainer)
        </T.P>
        <S.PreviewItem>
          <ExerciseExplainer
            beforeExercise={{
              text: state.form.beforeExerciseText,
              textAudioKey: state.form.beforeExerciseTextAudioKey,
              tip: state.form.beforeExerciseTip,
              tipAudioKey: state.form.beforeExerciseTipAudioKey,
            }}
            disabled
          />
        </S.PreviewItem>
      </S.Preview>
      <SuccessModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        exerciseId={createdExerciseId.current}
        isUpdate={isUpdate}
      />
    </S.Wrapper>
  );
};

export default ViewExercise;
