import { useState } from 'react';
import {
  Grid,
  Typography as T,
  Button,
  ProfileStats,
  Loading,
} from '../../../components';
import * as S from './style';
import { useGetDefaultFacilitator } from '../../../api-calls/facilitator.queries';
import { getMediaUrl } from '../../../helpers';
import { useGeneralState } from '../../../context/general-state';
import BadgesList from '../../../components/BadgesList';

import { useGetLearnerStats } from '../../../api-calls/learners.queries';

import { navRoutes as R, audio } from '../../../constants';
import { useAuth } from '../../../context/auth';
import { Badges } from '../../../api-calls';

const { Col } = Grid;

const Profile = () => {
  const { state: generalState } = useGeneralState();
  const [isLarge, setIsLarge] = useState(() => {
    return localStorage.getItem('isLarge') || false;
  });
  const { logout, user } = useAuth();
  const {
    facilitator,
    error: getFacilitatorError,
    isLoading: getFacilitatorLoading,
  } = useGetDefaultFacilitator();
  const { stats, isLoading, isError, isSuccess, error } = useGetLearnerStats({
    learnerId: user.id,
  });
  const { data: badges, isFetched: badgesLoaded } = Badges.useGetBadges({
    type: 'all',
  });

  if (isLoading) return <Loading />;

  return (
    <S.Wrapper>
      <S.PlayButtonWrapper>
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(
            audio.profile,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
          mr={3}
        />

        <T.H2 ml="3">Profile</T.H2>
      </S.PlayButtonWrapper>
      <S.PlayButtonWrapper mt="3">
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(
            audio.listenToSoundBoard,
            true,
            generalState?.preferredVoice
          )}
          mr={3}
          iconProps={{ width: 27.2 }}
        />
        <Col w={[3, 7, 7]} mlM="2">
          <Button.BasicButton
            handleClick={() => {}}
            to={R.LEARNER.SOUND_BOARD}
            variant="primary"
            ta="center"
          >
            <T.P color="white" weight="bold">
              Listen to sound board
            </T.P>
          </Button.BasicButton>
        </Col>
      </S.PlayButtonWrapper>
      <S.PlayButtonWrapper mt="3">
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(
            audio.seeListenToAppIcons,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
          mr={3}
        />

        <Col w={[3, 7, 7]} mlM="2">
          <Button.BasicButton
            handleClick={() => {}}
            to={R.LEARNER.ICONS}
            variant="secondary"
            ta="center"
          >
            <T.P color="white" weight="bold">
              See/listen to app icons
            </T.P>
          </Button.BasicButton>
        </Col>
      </S.PlayButtonWrapper>
      <S.PlayButtonWrapper mt="7" mb="3">
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(
            audio.accessibility,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
          mr={3}
        />

        <Col w={[3, 7, 7]} mlM="3">
          <T.H2>Accessibility</T.H2>
        </Col>
      </S.PlayButtonWrapper>
      <S.PlayButtonWrapper mt="3">
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(
            audio.addAColouredOverlay,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
          mr={3}
        />

        <Col w={[3, 7, 7]} mlM="2">
          <Button.BasicButton
            handleClick={() => {}}
            to={R.LEARNER.COLOURED_OVERLAY}
            variant="tertiary"
            iconMR="4px"
            ta="center"
          >
            <T.P color="neutralMain" weight="bold">
              {' '}
              Add a coloured overlay
            </T.P>
          </Button.BasicButton>
        </Col>
      </S.PlayButtonWrapper>
      <S.PlayButtonWrapper mt="3">
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(
            isLarge ? audio.decreaseFontSize : audio.increaseFontSize,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
          mr={3}
        />
        <Col w={[3, 7, 7]} mlM="2">
          <Button.BasicButton
            handleClick={() => {
              if (!isLarge) {
                document.getElementsByTagName('html')[0].style.fontSize =
                  '19px';
                localStorage.setItem('isLarge', 'true');
                setIsLarge(true);
              } else {
                document.getElementsByTagName('html')[0].style.fontSize =
                  '16px';
                localStorage.removeItem('isLarge');
                setIsLarge(false);
              }
            }}
            variant="primary"
            ta="center"
            icon="lens"
            iconMR="4px"
            padding="20px"
          >
            <T.P color="white" weight="bold">
              {!isLarge ? '+ Increase text size' : '- Decrease text size'}
            </T.P>
          </Button.BasicButton>
        </Col>
      </S.PlayButtonWrapper>
      {/* <S.PlayButtonWrapper mt="3">
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(
            audio.sendAThankYouToYourCoach,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
        />

        <Col w={[3, 7, 7]} mlM="3">
          <Button.BasicButton
            handleClick={() => {}}
            // to={R.LEARNER.SEND_THANKS}
            variant="primary"
            ta="center"
          >
            Send a thank you to your coach
          </Button.BasicButton>
        </Col>
      </S.PlayButtonWrapper> */}
      {/* Books */}
      {/* <S.PlayButtonWrapper mt="7" mb="3">
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(
            audio.books,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
        />

        <Col w={[3, 7, 7]} mlM="3">
          <T.H2>Books</T.H2>
        </Col>
      </S.PlayButtonWrapper>
      <Row mb="4">
        <Col w={[2, 3, 4]} mt={3}>
          <Image image="book1" />
        </Col>
        <Col w={[2, 3, 4]} mt={3}>
          <Image image="book2" />
        </Col>
      </Row> */}

      {badgesLoaded && badges?.length > 0 && (
        <>
          {/* badges */}
          <S.PlayButtonWrapper mt="7" mb="3">
            <Button.PlayButton
              width="56px"
              height="56px"
              audioUrl={getMediaUrl(
                audio.badges,
                true,
                generalState?.preferredVoice
              )}
              iconProps={{ width: 27.2 }}
              mr={3}
            />
            <Col w={[3, 7, 7]} mlM="3">
              <T.H2>Badges</T.H2>
            </Col>
          </S.PlayButtonWrapper>
          <BadgesList badges={badges} />
        </>
      )}
      {/* Statistics */}
      <S.PlayButtonWrapper mt="7" mb="3">
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(
            audio.statistics,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
          mr={3}
        />

        <Col w={[3, 7, 7]} mlM="3">
          <T.H2>Statistics</T.H2>
        </Col>
      </S.PlayButtonWrapper>
      {isSuccess ? (
        <S.StatsWrapper>
          <ProfileStats
            type="exercisesCompleted"
            size={stats.completedExercisesSize}
            percent={stats.completedExercisesPerc}
          />
          <ProfileStats
            type="learningTime"
            days={stats.totalSessionTimes.days}
            hours={stats.totalSessionTimes.hours}
            months={stats.totalSessionTimes.months}
          />
          {/* <ProfileStats type="newWords" words={stats.newWordsLearned} /> */}
          <ProfileStats
            type="milestoneReached"
            milestone={stats.milestoneReached}
            milestonesNum={stats.milestonesReached?.length}
          />
        </S.StatsWrapper>
      ) : isError ? (
        <T.P mb="2" color="error">
          {error.message}
        </T.P>
      ) : null}
      <S.PlayButtonWrapper mt="7">
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(
            audio.logout,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
          mr={3}
        />

        <Col w={[3, 7, 7]} mlM="3">
          <Button.BasicButton
            id="logout-button"
            handleClick={() => logout({ isLearner: true })}
            variant="tertiary"
          >
            Log out
          </Button.BasicButton>
        </Col>
      </S.PlayButtonWrapper>

      <Col w={[4, 8, 8]} mt="6">
        <Button.BasicButton
          to={R.LEARNER.DASHBOARD}
          variant="primary"
          icon="back"
        />
      </Col>
      <S.PlayButtonWrapper mt="7">
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(
            audio.deleteMyAccount,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
          mr={3}
        />

        <Col w={[3, 7, 7]} mlM="3">
          <T.H2>Delete my account</T.H2>
        </Col>
      </S.PlayButtonWrapper>
      <S.PlayButtonWrapper mt="3">
        <Button.PlayButton
          width="56px"
          height="56px"
          audioUrl={getMediaUrl(
            audio.phone,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: 27.2 }}
          mr={3}
        />

        <Col w={[3, 7, 7]} mlM="3">
          <Button.BasicButton
            id="phone-button"
            to={`tel:${facilitator?.phoneNumber}`}
            variant="tertiary"
            external
            disabled={getFacilitatorLoading || getFacilitatorError}
          >
            Phone
          </Button.BasicButton>
        </Col>
      </S.PlayButtonWrapper>
      {getFacilitatorError ? (
        <T.P mb="2" color="error" mt="4">
          {getFacilitatorError?.message}
        </T.P>
      ) : null}
    </S.Wrapper>
  );
};

export default Profile;
