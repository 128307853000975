import * as S from './style';
import Icon from '../Icon';
import * as T from '../Typography';
import { GENERAL, COACH } from '../../constants/nav-routes';

const Title = ({
  isLoggedIn, // this should comes from the user context when its ready!
  color = 'neutralLight',
  lightText,
  boldText,
  icon,
  iconProps,
  ...props
}) => {
  return (
    <S.TitleContainer
      to={isLoggedIn ? COACH.DASHBOARD : GENERAL.LOGIN}
      {...props}
    >
      <Icon icon={icon} color={color} {...iconProps} />
      <T.H1 weight="regular" ml="1" as="span" color={color}>
        {lightText}
      </T.H1>
      <T.H1 ml="1" as="span" color={color}>
        {boldText}
      </T.H1>
    </S.TitleContainer>
  );
};

export default Title;
