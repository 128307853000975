import { useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import Markdown from '../../../../components/Markdown';
import { createH1 } from '../../../../utils/markdown';
import DisplayMultipleWords from './DisplayMultipleWords';
import StepFooterButtons from '../StepFooterButtons';

import * as S from './style';

const ListenAndRepeatMultiple = ({
  data: {
    title,
    multipleWords,
    textAudio,
    textAudioUrl,
    textAudioKey,
    needsRowFormat,
  } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  selfLearning,
}) => {
  const [enableNext, setEnableNext] = useState(false);

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {textAudio && (textAudioUrl || textAudioKey) ? (
          <S.TextAudioWrapper mt={1} mb={4}>
            <PlayButton
              audioUrl={textAudioUrl}
              width="56px"
              height="56px"
              iconProps={{ width: '32px', height: '32px' }}
            />
            <T.P ml={4} weight="regular" size="medLarge">
              {textAudio}
            </T.P>
          </S.TextAudioWrapper>
        ) : null}
        {title ? (
          <Markdown
            text={createH1(title)}
            customStyles={{
              h1: {
                size: 'extraLarge',
                ta: 'center',
                weight: 'regular',
                mb: 2,
              },
            }}
          />
        ) : null}
        {multipleWords &&
        Array.isArray(multipleWords) &&
        multipleWords.length ? (
          <DisplayMultipleWords
            wordsArray={multipleWords}
            setEnableNext={setEnableNext}
            needsRowFormat={needsRowFormat}
          />
        ) : null}
      </S.ContentWrapper>
      <S.ButtonsWrapper>
        <StepFooterButtons
          stuckAtReview={stuckAtReview}
          handleNext={handleNext}
          preview={preview}
          disabled={disabled}
          enableNext={enableNext}
          selfLearning={selfLearning}
        />
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default ListenAndRepeatMultiple;
