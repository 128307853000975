import { useFetch, usePatch, usePost, useDelete } from '../utils/queries';
import { LEARNERS_GOALS_BASE } from '../constants';

function useGetLearnerGoals(options) {
  const context = useFetch(`${LEARNERS_GOALS_BASE}`, undefined, options);
  return { ...context };
}

function useUpdateLearnerGoalTask({
  taskId,
  personalGoalId,
  title,
  completed,
}) {
  return usePatch(`${LEARNERS_GOALS_BASE}/${personalGoalId}/tasks/${taskId}`, {
    title,
    completed,
  });
}

function useCreateLearnerGoalTask({ personalGoalId, title }) {
  return usePost(`${LEARNERS_GOALS_BASE}/${personalGoalId}/tasks`, {
    title,
  });
}

function useUpdateLearnerGoal({ personalGoalId, title, completed }) {
  return usePatch(`${LEARNERS_GOALS_BASE}/${personalGoalId}`, {
    title,
    completed,
  });
}

function useDeleteLearnerGoal({ personalGoalId }) {
  return useDelete(`${LEARNERS_GOALS_BASE}/${personalGoalId}`);
}

function useCreateLearnerGoal({ title, learnerId }) {
  const context = usePost(`${LEARNERS_GOALS_BASE}`, {
    title,
    learnerId,
  });
  return context;
}

export {
  useGetLearnerGoals,
  useUpdateLearnerGoalTask,
  useCreateLearnerGoalTask,
  useUpdateLearnerGoal,
  useDeleteLearnerGoal,
  useCreateLearnerGoal,
};
