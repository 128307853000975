import * as T from '../../../../components/Typography';
import { PlayButton, BasicButton } from '../../../../components/Button';
import { audio } from '../../../../constants';
import { getMediaUrl } from '../../../../helpers';
import { useGeneralState } from '../../../../context/general-state';

import * as S from './style';
// import Preview from './Preview';
import { AudioOut } from '../../../../components/WordButton';

const OtherCorrectAnswers = ({
  disabled,
  enableNext,
  handleNext,
  data: { correctOptions = [] } = {},
}) => {
  const { state: generalState } = useGeneralState();

  return (
    <S.Wrapper style={{ maxWidth: '320px' }}>
      <PlayButton
        audioUrl={getMediaUrl(
          audio.greatJobOtherWordsYouCouldHaveMade,
          true,
          generalState?.preferredVoice
        )}
        iconProps={{ width: '32px', height: '32px' }}
        padding="12px"
      >
        Great job, here are the all the other words you could have made
      </PlayButton>

      {correctOptions.map((e, i) => (
        <AudioOut mt={i === 0 ? 6 : 3} text={e.option} audioUrl={e.audioUrl} />
      ))}

      <S.ButtonWrapper flex={1}>
        <BasicButton
          disabled={disabled}
          handleClick={handleNext}
          icon="next"
          variant="primary"
          mt={4}
        >
          <T.P color="white" weight="semi">
            Next
          </T.P>
        </BasicButton>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default OtherCorrectAnswers;
