import styled from '@emotion/styled';
import * as T from '../../../components/Typography';

export const StatsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${({ theme }) => theme.media.mobile} {
    width: 102vw;
    margin-left: -5%;
    justify-content: center;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[5]}`};
  width: 50%;
  min-width: 300px;
  background: ${({ theme }) => theme.colors.neutralLight};
  border-radius: 16px;
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;

export const StyledP = styled(T.P)`
  padding: 10px 0px 0px 8px !important;
`;
