import { useState, useEffect } from 'react';
import { useAuth } from '../../../context/auth';
import { GroupCheckbox } from '../../../components/Inputs';
import Video from '../../../components/Video';
import { Col, Row } from '../../../components/Grid';
import { Coaches } from '../../../api-calls';
import { BasicButton } from '../../../components/Button';
import { navRoutes } from '../../../constants/';
import * as T from '../../../components/Typography';
import * as S from './style';

const CoachTrainingChecklist = ({ setIsModalVisible }) => {
  const { user, setUser } = useAuth();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const coachId = user.id;

  const _steps = [
    {
      id: 0,
      label: 'Watch the video below on how to use the tool',
      font: 'regular',
      component: (
        <S.VideoCard>
          <Video
            width="100%"
            height="236px"
            src={navRoutes.EXTERNAL.HOW_TO_USE_VIDEO}
          />
        </S.VideoCard>
      ),
    },
    {
      id: 1,
      label:
        'Watch the onboarding demo to understand how to onboard a learner and familarise yourself with the app',
      font: 'regular',
      component: (
        <S.VideoCard>
          <Video
            width="100%"
            height="236px"
            src={navRoutes.EXTERNAL.ONBOARDING_LEARNERS_VIDEO}
          />
        </S.VideoCard>
      ),
    },
    {
      id: 2,
      label: (
        <>
          <T.P>
            Complete your online training session (you will need your Shannon
            Trust Moodle account to do this).
          </T.P>
          <T.Link
            underline
            color="neutralMain"
            external
            target="_blank"
            to={navRoutes.EXTERNAL.MOODLE}
          >
            Click here to book your slot
          </T.Link>
        </>
      ),
      font: 'regular',
    },
  ];

  const [steps, setSteps] = useState(_steps);

  useEffect(() => {
    if (Object.values(steps).every((v) => v.checked === true))
      setDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);

  useEffect(() => {
    const checkLocalStorage = () => {
      const _video = localStorage.getItem('video');
      _steps[0].checked = _video === 'true' ? true : false;
      setSteps(_steps);
    };

    checkLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await Coaches.updateCoach({ coachId, onboarded: true });
    setIsModalVisible(true);
    setUser({ ...user, onboarded: true });
    setLoading(false);
  };

  return (
    <Row>
      <Col w={[4, 8, 12]} mt="4">
        <T.P color="neutral90">
          Before you can start coaching learners you need to complete the
          training checklist below
        </T.P>
      </Col>
      <Col w={[4, 8, 12]} mt="6">
        <T.H2 color="neutralMain">Training checklist</T.H2>
      </Col>
      <Col w={[4, 12, 12]} mt="4" jc="center">
        <GroupCheckbox state={steps} setState={setSteps} font={'regular'} />
      </Col>
      <Col w={[4, 12, 12]} mt="3">
        <BasicButton
          variant="primary"
          handleClick={handleSubmit}
          disabled={disabled}
          loading={loading}
        >
          I’ve completed my training
        </BasicButton>
      </Col>
    </Row>
  );
};

export default CoachTrainingChecklist;
