import { useState } from 'react';
import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';

import { ProfileStats } from '../../../components';
import { useAuth } from '../../../context/auth';
import { BasicButton } from './../../../components/Button';
import EditSection from './EditSection';
import { toTitleCase } from '../../../helpers';
import { Loading } from '../../../components';
import { useGetDefaultFacilitator } from '../../../api-calls/facilitator.queries';
import { useGetCoachDetails } from '../../../api-calls/coaches.queries';

import * as S from './style';

const Profile = () => {
  const [showEditSection, setShowEditSection] = useState(false);
  const { user } = useAuth();
  const {
    facilitator,
    error: getFacilitatorError,
    isLoading: getFacilitatorLoading,
  } = useGetDefaultFacilitator();
  const { coach, isError, error, isLoading } = useGetCoachDetails({
    coachId: user.id,
  });

  if (isLoading) return <Loading />;

  return (
    <>
      <Row>
        <Col w={[4, 9, 8]} mt="5" mb="3">
          <T.H2>Profile</T.H2>
        </Col>
      </Row>
      <S.StatsWrapper>
        <ProfileStats
          backgroundColor="white"
          type="noOfLearners"
          learners={Number(coach.totalLearners)}
        />

        <ProfileStats
          type="coachingOnTheApp"
          hours={coach.totalCoachingTime.hours}
          days={coach.totalCoachingTime.days}
          months={coach.totalCoachingTime.months}
        />
      </S.StatsWrapper>
      <Row>
        <Col w={[4, 9, 8]} mt="6" mb="3" ml="2">
          <T.H2>My details</T.H2>
        </Col>
      </Row>

      {!showEditSection ? (
        <S.CardWrapper>
          <Row>
            <Col w={[4, 9, 8]}>
              <T.P>Name</T.P>
            </Col>

            <Col w={[4, 9, 8]}>
              <T.P weight="bold">{coach.fullName || '-'}</T.P>
            </Col>
          </Row>

          <Row mt="4">
            <Col w={[4, 9, 8]}>
              <T.P>Email address</T.P>
            </Col>

            <Col w={[4, 9, 8]}>
              <T.P weight="bold">{coach.email || '-'}</T.P>
            </Col>
          </Row>

          <Row mt="4">
            <Col w={[4, 9, 8]}>
              <T.P>Capacity</T.P>
            </Col>

            <Col w={[4, 12, 12]}>
              <T.P weight="bold">
                {coach.availabilityHoursPerWeek || '-'} |{' '}
                {(coach.bestDayTime && toTitleCase(coach.bestDayTime)) || '-'}
              </T.P>
            </Col>
          </Row>

          <Row mt="4">
            <Col w={[4, 12, 12]}>
              <BasicButton onClick={() => setShowEditSection(true)}>
                Edit
              </BasicButton>{' '}
            </Col>
          </Row>
        </S.CardWrapper>
      ) : (
        <EditSection
          availableHoursPerWeek={coach.availabilityHoursPerWeek}
          bestTime={coach.bestDayTime}
          fName={coach.firstName}
          lName={coach.lastName}
          coachEmail={coach.email}
          setShowEditSection={setShowEditSection}
        />
      )}

      <Row>
        <Col w={[4, 9, 8]} mt="6">
          <T.H2>I can’t coach anymore</T.H2>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 7, 6]} mt="3">
          <T.P>
            To arrange the handover of your learners and deactivate your account
            you need to email Shannon Trust
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 7, 6]} mt="3">
          <BasicButton
            to={`mailto:${facilitator?.email}`}
            external
            disabled={getFacilitatorLoading || getFacilitatorError}
          >
            Email Shannon Trust
          </BasicButton>
        </Col>
      </Row>

      <Row>
        <Col w={[4, 9, 8]} mt="6">
          <T.H2>Delete my account</T.H2>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 7, 6]} mt="3">
          <T.P>
            If you would like to delete your account and all personal data,
            please contact Turning Pages
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 7, 6]} mt="3">
          <BasicButton
            to={`mailto:${facilitator?.email}`}
            external
            disabled={getFacilitatorLoading || getFacilitatorError}
          >
            Email
          </BasicButton>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 7, 6]} mt="2">
          <BasicButton
            to={`tel:${facilitator?.phoneNumber}`}
            external
            variant="secondary"
            disabled={getFacilitatorLoading || getFacilitatorError}
          >
            Phone
          </BasicButton>
        </Col>
      </Row>
      {(isError || getFacilitatorError) && (
        <Row>
          <T.P color="error">
            {' '}
            {error?.message || getFacilitatorError.message}
          </T.P>
        </Row>
      )}
    </>
  );
};

export default Profile;
