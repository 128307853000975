import { useState, useEffect } from 'react';
import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import TextFinderMdContent from './TextFinderMdContent';
import StepFooterButtons from '../StepFooterButtons';
import { useGeneralState } from '../../../../context/general-state';
import { UseExerciseWithSelfLearning } from '../../../../Hooks';

import * as S from './style';

const regex = /%(.*?)%/g;

const TextFinderComprehension = ({
  data: {
    text = '',
    textAudio,
    textAudioUrl,
    exampleStep,
    correctOptions = [],
  } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  selfLearning,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { setState } = useGeneralState();

  const formattedCorrectOptions =
    correctOptions.map((el, idx) => ({
      ...el,
      id: idx,
    })) || [];

  const matches = text?.match(regex)?.map((e) => e.replace(/%/g, '')) || [];

  const containsAllOptions = selectedOptions.length === correctOptions.length;

  const hasCorrectData = correctOptions.every(
    ({ option }, idx) => matches[idx] === option
  );
  const { setSubProgress } = UseExerciseWithSelfLearning();

  useEffect(() => {
    if (!exampleStep) {
      setState({
        headerSteps: {
          show: true,
          requiredSteps: correctOptions.length,
          completedSteps: selectedOptions.length,
        },
      });
    }
    setSubProgress(selectedOptions.length / correctOptions.length);

    return () => {
      setState({
        headerSteps: {
          show: false,
          requiredSteps: null,
          completedSteps: null,
        },
      });
    };
  }, [
    correctOptions.length,
    exampleStep,
    selectedOptions.length,
    setState,
    setSubProgress,
  ]);

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {hasCorrectData ? (
          <>
            {textAudio && textAudioUrl ? (
              <S.TextAudioWrapper mt={1} mb={5}>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                />
                <T.P ml={4} weight="regular" size="med">
                  {textAudio}
                </T.P>
              </S.TextAudioWrapper>
            ) : null}
            {text ? (
              <S.OuterWrapper bg="neutralLight">
                <S.MdContentWrapper>
                  <TextFinderMdContent
                    text={text}
                    matches={matches}
                    exampleStep={exampleStep}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                  />
                </S.MdContentWrapper>
              </S.OuterWrapper>
            ) : null}
            {correctOptions.length ? (
              <S.TableWrapper>
                <S.Row mt={4} titleRow>
                  <S.Col>Syllables</S.Col>
                  <S.Col right>Word</S.Col>
                </S.Row>
                {formattedCorrectOptions.map(
                  ({ id, option, splitOption }, idx) => {
                    const isSelected = Boolean(
                      selectedOptions?.filter(
                        (el) => Number(el.id) === Number(id)
                      ).length > 0
                    );
                    return (
                      <S.Row key={`row-${option}-${idx}`}>
                        <S.Col weight="bold">
                          {' '}
                          {splitOption.length
                            ? splitOption.split('/').length
                            : '--'}
                        </S.Col>
                        <S.Col right>
                          {isSelected || exampleStep ? splitOption : '-'}
                        </S.Col>
                      </S.Row>
                    );
                  }
                )}
              </S.TableWrapper>
            ) : null}
          </>
        ) : (
          <div style={{ alignSelf: 'center' }}>
            <T.P color="error">Exercise is missing required fields </T.P>
          </div>
        )}
      </S.ContentWrapper>
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={containsAllOptions || exampleStep}
        selfLearning={selfLearning}
      />
    </S.Wrapper>
  );
};

export default TextFinderComprehension;
