import { useRef, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { probationOfficerAddLearner as validate } from '../../validation/schemas';
import { Learners } from '../../api-calls';
import { GENERAL } from '../../constants/nav-routes';
import { Col, Row } from '../../components/Grid';
import { BasicInput, Checkbox } from '../../components/Inputs';
import { BasicButton } from '../../components/Button';
import { navRoutes as NR } from '../../constants';
import ThankYou from './ThankYou';
import SideDetails from './SideDetails';
import Loading from '../../components/Loading';
import Form from './Form';
import * as T from '../../components/Typography';
import * as S from './style';
import { cleanEmail } from '../../helpers';

const initialState = {
  form: {
    firstName: '',
    lastName: '',
    hasDeviceInternetAccess: '',
    involvedBefore: '',
    preReleased: true,
    nomisId: '',
    delius: '',
    email: '',
    dateOfBirth: null,
    probationOfficerName: '',
    probationOfficerEmail: '',
    availability: '',
    additionalInfo: '',
    //referrer
    referrerFirstName: '',
    referrerLastName: '',
    referrerEmail: '',
    referrerPhoneNumber: '',
    relationship: '',
    acceptPrivacyPolicy: '',
    confirmedPermissionType: '',
  },
  httpError: '',
  validationErrs: { contactLinks: {} },
  loading: false,
  submitLoading: false,
  isModalVisible: false,
};

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const ReferLearner = () => {
  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);

  const {
    form: {
      firstName,
      lastName,
      hasDeviceInternetAccess,
      involvedBefore,
      preReleased,
      nomisId,
      delius,
      email,
      dateOfBirth,
      probationOfficerName,
      probationOfficerEmail,
      availability,
      additionalInfo,
      referrerFirstName,
      referrerLastName,
      referrerEmail,
      referrerPhoneNumber,
      relationship,
      acceptPrivacyPolicy,
      confirmedPermissionType,
    },
    form,
    loading,
    submitLoading,
    validationErrs,
    httpError,
  } = state;
  const navigate = useNavigate();

  const setFormData = (data) =>
    setState((prevState) => ({ form: { ...prevState.form, ...data } }));

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstName,
    lastName,
    hasDeviceInternetAccess,
    involvedBefore,
    preReleased,
    nomisId,
    delius,
    email,
    probationOfficerName,
    probationOfficerEmail,
    availability,
    additionalInfo,
    referrerFirstName,
    referrerLastName,
    referrerEmail,
    referrerPhoneNumber,
    relationship,
    dateOfBirth,
    acceptPrivacyPolicy,
    confirmedPermissionType,
  ]);
  const validateForm = () => {
    try {
      validate(state.form);
      setState({ validationErrs: { hasError: false } });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  };

  const handleAddLearner = async () => {
    setState({ submitLoading: true });

    const { error } = await Learners.createLearner({
      form: {
        ...state.form,
        email: cleanEmail(email),
        referrerEmail: cleanEmail(referrerEmail),
      },
    });
    setState({ submitLoading: false });
    if (error) {
      setState({
        httpError: error.message,
        validationErrs:
          error?.data?.field === 'email'
            ? {
                ...validationErrs,
                email: error.message,
              }
            : { ...validationErrs },
      });
    } else {
      navigate(GENERAL.REFER_A_LEARNER_THANK_YOU);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleAddLearner();
    }
  };
  if (loading) return <Loading />;
  return (
    <S.Wrapper>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H2 color="neutralMain" weight="bold" mt={1}>
            Welcome! Do you know a new learner that would like to connect with
            Turning pages?
          </T.H2>
          <T.P color="neutralMain" mt={4}>
            Fill in the short form below and we will get in touch to discuss
            your learner’s needs and next steps to get them started.
          </T.P>
          <T.P color="neutralMain" mt={4}>
            If you would prefer to do this over the phone or have any questions
            then give us a call on 07704302607.
          </T.P>
        </Col>

        <Col w={[4, 12, 12]}>
          <T.H2 color="neutralMain" weight="bold" mt={6}>
            Learner details
          </T.H2>
        </Col>
      </Row>
      <Form
        setFormData={setFormData}
        state={form}
        validationErrs={validationErrs}
      />
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H2 color="neutralMain" weight="bold" mt={6}>
            My details
          </T.H2>
          <T.P color="neutralMain" mt={3}>
            So we can get in touch with you to discuss next steps please fill in
            your contact details below.
          </T.P>
        </Col>
        <Col w={[4, 6, 6]} mt="6">
          <BasicInput
            label="First name"
            placeholder="First name..."
            value={referrerFirstName}
            name="referrerFirstName"
            handleChange={(referrerFirstName) =>
              setFormData({ referrerFirstName })
            }
            error={validationErrs.referrerFirstName}
          />
        </Col>
        <Col w={[4, 6, 6]} mt="6">
          <BasicInput
            label="Last name"
            placeholder="Last name..."
            value={referrerLastName}
            name="referrerLastName"
            handleChange={(referrerLastName) =>
              setFormData({ referrerLastName })
            }
            error={validationErrs.referrerLastName}
          />
        </Col>
        <Col w={[4, 6, 6]} mt="6">
          <BasicInput
            label="Email address"
            placeholder="Email..."
            value={referrerEmail}
            name="referrerEmail"
            handleChange={(referrerEmail) => setFormData({ referrerEmail })}
            error={validationErrs.referrerEmail}
          />
        </Col>
        <Col w={[4, 6, 6]} mt="6">
          <BasicInput
            label="Phone number"
            placeholder="Phone..."
            value={referrerPhoneNumber}
            name="referrerPhoneNumber"
            handleChange={(referrerPhoneNumber) =>
              setFormData({ referrerPhoneNumber })
            }
            error={validationErrs.referrerPhoneNumber}
          />
        </Col>
        <Col w={[4, 6, 6]} mt="6">
          <BasicInput
            label="Relationship with learner"
            placeholder="e.g. Probation Service Officer"
            value={relationship}
            name="relationship"
            handleChange={(relationship) => setFormData({ relationship })}
            error={validationErrs.relationship}
          />
        </Col>
      </Row>

      <Row>
        <Col w={[4, 6, 12]} mt="5">
          <Checkbox
            plain
            label={
              <T.P>
                I agree to the
                <T.Link
                  color={'neutralMain'}
                  weight="bold"
                  underline
                  external
                  to={NR.EXTERNAL.TERMS_OF_USE}
                >
                  Terms of Use
                </T.Link>
                .&nbsp;By clicking submit I acknowledge the Turning Pages{' '}
                <T.Link
                  color={'neutralMain'}
                  weight="bold"
                  underline
                  external
                  to={NR.EXTERNAL.PRIVACY_POLICY}
                >
                  Privacy Policy
                </T.Link>
                .
              </T.P>
            }
            checked={form.acceptPrivacyPolicy}
            handleChange={(acceptPrivacyPolicy) => {
              setFormData({ acceptPrivacyPolicy });
            }}
            error={validationErrs.acceptPrivacyPolicy}
          />
        </Col>
        <Col w={[4, 6, 12]} mt="5">
          <Checkbox
            plain
            label={
              <T.P>
                I confirm I am a staff member employed by the HMPPS or MoJ and
                so am able to provide the details of the person I am referring
                under Part 3 of the Data Protection Act 2018
              </T.P>
            }
            checked={confirmedPermissionType === 'STAFF_MEMBER'}
            handleChange={(checked) => {
              setFormData({
                confirmedPermissionType: checked ? 'STAFF_MEMBER' : '',
              });
            }}
            error={validationErrs.confirmedPermissionType}
            hideErrorMsg
          />
        </Col>
        <T.P weight="bold" ml="7">
          OR
        </T.P>
        <Col w={[4, 6, 12]} mt="3">
          <Checkbox
            plain
            label={
              <T.P>
                I confirm I have spoken to the person I am referring and have
                their consent to provide their data on their behalf
              </T.P>
            }
            checked={confirmedPermissionType === 'PERSON'}
            handleChange={(checked) => {
              setFormData({ confirmedPermissionType: checked ? 'PERSON' : '' });
            }}
            error={validationErrs.confirmedPermissionType}
            hideErrorMsg
          />
        </Col>
        {validationErrs.confirmedPermissionType && (
          <Row>
            <Col w={[4, 12, 12]} mt={3}>
              <T.P color="error">{validationErrs.confirmedPermissionType}</T.P>
            </Col>
          </Row>
        )}
      </Row>

      <Row mt="5">
        {httpError && (
          <Col w={[4, 12, 12]}>
            <T.P color="error" mb={3}>
              {httpError}
            </T.P>
          </Col>
        )}
        {validationErrs.hasError && (
          <Col w={[4, 12, 12]}>
            <T.P color="error" mb={3}>
              Please fill all the required fields
            </T.P>
          </Col>
        )}
        <Col w={[4, 6, 6]}>
          <BasicButton
            variant="primary"
            disabled={validationErrs.hasError}
            loading={submitLoading}
            handleClick={handleSubmit}
          >
            <T.P color="white" weight="semi">
              Onboard learner
            </T.P>
          </BasicButton>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

ReferLearner.SideDetails = SideDetails;

export { ThankYou, SideDetails };
export default ReferLearner;
