import {
  types as Ty,
  comprehensionsKeys,
  exerciseTypes,
} from '../../../constants';

import ListenAndRepeat from '../../../pages/Exercises/ExerciseStep/ListenAndRepeat';
import ListenAndRepeatMultiple from '../../../pages/Exercises/ExerciseStep/ListenAndRepeatMultiple';
import ListenAndRepeatGrid from '../../../pages/Exercises/ExerciseStep/ListenAndRepeatGrid';
import ListenAndRepeatGridHidden from '../../../pages/Exercises/ExerciseStep/ListenAndRepeatGridHidden';
import MultipleChoice from '../../../pages/Exercises/ExerciseStep/MultipleChoice';
import WordBuild, {
  OtherCorrectAnswers,
} from '../../../pages/Exercises/ExerciseStep/WordBuild';
import SpotTheWord from '../../../pages/Exercises/ExerciseStep/SpotTheWord';
import MultipleChoiceGrid from '../../../pages/Exercises/ExerciseStep/MultipleChoiceGrid';
import {
  DragAndDropV1,
  DragAndDropV2,
  DragAndDropOrderSounds,
} from '../../../pages/Exercises/ExerciseStep/DragAndDrop';
import VowelBreak from '../../../pages/Exercises/ExerciseStep/VowelBreak';
import {
  WordJoin1,
  WordJoin2,
} from '../../../pages/Exercises/ExerciseStep/WordJoin';
import SentenceFill from '../../../pages/Exercises/ExerciseStep/SentenceFill';
import TypeNewEndings from '../../../pages/Exercises/ExerciseStep/TypeNewEndings';
import {
  TextFinderBasic,
  TextFinderComprehension,
} from '../../../pages/Exercises/ExerciseStep/TextFinder';
import CombineWords, {
  CombineWordsPre,
} from '../../../pages/Exercises/ExerciseStep/CombineWords';

import Comprehensions from '../../../pages/Exercises/ExerciseStep/Comprehensions';

const obj = {
  [Ty.exerciseTypes.LISTEN_AND_REPEAT]: ListenAndRepeat,
  [Ty.exerciseTypes.LISTEN_AND_REPEAT_MULTIPLE]: ListenAndRepeatMultiple,
  [Ty.exerciseTypes.LISTEN_AND_REPEAT_GRID]: ListenAndRepeatGrid,
  [Ty.exerciseTypes.LISTEN_AND_REPEAT_GRID_HIDDEN]: ListenAndRepeatGridHidden,
  [Ty.exerciseTypes.MULTIPLE_CHOICE]: MultipleChoice,
  [Ty.exerciseTypes.MULTIPLE_CHOICE_BUBBLE]: MultipleChoice,
  [Ty.exerciseTypes.WORD_BUILD]: WordBuild,
  [Ty.exerciseTypes.SPOT_THE_WORD]: SpotTheWord,
  [Ty.exerciseTypes.DRAG_AND_DROP_1]: DragAndDropV1,
  [Ty.exerciseTypes.DRAG_AND_DROP_2]: DragAndDropV2,
  [Ty.exerciseTypes.DRAG_AND_DROP_ORDER_SOUNDS]: DragAndDropOrderSounds,
  [Ty.exerciseTypes.VOWEL_BREAK]: VowelBreak,
  [Ty.exerciseTypes.WORD_JOIN_1]: WordJoin1,
  [Ty.exerciseTypes.WORD_JOIN_2]: WordJoin2,
  [Ty.exerciseTypes.SENTENCE_FILL]: SentenceFill,
  [Ty.exerciseTypes.COMBINE_WORDS_PRE]: CombineWordsPre,
  [Ty.exerciseTypes.COMBINE_WORDS]: CombineWords,
  [Ty.exerciseTypes.TYPE_NEW_ENDINGS]: TypeNewEndings,
  [Ty.exerciseTypes.MULTIPLE_CHOICE_GRID]: MultipleChoiceGrid,
  [Ty.exerciseTypes.TEXT_FINDER]: TextFinderBasic,
  [Ty.exerciseTypes.COMPREHENSION_TEXT_FINDER]: TextFinderComprehension,
};

const Preview = ({ type, step, exercise }) => {
  if (comprehensionsKeys.includes(exercise.key)) {
    return (
      <Comprehensions exKey={exercise.key} disabled cmsPreview {...step} />
    );
  }

  const Component = obj[type];
  return type ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Component
        cmsPreview
        disabled
        bubble={type === Ty.exerciseTypes.MULTIPLE_CHOICE_BUBBLE}
        {...step}
      />
      {type === exerciseTypes.WORD_BUILD && (
        <OtherCorrectAnswers cmsPreview disabled {...step} />
      )}
    </div>
  ) : null;
};

export default Preview;
