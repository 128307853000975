import styled from '@emotion/styled';

export const ButtonWrapper = styled.div`
  max-width: 300px;
  margin: 0 auto;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  flex: ${({ flex }) => flex || 0};
  display: flex;
  flex-direction: column;
  align-items: end;
  flex: 1;
`;
