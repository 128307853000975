import { fields, createSchema, validate as _validate } from '..';

const { textMax300Required } = fields;

const issue = createSchema({
  notes: textMax300Required,
});

const validate = (data) => _validate(issue, data);

export default validate;
