import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Header = styled.header`
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.primaryMain};
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  position: sticky;
  width: 100%;
  top: 0;
  height: ${({
    theme: {
      constants: { layout },
    },
  }) => layout.header.height};
  padding: ${({ theme }) =>
    `${theme.spacings[0]} ${theme.spacings[5]} ${theme.spacings[0]} ${theme.spacings[5]}`};

  ${({ theme }) => theme.media.tablet} {
    width: 100%;
    padding: ${({ theme }) => `${theme.spacings[0]} ${theme.spacings[5]} `};
  }
`;

export const TitleContainer = styled(Link)`
  display: flex;
`;

export const IconsContainer = styled.div`
  display: flex;
`;

export const ProgressWrapper = styled.header`
  display: flex;
  height: ${({
    theme: {
      constants: { layout },
    },
  }) => layout.header.progressHeight};
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: ${({ theme }) => `${theme.spacings[0]} ${theme.spacings[5]}`};
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.white};

  .ant-progress-inner {
    background-color: ${({ theme }) => theme.colors.neutral30};
  }
`;

export const IconButton = styled.button`
  border: none;
  background: none;
  outline: none;
`;

export const MenuWrapper = styled.div`
  display: flex;
  width: 450px;
  justify-content: space-between;
  flex-direction: row;
  color: white;
  & div > a {
    padding: 6px 8px;
  }
`;
