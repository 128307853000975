import axios from 'axios';
import handleError from './format-error';

const FACILITATORS_BASE = '/facilitators';

// const getFacilitatorCoaches = async ({ options } = {}) => {
//   try {
//     const { data } = await axios.get(`${FACILITATORS_BASE}/coaches`);
//     return { data };
//   } catch (error) {
//     const err = handleError(error, options);
//     return { error: err };
//   }
// };

const getFacilitatorById = async ({ facilitatorId, options } = {}) => {
  try {
    const { data } = await axios.get(`${FACILITATORS_BASE}/${facilitatorId}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};
const getFacilitatorByProbationArea = async ({
  ProbationArea,
  options,
} = {}) => {
  try {
    const { data } = await axios.get(
      `${FACILITATORS_BASE}/probation-area/${ProbationArea}`
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

// const getFacilitatorLearners = async ({ options } = {}) => {
//   try {
//     const { data } = await axios.get(`${FACILITATORS_BASE}/learners`);
//     return { data };
//   } catch (error) {
//     const err = handleError(error, options);
//     return { error: err };
//   }
// };

export {
  // getFacilitatorCoaches,
  // getFacilitatorLearners,
  getFacilitatorById,
  getFacilitatorByProbationArea,
};
