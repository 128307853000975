import { useNavigate } from 'react-router-dom';
import { useGeneralState } from '../../../context/general-state';
import { Typography as T, Button } from '../../../components';
import * as S from './style';
import { getMediaUrl } from '../../../helpers';

import { navRoutes as R, audio } from '../../../constants';

const Menu = () => {
  const navigate = useNavigate();
  const { state: generalState } = useGeneralState();

  return (
    <S.Wrapper>
      <S.PlayButtonWrapper>
        <Button.PlayButton
          audioUrl={getMediaUrl(
            audio.myExercises,
            true,
            generalState?.preferredVoice
          )}
          mr={4}
        />
        <div style={{ width: '227px' }}>
          <Button.BasicButton
            icon="exercises"
            variant="primary"
            handleClick={() => navigate(R.LEARNER.DASHBOARD)}
          >
            <T.P color="white" weight="bold">
              My exercises
            </T.P>
          </Button.BasicButton>
        </div>
      </S.PlayButtonWrapper>
      <S.PlayButtonWrapper mt="3">
        <Button.PlayButton
          audioUrl={getMediaUrl(
            audio.myGoals,
            true,
            generalState?.preferredVoice
          )}
          mr={4}
        />
        <div style={{ width: '227px' }}>
          <Button.BasicButton
            icon="goals"
            variant="secondary"
            handleClick={() => navigate(R.LEARNER.GOALS)}
          >
            <T.P color="white" weight="bold">
              My goals
            </T.P>
          </Button.BasicButton>
        </div>
      </S.PlayButtonWrapper>
      <S.PlayButtonWrapper mt="3">
        <Button.PlayButton
          audioUrl={getMediaUrl(
            audio.myProfile,
            true,
            generalState?.preferredVoice
          )}
          mr={4}
        />
        <div style={{ width: '227px' }}>
          <Button.BasicButton
            icon="profile"
            variant="tertiary"
            iconProps={{
              width: '23px',
              height: '23px',
            }}
            handleClick={() => navigate(R.LEARNER.PROFILE)}
          >
            My profile
          </Button.BasicButton>
        </div>
      </S.PlayButtonWrapper>
    </S.Wrapper>
  );
};

export default Menu;
