import { useNavigate, useParams, useLocation } from 'react-router-dom';
import * as T from '../../../components/Typography';
import { BasicButton, PlayButton } from '../../../components/Button';
import { Row, Col } from '../../../components/Grid';
import Badge from '../../../components/Badge';
import data from '../../../constants/badges';
import { getMediaUrl } from '../../../helpers';

import * as S from './style';
import { UseExerciseWithSelfLearning } from '../../../Hooks';
import { useGeneralState } from '../../../context/general-state';
import { navRoutes } from '../../../constants';
import { Badges } from '../../../api-calls';

const WonNewBadge = () => {
  const { badgeType } = useParams();
  const { handleNext } = UseExerciseWithSelfLearning();
  const { state } = useLocation();
  const { mutateAsync, isLoading } = Badges.UpdateBadges();

  const {
    state: { preferredVoice },
  } = useGeneralState();
  const navigate = useNavigate();

  const onNext = async () => {
    await mutateAsync({ markAllAsSeen: true });
    if (state?.isAllExercisesCompleted) {
      navigate(
        navRoutes.LEARNER.SUCCESS_MILESTONE.replace(':id', state?.milestoneId)
      );
    } else {
      handleNext({ isExerciseCompletedPage: true });
    }
  };

  return (
    <S.Wrapper>
      <Row jc="center">
        <Col w={[4, 8, 8]}>
          <PlayButton
            audioUrl={getMediaUrl(data[badgeType].audio, true, preferredVoice)}
            iconProps={{ width: '32px', height: '32px' }}
            padding="12px"
          >
            {data[badgeType].audioText}
          </PlayButton>
        </Col>
      </Row>
      <Row jc="center" mt={7} mb={7}>
        <Badge type={badgeType} large />
      </Row>
      <Row jc="center" ai="flex-end" style={{ flex: 1 }}>
        <Col w={[4, 8, 8]}>
          <S.ButtonWrapper flex="1">
            <BasicButton
              handleClick={onNext}
              icon="next"
              variant="primary"
              iconProps={{ width: '20', height: '20' }}
              loading={isLoading}
            >
              <T.P color="white" weight="semi">
                Next
              </T.P>
            </BasicButton>
          </S.ButtonWrapper>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default WonNewBadge;
