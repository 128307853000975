import { useReducer } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, number, array, object } from 'yup';
import MDEditor from '../../../../components/MDEditor';
import { MediaKeyInput } from '../../../Components';
import { Checkbox } from 'antd';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data
    ? {
        ...data,
        correctOptions: data?.correctOptions?.map((e, i) => ({
          ...e,
          id: i,
        })) || [{ id: 0, splitOption: '', option: '' }],
      }
    : {
        text: '',
        correctOptions: [{ id: 0, splitOption: '', option: '' }],
        textAudioKey: '',
        textAudio: '',
        exampleStep: false,
      },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const TextFinderComprehension = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const _correctOptions =
        data?.correctOptions || prevState?.form?.correctOptions;
      const form = {
        ...prevState.form,
        ...data,
      };
      onChange({
        ...form,
        correctOptions: _correctOptions,
      });
      return { form };
    });

  const removeCorrectOptions = (id) => {
    setFormData({
      correctOptions: state.form.correctOptions.filter((e) => e.id !== id),
    });
  };

  const onCorrectOptionsSplitChange = (value, id) => {
    setFormData({
      correctOptions: state.form.correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, splitOption: value };
      }),
    });
  };
  const onCorrectOptionsOptionChange = (value, id) => {
    setFormData({
      correctOptions: state.form.correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const addOption = () => {
    const maxId = Math.max(...state.form.correctOptions.map((e) => e.id), 0);
    setFormData({
      correctOptions: [
        ...state.form.correctOptions,
        { id: maxId + 1, option: '', splitOption: '' },
      ],
    });
  };

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={state.form.textAudio}
        handleChange={(textAudio) => setFormData({ textAudio })}
        m={{ mt: 8 }}
        error={errors?.textAudio}
      />
      <MediaKeyInput
        label={`Explainer Text file Key`}
        value={state.form.textAudioKey}
        handleChange={(textAudioKey) => {
          setFormData({ textAudioKey });
        }}
        m={{ mt: 5 }}
        error={errors?.textAudioKey}
      />

      <T.P mt={8}>
        <Checkbox
          checked={state.form.exampleStep}
          onChange={(e) => setFormData({ exampleStep: e.target.checked })}
        >
          <T.P size="med" weight="bold">
            Example Step?
          </T.P>
        </Checkbox>
        check to allow learner to skip
      </T.P>

      <MDEditor
        value={state.form.text}
        onChange={(text) => setFormData({ text })}
        mode={'edit'}
        label="Text"
        m={{ mt: 8 }}
        helper='put "%" around the letter/word to make it selectable'
        error={errors.text}
      />

      <T.P mt={8} size="large" weight="bold">
        Syllables table{' '}
      </T.P>

      {errors?.correctOptions && typeof errors.correctOptions === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.correctOptions}
        </T.P>
      )}

      {state.form.correctOptions.map((e, i) => {
        return (
          <>
            <BasicInput
              label={`Word ${i + 1}`}
              value={e.option}
              handleChange={(value) => {
                onCorrectOptionsOptionChange(value, e.id);
              }}
              m={{ mb: 2, mt: 5 }}
              helper='add the words you quoted with the "%"'
              error={
                errors?.correctOptions && errors?.correctOptions[i]?.option
              }
            />

            <BasicInput
              label="Syllables Word"
              value={e.splitOption}
              handleChange={(value) => {
                onCorrectOptionsSplitChange(value, e.id);
              }}
              m={{ mb: 2, mt: 5 }}
              helper='e.g. "fit/ness"'
              error={
                errors?.correctOptions && errors?.correctOptions[i]?.splitOption
              }
            />

            {state.form.correctOptions.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeCorrectOptions(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}

      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={state.form.correctOptions.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add words
      </S.Button>
    </div>
  );
};

const validationSchema = {
  text: string().required('required field'),
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().required(),
          splitOption: string().required('required field'),
          option: string().required('required field'),
        })
        .required()
    )
    .required('You must provide correct words')
    .min(1, 'You must provide correct words'),
  textAudioKey: string().required('required field'),
  textAudio: string().required('required field'),
};
export { validationSchema };

export default TextFinderComprehension;
