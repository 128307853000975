import { useParams, useNavigate } from 'react-router-dom';
import { Col, Row } from '../../../components/Grid';
import { BigButton, PlayButton } from '../../../components/Button';
import { UseExerciseWithSelfLearning } from './../../../Hooks';
import { LearningSession } from './../../../api-calls';
import { navRoutes, audio } from './../../../constants';
import * as T from './../../../components/Typography';
import { getMediaUrl } from '../../../helpers';
import { useGeneralState } from '../../../context/general-state';

const SelectWithCoachOrNot = () => {
  const navigate = useNavigate();
  const { exerciseId } = useParams();
  const { state: generalState } = useGeneralState();

  const { setWithCoach } = UseExerciseWithSelfLearning();

  const {
    mutateAsync,
    error: httpError,
    isLoading,
    isError,
  } = LearningSession.useCreateLearningSession();

  const handleClick = async (withCoach) => {
    await mutateAsync({ selfLearning: !withCoach });
    setWithCoach(withCoach);
    navigate(
      navRoutes.EXERCISES.EXERCISE_EXPLAINER.replace(':exerciseId', exerciseId)
    );
  };

  return (
    <Row jc="center">
      <Col w={[4, 7, 7]} mtM={2}>
        <PlayButton
          audioUrl={getMediaUrl(
            audio.areYouLearningAloneOrWithYourCoach,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: '32px', height: '32px' }}
          padding="12px"
        >
          Are you learning alone or with your coach?
        </PlayButton>
      </Col>
      <Col w={[4, 7, 7]} mt="2">
        <BigButton
          variant={'byYourSelf'}
          onClick={() => handleClick(false)}
          mb={5}
          mt={5}
          loading={isLoading}
        />
        <BigButton
          variant={'withCoach'}
          onClick={() => handleClick(true)}
          loading={isLoading}
        />
        {isError && (
          <T.P color="error" mt={4}>
            {httpError.message}
          </T.P>
        )}
      </Col>
    </Row>
  );
};

export default SelectWithCoachOrNot;
