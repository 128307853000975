import { Route, Routes } from 'react-router-dom';

import { EXERCISES } from './../../constants/nav-routes';
import SelfLearning from './SelfLearning';
import Exercise from './Exercise';
const { BASE } = EXERCISES;

function ExercisesRouter() {
  return (
    <Routes>
      <Route
        path={`${EXERCISES.SL_BASE.replace(BASE, '')}/*`}
        element={<SelfLearning />}
      />
      <Route
        path={`${EXERCISES.BASE.replace(BASE, '')}/*`}
        element={<Exercise />}
      />
    </Routes>
  );
}

export default ExercisesRouter;
