import { Row, Col } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import { IconWithParagraph, Tip } from '../../../components';
import { BasicButton } from '../../../components/Button';
import { useParams } from 'react-router-dom';
import { COACH } from '../../../constants/nav-routes';
import * as S from './style';

const NewLearner = () => {
  const { userId } = useParams();

  return (
    <S.Wrapper>
      <T.P size="med" weight="bold">
        Getting started
      </T.P>
      <Row>
        <Col w={[4, 11, 9]}>
          <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
            <T.P>
              Have an <strong>introductory meeting</strong> to get to know your
              learner. In this meeting, click onboarding below so you can run
              through safeguarding and agree how and when you will meet. To
              arrange this please{' '}
              <T.Link
                to="mailto:chloe.bradbury@shannontrust.org.uk"
                external
                underline
                color="neutralMain"
                weight="bold"
              >
                contact Shannon Trust.
              </T.Link>
            </T.P>
          </IconWithParagraph>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 11, 9]}>
          <IconWithParagraph icon="smallTick" ai="flex-start" mt="3">
            <T.P>
              In this meeting you will also{' '}
              <strong>take them through a run-through</strong> on how the app
              and programme works.
            </T.P>
          </IconWithParagraph>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 11, 9]}>
          <IconWithParagraph icon="smallTick" ai="flex-start" mt="3">
            <T.P>
              When you get to the learner <strong>progress profile</strong>,{' '}
              please make sure to explain it’s not a test, just exploring where
              they're currently at with their reading.{' '}
            </T.P>
          </IconWithParagraph>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 11, 9]}>
          <S.TipWrapper>
            <Tip
              text={`Please note that you will need to be with them in person or on a call to complete these stages. The total process should not take longer than 30-40 minutes.
             **Important**: Please make sure you complete the onboarding in one go as this step is needed to get the tool setup for your learner before you can start coaching.`}
            />
          </S.TipWrapper>
        </Col>
      </Row>
      <S.ButtonsWrapper>
        <Row>
          <Col w={[4, 11, 9]} mt="5">
            <BasicButton
              variant="primary"
              to={COACH.INTRODUCTORY_MEETING.replace(':userId', userId)}
            >
              <T.P color="white" weight="semi">
                Start onboarding
              </T.P>
            </BasicButton>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 11, 9]} mt="3">
            <BasicButton variant="secondary" to={COACH.LEARNERS}>
              <T.P color="white" weight="semi">
                Go back
              </T.P>
            </BasicButton>
          </Col>
        </Row>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default NewLearner;
