import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';

export const MilestoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.neutralLight};
  margin-left: 12px !important ;
  max-width: 350px;
  margin-bottom: ${({ theme }) => theme.spacings[3]};
`;

export const Wrapper = styled.div`
  ${setMargin}
  display: flex;
`;

export const OuterWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings[1]};
`;
