import theme from '../../../theme';

const Speaker = ({ width, height, color, ...props }) => (
  <svg
    width={width || '42'}
    height={height || '27'}
    viewBox="0 0 42 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.56328 7.39032C9.37901 7.39032 10.1813 7.18243 10.8944 6.78628L21.5212 0.882483C22.9116 0.110029 24.665 0.610987 25.4374 2.0014C25.6751 2.42925 25.7998 2.91062 25.7998 3.40006V24.0077C25.7998 25.5983 24.5104 26.8877 22.9198 26.8877C22.5006 26.8877 22.0865 26.7962 21.7063 26.6196L10.8056 21.5546C10.172 21.2602 9.48171 21.1077 8.78301 21.1077H5.63984C2.98888 21.1077 0.839844 18.9586 0.839844 16.3077V12.1903C0.839844 9.53936 2.98888 7.39032 5.63984 7.39032H8.56328ZM30.5999 24.0394C29.8046 24.0394 29.1599 23.3947 29.1599 22.5994C29.1599 21.8042 29.8046 21.1594 30.5999 21.1594C35.1065 21.1594 38.7599 17.5061 38.7599 12.9994C38.7599 8.4928 35.1065 4.83944 30.5999 4.83944C29.8046 4.83944 29.1599 4.19473 29.1599 3.39944C29.1599 2.60415 29.8046 1.95944 30.5999 1.95944C36.6971 1.95944 41.6399 6.90222 41.6399 12.9994C41.6399 19.0967 36.6971 24.0394 30.5999 24.0394ZM29.1599 16.8394C29.1599 17.6347 29.8046 18.2794 30.5999 18.2794C33.5159 18.2794 35.8799 15.9155 35.8799 12.9994C35.8799 10.0834 33.5159 7.71944 30.5999 7.71944C29.8046 7.71944 29.1599 8.36415 29.1599 9.15944C29.1599 9.95473 29.8046 10.5994 30.5999 10.5994C31.9254 10.5994 32.9999 11.674 32.9999 12.9994C32.9999 14.3249 31.9254 15.3994 30.5999 15.3994C29.8046 15.3994 29.1599 16.0441 29.1599 16.8394Z"
      fill={color || theme.colors.neutralMain}
    />
  </svg>
);

export default Speaker;
