import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getMediaUrl } from '../../../../helpers';
import { useGeneralState } from '../../../../context/general-state';

import * as T from '../../../../components/Typography';
import {
  BasicButton,
  PlayButton,
  PlayBig,
} from '../../../../components/Button';

import EmptySpace from '../../../../components/EmptySpace';
import Option from '../../../../components/Option';
import * as S from './style';
import { showMovingBlock } from '../../../../helpers';
import { audio } from '../../../../constants/static-files';

import { navRoutes } from '../../../../constants';

const activePositionData = {
  // indexes
  0: {
    textAudio: 'Play the word and select first sound',
    textAudioUrl: audio.playTheWordAndSelectFirstSound,
  },
  1: {
    textAudio: 'Play the word and select second sound',
    textAudioUrl: audio.playTheWordAndSelectSecondSound,
  },
  2: {
    textAudio: 'Play the word and select third sound',
    textAudioUrl: audio.playTheWordAndSelectThirdSound,
  },
  3: {
    textAudio: 'Play the word and select fourth sound',
    textAudioUrl: audio.playTheWordAndSelectFourthSound,
  },
  4: {
    textAudio: 'Play the word and select fifth sound',
    textAudioUrl: audio.playTheWordAndSelectFifthSound,
  },
  completed: {
    textAudio: 'Play the completed word',
    textAudioUrl: audio.playTheCompletedWord,
  },
};
const DragAndDropOrderSounds = ({
  data: { options: _options = [], correctAnswer = '' } = {},
  audioUrl,
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  selfLearning,
  cmsPreview,
}) => {
  const { exerciseId } = useParams();
  const { state: generalState } = useGeneralState();

  const [showAnswer, setShowAnswer] = useState(false);
  const [answers, setAnswers] = useState(
    correctAnswer.split('-').map((e, i) => ({ text: '' }))
  );
  const [options, setOptions] = useState(
    _options.map((e, i) => ({
      text: e.option,
      id: i,
    }))
  );
  const [activePosition, setActivePosition] = useState(0);
  const onClickAnswer = (answer, i) => {
    setActivePosition(i);

    if (answer?.text) {
      showMovingBlock({
        OptionElId: `option-${answer.id}`,
        answerElId: `answer-${i}`,
        movingElId: 'moving-div',
        isBeingSelected: true,
        done: () => {
          setOptions((_ops) =>
            _ops.map((e) => {
              if (e.id === answer.id) {
                return { ...e };
              }
              return e;
            })
          );
        },
      });
      setAnswers((_answers) =>
        _answers.map((e, _i) => {
          if (_i === i) {
            return { id: null, text: null };
          }
          return e;
        })
      );
    }
  };

  const onClickOption = (option) => {
    setOptions((_ops) =>
      _ops.map((e) => {
        if (e.id === option.id) {
          return { ...e };
        }
        return e;
      })
    );
    showMovingBlock({
      OptionElId: `option-${option.id}`,
      answerElId: `answer-${activePosition}`,
      movingElId: 'moving-div',
      isBeingSelected: false,
      done: () => {
        setAnswers((_answers) => {
          const newAnswers = _answers.map((e, i) => {
            if (i === activePosition) {
              return { ...option };
            }
            return e;
          });

          return newAnswers;
        });
      },
    });
    setActivePosition((_activePosition) => {
      return _activePosition === answers.length - 1
        ? _activePosition
        : _activePosition + 1;
    });
  };

  const onFocusAnswer = (i) => {
    setActivePosition(i);
  };

  const onClickNext = () => {
    if (showAnswer || stuckAtReview) {
      handleNext();
    } else {
      setShowAnswer(true);
    }
  };
  const answer = answers.map((e) => e.text).join('');

  if (!options?.length) {
    return (
      <S.Wrapper>
        {<T.P color="error">Exercise missing required fields </T.P>}
      </S.Wrapper>
    );
  }

  const activeDataKey = showAnswer ? 'completed' : activePosition;
  return (
    <S.Wrapper>
      {/* keep this to animation */}
      <div style={{ display: 'none' }}>
        <Option id="moving-div"></Option>
      </div>
      <S.InstructionWrapper>
        <PlayButton
          audioUrl={getMediaUrl(
            activePositionData[activeDataKey]?.textAudioUrl,
            true,
            generalState?.preferredVoice
          )}
          iconProps={{ width: '32px', height: '32px' }}
          padding="12px"
        >
          {activePositionData[activeDataKey]?.textAudio}
        </PlayButton>
      </S.InstructionWrapper>

      <S.ButtonWrapper>
        <PlayBig audioUrl={audioUrl} mt="3" />
      </S.ButtonWrapper>

      <S.PreFilledAnswersGridWrapper>
        {answers.map((answer, i) => {
          let bgColor = answer?.text
            ? 'neutralSurface'
            : 'rgba(255, 255, 255, 0.5)';
          bgColor = answer.preFilled ? 'white' : bgColor;

          const allowClear =
            (i === activePosition - 1 && !answers[activePosition]?.text) ||
            (i === activePosition &&
              answers[activePosition]?.text &&
              i === answers.length - 1);
          return (
            <EmptySpace
              isActive={i === activePosition && !cmsPreview}
              onClick={() =>
                (allowClear || i === activePosition) && !showAnswer
                  ? onClickAnswer(answer, i)
                  : undefined
              }
              onFocus={() =>
                (allowClear || i === activePosition) && !showAnswer
                  ? onFocusAnswer(i)
                  : undefined
              }
              id={`answer-${i}`}
              bgColor={bgColor}
              key={i}
              w="74px"
              h="74px"
              size="large"
              disabled={showAnswer || (i !== activePosition && !allowClear)}
            >
              {answer?.text}
            </EmptySpace>
          );
        })}
      </S.PreFilledAnswersGridWrapper>
      <S.OptionsGridWrapper showAnswer={showAnswer}>
        {showAnswer ? (
          <T.P m={5} size="extraLarge">
            = {answer}
          </T.P>
        ) : (
          <S.OptionsGrid>
            {options.map((op) => (
              <Option
                onClick={() => onClickOption(op)}
                id={`option-${op.id}`}
                key={op.id}
                size="large"
              >
                {op.text}
              </Option>
            ))}
          </S.OptionsGrid>
        )}
      </S.OptionsGridWrapper>

      <S.ButtonWrapper>
        {stuckAtReview ? (
          <BasicButton handleClick={handleNext} variant="primary">
            <T.P color="white" weight="semi">
              Complete review
            </T.P>
          </BasicButton>
        ) : (
          <div style={{ width: '100%' }}>
            <BasicButton
              disabled={disabled || answer !== correctAnswer?.replace(/-/g, '')}
              handleClick={onClickNext}
              icon="next"
              variant="primary"
            >
              <T.P color="white" weight="semi">
                Next
              </T.P>
            </BasicButton>

            {preview && (
              <BasicButton
                icon="back"
                variant="primary"
                to={(selfLearning
                  ? navRoutes.EXERCISES.EXERCISE_EXPLAINER_SL
                  : navRoutes.EXERCISES.EXERCISE_EXPLAINER
                ).replace(':exerciseId', exerciseId)}
                mt={5}
              >
                <T.P color="white" weight="semi">
                  Back to exercise
                </T.P>
              </BasicButton>
            )}
          </div>
        )}
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default DragAndDropOrderSounds;
