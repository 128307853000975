import { useState } from 'react';
import { useParams } from 'react-router-dom';
import * as T from '../../../components/Typography';
import CoachesTableNew from './CoachesTableNew';
import CoachesTableAll from './CoachesTableAll';
import CoachesTableRejected from './CoachesTableRejected';

import { useGetFacilitatorCoaches } from '../../../api-calls/facilitator.queries';
import { coachStatusesQuery } from '../../../constants';

const FacilitatorCoaches = () => {
  const { statusType } = useParams();
  const pageSize = 20;
  const [page, setPage] = useState(1);
  const {
    coaches,
    error: getCoachesError,
    isLoading: getCoachesLoading,
  } = useGetFacilitatorCoaches({ statusType, page, limit: pageSize });

  if (getCoachesError?.message) {
    return (
      <T.P mb={2} mt={2} color="error">
        {getCoachesError?.message}
      </T.P>
    );
  }

  switch (statusType) {
    case coachStatusesQuery.NEW:
      return (
        <CoachesTableNew
          statusType={statusType}
          dataSource={coaches}
          pageSize={pageSize}
          onChange={({ current }) => setPage(current)}
          isLoading={getCoachesLoading}
          page={page}
        />
      );

    case coachStatusesQuery.REJECTED:
      return (
        <CoachesTableRejected
          statusType={statusType}
          dataSource={coaches}
          pageSize={pageSize}
          onChange={({ current }) => setPage(current)}
          isLoading={getCoachesLoading}
          page={page}
        />
      );

    default:
      return (
        <CoachesTableAll
          statusType={statusType}
          dataSource={coaches}
          pageSize={pageSize}
          onChange={({ current }) => setPage(current)}
          isLoading={getCoachesLoading}
          page={page}
        />
      );
  }
};

export default FacilitatorCoaches;
