import { useExercise } from '../context/exercise';
import { useSelfLearning } from '../context/self-learning';

const UseExerciseWithSelfLearning = () => {
  const exerciseContext = useExercise();
  const selfLearningContext = useSelfLearning();

  let setSubProgress = () => {};
  if (exerciseContext.setSubProgress) {
    setSubProgress = exerciseContext.setSubProgress;
  } else if (selfLearningContext.setSubProgress) {
    setSubProgress = selfLearningContext.setSubProgress;
  }

  const context = exerciseContext.exercise.id
    ? exerciseContext
    : selfLearningContext;
  return {
    ...context,
    selfLearning: !exerciseContext.exercise.id,
    setSubProgress,
  };
};

export default UseExerciseWithSelfLearning;
