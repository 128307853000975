export const getLastCompletedEx = (milestones) => {
  let lastCompletedEx;
  let order = 1;

  milestones.forEach((m) => {
    m.exercises.forEach((e) => {
      if (e.exerciseComplete || e.savedExercise) {
        lastCompletedEx = { ...e, order };
        order += 1;
      }
    });
  });
  return lastCompletedEx;
};

export const getActiveExWithFormattedMilestones = (milestones) => {
  let activeExercise = milestones[0].exercises[0];
  let _milestones = [...milestones];
  let lastCompletedEx = getLastCompletedEx(milestones);
  let order = 1;

  _milestones = milestones.map((m) => {
    return {
      ...m,
      exercises: m.exercises.map((e, i) => {
        let _e = { ...e, order };
        if (lastCompletedEx) {
          if (_e.id === lastCompletedEx?.id) {
            activeExercise = m.exercises[i + 1];
          }
          if (!activeExercise && i === 0) {
            activeExercise = _e;
          }
          if (_e.order < lastCompletedEx?.order) {
            _e = { ..._e, exerciseComplete: true };
          }
        } else if (_e?.stuckAt && _e.stuckAt[0]) {
          activeExercise = m.exercises[i + 1];
        }
        order += 1;
        return _e;
      }),
    };
  });
  return { milestones: _milestones, activeExercise };
};

export const getProgress = ({
  milestones,
  activeExercise,
  setMilestoneIndex,
  setProgress,
}) => {
  milestones.forEach((m) => {
    m?.exercises.forEach((e) => {
      if (e.id === activeExercise?.id) {
        setProgress((prev) => ({
          ...prev,
          total: m.exercises?.length,
          complete: m.exercises.filter((c) => c.exerciseComplete).length,
        }));
        setMilestoneIndex(m.milestoneId);
      }
    });
  });
};
