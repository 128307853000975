import { useState, useLayoutEffect, useEffect, useRef } from 'react';

const placeholder =
  'https://shannon-trust-s3-staging.s3.eu-west-2.amazonaws.com/placeholder.mp3';

export default function UseAudio({
  audioUrl,
  handleClick,
  onEnd, //to be used if audio should end before enable continue
}) {
  const [canPlay, setCanPlay] = useState(false);
  const [error, setError] = useState(false);
  const [canPlayPlaceholder, setCanPlayPlaceholder] = useState(false);
  const [playing, setPlaying] = useState(false);
  const audioRef = useRef();
  const placeholderAudioRef = useRef();

  useEffect(() => {
    if (audioUrl) {
      audioRef.current = new Audio(audioUrl);
    }
  }, [audioUrl]);

  useEffect(() => {
    if (error && audioUrl) {
      placeholderAudioRef.current = new Audio(placeholder);
    }
  }, [audioUrl, error]);

  const handleAudioClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (typeof handleClick === 'function') {
      handleClick();
    }

    if (canPlay || canPlayPlaceholder) {
      setPlaying((p) => !p);
    }
  };

  useLayoutEffect(() => {
    let audio;
    if (canPlay) {
      audio = audioRef.current;
    } else {
      audio = placeholderAudioRef.current;
    }

    if (audio && audioUrl) {
      if (playing) {
        audio.play();
      } else {
        audio.pause();
        audio.currentTime = 0;
      }
    }
  }, [playing, audioUrl, canPlay]);

  useEffect(() => {
    if (!audioUrl) return;

    const audio = audioRef.current;
    audio.src = audioUrl;

    if (audio) {
      audio.addEventListener('canplaythrough', () => {
        setCanPlay(true);
      });

      audio.addEventListener('error', () => {
        setError(true);
      });

      audio.addEventListener('ended', () => {
        setPlaying(false);
        if (typeof onEnd === 'function') {
          onEnd();
        }
      });
      audio.load();
    }

    return () => {
      audio.pause();
      audio.removeEventListener('canplaythrough', () => {});
      audio.removeEventListener('ended', () => {});
      audio.removeEventListener('error', () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl]);

  useEffect(() => {
    if (!error) return;
    const placeholderAudio = placeholderAudioRef.current;
    placeholderAudio.src = placeholder;
    placeholderAudio.addEventListener('canplaythrough', () => {
      setCanPlayPlaceholder(true);
    });
    placeholderAudio.addEventListener('ended', () => {
      setPlaying(false);
      if (typeof onEnd === 'function') {
        onEnd();
      }
    });
    placeholderAudio.load();

    return () => {
      placeholderAudio.pause();
      placeholderAudio.removeEventListener('canplaythrough', () => {});
      placeholderAudio.removeEventListener('ended', () => {});
    };
  }, [error, onEnd]);

  return { canPlay: canPlay || canPlayPlaceholder, handleAudioClick };
}
