import { useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayBig, PlayButton } from '../../../../components/Button';
import Markdown from '../../../../components/Markdown';
import { createH1 } from '../../../../utils/markdown';
import TryAgainModal from '../../../../components/ModalNew/TryAgainModal';
import OptionButton from './OptionButton';
import * as S from './style';
import StepFooterButtons from '../StepFooterButtons';

const Step = ({
  data: { title, options, textAudio, textAudioUrl } = {},
  audioUrl,
  imageUrl,
  handleNext,
  disabled,
  stuckAtReview,
  selfLearning,
  preview,
  bubble,
}) => {
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [selected, setSelected] = useState({
    option: '',
    id: '',
    isCorrect: null,
  });

  const handleClick = () => {
    if (selected.isCorrect || stuckAtReview) {
      handleNext();
    } else {
      setShowTryAgain(true);
    }
  };

  return (
    <S.Wrapper>
      {textAudio && !bubble ? (
        <S.ButtonWrapper>
          <S.TextAudioWrapper mb={4}>
            <PlayButton
              audioUrl={textAudioUrl}
              width="56px"
              height="56px"
              iconProps={{ width: '32px', height: '32px' }}
            />
            <T.P ml={4} weight="regular" size="med">
              {textAudio}
            </T.P>
          </S.TextAudioWrapper>
        </S.ButtonWrapper>
      ) : null}
      {bubble ? (
        <S.ButtonWrapper>
          <PlayButton
            audioUrl={textAudioUrl}
            height="56px"
            iconProps={{ width: '32px', height: '32px' }}
            weight="regular"
            mb={7}
            bubble
          >
            {textAudio}
          </PlayButton>
        </S.ButtonWrapper>
      ) : null}
      {title ? (
        <Markdown
          text={createH1(title)}
          customStyles={{
            h1: {
              size: 'extraLarge',
              ta: 'center',
              weight: 'regular',
              mb: 3,
              mt: 0,
            },
          }}
        />
      ) : null}
      {imageUrl ? (
        <S.ImageWrapper>
          <img
            alt={imageUrl.split('/').pop().split('.')[0]}
            src={imageUrl}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </S.ImageWrapper>
      ) : null}
      {!bubble && (
        <S.ButtonWrapper>
          <PlayBig label={'Play sound'} audioUrl={audioUrl} mt="3" mb="3" />
        </S.ButtonWrapper>
      )}
      <S.ImagesWrapper>
        {options?.map(
          ({ imageUrl, audioUrl: optionAudioUrl, option, isCorrect }, i) => (
            <OptionButton
              mb={3}
              grid={options.length > 3 && !bubble}
              index={i}
              key={option + i}
              option={option}
              handleClick={setSelected}
              selected={selected}
              imageUrl={imageUrl}
              audioUrl={optionAudioUrl}
              isCorrect={isCorrect}
              bubble={bubble}
            />
          )
        )}
      </S.ImagesWrapper>
      <S.ButtonsContainer>
        <StepFooterButtons
          stuckAtReview={stuckAtReview}
          handleNext={handleClick}
          preview={preview}
          disabled={disabled}
          enableNext={selected.option}
          selfLearning={selfLearning}
        />
      </S.ButtonsContainer>
      <TryAgainModal
        aria-modal="true"
        isModalVisible={showTryAgain}
        setIsModalVisible={setShowTryAgain}
      />
    </S.Wrapper>
  );
};

export default Step;
