import styled from '@emotion/styled';
import * as T from '../../../components/Typography';

export const Wrapper = styled.div`
  max-width: 635px;
`;

export const P = styled(T.P)`
  line-height: 28px !important;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
