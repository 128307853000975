import { useEffect, useState } from 'react';

import * as S from './style';
import { Typography as T, Icon, Inputs } from '../../../components';
import {
  useUpdateLearnerGoalTask,
  useCreateLearnerGoalTask,
} from '../../../api-calls/learnersGoals.queries';
const Task = ({
  title: _title,
  id,
  goalId,
  goalTitle,
  completed,
  handleTaskCheck,
  setShowNewTask,
  updateGoal,
  setGoals,
  newTask,
  refetch,
  ...props
}) => {
  const [title, setTitle] = useState('');
  const [editMode, setEditMode] = useState(false);

  const { mutateAsync: updateTask } = useUpdateLearnerGoalTask({
    taskId: id,
    personalGoalId: goalId,
    title,
    completed,
  });

  const { mutateAsync: createTask } = useCreateLearnerGoalTask({
    personalGoalId: goalId,
    title,
  });

  useEffect(() => {
    setTitle(newTask ? '' : _title);
    if (newTask) {
      setEditMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBlur = async () => {
    if (!title && newTask) {
      return;
    }
    if (title && newTask) {
      // create task
      await createTask({ personalGoalId: goalId, title });
      refetch();

      if (typeof setShowNewTask === 'function') {
        setShowNewTask(false);
      }
      return;
    }
    if (title) {
      // handle update task
      await updateTask({
        taskId: id,
        personalGoalId: goalId,
        title,
        completed: completed,
      });
      setEditMode(false);
      await refetch();
    } else {
      // handle Delete task
      await updateTask({
        taskId: id,
        personalGoalId: goalId,
        title: '',
        completed: completed,
      });
      await refetch();
    }
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13 || e.which === 13 || e.charCode === 13) {
      handleBlur();
    }
  };
  return (
    <S.TaskWrapper {...props}>
      <Inputs.Checkbox
        plain
        font="regular"
        noPadding
        checked={completed}
        disabled={editMode}
        borderColor="white"
        handleChange={() => {
          updateTask({
            taskId: id,
            personalGoalId: goalId,
            title,
            completed: !completed,
          });
          return handleTaskCheck({
            goalId,
            taskId: id,
            completed,
            title,
            cb: () =>
              updateGoal({
                personalGoalId: goalId,
                title: goalTitle,
                completed: true,
              }),
          });
        }}
        checkboxBgColor="rgb(255 255 255 / 30%)"
        label={editMode ? null : <T.P color="white">{title}</T.P>}
        w={editMode ? '32px' : '100%'}
      />
      {editMode ? (
        <S.EditInput
          value={title}
          placeholder="Type in your task..."
          onChange={(e) => setTitle(e.target.value)}
          onBlur={handleBlur}
          onKeyPress={handleKeypress}
        />
      ) : (
        <S.EditButton type="button" onClick={() => setEditMode(true)}>
          <Icon icon="edit" color="white" />
        </S.EditButton>
      )}
    </S.TaskWrapper>
  );
};

export default Task;
