import styled from '@emotion/styled';
import * as T from '../../components/Typography';

const getGradient = (color) => {
  if (color === 'blue')
    return 'linear-gradient(0deg, rgba(61, 111, 182, 0.9), rgba(61, 111, 182, 0.9)),';
  if (color === 'pink')
    return 'linear-gradient(0deg, rgba(130, 59, 125, 0.85), rgba(130, 59, 125, 0.85)),';
  if (color === 'black')
    return ' linear-gradient(0deg, rgba(5, 23, 48, 0.8), rgba(5, 23, 48, 0.8)),';
  if (color === 'lightBlue')
    return ' linear-gradient(0deg, rgba(41, 74, 121, 0.85), rgba(41, 74, 121, 0.85)),';
  else return '';
};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 157px;
  background: ${({ img, color }) => `${getGradient(color)} url(${img})`};
  padding: ${({ theme }) => theme.spacings[5]};
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  background-position: ${({ position = 'center' }) => position};
`;

export const StyledFindOut = styled(T.Link)`
  flex: 1;
  align-items: flex-end;
  display: flex;
  justify-content: center;
  &:hover {
    color: white !important;
  }
  &:focus {
    outline: none;
  }
`;
