import { useState } from 'react';

import * as T from '../../../../components/Typography';
import EmptySpace from '../../../../components/EmptySpace';
import Option from '../../../../components/Option';
import * as S from './style';
import { showMovingBlock } from '../../../../helpers';
import TryAgainModal from '../../../../components/Modal/TryAgainModal';
import StepFooterButtons from '../StepFooterButtons';

const WordJoin2 = ({
  data: { options: _options = [], correctOptions = [] } = {},
  handleNext,
  stuckAtReview,
  preview,
  selfLearning,
  cmsPreview,
}) => {
  const [disableCheck, setDisableCheck] = useState(false);
  const [activePosition, setActivePosition] = useState(0);

  const [showTryAgain, setShowTryAgain] = useState(false);

  const options = _options.map((op, i) => ({ option: op.option, id: i }));

  const [answers, setAnswers] = useState(correctOptions.map(() => null));

  const onClickAnswer = (row, answer) => {
    if (answer?.option) {
      setActivePosition(row);
      showMovingBlock({
        OptionElId: `option-${answer.id}`,
        answerElId: `answer-${row}`,
        movingElId: 'moving-div',
        isBeingSelected: true,
      });
      setAnswers((_answers) =>
        _answers.map((answer, ir) => {
          if (ir === row) {
            return null;
          } else {
            return answer;
          }
        })
      );
    } else {
      setActivePosition(row);
    }
  };

  const onFocusAnswer = (row) => {
    setActivePosition(row);
  };

  const onClickOption = (option) => {
    setDisableCheck(true);
    showMovingBlock({
      OptionElId: `option-${option.id}`,
      answerElId: `answer-${activePosition}`,
      movingElId: 'moving-div',
      isBeingSelected: false,
      done: () => {
        setAnswers((_answers) =>
          _answers.map((answer, ir) => {
            if (ir === activePosition) {
              return option;
            } else {
              return answer;
            }
          })
        );
        setDisableCheck(false);
      },
    });

    setActivePosition((_activePosition) =>
      Math.min(_activePosition + 1, answers.length - 1)
    );
  };

  const validateRow = (row) => {
    const currentAnswer = answers[row]?.option;
    return !!correctOptions[row]?.answers.find((e) => e === currentAnswer);
  };

  const onNext = () => {
    const allCorrect = answers.every((e, i) => validateRow(i));

    if (allCorrect || stuckAtReview) {
      handleNext();
    } else {
      setShowTryAgain(true);
    }
  };

  return (
    <S.Wrapper>
      {/* keep this to animation */}
      <div style={{ display: 'none' }}>
        <Option id="moving-div"></Option>
      </div>
      <TryAgainModal
        isModalVisible={showTryAgain}
        setIsModalVisible={setShowTryAgain}
      />
      <S.Grid>
        {answers.map((answer, ir) => (
          <S.Row key={ir}>
            <T.P size="large" mr={2}>
              {correctOptions[ir].pre}
            </T.P>
            <EmptySpace
              isActive={ir === activePosition && !cmsPreview}
              onClick={() => onClickAnswer(ir, answer)}
              onFocus={() => onFocusAnswer(ir)}
              id={`answer-${ir}`}
              bgColor={
                answer?.id ? 'rgba(255, 255, 255, 0.5)' : 'neutralSurface'
              }
              key={`${ir}`}
              disabled={ir !== activePosition}
              w="78px"
              h="58px"
              size="large"
              fontSize="20px"
            >
              {answer?.option}
            </EmptySpace>
            <T.P size="large"> {correctOptions[ir].post}</T.P>
          </S.Row>
        ))}
      </S.Grid>

      <S.OptionsGridWrapper
        ai={options.length === 1 ? 'flex-start' : 'flex-end'}
      >
        <S.OptionsGrid jc="center">
          {options.map((op) => (
            <Option
              m={1}
              onClick={() => onClickOption(op)}
              id={`option-${op.id}`}
              key={op.id}
              style={{ minWidth: '74px' }}
              fontSize="20px"
            >
              {op.option}
            </Option>
          ))}
        </S.OptionsGrid>
      </S.OptionsGridWrapper>

      {(!_options || !correctOptions) && (
        <T.P color="error">Exercise missing required fields </T.P>
      )}
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={onNext}
        preview={preview}
        disabled={disableCheck}
        enableNext={
          answers.filter((e) => e?.option).length >= correctOptions.length
        }
        selfLearning={selfLearning}
      />
    </S.Wrapper>
  );
};

export default WordJoin2;
