export default {
  primaryMain: '#405ED6',
  neutralMain: '#050D30',
  neutral90: '#3B3C42',
  neutral80: '#575961',
  neutral70: '#696C75',
  neutral50: '#D2D4D9',
  neutral60: '#999A9E',
  neutral40: '#E5E7EB',
  neutral30: '#F0F1F5',
  neutralLight: '#F0F1F5',
  neutralLight90: 'rgba(245, 245, 245, 0.9)',
  neutralSurface: '#FDFCF9',
  neutralSurface5: 'rgba(253, 252, 249, 0.05)',
  white: '#FFFFFF',
  secondaryMain: '#002666',
  tertiaryMain: '#FF5B59',
  quaternaryMain: '#55225D',
  quinaryMain: '#8CB036',
  irlenBlue: 'rgba(150, 173, 252, 1)',
  irlenGreen: 'rgba(168, 242, 154, 1)',
  irlenYellow: 'rgba(237, 221, 110, 1)',
  irlenRed: 'rgba(224, 166, 170, 1)',
  primaryMain10: 'rgba(61, 111, 182, 0.1)',
  primaryMain90: 'rgba(64, 94, 214, 0.9)',
  neutralMain50: 'rgba(5, 13, 48, 0.5)',
  white50: 'rgba(250, 250, 250, 0.5)',
  neutralMain80: 'rgba(5, 13, 48, 0.8)',
  quaternaryMain85: 'rgba(130, 59, 125, 0.85)',
  error: '#D83A2C',
  placeholder: '#696C75',
  primary30: '#E7ECFF',
  primaryLight: '#F5F7FF',
  secondaryLight: '#F2F4F7',
  tertiaryLight: '#FFF7F7',
  quaternary30: '#FDEFFF',
  quaternaryLight: '#F1EDF2',
};
