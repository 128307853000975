import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import InviteLink from './InviteLink';

import { FACILITATOR } from '../../../constants/nav-routes';

const ApproveCoach = () => {
  return (
    <>
      <Row>
        <T.H1 ml={2} color="neutralMain">
          Approve application
        </T.H1>
      </Row>
      <Row>
        <T.P color="neutralMain" mt="5" ml={2}>
          Please share this unique link with the coach.
        </T.P>
        <InviteLink />
      </Row>
      <Row mt="3">
        <Col w={[4, 7, 7]}>
          <BasicButton variant="primary" to={FACILITATOR.DASHBOARD}>
            <T.P color="white" weight="semi">
              Return to dashboard
            </T.P>
          </BasicButton>
        </Col>
      </Row>
    </>
  );
};

export default ApproveCoach;
