import { COACHES_BASE } from '../constants';
import {
  useFetch,
  usePost,
  usePatch,
  formatQueryError,
} from '../utils/queries';

function useAddCoaches() {
  // usePost takes url and params
  // formData gets picked up by mutate prop
  return usePost(`${COACHES_BASE}`, undefined);
}

function useUpdateCoachInfo({ coachId, form }) {
  return usePatch(
    `${COACHES_BASE}/update-coach-info`,
    {
      coachId,
      form,
    },
    {
      options: {
        invalidateAll: true,
      },
    }
  );
}

function useGetCoachDetails({ coachId }) {
  const context = useFetch(`${COACHES_BASE}/${coachId}/details`);
  return {
    ...context,
    coach: context.data,
    error: formatQueryError(context),
  };
}

export { useAddCoaches, useUpdateCoachInfo, useGetCoachDetails };
