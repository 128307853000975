import styled from '@emotion/styled';
import { setMargin } from '../../helpers';

export const PlayButtonWrapper = styled.button`
  ${setMargin}
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  padding-left: 1rem;
`;

export const Wrapper = styled.button`
  background-image: ${({ image }) => `url(${image})`};
  width: 100%;
  height: 189.48px;
  border-radius: 16px;
  background-size: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const CompletedWrapper = styled.div`
  ${setMargin}
  display: flex;
  align-items: center;
  z-index: 10;
  padding-bottom: ${({ theme }) => theme.spacings[4]};
`;
