import { useParams } from 'react-router-dom';
import * as S from './style';
import { PlayButton, BasicButton } from '../../../../../../components/Button';

import { navRoutes } from '../../../../../../constants';
import * as T from '../../../../../../components/Typography';

const ListenAndRepeat = ({
  data: { questionText, questionAudioUrl } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  selfLearning,
}) => {
  const { exerciseId } = useParams();

  return (
    <>
      <PlayButton
        audioUrl={questionAudioUrl}
        iconProps={{ width: '32px', height: '32px' }}
        weight="bold"
        mb={5}
      >
        {questionText}
      </PlayButton>

      <S.ButtonWrapper>
        {stuckAtReview ? (
          <BasicButton handleClick={handleNext} variant="primary">
            <T.P color="white" weight="semi">
              Complete review
            </T.P>
          </BasicButton>
        ) : (
          <div style={{ width: '100%' }}>
            <BasicButton
              disabled={disabled || preview}
              handleClick={handleNext}
              icon="next"
              variant="primary"
            >
              <T.P color="white" weight="semi">
                Next
              </T.P>
            </BasicButton>
            {preview && (
              <BasicButton
                icon="back"
                variant="primary"
                to={(selfLearning
                  ? navRoutes.EXERCISES.EXERCISE_EXPLAINER_SL
                  : navRoutes.EXERCISES.EXERCISE_EXPLAINER
                ).replace(':exerciseId', exerciseId)}
                mt={5}
              >
                <T.P color="white" weight="semi">
                  Back to exercise
                </T.P>
              </BasicButton>
            )}
          </div>
        )}
      </S.ButtonWrapper>
    </>
  );
};

export default ListenAndRepeat;
