import styled from '@emotion/styled';
import { Tag as AntdTag, Button as AntdButton } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: -5%;
`;

export const LearnerNameColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  span {
    color: ${({ theme }) => theme.colors['neutralMain']};
    font-weight: bold;
    font-size: 1rem;
  }
`;

export const Button = styled(AntdButton)`
  padding: 0;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }
`;

export const Tag = styled(AntdTag)`
  background-color: ${({ bgColor, theme }) => theme.colors[bgColor] ?? bgColor};
  color: ${({ fontColor, theme }) =>
    theme.colors[fontColor] || theme.colors['white']};
  border-radius: 24px;
  font-size: 1rem;
  font-weight: 700;
  padding: 2px 8px;
`;

export const TableTitleWrapper = styled.div`
  min-height: 72px;
  display: flex;
  align-items: center;
`;
