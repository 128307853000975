import { useReducer, useState } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import { string, number, array, object } from 'yup';
import mealsData from '../../../../pages/Exercises/ExerciseStep/Comprehensions/Recipes/meals-data';
import { TreeSelect } from 'antd';
import { MediaKeyInput } from '../../../Components';

const { TreeNode } = TreeSelect;

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data
    ? {
        ...data,
        correctOptions: data?.correctOptions?.map((e, i) => ({
          ...e,
          id: i,
        })) || [{ id: 0, option: '' }],
      }
    : {
        correctOptions: [{ id: 0, option: '' }],
        textAudio: '',
        textAudioKey: '',
      },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const SelectRecipes = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));
  const [value, setValue] = useState(
    data ? data.correctOptions.map((e, i) => e.option) : []
  );

  const setFormData = (data) =>
    setState((prevState) => {
      const form = {
        ...prevState.form,
        ...data,
      };
      onChange({
        ...form,
        correctOptions: form?.correctOptions?.filter((e) => e.option),
      });
      return { form };
    });

  const onChange2 = (newValue) => {
    setFormData({
      correctOptions: newValue.map((e) => {
        return { id: e, option: e };
      }),
    });

    setValue(newValue);
  };

  return (
    <div>
      <BasicInput
        value={state.form.textAudio}
        handleChange={(textAudio) => setFormData({ textAudio })}
        label="Top Question text"
        error={errors.textAudio}
        m={{ mt: 8 }}
      />

      <MediaKeyInput
        label="Top Question text Audio"
        value={state.form.textAudioKey}
        handleChange={(textAudioKey) => setFormData({ textAudioKey })}
        m={{ mt: 5 }}
        error={errors.textAudioKey}
      />

      <T.P mb={2} mt={8} weight="bold">
        Correct Options
      </T.P>
      <TreeSelect
        showSearch
        style={{
          width: '100%',
        }}
        value={value}
        dropdownStyle={{
          maxHeight: 400,
          overflow: 'auto',
        }}
        placeholder="Please select"
        allowClear
        multiple
        treeDefaultExpandAll
        onChange={onChange2}
      >
        {mealsData.map((day) => (
          <TreeNode
            value={day.title}
            title={day.title}
            key={day.title}
            selectable={false}
          >
            {day.meals.map((meal) => (
              <TreeNode
                value={meal.title}
                title={meal.title}
                key={meal.title + day.title}
                selectable={false}
              >
                {meal.recipes.map((recipe) => (
                  <TreeNode
                    value={recipe.id}
                    title={recipe.title}
                    key={recipe.id + day.title}
                  >
                    {recipe.title}
                  </TreeNode>
                ))}
              </TreeNode>
            ))}
          </TreeNode>
        ))}
      </TreeSelect>

      {errors?.correctOptions && typeof errors.correctOptions === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.correctOptions}
        </T.P>
      )}
    </div>
  );
};

const validationSchema = {
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().required('required field'),
        })
        .required('required field')
    )
    .required('You must provide options')
    .min(1, 'You must provide options'),

  textAudio: string().required('required field'),
  textAudioKey: string().required('required field'),
};
export { validationSchema };

export default SelectRecipes;
