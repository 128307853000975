import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
`;

export const CallLearnerWrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacings[4]};
`;

export const StartCoachingWrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: ${({ theme, margin }) => margin || theme.spacings[7]};
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.neutralMain};
  background: ${({ theme }) => theme.colors.neutralLight};
  border: none;
  border-radius: 16px;

  padding: ${({ theme }) => `${theme.spacings[3]}`};

  box-shadow: 0px 2px 5px -1px rgba(4, 13, 38, 0.11);

  /* clicking style */
  :active {
    opacity: 0.9;
    transform: translateY(1px) scale(0.99);
  }
`;

export const StatsWrapper = styled.div`
  @media (max-width: 376px) {
    padding-left: ${({ theme }) => `${theme.spacings[4]}`};
  }
`;
