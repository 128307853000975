import { useAuth } from '../../../context/auth';
import { navRoutes as NR, types, audio } from '../../../constants';
import { useGeneralState } from '../../../context/general-state';
import { getMediaUrl } from '../../../helpers';
import WithText from '../../../components/Button/WithText';
import PlayButton from '../../Button/Play/';
import * as S from './style';
import { Row, Col } from '../../../components/Grid';

const Footer = ({ showLearnerFooter }) => {
  const { user } = useAuth();
  const { COACH, FACILITATOR } = types.userRoles;
  const { state: generalState } = useGeneralState();

  const learnerFooter = (
    <S.Footer showLearnerFooter>
      <S.Container>
        <S.FooterContent>
          <Row>
            <Col w={[4, 12, 12]}>
              <PlayButton
                width={'50px'}
                height={'40px'}
                radius={12}
                iconProps={{
                  height: '25px',
                  width: '25px',
                }}
                weight="bold"
                size="regular"
                color="white"
                backgroundColor="white"
                mlText={1}
                to={`mailto:${NR.EXTERNAL.CONTACT_US}`}
                external
                audioUrl={getMediaUrl(
                  audio.contactUs,
                  true,
                  generalState?.preferredVoice
                )}
              >
                Contact us
              </PlayButton>
            </Col>
            <Col w={[4, 12, 12]}>
              <PlayButton
                width={'50px'}
                height={'40px'}
                radius={12}
                iconProps={{
                  height: '25px',
                  width: '25px',
                }}
                weight="bold"
                size="regular"
                color="white"
                backgroundColor="white"
                mlText={1}
                mt={4}
                mbM={'0px'}
                to={NR.EXTERNAL.PRIVACY_POLICY}
                external
                audioUrl={getMediaUrl(
                  audio.privacyPolicy,
                  true,
                  generalState?.preferredVoice
                )}
              >
                Privacy Policy
              </PlayButton>
            </Col>
          </Row>
        </S.FooterContent>
      </S.Container>
      <S.SidePlaceholder />
    </S.Footer>
  );

  const coachFacilitatorFooter = (
    <S.Footer>
      <S.Container>
        <S.FooterContent>
          <Row>
            <Col w={[4, 12, 12]}>
              <WithText
                color="white"
                text="Contact us"
                weight="700"
                header
                external
                to={`mailto:${NR.EXTERNAL.CONTACT_US}`}
              />
            </Col>
            <Col w={[4, 12, 12]}>
              <WithText
                mt={'24px'}
                mb={'12px'}
                color="white"
                text="Privacy Policy"
                weight="700"
                header
                external
                to={NR.EXTERNAL.PRIVACY_POLICY}
              />
            </Col>
          </Row>
        </S.FooterContent>
      </S.Container>
      <S.SidePlaceholder />
    </S.Footer>
  );

  if (
    (user.role === FACILITATOR || user.role === COACH || user.role === '') &&
    !showLearnerFooter
  )
    return coachFacilitatorFooter;
  return learnerFooter;
};

export default Footer;
