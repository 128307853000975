import { Col, Row } from '../../components/Grid';
import { Typography as T } from '../../components';

import ExerciseTimeline from '../../components/ExerciseTimeline';

import { useGetExercises } from '../../api-calls/exercises.queries';
import Loading from '../../components/Loading';

const LearnerDashboard = () => {
  const { isLoading, isError, error, isSuccess, milestones } =
    useGetExercises();

  if (isLoading) return <Loading />;
  return (
    <Row>
      <Col w={[4, 12, 12]} jc="center">
        {isSuccess ? <ExerciseTimeline milestones={milestones} /> : null}
        {isError ? (
          <T.P mb="2" color="error">
            {error?.message}
          </T.P>
        ) : null}
      </Col>
    </Row>
  );
};

export default LearnerDashboard;
