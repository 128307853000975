import styled from '@emotion/styled';
import { setMargin } from '../../../helpers';

export const PlayButtonWrapper = styled.div`
  ${setMargin};
  display: flex;
  align-items: center;
`;

export const CardWrapper = styled.div`
  min-height: ${({ completed }) => (completed ? '200px' : '500px')};
  border-radius: 16px;
  width: 100%;
  background-image: ${({ bgImage }) => `
  linear-gradient(to bottom, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .85) 70%, rgba(0, 0, 0, 1) 100%), url(${bgImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 24px 24px 24px;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
}
`;

export const GoalProgressWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const GoalProgressTube = styled.div`
  flex: 1;
  width: 100%;
  border-radius: 42.79px;
  border: 1px solid white;
  position: relative;
  height: 15px;
  box-sizing: content-box;
`;

export const GoalProgressDone = styled.div`
  background: white;
  width: ${({ percentage }) => `${percentage}%`};
  height: 15px;
  border-radius: 42.79px;
  transition: 0.3s;
`;

export const TaskWrapper = styled.div`
  ${setMargin};
  min-height: 64px;
  background: rgba(245, 245, 245, 0.1);
  border-radius: 16px;
  padding: 8px 4px 8px 16px;
  display: flex;
  align-items: center;
`;

export const TaskCheckWrapper = styled.div`
  height: 32px;
  width: 32px;
`;

export const EditInput = styled.input`
  flex: 1;
  min-height: 62px;
  background: transparent;
  border: 1px solid white;
  color: white;
  font-family: 'BasicCommercial', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  padding: 12px;
  text-align: left;
  border-radius: 16px;
  margin-left: 16px;
  width: 100%;
  margin-right: 8px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: white;
    font-family: 'BasicCommercial', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
  }
  :-ms-input-placeholder {
    color: white;
    font-family: 'BasicCommercial', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
  }
`;

export const EditButton = styled.button`
  border: none;
  background: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  margin-right: 12px;
`;

export const IconButton = styled.button`
  border: none;
  background: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const CardButtons = styled.div`
  ${setMargin};
  width: 100%;
  display: flex;
`;

export const BigBtn = styled.div`
  flex: 65%;
  margin-left: 10px;
`;

export const SmallBtn = styled.div`
  flex: 35%;
`;

export const CardButtonsWrapper = styled.div`
  margin-top: 8px;
  flex: 1;
  display: flex;
  align-items: flex-end;
`;
