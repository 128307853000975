import { useEffect, useState } from 'react';
import { AudioIn } from '../../../../components/WordButton';
import { Row, Col } from '../../../../components/Grid';
import { UseExerciseWithSelfLearning } from '../../../../Hooks';

const formateOption = (opTxt, showBold) => {
  if (!opTxt) return '';

  if (showBold) {
    return opTxt.replace(/%/g, '**');
  }
  const regex = /%(.*?)%/g;
  const replacedText = opTxt.replace(regex, '_');
  return replacedText;
};

const DisplayMultipleWords = ({ wordsArray, setEnableNext }) => {
  const formattedWordsArray = wordsArray.map((el, idx) => ({ ...el, id: idx }));
  const { setSubProgress } = UseExerciseWithSelfLearning();

  const [itemsPlayed, setItemsPlayed] = useState([]);

  const handleClick = (el) => {
    if (!el.isPlayed) {
      el.isPlayed = true;
      setItemsPlayed((itemsPlayed) => [...itemsPlayed, el]);
    }
    return;
  };

  useEffect(() => {
    if (itemsPlayed.length === wordsArray.length) {
      setEnableNext(true);
    }

    setSubProgress(itemsPlayed.length / wordsArray.length);
  }, [itemsPlayed.length, setEnableNext, setSubProgress, wordsArray.length]);

  return (
    <Row>
      {formattedWordsArray.map((el, i) => (
        <Col w={[2, 3, 3]} key={`col-${el?.option}-${i}`}>
          <AudioIn
            audioUrl={el?.audioUrl}
            handleClick={() => handleClick(el)}
            innerText={
              itemsPlayed.some((item) => item.id === el.id)
                ? formateOption(el?.option, true)
                : formateOption(el?.option)
            }
            mb={3}
            key={`div-${el?.option}-${i}`}
            hidden
          />
        </Col>
      ))}
    </Row>
  );
};

export default DisplayMultipleWords;
