import { useLocation } from 'react-router-dom';
import { Col, Row } from '../../../components/Grid';
import TextWithIcon from '../../../components/TextWithIcon';
import { Loading } from '../../../components';
import * as T from '../../../components/Typography';

import {
  navRoutes as NR,
  coachStatusesQuery,
  userRoles,
} from '../../../constants';
import { useGetFacilitatorCoaches } from '../../../api-calls/facilitator.queries';
import { useGetLearners } from '../../../api-calls/learners.queries';

import CoachesTableAll from '../Coaches/CoachesTableAll';
import LearnersTable from '../Learners/LearnersTable';
import TableHeader from './TableHeader';
import * as S from './style';
import { BasicButton } from '../../../components/Button';
import useCsvDownload from './../../../Hooks/useCsvDownload';

import { useAuth } from '../../../context/auth';
import { useEffect } from 'react';

const Dashboard = () => {
  const { logout } = useAuth();
  let location = useLocation();

  const {
    coaches,
    error: getCoachesError,
    isLoading: getCoachesLoading,
    refetch: refetchCoaches,
  } = useGetFacilitatorCoaches({
    statusType: coachStatusesQuery.ALL,
    page: 1,
    limit: 5,
  });

  const [data, handleClick] = useCsvDownload(`/csv/kpis`);

  const {
    learners,
    error: getLearnersError,
    isLoading: getLearnersLoading,
    refetch: refetchLearners,
  } = useGetLearners({ page: 1, limit: 5 });

  useEffect(() => {
    if (location?.state?.refetch) {
      if (location.state.refetch === userRoles.LEARNER) {
        refetchLearners();
      } else if (location.state.refetch === userRoles.COACH) {
        refetchCoaches();
      }
    }
  }, [location?.state?.refetch, refetchLearners, refetchCoaches]);

  if (getCoachesLoading || getLearnersLoading) {
    return <Loading />;
  }
  if (getCoachesError || getLearnersError) {
    return (
      <T.P mb={2} mt={2} color="error">
        {getCoachesError?.error ||
          getLearnersError?.error ||
          'There was an error with your request.'}
      </T.P>
    );
  }
  return (
    <S.Wrapper>
      <Row key={'learners-table'}>
        <TableHeader
          title="My learners"
          iconButtonText="Setup a new Learner"
          iconButtonLink={NR.FACILITATOR.ADD_LEARNER}
          basicButtonText="View all learners"
          basicButtonLink={NR.FACILITATOR.LEARNERS}
        />
        <LearnersTable
          dataSource={learners}
          dashboard
          loading={getLearnersLoading}
        />
      </Row>
      <Row key={'coaches-table'}>
        <TableHeader
          title="My Coaches"
          iconButtonText="New coaches"
          iconButtonLink={NR.FACILITATOR.COACHES.replace(
            ':statusType',
            coachStatusesQuery.NEW
          )}
          basicButtonText="View all coaches"
          basicButtonLink={NR.FACILITATOR.COACHES.replace(
            ':statusType',
            coachStatusesQuery.ALL
          )}
        />

        <CoachesTableAll
          embed
          dataSource={coaches}
          statusType={coachStatusesQuery.ALL}
          dashboard
          loading={getCoachesLoading}
          pageSize={5}
        />
      </Row>
      <Row mtT="7" mt="8">
        <Col w={[4, 4, 4]}>
          <BasicButton
            variant="secondary"
            loading={data.loading}
            onClick={handleClick}
          >
            <T.P color="white" weight="bold">
              Export KPIs
            </T.P>
          </BasicButton>
        </Col>
      </Row>
      <Row mtT="4" mt="5">
        <Col w={[4, 4, 4]}>
          <TextWithIcon
            icon="logout"
            text="Log out"
            weight="semi"
            handleClick={logout}
            iconProps={{ style: { marginRight: 4 } }}
            isButton
          />
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default Dashboard;
