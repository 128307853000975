import { useReducer, useEffect, useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

import {
  Grid,
  Typography as T,
  Inputs as I,
  Button,
  ReCaptcha,
} from '../../components';
import * as S from './style';
import validate from '../../validation/schemas/login';
import { Users } from '../../api-calls';
import { useAuth } from '../../context/auth';

import { navRoutes as R, userRoles } from '../../constants';
import { cleanEmail } from '../../helpers';

const { Row, Col } = Grid;

const initialState = {
  email: '',
  password: '',
  httpError: '',
  validationErrs: {},
  loading: false,
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const Login = () => {
  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const { email, password, loading, validationErrs, httpError } = state;
  const navigate = useNavigate();
  const { setUser } = useAuth();

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  useLayoutEffect(() => {
    const script = document.createElement('script');

    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    const body = document.querySelector('body');
    body.appendChild(script);

    return () => {
      body.removeChild(script);
    };
  }, []);

  const validateForm = () => {
    try {
      validate({
        email: cleanEmail(email),
        password,
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  const handleLogin = async (values) => {
    setState({ loading: true });

    const { error, data } = await Users.login(values);

    setState({ loading: false });

    if (error) {
      setState({ httpError: error.message });
    } else {
      setUser(data);

      if (data.role === userRoles.LEARNER || data.role === userRoles.COACH) {
        ReactGA.event({
          category: 'login',
          action: 'login',
        });
        ReactGA.set({
          ...data,
        });
      }
      if (data.role === userRoles.FACILITATOR) {
        navigate(R.FACILITATOR.DASHBOARD);
      } else if (data.role === userRoles.CONTENT_EDITOR) {
        navigate(R.CMS.BASE);
      } else {
        navigate(R.COACH.DASHBOARD);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      if (process.env.NODE_ENV === 'production') {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
              action: 'login',
            })
            .then((reToken) => {
              handleLogin({
                email: cleanEmail(email),
                password,
                reToken,
              });
            });
        });
      } else {
        handleLogin({
          email: cleanEmail(email),
          password,
        });
      }
    }
  };

  return (
    <S.Form onSubmit={handleSubmit}>
      <Row mb="3">
        <Col w={[4, 12, 12]}>
          <T.H2 weight="bold" color="neutralMain">
            Log in
          </T.H2>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 11, 6]} mt="5">
          <I.BasicInput
            id="email"
            label="Email address"
            placeholder="Type your email..."
            margins={{ mt: '2', mb: '1' }}
            type="email"
            value={email}
            autoFocus
            handleChange={(input) => setState({ email: input })}
            error={validationErrs.email}
          />
        </Col>
      </Row>
      <Row mt="6">
        <Col w={[4, 11, 6]}>
          <I.BasicInput
            id="password"
            label="Password"
            placeholder="Type your password..."
            margins={{ mt: '2', mb: '1' }}
            type="password"
            value={password}
            handleChange={(input) => setState({ password: input })}
            error={validationErrs.password}
          />
          <S.Link onClick={() => navigate(R.GENERAL.FORGET_PASSWORD)}>
            Forgot your password?
          </S.Link>
        </Col>
      </Row>
      <S.ButtonsWrapper mt="7" mtT="6">
        <S.ButtonContainer w={[4, 11, 6]}>
          {loading ? <T.P mb="2">...loading</T.P> : null}
          {httpError && (
            <T.P mb="2" color="error">
              {httpError}
            </T.P>
          )}

          <Button.BasicButton
            variant="primary"
            disabled={false}
            loading={loading}
            type="submit"
            id="login-button"
          >
            Log in
          </Button.BasicButton>
        </S.ButtonContainer>
      </S.ButtonsWrapper>
      <Row mt="7" mtT="6">
        <Col w={[4, 11, 6]}>
          <ReCaptcha />
        </Col>
      </Row>
    </S.Form>
  );
};

export default Login;
