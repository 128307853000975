import { useDelete, formatQueryError } from '../utils/queries';

import { USERS_BASE } from '../constants';

function useDeleteUser({ id, role }) {
  const context = useDelete(`${USERS_BASE}/delete/${role}/${id}`);
  return { ...context, error: formatQueryError(context) };
}

export { useDeleteUser };
