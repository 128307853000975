import {
  useFetch,
  usePost,
  usePatch,
  formatQueryError,
} from '../utils/queries';
import { FACILITATORS_BASE } from '../constants';

function useGetFacilitatorCoaches({ statusType, page, limit }) {
  const context = useFetch(`${FACILITATORS_BASE}/coaches/`, {
    statusType,
    page,
    limit,
  });

  return {
    ...context,
    coaches: context.data,
    error: formatQueryError(context),
  };
}

// function useGetFacilitatorCoach({ coachId, statusType }) {
//   const context = useGetFacilitatorCoaches({ statusType });
//   return {
//     ...context,
//     coach: context?.data?.find((el) => el.id.toString() === coachId ?? null),
//     error: formatQueryError(context),
//   };
// }

function useGetFacilitatorCoachNotes({ coachId }) {
  const context = useFetch(`${FACILITATORS_BASE}/coach-notes/`, { coachId });
  return {
    ...context,
    notes: context.data,
    error: formatQueryError(context),
  };
}

function useFacilitatorAddCoachNote({ coachId, note }) {
  return usePost(
    `${FACILITATORS_BASE}/coach-notes/add`,
    {
      coachId,
      note,
    },
    {
      options: {
        invalidateKey: [`${FACILITATORS_BASE}/coach-notes/`, { coachId }],
      },
    }
  );
}

function useUpdateCoachDBS({ value, coachId }) {
  return usePatch(
    `${FACILITATORS_BASE}/update-coach-dbs`,
    {
      value,
      coachId,
    },
    {
      options: {
        invalidateAll: true,
      },
    }
  );
}

function useUpdateCoachStatus({ note, coachId, status }) {
  return usePatch(
    `${FACILITATORS_BASE}/update-coach-status`,
    {
      note,
      coachId,
      status,
    },
    {
      options: {
        invalidateAll: true,
      },
    }
  );
}

function useUpdateCoachInterviewDate({ date = null, coachId }) {
  return usePatch(
    `${FACILITATORS_BASE}/update-coach-interview-date`,
    {
      date,
      coachId,
    },
    {
      options: {
        invalidateAll: true,
      },
    }
  );
}

function useUpdateCoachType({ value = null, coachId }) {
  return usePatch(
    `${FACILITATORS_BASE}/update-coach-type`,
    {
      value,
      coachId,
    },
    {
      options: {
        invalidateAll: true,
      },
    }
  );
}

function useFacilitatorUpdateCoachNote({ note, noteId, coachId }) {
  return usePatch(
    `${FACILITATORS_BASE}/coach-notes/update`,
    {
      note,
      noteId,
    },
    {
      options: {
        invalidateKey: [`${FACILITATORS_BASE}/coach-notes/`, { coachId }],
      },
    }
  );
}

function useGetDefaultFacilitator() {
  const context = useFetch(
    `${FACILITATORS_BASE}/default-facilitator`,
    undefined
  );

  return {
    ...context,
    facilitator: context.data,
    error: formatQueryError(context),
  };
}

export {
  useGetFacilitatorCoaches,
  useGetFacilitatorCoachNotes,
  useFacilitatorAddCoachNote,
  useUpdateCoachDBS,
  useUpdateCoachStatus,
  useUpdateCoachInterviewDate,
  useUpdateCoachType,
  useFacilitatorUpdateCoachNote,
  useGetDefaultFacilitator,
};
