export const getFirstStep = (steps) => {
  let lastCompletedStepIndex = steps.findIndex((s) => s.completed);

  if (lastCompletedStepIndex === steps.length - 1) {
    lastCompletedStepIndex = -1;
  }

  return steps[lastCompletedStepIndex + 1];
};

export const getNextStep = (steps) => {
  let lastCompletedStepIndex = steps.findIndex((s) => s.completed);

  if (lastCompletedStepIndex === -1) {
    lastCompletedStepIndex = 0;
  }

  return steps[lastCompletedStepIndex + 1];
};
