import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import Icon from '../../Icon';
import * as S from './style';
import { useAuth } from '../../../context/auth';

export const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const BasicButton = ({
  variant = 'primary',
  icon,
  loading,
  handleClick,
  disabled,
  to,
  customColor,
  bgColor,
  external,
  iconProps,
  children,
  iconMR,
  id,
  linkState = {},
  ...props
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const onClick = (e) => {
    if (external) return;
    if (to) navigate(to, { state: { ...linkState } });
    if (handleClick instanceof Function) handleClick(e);
  };

  if (external) {
    props.href = to;
    props.target = '_blank';
  }

  return (
    <S.BasicButton
      type="button"
      id={id}
      variant={variant}
      disabled={disabled || loading}
      onClick={onClick}
      as={external ? 'a' : 'button'}
      icon={icon}
      customColor={customColor}
      bgColor={bgColor}
      overlayColor={user.overlayColor}
      loading={loading}
      aria-disabled={disabled || loading ? 'true' : 'false'}
      aria-label={icon && icon}
      {...props}
    >
      {icon && (
        <Icon
          icon={icon}
          mr={iconMR || '9.5px'}
          {...iconProps}
          style={{ cursor: 'pointer' }}
        />
      )}
      <S.InnerTextAlign>{children}</S.InnerTextAlign>
      {loading && <S.Loading variant={variant} indicator={antIcon} />}
    </S.BasicButton>
  );
};

export default BasicButton;
