import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';

import * as S from './style';
import { useGeneralState } from '../../../context/general-state';

const LearnerId = () => {
  const { state } = useGeneralState();
  return (
    <S.SideDetailsWrapper>
      <Row jc="center">
        <Col w={[4, 12, 12]} mt="5">
          <T.H2 color="neutralMain">
            This is their Learner ID for when they want to log in. Please keep
            it safe for them
          </T.H2>
        </Col>
        <Col w={[4, 12, 12]} mt="6" jc="center">
          <S.Circle>
            <T.H1 color="white">{state.learnerUniqueId}</T.H1>
          </S.Circle>
        </Col>
      </Row>
    </S.SideDetailsWrapper>
  );
};

export default LearnerId;
