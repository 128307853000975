import React, { useEffect, useState, useRef } from 'react';
import * as S from './style';
import Exercises, { MilestoneCard } from '../Exercises';
import { navRoutes } from './../../constants';
import { useAuth } from './../../context/auth';
import { userRoles } from './../../constants/data-types';
import * as utils from './utils';
import ProgressCheck from './../../components/ProgressCheck';
import * as C from '../../components';
import { Button } from 'antd';
import { EyeFilled } from '@ant-design/icons';

export const Circle = ({ overlayColor }) => {
  return <S.Circle overlayColor={overlayColor} />;
};

export const DashedLine = () => {
  return <C.Icon icon="dashedLine" />;
};

export const CurvedDashedLine = ({ firstExercise }) => {
  return firstExercise ? (
    <S.CurvedLineWrapper>
      <C.Icon icon="curvedDashedLine" height={200} width={100} />
    </S.CurvedLineWrapper>
  ) : (
    <C.Icon icon="curvedDashedLine" height={200} width={100} />
  );
};

export const ArrowLineDown = () => {
  return <C.Icon icon="arrowLine" />;
};

export const ArrowLineUp = () => {
  return <C.Icon icon="arrowLineUp" mt={5} mtM={7} />;
};

const scrollToRef = (ref) => {
  window.scrollTo({ top: ref.offsetTop + 240, behavior: 'smooth' });
};

const ExerciseTimeline = ({ milestones: _m }) => {
  const { activeExercise, milestones } =
    utils.getActiveExWithFormattedMilestones(_m);
  // check if the learner has an active milestone they are working on - if not default to the first one
  const currentMilestone = activeExercise?.milestoneId ?? 1;
  const [milestoneStartIndex, setMilestoneStartIndex] =
    useState(currentMilestone);
  const [milestoneEndIndex, setMilestoneEndIndex] = useState(currentMilestone);
  const [progress, setProgress] = useState({});
  const { user } = useAuth();
  const activeExerciseRef = useRef();
  const shouldScroll = useRef(true);

  const executeScroll = (ref) => scrollToRef(ref);

  useEffect(() => {
    if (activeExerciseRef.current && shouldScroll.current) {
      executeScroll(activeExerciseRef.current);
    }
  }, [milestoneStartIndex, milestoneEndIndex]);

  useEffect(() => {
    utils.getProgress({
      milestones,
      activeExercise,
      setMilestoneIndex: setMilestoneEndIndex,
      setProgress,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeExercise?.id]);

  // creates collection of milestones to be rendered
  const _milestones =
    milestoneStartIndex !== milestoneEndIndex
      ? milestones.slice(milestoneStartIndex - 1, milestoneEndIndex)
      : [milestones[milestoneStartIndex - 1]];

  const showPrevMilestone = () => {
    shouldScroll.current = false;
    setMilestoneStartIndex((p) => p - 1);
  };

  const showNextMilestone = () => {
    shouldScroll.current = false;
    setMilestoneEndIndex((p) => p + 1);
  };
  return (
    <S.Wrapper>
      {milestoneStartIndex > 1 && (
        <>
          <ArrowLineUp />
          <S.ButtonWrapper>
            <Button
              type="text"
              onClick={showPrevMilestone}
              icon={<EyeFilled />}
            >
              See previous exercises
            </Button>
          </S.ButtonWrapper>
        </>
      )}
      {_milestones.map((milestone) => (
        <React.Fragment key={milestone.id}>
          {milestone.exercises.map((item, idx) => (
            <S.CardWrapper
              key={item.id}
              ref={activeExercise?.id === item.id ? activeExerciseRef : null}
            >
              {item.id === activeExercise?.id ? (
                <CurvedDashedLine firstExercise={idx === 0} />
              ) : (
                <DashedLine />
              )}
              {item.id === activeExercise?.id && (
                <Circle overlayColor={user.overlayColor} />
              )}
              <Exercises
                title={item.title}
                exerciseComplete={item.exerciseComplete && !item.savedExercise}
                savedExercise={item.savedExercise}
                titleAudioKey={item.titleAudioKey}
                to={
                  user.role === userRoles.COACH
                    ? navRoutes.EXERCISES.EXERCISE_EXPLAINER.replace(
                        ':exerciseId',
                        item.id
                      )
                    : navRoutes.EXERCISES.SELECT_WITH_COACH_OR_NOT.replace(
                        ':exerciseId',
                        item.id
                      )
                }
                disabled={
                  !item.exerciseComplete &&
                  !item.savedExercise &&
                  item.id !== activeExercise?.id
                }
              />
            </S.CardWrapper>
          ))}
          <S.CardWrapper>
            <DashedLine />
            <MilestoneCard title={milestone.title} id={milestone.milestoneId} />

            <DashedLine />
            <ProgressCheck
              userRole={user.role}
              code={milestone.code}
              progressProfileFilled={milestone.progressProfileFilled}
              milestoneId={milestone.milestoneId}
              progressCheckFilled={milestone.progressCheckFilled}
            />
            {/* show more exercises - only attach to last milestone */}
            {Number(milestoneEndIndex) === Number(milestone.milestoneId) && (
              <>
                <ArrowLineDown />
                <S.ButtonWrapper>
                  <Button
                    type="text"
                    onClick={showNextMilestone}
                    icon={<EyeFilled />}
                  >
                    See more exercises
                  </Button>
                </S.ButtonWrapper>
              </>
            )}
          </S.CardWrapper>
        </React.Fragment>
      ))}
      <C.ProgressBar progress={progress} />
    </S.Wrapper>
  );
};

export default ExerciseTimeline;
