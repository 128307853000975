const FeedbackMedium = ({ width, height, color, ...props }) => (
  <svg
    width={width || '44'}
    height={height || '44'}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.0925293 22.2927C0.0925293 34.1968 9.74267 43.8469 21.6467 43.8469C33.5508 43.8469 43.2009 34.1968 43.2009 22.2927C43.2009 10.3887 33.5508 0.738525 21.6467 0.738525C9.74267 0.738525 0.0925293 10.3887 0.0925293 22.2927ZM13.4355 29.477H29.8513C30.4182 29.477 30.8777 29.9365 30.8777 30.5034C30.8777 31.0702 30.4182 31.5298 29.8513 31.5298H13.4355C12.8686 31.5298 12.4091 31.0702 12.4091 30.5034C12.4091 29.9365 12.8686 29.477 13.4355 29.477ZM16.5149 19.2136C17.6486 19.2136 18.5677 18.2946 18.5677 17.1609C18.5677 16.0272 17.6486 15.1081 16.5149 15.1081C15.3812 15.1081 14.4621 16.0272 14.4621 17.1609C14.4621 18.2946 15.3812 19.2136 16.5149 19.2136ZM28.8315 17.1609C28.8315 18.2946 27.9124 19.2136 26.7787 19.2136C25.645 19.2136 24.7259 18.2946 24.7259 17.1609C24.7259 16.0272 25.645 15.1081 26.7787 15.1081C27.9124 15.1081 28.8315 16.0272 28.8315 17.1609Z"
      fill={color || '#051730'}
    />
  </svg>
);

export default FeedbackMedium;
