import { fields, createSchema, validate as _validate } from '..';

const {
  firstName,
  lastName,
  email,
  phoneNumber,
  arrayOfString,
  requiredBoolean,
  requiredText,
  shouldBeTrue,
  postcode,
  positiveNumber,
  optionalStringIfAnswerNo,
  optionalText,
  city,
} = fields;

const schema = createSchema({
  firstName,
  lastName,
  email,
  phoneNumber,
  coachingLocations: arrayOfString,
  readRoleDescription: shouldBeTrue,
  canOfferNonJudgementalApproach: shouldBeTrue,
  ableToGiveRegularCommitment: shouldBeTrue,
  inTheRightLocation: shouldBeTrue,
  hasStrongCommunicationSkills: shouldBeTrue,
  ableToPlanAndManageTime: shouldBeTrue,
  ableToKeepUpToDate: shouldBeTrue,
  hasInternetAccess: shouldBeTrue,
  addressLine1: requiredText,
  addressLine2: optionalText,
  city,
  postcode,
  volunteeringReason: requiredText,
  availabilityHoursPerMonth: positiveNumber,
  skillsDescription: requiredText,
  hasDrivingLicence: requiredBoolean,
  hasCar: requiredBoolean,
  hasComputerAccess: requiredBoolean,
  accessRequirements: optionalText,
  hasPreviousConvictions: requiredBoolean,
  hasCourtAppearances: requiredBoolean,
  criminalRecordDetails: optionalStringIfAnswerNo,
  // genders: arrayOfString,
  // ageGroup: requiredText,
  // ethnicOrigin: requiredText,
  // heardOfBy: requiredText,
  trueAndCompleteInfo: shouldBeTrue,
  giveConsent: shouldBeTrue,
});

const validate = (data) => _validate(schema, data);

export default validate;
