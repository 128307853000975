import { useState } from 'react';
import * as S from './style';
import { PlayButton, BasicButton } from '../../../../../../components/Button';
import TryAgainModal from '../../../../../../components/Modal/TryAgainModal';
import Markdown from '../../../../../../components/Markdown';

import StepFooterButtons from '../../../StepFooterButtons';

const MultipleChoice = ({
  data: { options = [], questionAudioUrl, questionText } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  selfLearning,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(false);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const noOptions = !options || !options.length;
  const handleClick = () => {
    if (selectedAnswer.isCorrect || noOptions || stuckAtReview) {
      handleNext();
    } else {
      setShowTryAgain(true);
    }
  };
  return (
    <>
      <PlayButton
        audioUrl={questionAudioUrl}
        iconProps={{ width: '32px', height: '32px' }}
        weight="bold"
        mb="12px"
        width="70px"
      >
        {questionText}
      </PlayButton>
      <S.ButtonWrapper>
        {options.map((opt, index) => {
          return (
            <BasicButton
              key={opt.option + index}
              handleClick={handleClick}
              variant={
                opt.option === selectedAnswer.option ? 'secondary' : 'tertiary'
              }
              noOutline
              onClick={() => setSelectedAnswer(opt)}
              mt={2}
              left
            >
              <Markdown
                text={opt.option}
                customStyles={{
                  p: {
                    weight: 'regular',
                    color:
                      opt.option === selectedAnswer.option
                        ? 'white'
                        : 'neutralMain',
                  },
                }}
              />
            </BasicButton>
          );
        })}
      </S.ButtonWrapper>
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={() => {
          handleClick();
          setSelectedAnswer(false);
        }}
        preview={preview}
        disabled={disabled}
        enableNext
        selfLearning={selfLearning}
      />
      <TryAgainModal
        isModalVisible={showTryAgain}
        setIsModalVisible={setShowTryAgain}
      />
    </>
  );
};

export default MultipleChoice;
