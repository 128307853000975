import * as S from './style';
import * as T from '../Typography';
import Icon from '../Icon';
import InfoCardWithButton from './InfoCardWithButton';
import InfoCardWithIcon from './InfoCardWithIcon';

const InfoCard = ({
  firstColumnLabel,
  firstColumnValue,
  secondColumnLabel,
  secondColumnValue,
  to,
  noIcon,
  buttonText = 'View',
  bgColor,
  ...props
}) => {
  return (
    <S.Wrapper bgColor={bgColor} {...props}>
      <div>
        <T.P color="neutral90">{firstColumnLabel}</T.P>
        <T.H3 color="neutralMain" weight="bold">
          {firstColumnValue}
        </T.H3>
      </div>
      {secondColumnValue && (
        <div>
          <T.P color="neutral90">{secondColumnLabel}</T.P>
          <T.H3 color="neutralMain" weight="bold">
            {secondColumnValue}
          </T.H3>
        </div>
      )}
      {!noIcon && (
        <S.StyledLink bgColor={bgColor} to={to}>
          <Icon icon="eye" color="neutralMain" mb="5px" />
          <T.H3 color="neutralMain" weight="bold">
            {buttonText}
          </T.H3>
        </S.StyledLink>
      )}
    </S.Wrapper>
  );
};

export { InfoCardWithButton, InfoCardWithIcon };
export default InfoCard;
