import React from 'react';
import Layout from './../../components/Layout';
import { authorization } from '../../helpers';
import { useAuth } from '../../context/auth';

import { navRoutes, userRoles } from '../../constants';
import Redirect from './Redirect';

const Route = (props) => {
  const { isPrivate, layout, Component, allowedRoles, publicOnly } = props;

  const { user } = useAuth();

  if (publicOnly && user.id) {
    if (user.role === userRoles.COACH) {
      return <Redirect to={navRoutes.COACH.DASHBOARD} />;
    }
    if (user.role === userRoles.FACILITATOR) {
      return <Redirect to={navRoutes.FACILITATOR.DASHBOARD} />;
    } else if (user.role === userRoles.CONTENT_EDITOR) {
      return <Redirect to={navRoutes.CMS.BASE} />;
    }
    return <Redirect to={navRoutes.LEARNER.DASHBOARD} />;
  }

  if (isPrivate) {
    const authorized = authorization(user.role, allowedRoles);

    if (user.id) {
      if (layout) {
        return (
          <Layout layout={layout} {...props}>
            {authorized ? (
              <Component {...props} />
            ) : (
              <Redirect to={navRoutes.GENERAL.UNAUTHORIZED} {...props} />
            )}
          </Layout>
        );
      } else {
        return authorized ? (
          <Component {...props} />
        ) : (
          <Redirect to={navRoutes.GENERAL.UNAUTHORIZED} {...props} />
        );
      }
      // check if user is logging out and redirect to correct login page
    } else if (user.logout) {
      return user.isLearner ? (
        <Redirect to={navRoutes.LEARNER.LOGIN} />
      ) : (
        <Redirect to={navRoutes.GENERAL.LOGIN} />
      );
    }
    // return general login as default fallback
    return <Redirect to={navRoutes.GENERAL.LOGIN} />;
  }

  if (layout) {
    return (
      <Layout layout={layout} overlayColor={user.overlayColor} {...props}>
        <Component layout={layout} {...props} />
      </Layout>
    );
  }

  return <Component layout={layout} {...props} />;
};

Route.Redirect = Redirect;
export default Route;
