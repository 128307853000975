const GENERAL = {
  HOME: '/',
  UNAUTHORIZED: '/unauthorized',
  FORGET_PASSWORD: '/forget-password',
  RESET_PASSWORD: '/reset-password/:token',
  NOT_FOUND: '/not-found',
  LOGIN: '/login',
  INTEREST: `/sign-up-as-coach`,
  INTEREST_THANK_YOU: `/sign-up-as-coach/thank-you`,
  REFER_A_LEARNER: '/refer-a-learner',
  REFER_A_LEARNER_THANK_YOU: '/refer-a-learner/thank-you',
};

const learner = '/learner';
const LEARNER = {
  MENU: `${learner}/menu`,
  PROFILE: `${learner}/profile`,
  LOGIN: `${learner}/login`,
  DASHBOARD: `${learner}/dashboard`,
  SOUND_BOARD: `${learner}/sound-board`,
  ICONS: `${learner}/icons`,
  COLOURED_OVERLAY: `${learner}/coloured-overlay`,
  SEND_THANKS: `${learner}/send-thanks`,
  LEARNER_ID: `${learner}/learner-id/:userId`,
  ONBOARDING_1: `${learner}/onboarding-1`,
  ONBOARDING_4: `${learner}/onboarding-4`,
  ONBOARDING_2: `${learner}/onboarding-2`,
  ONBOARDING_5: `${learner}/onboarding-5`,
  SETUP_PROCESS: `${learner}/setup-process/:id`,
  PROGRESS_FIRST_PAGE: `${learner}/progress-page-1`,
  PROGRESS_REPORT_QUESTIONS: `${learner}/progress-report-questions/:id`,
  PROGRESS_REPORT_COMPLETED: `${learner}/progress-report-completed`,
  LEARNING_BY_YOURSELF_OR_WITH_COACH: `${learner}/learn-by-yourself-or-with-coach`,
  COACH_CODE: `${learner}/coach-code`,
  PROGRESS_CHECK_THANK_YOU: `${learner}/progress-check-thank-you`,
  PROGRESS_PROFILE_QUESTIONS: `${learner}/progress-profile-questions/:id/:milestoneId`,
  PROGRESS_CHECK_QUESTIONS: `${learner}/progress-check-questions/:stepId/:milestoneId`,
  SUCCESS_MILESTONE: `${learner}/success/:id`,
  GOALS: `${learner}/goals`,
};

const exerciseBase = '/exercise/:exerciseId';
const SLBase = `${exerciseBase}/review`;

const EXERCISES = {
  BASE: `${exerciseBase}`,
  SL_BASE: SLBase, // self learning base
  COACH_NOTES: `${exerciseBase}/coach-notes`,
  SELECT_WITH_COACH_OR_NOT: `${exerciseBase}/select-with-coach-or-not`,
  SINGLE_STEP: `${exerciseBase}/steps/:stepId`,
  SINGLE_STEP_PREVIEW: `${exerciseBase}/steps/:stepId/preview`,
  EXERCISE_EXPLAINER: `${exerciseBase}/explain`,
  EXERCISE_FEEDBACK: `${exerciseBase}/feedback`,
  EXERCISE_COMPLETED: `${exerciseBase}/:milestoneId/completed`,
  REVIEW_COMPLETED_SL: `${exerciseBase}/review/completed`,
  WON_NEW_BADGE: `${exerciseBase}/new-badge/:badgeType`,
  EXERCISE_EXPLAINER_SL: `${SLBase}/explain`,
  SINGLE_STEP_SL: `${SLBase}/steps/:stepId`,
  COACH_NOTES_SL: `${SLBase}/coach-notes`,
  SINGLE_STEP_PREVIEW_SL: `${SLBase}/steps/:stepId/preview`,
};

const facilitator = '/facilitator';

const FACILITATOR = {
  BASE: `${facilitator}`,
  LOGIN: `${facilitator}/login`,
  DASHBOARD: `${facilitator}/dashboard`,
  MY_LEARNERS: `${facilitator}/my-learners`,
  MY_COACHES: `${facilitator}/my-coaches`,
  ADD_LEARNER: `${facilitator}/add-learner`,
  ADD_COACH: `${facilitator}/add-coach`,
  APPROVE_COACH: `${facilitator}/coaches/:coachId/approve/:token`,
  COACH_INVITE_LINK: `${facilitator}/coaches/:coachId/invite/:token`,
  MANAGE_COACH_APPLICATION: `${facilitator}/coaches/:coachId/manage/:action`,
  COACHES: `${facilitator}/coaches/:statusType`,
  LEARNER: `${facilitator}/learner/:userId`,
  LEARNERS: `${facilitator}/learners`,
  ABOUT: `${facilitator}/about-shannon-trust`,
  TESTIMONIALS: `${facilitator}/testimonials`,
  COACH_PROFILE: `${facilitator}/coaches/:statusType/:coachId`,
  COACH_DETAILS: `${facilitator}/coaches/:statusType/:coachId/details`,
  COACH: `${facilitator}/coach/:signupToken`,
  LEARNER_REFERRAL: `${facilitator}/learner/:id/referral`,
  LEARNER_PROGRESS_REPORTS: `${facilitator}/learner/:id/progress-reports`,
  ADD_LEARNER_PROGRESS_REPORTS: `${facilitator}/learner/:id/progress-reports/add`,
  ASSIGN_COACH: `${facilitator}/learner/:id/assign`,
  COACH_NOTES: `${facilitator}/coaches/:statusType/:coachId/notes`,
  EDIT_NOTE: `${facilitator}/coaches/editNote`,
  LEARNER_REACHED_MILESTONES: `${facilitator}/learner/learner-reached-milestones/:learnerId`,
};

const coach = '/coach';

const COACH = {
  DASHBOARD: `${coach}/dashboard`,
  PROFILE: `${coach}/profile`,
  STUCK: `${coach}/stuck`,
  LOGIN: `${coach}/login`,
  SIGNUP: `${coach}/signup/:signupToken`,
  WELCOME: `${coach}/welcome`,
  NEW_LEARNER: `${coach}/new-learner/:userId`,
  INTRODUCTORY_MEETING: `${coach}/new-learner/:userId/introductory-meeting`,
  LEARNERS: `${coach}/learners`,
  LEARNERS_STUCK_AT: `${coach}/learners-stuck-at`,
  REVIEW_STUCK_AT: `${coach}${exerciseBase}/steps/:stepId/review-stuck-at`,
  REVIEW_LEARNER_SELF_LEARNING: `${coach}/learners/:id/review-self-learning`,
  COLOURED_OVERLAY: `${coach}/coloured-overlay`,
  ONBOARDING_1: `${coach}/onboarding-1`,
  ONBOARDING_2: `${coach}/onboarding-2`,
  ONBOARDING_3: `${coach}/onboarding-3`,
  ONBOARDING_4: `${coach}/onboarding-4`,
  ONBOARDING_5: `${coach}/onboarding-5`,
  ONBOARDING_6: `${coach}/onboarding-6`,
  LEARNER_DASHBOARD: `${coach}/learners/:id/dashboard`,
  LEARNER_PROFILE: `${coach}/learners/:id/profile`,
  CONFIRM_LEARNER: `${coach}/learner/:id/confirm`,
  HAVE_ISSUE: `${coach}/learner/:id/have-issue`,
  COACH_PROFILE: `${coach}/profile`,
  TRAINING_CHECKLIST: `${coach}/training-checklist`,
  DASHBOARD_ADVICE: `${coach}/dashboard/advice`,
  DASHBOARD_TRAINING_CHECKLIST: `${coach}/dashboard/training-checklist`,
  DASHBOARD_SAFEGUARDING: `${coach}/dashboard/safeguarding`,
  DASHBOARD_QUALITIES: `${coach}/dashboard/qualities`,
  LEARNER_REACHED_MILESTONES: `${coach}/learners/learner-reached-milestones/:learnerId`,
};

const cms = '/cms';

const CMS = {
  BASE: `${cms}`,
  CREATE_MILESTONE: `${cms}/milestones/new`,
  VIEW_MILESTONES: `${cms}/milestones/all`,
  VIEW_EXERCISES: `${cms}/exercises/all`,
  VIEW_EXERCISE: `${cms}/exercises/:id`,
  CREATE_EXERCISES: `${cms}/exercises/new`,
  VIEW_EXERCISE_STEPS: `${cms}/exercises/:id/steps/all`,
  SINGLE_STEP: `${cms}/exercises/:id/steps/:stepId`,
  VIEW_STEP: `${cms}/exercises/:id/steps/:stepId`,
  MEDIA: `${cms}/media`,
  MEDIA_UPDATE: `${cms}/media/:id`,
};

const EXTERNAL = {
  ONBOARDING_LEARNERS_VIDEO:
    'https://www.loom.com/embed/97306ad3e4fe45afa826472f9cb9fb45',
  HOW_TO_USE_VIDEO:
    'https://www.loom.com/embed/56d6221148f749de9940364c42c1d66c',
  TERMS_OF_USE:
    'https://drive.google.com/file/d/1MCIlPUjjQkMw1m2e9Wpo_nxVbCZUdNrp/view',
  PRIVACY_POLICY:
    'https://docs.google.com/document/d/1jN_VCgQitvs4BnnSxeNl_VlaiFnI-H8z/edit?usp=sharing&ouid=114487763822736370607&rtpof=true&sd=true',
  CONTACT_US: 'chloe.bradbury@shannontrust.org.uk',
  REFER_A_LEARNER:
    'https://www.shannontrust.org.uk/get-started-with-turning-pages',
  MOODLE: 'https://shannontrust.clcmoodle.org/local/roadmaplogin/login.php',
};

export { GENERAL, EXTERNAL, LEARNER, COACH, FACILITATOR, EXERCISES, CMS };
