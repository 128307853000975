import { useState } from 'react';
import moment from 'moment';
import { Button, DatePicker, Select, Table } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import { Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';

import NameColumn from './NameColumn';
import * as S from './style';
import { FACILITATOR } from '../../../constants/nav-routes';
import { coachApprovalStatuses, coachTypes } from '../../../constants';
import {
  useUpdateCoachInterviewDate,
  useUpdateCoachType,
} from '../../../api-calls/facilitator.queries';
import {
  dateFormat,
  decideCoachTypeTagProps,
  decideStatusTagProps,
} from './helpers';

const optionsCoachType = [
  { value: coachTypes.PEER_COACH, label: 'Peer coach' },
  { value: coachTypes.COACH, label: 'Coach' },
  { value: coachTypes.PROFESSIONAL_COACH, label: 'Professional coach' },
];

const Option = Select.Option;

const CoachesTableNew = ({
  statusType,
  dataSource,
  pageSize,
  onChange,
  isLoading,
  page,
}) => {
  const [interviewDateUpdateData, setInterviewDateUpdateData] = useState({});
  const [coachTypeUpdateData, setCoachTypeUpdateData] = useState({});
  const {
    mutate: updateCoachInterviewDate,
    error: updateCoachInterviewDateError,
    isLoading: updateCoachInterviewDateLoading,
  } = useUpdateCoachInterviewDate({
    interviewDateUpdateData,
  });
  const pagination = {
    showSizeChanger: false,
    pageSize,
    total: dataSource?.[0]?.total || 0,
  };
  const {
    mutate: updateCoachType,
    error: updateCoachTypeError,
    isLoading: updateCoachTypeLoading,
  } = useUpdateCoachType({
    coachTypeUpdateData,
    page,
    pageSize,
  });

  const handleInterviewDateChange = ({ date, coachId }) => {
    return setInterviewDateUpdateData({
      date: date?._isValid ? date.format('YYYY-MM-DD HH:mm:ss') : null,
      coachId,
    });
  };

  const handleCoachTypeChange = ({ value, coachId }) => {
    setCoachTypeUpdateData({ value, coachId });
  };

  const columnsNewCoaches = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (name, { id }) => (
        <NameColumn name={name} id={id} statusType={statusType} />
      ),
      responsive: ['sm'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      responsive: ['sm'],
    },
    {
      title: 'Name / Email',
      render: (record) => (
        <>
          <NameColumn
            name={record.fullName}
            id={record.id}
            statusType={statusType}
          />

          <br />
          {record.email}
        </>
      ),
      responsive: ['xs'],
    },
    {
      title: 'Status',
      dataIndex: 'approvalStatus',
      key: 'approvalStatus',
      render: (status, record) => {
        const statusTagProps = decideStatusTagProps(status);
        const StatusTag = () => (
          <S.Tag
            bgColor={statusTagProps.bgColor}
            fontColor={statusTagProps.fontColor}
          >
            {statusTagProps.text}
          </S.Tag>
        );

        if (status === coachApprovalStatuses.APPROVED) {
          return (
            <S.ApprovedStatusColWrapper>
              <StatusTag />
              <T.Link
                to={FACILITATOR.COACH_INVITE_LINK.replace(
                  ':coachId',
                  record?.id
                ).replace(':token', record?.token)}
              >
                <Button type="text" icon={<EyeOutlined />}>
                  View invite link
                </Button>
              </T.Link>
            </S.ApprovedStatusColWrapper>
          );
        }
        return <StatusTag />;
      },
      responsive: ['sm'],
    },
    {
      title: 'Type of coach',
      dataIndex: 'type',
      key: 'type',
      render: (type, record) => {
        return (
          <Select
            style={{ width: '210px' }}
            onChange={(value) => {
              handleCoachTypeChange({ value, coachId: record.id });
              // only run update when selected value is different
              if (value !== type) {
                updateCoachType({ value, coachId: record.id });
              }
            }}
            placeholder="Select option..."
            value={type}
          >
            {optionsCoachType.map(({ value, label }) => {
              const coachTypeTagProps = decideCoachTypeTagProps(value);

              return (
                <Option value={value} key={value}>
                  <S.Tag
                    bgColor={coachTypeTagProps.bgColor}
                    fontColor={coachTypeTagProps.fontColor}
                  >
                    {label}
                  </S.Tag>
                </Option>
              );
            })}
          </Select>
        );
      },
      responsive: ['sm'],
    },
    {
      title: 'Interview date',
      dataIndex: 'interviewTime',
      key: 'interviewTime',
      render: (interviewTime, record) => {
        let formattedInterviewDate;
        if (interviewTime) {
          formattedInterviewDate =
            interviewTime[interviewTime.length - 1] === 'Z'
              ? moment(interviewTime.slice(0, -1))
              : moment(interviewTime);
        }
        if (
          record.approvalStatus === coachApprovalStatuses.INVITED_TO_INTERVIEW
        ) {
          return (
            <DatePicker
              style={{ width: '180px' }}
              showTime={{ format: 'HH:mm' }}
              onChange={(date, strDate) => {
                handleInterviewDateChange({
                  date: strDate,
                  coachId: record.id,
                });
                updateCoachInterviewDate({
                  date: strDate || date,
                  coachId: record.id,
                });
              }}
              format={dateFormat}
              value={formattedInterviewDate}
              placeholder="Select date..."
            />
          );
        }
        // in cases where coaches are still in this table and have an active interview date render it
        return formattedInterviewDate
          ? formattedInterviewDate.format('DD MMMM YYYY HH:mm')
          : '-';
      },
      responsive: ['sm'],
    },
    {
      title: 'Submission date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => (date ? moment(date).format('DD MMMM YYYY') : '-'),
      responsive: ['sm'],
    },
    {
      title: 'Interview / Submission dates',
      render: (record) => (
        <>
          {record?.interviewTime
            ? moment(record?.interviewTime).format('DD/MM/YY')
            : '-'}

          <br />
          {record?.createdAt
            ? moment(record?.createdAt).format('DD/MM/YY')
            : '-'}
        </>
      ),
      responsive: ['xs'],
    },
  ];

  return (
    <S.Wrapper fullWidth>
      <Row mt="8">
        <T.H2 color="neutralMain" size="medLarge">
          Coach recruitment
        </T.H2>
        <Row mt="5" mb="5">
          <T.H2 color="neutralMain" size="med">
            Requests
          </T.H2>
        </Row>
      </Row>
      <Table
        columns={columnsNewCoaches}
        dataSource={dataSource}
        pagination={pagination}
        onChange={onChange}
        loading={
          isLoading || updateCoachTypeLoading || updateCoachInterviewDateLoading
        }
        rowKey="id"
      />
      {updateCoachInterviewDateError || updateCoachTypeError ? (
        <T.P color="error">
          {updateCoachInterviewDateError?.message ||
            updateCoachTypeError?.message}
        </T.P>
      ) : null}
    </S.Wrapper>
  );
};

export default CoachesTableNew;
