import { Col, Row } from '../../components/Grid';

import * as T from '../../components/Typography';

const ThankYou = () => {
  return (
    <Row>
      <Col w={[4, 6, 6]}>
        <T.H2>Thank you for submitting the interest form!</T.H2>
        <T.P mt={3}>
          You will be contacted soon with next steps. This usually takes 2-3
          working days.
        </T.P>
      </Col>
    </Row>
  );
};

export default ThankYou;
