import * as T from '../../../components/Typography';
import { BasicButton } from '../../../components/Button';

import * as S from './style';
import { UseExerciseWithSelfLearning } from '../../../Hooks';

const ReviewCompleted = () => {
  const { handleNext } = UseExerciseWithSelfLearning();

  return (
    <S.Wrapper>
      <S.ButtonWrapper flex="1">
        <T.H2 ta="center">Review complete!</T.H2>
      </S.ButtonWrapper>
      <S.ButtonWrapper flex="1">
        <BasicButton
          handleClick={() => handleNext({ isReviewCompleted: true })}
          icon="next"
          variant="primary"
        >
          <T.P color="white" weight="semi">
            Next
          </T.P>
        </BasicButton>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default ReviewCompleted;
