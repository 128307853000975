import { useEffect, useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayButton, PlayBig } from '../../../../components/Button';
import Markdown from '../../../../components/Markdown';
import { createH1 } from '../../../../utils/markdown';
import StepFooterButtons from '../StepFooterButtons';
import * as S from './style';

const Step = ({
  data: {
    title,
    subtitle,
    sentence,
    titleImage,
    textAudio,
    textAudioUrl,
    options,
  } = {},
  imageUrl,
  audioUrl,
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  selfLearning,
}) => {
  const [enableNext, setEnableNext] = useState(false);

  useEffect(() => {
    if (!audioUrl) {
      setEnableNext(true);
    } else {
      setEnableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl]);

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {textAudio && textAudioUrl ? (
          <S.TextAudioWrapper ml={3} mlM={1} mlT={2} mt={1} mb={7}>
            <PlayButton
              audioUrl={textAudioUrl}
              width="56px"
              height="56px"
              iconProps={{ width: '32px', height: '32px' }}
            />
            <T.P ml={3} weight="regular" size="med">
              {textAudio}
            </T.P>
          </S.TextAudioWrapper>
        ) : null}
        {title ? (
          <Markdown
            text={createH1(title)}
            customStyles={{
              h1: {
                size: 'extraLarge',
                ta: 'center',
                weight: 'bold',
                mb: 2,
              },
            }}
          />
        ) : null}
        {titleImage && imageUrl ? (
          <S.TitleImageWrapper mt={6} mb={4}>
            <S.TitleImageContainer>
              <S.TitleImg src={imageUrl} alt={titleImage} />
            </S.TitleImageContainer>
            <Markdown
              text={createH1(titleImage)}
              customStyles={{
                h1: {
                  size: 'extraLarge',
                  weight: 'regular',
                },
              }}
            />
          </S.TitleImageWrapper>
        ) : null}

        {subtitle ? (
          <Markdown
            text={subtitle}
            customStyles={{
              p: { size: 'med', ta: 'center', mb: 4 },
            }}
          />
        ) : null}

        {sentence ? (
          <S.SentenceWrapper>
            <Markdown
              text={sentence.length < 20 ? createH1(sentence) : sentence}
              customStyles={{
                pre: {
                  size: 'med',
                  weight: 'regular',
                  mt: 5,
                  ta: 'left',
                },
                p: {
                  size: 'large',
                  weight: 'regular',
                  mt: 5,
                  lh: '150%',
                  ta: 'left',
                },
                h1: {
                  size: 'large',
                  ta: 'center',
                  weight: 'regular',
                  mt: 5,
                },
              }}
            />
          </S.SentenceWrapper>
        ) : null}

        {imageUrl && !titleImage ? (
          <S.ImageWrapper>
            <S.Img src={imageUrl} alt={subtitle} />
          </S.ImageWrapper>
        ) : null}
      </S.ContentWrapper>
      <S.ButtonsWrapper>
        <S.ButtonWrapper>
          <PlayBig
            handleClick={() => setEnableNext(true)}
            audioUrl={audioUrl}
            mt="3"
            mb="5"
          />
        </S.ButtonWrapper>
        <StepFooterButtons
          stuckAtReview={stuckAtReview}
          handleNext={() => {
            handleNext();
            setEnableNext(false);
          }}
          preview={preview}
          disabled={disabled}
          enableNext={enableNext}
          selfLearning={selfLearning}
        />
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default Step;
