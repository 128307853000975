import { useEffect, useState, useReducer, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BasicButton } from './../../../components/Button';
import validate from './validation';
import { Exercises, Steps } from './../../../api-calls';
import { BasicInput, Dropdown } from './../../../components/Inputs';
import { comprehensionsKeys, exerciseTypes } from './../../../constants';
import { useGeneralState } from './../../../context/general-state';
import Preview from './Preview';
import * as S from './style';
import {
  replaceMediaKeysWithUrls,
  convertSnakeCaseToSpaces,
} from './../../../helpers';
import StepForms from './StepForms';
import SuccessModal from './SuccessModal';
import VoiceToggle from '../../VoiceToggle';
import Loading from '../../../components/Loading';
import { CMS } from '../../../constants/nav-routes';
const exerciseTypesComprehension = [
  exerciseTypes.TYPING,
  exerciseTypes.MULTIPLE_CHOICE,
  exerciseTypes.REORDER,
  exerciseTypes.COMPREHENSION_LISTEN_AND_RESPOND,
  // exerciseTypes.SELECT_RECIPES,
];

const exerciseTypesDefault = [
  exerciseTypes.LISTEN_AND_REPEAT,
  exerciseTypes.LISTEN_AND_REPEAT_MULTIPLE,
  exerciseTypes.DRAG_AND_DROP_1,
  exerciseTypes.DRAG_AND_DROP_2,
  exerciseTypes.DRAG_AND_DROP_ORDER_SOUNDS,
  exerciseTypes.SPOT_THE_WORD,
  exerciseTypes.WORD_BUILD,
  exerciseTypes.VOWEL_BREAK,
  exerciseTypes.LISTEN_AND_REPEAT_GRID,
  exerciseTypes.LISTEN_AND_REPEAT_GRID_HIDDEN,
  exerciseTypes.WORD_JOIN_1,
  exerciseTypes.WORD_JOIN_2,
  exerciseTypes.SENTENCE_FILL,
  exerciseTypes.TYPE_NEW_ENDINGS,
  exerciseTypes.COMBINE_WORDS,
  exerciseTypes.COMBINE_WORDS_PRE,
  exerciseTypes.TEXT_FINDER,
  exerciseTypes.COMPREHENSION_TEXT_FINDER,
  exerciseTypes.MULTIPLE_CHOICE,
  exerciseTypes.MULTIPLE_CHOICE_BUBBLE,
  exerciseTypes.MULTIPLE_CHOICE_GRID,
];

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const initialState = {
  form: {
    order: null,
    type: null,
  },
  data: {},
  httpError: '',
  validationErrs: {},
  getStepLoading: false,
  getExerciseLoading: false,
  key: 0,
};

const CreateStep = () => {
  const [exercise, setExercise] = useState();
  const [state, setState] = useReducer(reducer, initialState);
  const submitAttempt = useRef(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id, stepId } = useParams();
  const activeStep = useRef({
    id: null,
    mode: stepId === 'new' ? 'create' : 'update',
  });
  const { state: generalState } = useGeneralState();
  const isComprehension = comprehensionsKeys.includes(exercise?.key);
  const navigate = useNavigate();

  const _exerciseTypesComprehension =
    isComprehension && exercise?.key === 'M5_P164&166'
      ? [...exerciseTypesComprehension, exerciseTypes.SELECT_RECIPES]
      : exerciseTypesComprehension;

  const setFormData = (data) => {
    setState((prevState) => {
      return {
        form: { ...prevState.form, ...data },
      };
    });
  };

  const onChangeData = (data) =>
    setState((prevState) => ({
      data: { ...prevState.data, ...data },
      form: {
        ...prevState.form,
        audioKey: data.audioKey,
        imageKey: data.imageKey,
      },
      key: prevState.key + 1,
    }));

  const [loading, setLoading] = useState(false);

  const getExercise = async () => {
    setState({ getExerciseLoading: true });

    const { data } = await Exercises.getExerciseById({ id });

    if (data) {
      setExercise(data);
      setFormData({
        order: Math.max(...(data?.steps?.map((e) => e.order) || [0]), 0) + 1,
      });
      setState({ getExerciseLoading: false });
    }
  };

  const getStep = async () => {
    setState({ getStepLoading: true });
    const { data } = await Steps.getStep({ id: stepId });
    if (data) {
      setState({
        data: {
          ...data.data,
        },
        form: {
          type: data.type,
          order: data.order,
          imageKey: data.imageKey,
          audioKey: data.audioKey,
        },
        getStepLoading: false,
      });
    }
  };

  useEffect(() => {
    getExercise();
    if (stepId !== 'new') {
      getStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, stepId]);

  const createStep = async () => {
    setLoading(true);
    const { error, data } = await Steps.createStep({
      body: { exerciseId: id, ...state.form, data: state.data },
    });
    if (!error) {
      // setFormData(initialState.form);
      activeStep.current = { id: data.id, mode: 'create' };
      // await getExercise();
      setIsModalVisible(true);

      setLoading(false);
    } else {
    }
  };
  const deleteStep = async () => {
    setLoading(true);
    const { error } = await Steps.deleteStep({
      id: stepId,
    });
    setLoading(false);
    if (!error) {
      navigate(CMS.VIEW_EXERCISE_STEPS.replace(':id', id));
    } else {
    }
  };

  const updateStep = async () => {
    setLoading(true);
    const { error } = await Steps.updateStep({
      id: stepId,
      body: { exerciseId: id, ...state.form, data: state.data },
    });
    if (!error) {
      activeStep.current = { id: stepId, mode: 'update' };
      setIsModalVisible(true);
      setLoading(false);
    } else {
    }
  };

  const validateForm = () => {
    try {
      validate({
        ...state.form,
        ...state.data,
        isComprehension,
      });

      setState({ validationErrs: { hasError: false } });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: { ...error.inner, hasError: true } });
      }

      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  const handleSubmit = () => {
    submitAttempt.current = true;
    const isValid = validateForm();
    if (!isValid) return;
    if (stepId === 'new') createStep();
    else updateStep();
  };

  const types = Object.keys(exerciseTypes)
    .filter((e) =>
      isComprehension
        ? _exerciseTypesComprehension.includes(e)
        : exerciseTypesDefault.includes(e)
    )
    .map((e) => ({
      label: convertSnakeCaseToSpaces(e),
      value: e,
    }));

  if ((stepId !== 'new' && state.getStepLoading) || state.getExerciseLoading) {
    return <Loading />;
  }

  return (
    <S.Wrapper>
      <VoiceToggle />
      <S.Form>
        <BasicInput
          label="Step order in exercise"
          value={state.form.order}
          handleChange={(order) => setFormData({ order })}
          m={{ mb: 5 }}
          type="number"
          disabled
        />

        <Dropdown
          label="Type"
          options={types}
          selected={
            state.form.type
              ? {
                  value: state.form.type,
                  label: convertSnakeCaseToSpaces(state.form.type),
                }
              : null
          }
          search
          handleChange={(type) => {
            setFormData({ type });
            setState({ data: {} });
          }}
          // error={validationErrs.milestoneId}
          m={{ mb: 5 }}
          disabled={stepId !== 'new'}
        />

        {state.form.type && (
          <StepForms
            type={state.form.type}
            onChange={onChangeData}
            errors={state.validationErrs}
            exercise={exercise}
            data={
              stepId !== 'new'
                ? {
                    ...state.data,
                    imageKey: state.form.imageKey,
                    audioKey: state.form.audioKey,
                  }
                : undefined
            }
          />
        )}

        <BasicButton onClick={handleSubmit} mt={5} loading={loading}>
          {stepId === 'new' ? 'Submit' : 'Update'}
        </BasicButton>
        {stepId !== 'new' && (
          <BasicButton
            onClick={() => {
              activeStep.current = { mode: 'confirmDelete' };
              setIsModalVisible(true);
            }}
            mt={5}
            loading={loading}
            variant="transparent"
            customColor="error"
          >
            Delete Step
          </BasicButton>
        )}
      </S.Form>
      {state.form.type && (
        <S.Preview
          key={state.key}
          increaseWidth={state.form.type === exerciseTypes.VOWEL_BREAK}
        >
          <Preview
            type={state.form.type}
            exercise={exercise}
            step={{
              ...replaceMediaKeysWithUrls(
                { ...state.form, data: state.data },
                generalState.preferredVoice
              ),
            }}
          />
        </S.Preview>
      )}
      <SuccessModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        stepId={activeStep.current?.id}
        mode={activeStep.current?.mode}
        onConfirmDelete={deleteStep}
        exerciseId={id}
      />
    </S.Wrapper>
  );
};

export default CreateStep;
