import { useReducer, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import { LEARNER } from '../../../constants/nav-routes';
import { useNavigate } from 'react-router-dom';
import { progressCheck as validate } from '../../../validation/schemas';
import { BasicSelect } from '../../../components/Inputs';
import { questionsArray as fullArray } from './Data';
import { Learners } from '../../../api-calls';
import { useAuth } from '../../../context/auth';
import { userRoles, marks } from '../../../constants/data-types';
import { useGetStepsByMilestoneId } from '../../../api-calls/progressChecks.queries';

import * as S from './style';

const ProgressProfileQuestions = () => {
  const questionsArray = fullArray.filter((q) => q.showOnProgressReport);
  const { user } = useAuth();

  const submitAttempt = useRef(false);
  const { id, milestoneId } = useParams();
  const { steps } = useGetStepsByMilestoneId({ milestoneId });
  const index = id - 1;
  const key = questionsArray[index].key;
  const navigate = useNavigate();
  const initialState = {
    form: {
      optimisticAboutFuture: '',
      feelingUseful: '',
      feelingRelaxed: '',
      dealingWithProblems: '',
      thinkingClearly: '',
      feelingCloseToOtherPeople: '',
      ableToMakeUpMind: '',
      feelingConfident: '',
      interestedInNewThings: '',
      education: '',
      employment: '',
      confidentInReadingAbility: 0,
      ableToReadLetters: 0,
      happyWithProgress: '',
      communicateWellWithOthers: 0,
    },
    httpError: '',
    validationErrs: { hasError: false },
    loading: false,
  };
  const [state, setState] = useReducer(reducer, initialState);

  const {
    form: {
      optimisticAboutFuture,
      feelingUseful,
      feelingRelaxed,
      dealingWithProblems,
      thinkingClearly,
      feelingCloseToOtherPeople,
      ableToMakeUpMind,
      feelingConfident,
      interestedInNewThings,
      education,
      employment,
      confidentInReadingAbility,
      ableToReadLetters,

      communicateWellWithOthers,
      happyWithProgress,
    },

    validationErrs,
  } = state;

  const setFormData = (value) => {
    setState((prevState) => ({ form: { ...prevState.form, [key]: value } }));
  };

  function reducer(state, newState) {
    let value = newState;
    if (typeof newState === 'function') {
      value = newState(state);
    }
    return { ...state, ...value };
  }

  const validateForm = () => {
    try {
      validate({
        ...state.form,
      });
      setState({ validationErrs: { hasError: false } });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  };
  const addFormData = async () => {
    await Learners.createProgressReport({
      learnerId:
        user.role === userRoles.COACH ? user.selectedLearnerId : user.id,
      form: {
        ...state.form,
      },
      milestoneId,
    });
  };

  const handleSubmit = () => {
    if (index === questionsArray.length - 1) {
      submitAttempt.current = true;
      const isValid = validateForm();
      if (isValid) {
        addFormData();
        navigate(
          LEARNER.PROGRESS_CHECK_QUESTIONS.replace(':stepId', 1).replace(
            ':milestoneId',
            milestoneId
          ),
          {
            state: { steps },
          }
        );
      }
    } else {
      navigate(
        LEARNER.PROGRESS_PROFILE_QUESTIONS.replace(
          ':id',
          Number(id) + 1
        ).replace(':milestoneId', milestoneId)
      );
    }
  };

  useEffect(() => {
    if (id !== 1) {
      navigate(
        LEARNER.PROGRESS_PROFILE_QUESTIONS.replace(':id', 1).replace(
          ':milestoneId',
          milestoneId
        ),
        { replace: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    optimisticAboutFuture,
    feelingUseful,
    feelingRelaxed,
    dealingWithProblems,
    thinkingClearly,
    feelingCloseToOtherPeople,
    ableToMakeUpMind,
    feelingConfident,
    interestedInNewThings,
    education,
    employment,
    confidentInReadingAbility,
    ableToReadLetters,
    communicateWellWithOthers,
    happyWithProgress,
  ]);

  const isNextDisabled = () => {
    return !state.form[key];
  };

  return (
    <S.OuterWrapper jc="center">
      <Row jc="center">
        <Col w={[4, 9, 9]} mt="4">
          <T.H2 color="neutralMain">{questionsArray[index].question}</T.H2>
        </Col>
      </Row>
      {questionsArray[index].note && (
        <Row jc="center">
          <Col w={[4, 9, 9]} mt="4">
            <T.P color="neutralMain">{questionsArray[index].note}</T.P>
          </Col>
        </Row>
      )}
      <Row jc="center">
        <Col w={[4, 9, 9]} mt="3">
          {(questionsArray[index].type === 'select' ||
            questionsArray[index].type === 'yesNo') &&
            !questionsArray[index].skip && (
              <BasicSelect
                key={key}
                options={questionsArray[index].options}
                selected={state.form[key]}
                handleChange={(value) => setFormData(value)}
                error={validationErrs[key]}
              />
            )}
          {questionsArray[index].type === 'slider' && (
            <S.StyledSliderInput
              value={state.form[key]}
              onChange={(value) => {
                setFormData(value.target.value);
              }}
              aria-label="Default"
              valueLabelDisplay="auto"
              marks={marks}
              max="10"
            />
          )}
        </Col>
      </Row>

      {questionsArray[index].helper && (
        <Row jc="center">
          <Col w={[4, 9, 9]}>
            <T.P mb={3} mt={3} color="neutral90">
              {questionsArray[index].helper}
            </T.P>
          </Col>
        </Row>
      )}
      {validationErrs.hasError && (
        <Row jc="center">
          <Col w={[4, 9, 9]}>
            <T.P color="error" mb={3}>
              Please go back and fill all the required fields
            </T.P>
          </Col>
        </Row>
      )}
      <S.ButtonWrapper mt="6" style={{ maxWidth: '600px' }} jc="center">
        <Col w={[4, 9, 9]}>
          <BasicButton
            variant="primary"
            icon="next"
            iconProps={{ width: '24px', height: '24px' }}
            handleClick={handleSubmit}
            disabled={isNextDisabled()}
          />
        </Col>
      </S.ButtonWrapper>
    </S.OuterWrapper>
  );
};

export default ProgressProfileQuestions;
