import { useState } from 'react';

import { Grid, Typography as T, Inputs, Button } from '../../../components';
import { getMediaUrl } from '../../../helpers';
import { useGeneralState } from '../../../context/general-state';

import { defaultGoals } from './helpers';
import { audio } from '../../../constants';
import { useCreateLearnerGoal } from '../../../api-calls/learnersGoals.queries';

import * as S from './style';

const { Col, Row } = Grid;

const defaultGoalsArr = Object.values(defaultGoals).map((g) => ({
  label: g.title,
  value: g.title,
  imageUrl: g.image,
}));

const AddGoal = ({ goals, setGoals, addTopSpace }) => {
  const [personalGoals, setPersonalGoals] = useState([]);
  const [otherGoal, setOtherGoal] = useState('');
  const [showInputs, setShowInputs] = useState(false);

  const { mutateAsync: createGoal } = useCreateLearnerGoal({
    title: personalGoals[0] || otherGoal,
  });
  const handleCreateGoal = async () => {
    const { data } = await createGoal({ title: personalGoals[0] || otherGoal });
    setShowInputs(false);
    setOtherGoal('');
    setPersonalGoals([]);
    setGoals((goals) => ({
      ...goals,
      unCompleted: [...goals.unCompleted, data],
    }));
  };

  const goalsTitles = goals.map((g) => g.title);

  const { state: generalState } = useGeneralState();

  return (
    <>
      {showInputs ? (
        <>
          {/* <Row jc="center">
            <Col w={[4, 8, 8]} mt={4} noWrap>
              <Button.PlayButton
                audioUrl={getMediaUrl(
                  audio.maximumOf3Goals,
                  true,
                  generalState?.preferredVoice
                )}
                mr={'12px'}
              />
              <Tip
                customPrefix={'Remember'}
                text={'We suggest having a maximum of 3 goals to keep focussed'}
              />
            </Col>
          </Row> */}
          <Row mb={4} jc="center" mt={addTopSpace && 4}>
            <Col w={[4, 10, 10]} mt="4">
              <Inputs.ImageWithText
                options={defaultGoalsArr.filter(
                  (opt) => !goalsTitles.includes(opt.label)
                )}
                value={personalGoals}
                setValue={(props) => {
                  if (otherGoal) {
                    setOtherGoal('');
                  }
                  return setPersonalGoals(props);
                }}
                single
              />
            </Col>
          </Row>
          <Row jc="center">
            <Col w={[4, 10, 10]} mt="5">
              <T.P color="neutralMain" weight="bold">
                Something else? Please write it down{' '}
              </T.P>
            </Col>
            <Col w={[4, 10, 10]} mt="2">
              <Inputs.BasicInput
                placeholder="A personal goal..."
                type="text"
                optional="true"
                margins={{ mt: '2', mb: '1' }}
                value={otherGoal}
                autoFocus
                handleChange={(input) => {
                  if (personalGoals.length > 0) {
                    setPersonalGoals([]);
                  }
                  return setOtherGoal(input);
                }}
              />
            </Col>

            <Col w={[4, 10, 10]} mt={6}>
              <Button.BasicButton
                // send api to update the goals
                handleClick={handleCreateGoal}
                icon="next"
                variant="primary"
                disabled={personalGoals.length === 0 && !otherGoal}
                iconProps={{ width: '20', height: '20' }}
              />
            </Col>
          </Row>
        </>
      ) : (
        <S.PlayButtonWrapper mt="3">
          <Button.PlayButton
            width="56px"
            height="56px"
            audioUrl={getMediaUrl(
              audio.addANewGoal,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: 27.2 }}
          />
          <Col w={[3, 7, 7]} ml="2" mt={addTopSpace && 4}>
            <Button.BasicButton
              handleClick={() => setShowInputs(true)}
              variant="primary"
              ta="center"
            >
              Add a new goal
            </Button.BasicButton>
          </Col>
        </S.PlayButtonWrapper>
      )}
    </>
  );
};

export default AddGoal;
