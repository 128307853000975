import { Radio } from 'antd';
import { useGeneralState } from './../context/general-state';

const VoiceToggle = () => {
  const { setState: setGeneralState } = useGeneralState();

  return (
    <div
      style={{
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'fixed',
        right: '0',
        zIndex: 1000,
      }}
    >
      <Radio.Group
        onChange={(e) => {
          setGeneralState({ preferredVoice: e.target.value });
        }}
        defaultValue="MALE"
      >
        <Radio.Button value="MALE">Male Voice</Radio.Button>
        <Radio.Button value="FEMALE">Female Voice</Radio.Button>
      </Radio.Group>{' '}
    </div>
  );
};

export default VoiceToggle;
