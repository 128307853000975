import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: -5%;
`;

export const TableButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SideWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  margin-top: 195px;
  padding: 0 4vw;
`;
