import { useState, useEffect } from 'react';

import {
  Grid,
  Typography as T,
  Button,
  Modal,
  Loading,
} from '../../../components';
import { useGetLearnerGoals } from '../../../api-calls/learnersGoals.queries';

import * as S from './style';
import { getMediaUrl } from '../../../helpers';
import { audio } from '../../../constants';
import { useGeneralState } from '../../../context/general-state';
import GoalCard from './GoalCard';
import AddGoal from './AddGoal';

const { Col, Row } = Grid;

const categorizeGoals = (data) => {
  return data.reduce(
    (result, g) => {
      if (g.completed) {
        return {
          _goals: result._goals,
          _completedGoals: [...result._completedGoals, g],
        };
      } else {
        return {
          _completedGoals: result._completedGoals,
          _goals: [...result._goals, g],
        };
      }
    },
    { _goals: [], _completedGoals: [] }
  );
};

const Goals = () => {
  const { data, isLoading, isFetching, isFetched, refetch } =
    useGetLearnerGoals({ enabled: false });

  const [goals, setGoals] = useState({ unCompleted: [], completed: [] });
  const [openModal, setOpenModal] = useState(false);

  const { state: generalState } = useGeneralState();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetched) {
      const { _goals, _completedGoals } = categorizeGoals(data);
      setGoals({ completed: _completedGoals, unCompleted: _goals });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const handleTaskCheck = async ({ goalId, taskId, completed, cb }) => {
    /* This code is needed for auto completing a goal. Commenting this logic for now as we've decided not to use this feature (https://github.com/yalla-coop/shannon-trust/issues/1107)
    const currentGoal = goals.unCompleted.filter((g) => g.id === goalId)[0];
    let isLastOne =
    currentGoal.tasks.filter((t) => t.completed).length ===
    currentGoal.tasks.length - 1;
    
    if (isLastOne && completed === false) {
      // mark the goal as completed
      await cb();
      setOpenModal(true);
      const _unCompletedGoals = goals.unCompleted.filter(
        (g) => g.id !== goalId
        );
        setGoals({
          unCompleted: _unCompletedGoals,
          completed: [currentGoal, ...goals.completed],
        });
        return null;
      }
      */

    const newGoals = goals.unCompleted.map((g) => {
      if (g.id === goalId) {
        const newTasks = g.tasks.map((t) => {
          if (t.id === taskId) {
            return { ...t, completed: !completed };
          } else {
            return t;
          }
        });

        return { ...g, tasks: newTasks };
      } else {
        return g;
      }
    });
    setGoals({ ...goals, unCompleted: newGoals });
  };

  const handleDeleteGoal = async ({ goalId, completed, cb }) => {
    await cb();
    refetch();
  };

  if (isLoading || isFetching) {
    return <Loading />;
  }
  return (
    <>
      {goals.unCompleted.length > 0 && (
        <>
          <S.PlayButtonWrapper>
            <Button.PlayButton
              width="56px"
              height="56px"
              audioUrl={getMediaUrl(
                audio.myGoals,
                true,
                generalState?.preferredVoice
              )}
              iconProps={{ width: 27.2 }}
            />

            <T.H2 ml="3">My goals</T.H2>
          </S.PlayButtonWrapper>
          <Row mt="4">
            {goals.unCompleted.map((g) => (
              <Col key={g.id} w={[4, 12, 12]} mb={2}>
                <GoalCard
                  goalId={g.id}
                  title={g.title}
                  audio={g.audio}
                  tasks={g.tasks}
                  handleTaskCheck={handleTaskCheck}
                  handleDeleteGoal={handleDeleteGoal}
                  setGoals={setGoals}
                  setOpenModal={setOpenModal}
                  refetch={refetch}
                />
              </Col>
            ))}
          </Row>
        </>
      )}

      <AddGoal
        goals={[...goals.completed, ...goals.unCompleted]}
        setGoals={setGoals}
        addTopSpace={!goals.unCompleted.length}
      />
      {goals.completed.length > 0 && (
        <>
          {' '}
          <S.PlayButtonWrapper mt="7" mb="5">
            <Button.PlayButton
              width="56px"
              height="56px"
              audioUrl={getMediaUrl(
                audio.completedGoals,
                true,
                generalState?.preferredVoice
              )}
              iconProps={{ width: 27.2 }}
            />

            <Col w={[3, 7, 7]} ml="2">
              <T.H2>Completed goals</T.H2>
            </Col>
          </S.PlayButtonWrapper>
          <Row>
            {goals.completed.map((g) => (
              <Col key={g.id} w={[4, 12, 12]} mb={5}>
                <GoalCard
                  goalId={g.id}
                  title={g.title}
                  audio={g.audio}
                  tasks={g.tasks}
                  handleTaskCheck={handleTaskCheck}
                  handleDeleteGoal={handleDeleteGoal}
                  completed
                />
              </Col>
            ))}
          </Row>
        </>
      )}
      <Modal
        bgColor="primaryMain"
        visible={openModal}
        setIsModalVisible={setOpenModal}
        aria-label="goal-completed"
        aria-modal="true"
        role="dialog"
        id="title"
        title="Title"
      >
        <Row>
          <Col w={[4, 12, 12]} jc="center" mt={3}>
            <Button.PlayButton
              color="white"
              audioUrl={getMediaUrl(
                audio.goalCompleted,
                true,
                generalState?.preferredVoice
              )}
            >
              Goal completed! This has now moved to your completed goals
            </Button.PlayButton>
          </Col>

          <Col w={[4, 12, 12]} mt={4}>
            <Button.BasicButton
              handleClick={() => setOpenModal(false)}
              icon="tick"
              variant="secondary"
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Goals;
