import styled from '@emotion/styled';

import setMargin from '../../../helpers/set-margin';
import * as CS from '../style';

export const TextArea = styled.textarea`
  ${setMargin};
  ${CS.placeholderStyle};
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
  border: none;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacings[3]};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutralMain};
  font-size: 1rem;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
  box-shadow: ${({ error, theme }) =>
    error
      ? `inset 0px 0px 0px 2px ${theme.colors.error} `
      : `inset 0px 0px 0px 2px ${theme.colors.neutralMain} `};

  :hover {
    box-shadow: ${({ error, theme }) =>
      error
        ? `inset 0px 0px 0px 2px ${theme.colors.error} !important`
        : `inset 0px 0px 0px 2px ${theme.colors.primaryMain} !important`};
  }

  :focus {
    transition: unset;
    box-shadow: inset 0px 0px 0px 2px rgba(5, 23, 48, 1) !important;
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${({ theme }) => theme.colors.primaryMain};
    outline-offset: 0.2em;
  }
  // TODO: Remove transition from border
`;

export const InfoWrapper = styled.button`
  margin-bottom: ${({ theme }) => theme.spacings[2]};
  margin-left: ${({ theme }) => theme.spacings[2]};
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
`;

export const RelativeDiv = styled.div`
  position: relative;
  flex: 1;
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AlertWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 12px;
`;

export const ErrorWrapper = styled.div`
  display: flex;
`;
