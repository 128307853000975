const FeedbackLow = ({ width, height, color, ...props }) => (
  <svg
    width={width || '44'}
    height={height || '44'}
    viewbox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.240967 22.2927C0.240967 34.1968 9.89111 43.8469 21.7952 43.8469C33.6992 43.8469 43.3494 34.1968 43.3494 22.2927C43.3494 10.3887 33.6992 0.738525 21.7952 0.738525C9.89111 0.738525 0.240967 10.3887 0.240967 22.2927ZM18.7166 17.1609C18.7166 18.2946 17.7975 19.2136 16.6638 19.2136C15.5301 19.2136 14.611 18.2946 14.611 17.1609C14.611 16.0272 15.5301 15.1081 16.6638 15.1081C17.7975 15.1081 18.7166 16.0272 18.7166 17.1609ZM26.9276 19.2136C28.0613 19.2136 28.9804 18.2946 28.9804 17.1609C28.9804 16.0272 28.0613 15.1081 26.9276 15.1081C25.7939 15.1081 24.8748 16.0272 24.8748 17.1609C24.8748 18.2946 25.7939 19.2136 26.9276 19.2136ZM29.7339 27.7483C26.119 26.7501 22.4349 28.1138 20.6151 31.044C20.316 31.5256 19.6832 31.6735 19.2017 31.3744C18.7201 31.0754 18.5722 30.4426 18.8713 29.961C21.1797 26.244 25.8022 24.533 30.2803 25.7696C30.8267 25.9205 31.1473 26.4858 30.9964 27.0322C30.8456 27.5786 30.2803 27.8992 29.7339 27.7483Z"
      fill={color || '#051730'}
    />
  </svg>
);

export default FeedbackLow;
