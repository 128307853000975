import theme from '../../../theme';

const Edit = ({ width, height, color, ...props }) => (
  <svg
    width={width || '25'}
    height={height || '25'}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.875 8.63433C18.875 8.22011 19.2108 7.88433 19.625 7.88433C20.0392 7.88433 20.375 8.22011 20.375 8.63433V16.9534C20.375 18.7767 18.8615 20.25 17 20.25H8.25C6.38848 20.25 4.875 18.7767 4.875 16.9534V8.04664C4.875 6.22329 6.38848 4.75 8.25 4.75H16.3942C16.8084 4.75 17.1442 5.08579 17.1442 5.5C17.1442 5.91421 16.8084 6.25 16.3942 6.25H8.25C7.21158 6.25 6.375 7.05754 6.375 8.04664V16.9534C6.375 17.9425 7.21158 18.75 8.25 18.75H17C18.0384 18.75 18.875 17.9425 18.875 16.9534V8.63433Z"
      fill={color || theme.colors.neutralSurface}
    />
    <path
      d="M20.0947 3.96967C20.3876 3.67678 20.8624 3.67678 21.1553 3.96967C21.4482 4.26256 21.4482 4.73744 21.1553 5.03033L14.1553 12.0303C13.8624 12.3232 13.3876 12.3232 13.0947 12.0303C12.8018 11.7374 12.8018 11.2626 13.0947 10.9697L20.0947 3.96967Z"
      fill={color || theme.colors.neutralSurface}
    />
  </svg>
);

export default Edit;
