import { useReducer, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Grid,
  Typography as T,
  Inputs as I,
  Button,
} from '../../../components';
import * as S from './style';
import validate from '../../../validation/schemas/login';
import { Learners } from '../../../api-calls';
import { useAuth } from '../../../context/auth';

import { navRoutes as R, userRoles } from '../../../constants';
import ReactGA from 'react-ga';

const { Row, Col } = Grid;

const initialState = {
  learnerUniqueId: '',
  httpError: '',
  validationErrs: {},
  loading: false,
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const Login = () => {
  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const { learnerUniqueId, loading, validationErrs, httpError } = state;
  const navigate = useNavigate();
  const { setUser } = useAuth();

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnerUniqueId]);

  const validateForm = () => {
    try {
      validate({
        learnerUniqueId,
        role: userRoles.LEARNER,
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  const handleLogin = async () => {
    setState({ loading: true });

    const { error, data } = await Learners.login({ learnerUniqueId });

    setState({ loading: false });

    if (error) {
      setState({ httpError: error.message });
    } else {
      setUser(data);
      if (data.role === userRoles.LEARNER || data.role === userRoles.COACH) {
        ReactGA.event({
          category: 'login',
          action: 'login',
        });
        ReactGA.set({
          ...data,
        });
      }
      navigate(R.LEARNER.DASHBOARD);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleLogin();
    }
  };

  return (
    <S.Form onSubmit={handleSubmit}>
      <Row>
        <Col w={[4, 12, 12]}>
          <T.H2 weight="bold" color="neutralMain">
            Log in
          </T.H2>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 11, 6]} mt="5">
          <I.BasicInput
            id="learner-id"
            label="Enter your Learner ID..."
            placeholder="Learner ID..."
            margins={{ mt: '2', mb: '1' }}
            type="text"
            value={learnerUniqueId}
            autoFocus
            handleChange={(input) => setState({ learnerUniqueId: input })}
            error={validationErrs.learnerUniqueId}
          />
        </Col>
      </Row>

      <S.ButtonsWrapper mt="6">
        <S.ButtonContainer w={[4, 11, 6]}>
          {httpError && (
            <T.P mb="2" color="error">
              {httpError}
            </T.P>
          )}
          <Button.BasicButton
            id="login-button"
            variant="primary"
            disabled={false}
            loading={loading}
            type="submit"
            icon="tick"
            iconProps={{ width: '12', height: '12' }}
          ></Button.BasicButton>
        </S.ButtonContainer>
      </S.ButtonsWrapper>
    </S.Form>
  );
};

export default Login;
