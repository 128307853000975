import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import Loading from '../../../components/Loading';
import { PlayButton } from '../../../components/Button';
import PlaySoundWithImage from '../../../components/PlaySoundWithImage';
import { Typography as T } from '../../../components';
import { useGetLetterSoundsSteps } from '../../../api-calls/exercises.queries';

import { navRoutes } from '../../../constants';
import * as S from './style';
import { audio } from '../../../constants';
import { getMediaUrl } from '../../../helpers';
import { useGeneralState } from '../../../context/general-state';

const SoundBoard = () => {
  const { sounds, error, isLoading, isSuccess } = useGetLetterSoundsSteps();
  const { state: generalState } = useGeneralState();

  if (isLoading) return <Loading />;
  return isSuccess && !error ? (
    <>
      <Row jc="center">
        <Col w={[4, 7, 7]}>
          <PlayButton
            iconProps={{ width: '32px', height: '32px' }}
            padding="12px"
            audioUrl={getMediaUrl(
              audio.clickOnThePicturesBelowToPlayTheSound,
              true,
              generalState?.preferredVoice
            )}
          >
            Click on the pictures below to play the sound
          </PlayButton>
        </Col>
      </Row>
      <Row jc="center" mt={5}>
        <Col jc="start" w={[4, 7, 7]}>
          <S.CardsWrapper>
            {sounds?.map(({ imageKey, audioKey, data: { title } }, i) => (
              <S.CardWrapper>
                <PlaySoundWithImage
                  imageUrl={getMediaUrl(imageKey, false)}
                  audioUrl={getMediaUrl(
                    audioKey,
                    true,
                    generalState?.preferredVoice
                  )}
                  text={
                    title.length === 1
                      ? `${title.toLowerCase()} ${title.toUpperCase()}`
                      : title
                  }
                />
              </S.CardWrapper>
            ))}
          </S.CardsWrapper>
        </Col>
      </Row>
      <Row jc="center" mt={5}>
        <Col w={[4, 7, 7]} jc="center">
          <BasicButton
            variant="primary"
            icon="back"
            to={navRoutes.LEARNER.PROFILE}
          ></BasicButton>
        </Col>
      </Row>
    </>
  ) : (
    <>
      <Row jc="center">
        <Col w={[4, 7, 7]} mt={6}>
          <T.P mb="2" color="error">
            {error.message}
          </T.P>
        </Col>
      </Row>
    </>
  );
};

export default SoundBoard;
