import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import moment from 'moment';
import { Learners } from '../../../api-calls';
import Loading from '../../../components/Loading';
import ManualCard from '../../../components/ManualCard';
import * as S from './style';

const LearnerMilestonesReached = () => {
  const { learnerId } = useParams();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [stats, setStats] = useState([]);
  const resultsRender = [];
  let manNum = 0;
  for (let i = 0; i < stats.length; i += 3) {
    manNum++;
    resultsRender.push(
      <S.Wrapper>
        <ManualCard manualNumber={manNum} />
        <S.OuterWrapper>
          {stats.slice(i, i + 3).map((e) => (
            <S.MilestoneWrapper>
              <T.P weight="bold" mt="4" ml="5">
                {e.title}
              </T.P>
              <T.P color="neutral90" mt="3" ml="5" mr="5" mb="4">
                Achieved on {moment(e.createdAt).format('DD MMMM YYYY')}
              </T.P>
            </S.MilestoneWrapper>
          ))}
        </S.OuterWrapper>
      </S.Wrapper>
    );
  }
  useEffect(() => {
    let mount = true;
    async function fetchData() {
      setLoading(true);
      const { data, error } = await Learners.getLearnerStats({ learnerId });
      if (mount) {
        if (!error) {
          setStats(data.milestonesReached);
        } else {
          setErr(error.message);
        }
        setLoading(false);
      }
    }
    fetchData();
    return () => {
      mount = false;
    };
  }, [learnerId]);

  if (loading) return <Loading />;
  return (
    <div>
      <T.H2 mb="5">Milestones reached</T.H2>
      {stats?.length !== 0 ? (
        resultsRender
      ) : (
        <Row>
          <Col w={[4, 9, 9]} mb="5">
            <T.P>None of the milestones reached yet.</T.P>
          </Col>
        </Row>
      )}
      {err && (
        <Row>
          <Col w={[4, 7, 7]}>
            <T.P>{err.message}</T.P>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default LearnerMilestonesReached;
