import * as T from '../../Typography';
import * as CS from '../style';
import * as S from './style';
import Icon from './../../Icon';

const BasicInput = ({
  type = 'text',
  id,
  name,
  placeholder = 'Type here...',
  label,
  error,
  value,
  handleChange,
  helper,
  color = 'neutralMain',
  placeholderColor = 'neutral80',
  w, // width
  disabled,
  autoComplete,
  m, // margins
  showPasswordInfo,
  optional,
  labelColor,
  bgColor,
  key,
  suffix,
  ...props
}) => {
  const onChange = (e) => {
    handleChange(e.target.value, e);
  };

  return (
    <CS.Field w={w} disabled={disabled} {...m}>
      {label && (
        <CS.Label htmlFor={label} mb={helper ? '1' : '2'}>
          <T.P weight="bold" color={labelColor || color} m="0">
            {label}
          </T.P>
          {optional && <CS.OptionalTag ml="1">(optional)</CS.OptionalTag>}
        </CS.Label>
      )}
      {helper && (
        <T.P isSmall color={'neutral80'} mb="2" ml="2">
          {helper}
        </T.P>
      )}
      <S.BasicInputRelativeDiv>
        <S.Input
          key={key}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          color={color}
          disabled={disabled}
          autoComplete={autoComplete || 'on'}
          error={error}
          bgColor={bgColor}
          placeholderColor={placeholderColor}
          id={id}
          {...props}
        />
        {suffix && <S.AlertWrapper>{suffix}</S.AlertWrapper>}
        {error && (
          <S.AlertWrapper>
            <Icon width="28px" height="28px" icon="alert" color="error" />
          </S.AlertWrapper>
        )}
      </S.BasicInputRelativeDiv>
      {error && (
        <T.P color="error" m="0" mt="2" mx={2}>
          {error}
        </T.P>
      )}
    </CS.Field>
  );
};

export default BasicInput;
