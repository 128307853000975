import {
  useFetch,
  formatQueryError,
  usePost,
  usePatch,
} from '../utils/queries';
import { EXERCISES_BASE } from '../constants';

function useGetExercises() {
  const context = useFetch(`${EXERCISES_BASE}`);
  return {
    ...context,
    milestones: context.data,
    error: formatQueryError(context),
  };
}

function useGetLetterSoundsSteps() {
  const context = useFetch(`${EXERCISES_BASE}/letter-sounds`, undefined);
  return { ...context, sounds: context.data, error: formatQueryError(context) };
}

const useCreateCompletedStep = ({ id, learnerId, withCoach }) => {
  return usePost(
    `${EXERCISES_BASE}/steps/${id}/complete`,
    {
      learnerId,
      withCoach,
    },
    {
      options: {
        invalidateKey: [`${EXERCISES_BASE}`],
      },
    }
  );
};

function useGetMilestoneAllExercisesCompleted({ milestoneId }) {
  const context = useFetch(
    `${EXERCISES_BASE}/milestone-last-ex/${milestoneId}`
  );
  return {
    ...context,
    lastExercise: context.data,
    error: formatQueryError(context),
  };
}

function useUpdateExerciseFeedback({ exerciseId }) {
  return usePatch(`${EXERCISES_BASE}/${exerciseId}/feedback`);
}
export {
  useGetExercises,
  useGetLetterSoundsSteps,
  useCreateCompletedStep,
  useGetMilestoneAllExercisesCompleted,
  useUpdateExerciseFeedback,
};
