import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  ${setMargin};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 300px;
  background-color: ${({ theme, bgColor }) =>
    bgColor || theme.colors.neutralLight};
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
  padding: ${({ theme }) => ` ${theme.spacings[4]} ${theme.spacings[5]}`};
`;

export const StyledLink = styled(Link)`
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, bgColor }) =>
    bgColor || theme.colors.neutralLight};
`;

export const WithButtonWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralLight};
  padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[5]}`};
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
`;
export const WithButtonIcon = styled(WithButtonWrapper)`
  ${setMargin};
  flex-direction: row;
`;

export const EmptyWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralLight};
  padding: ${({ theme }) => `28px ${theme.spacings[4]}`};
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radiusLarge};
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Item = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '45%')};
`;
