import { useReducer } from 'react';

import * as T from '../../../../components/Typography';
import { Textarea } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { MediaKeyInput } from '../../../Components';
import { BasicInput } from '../../../../components/Inputs';

import { string, number, boolean, array, object } from 'yup';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data
    ? {
        ...data,
        options: data?.options?.map((e, i) => ({
          ...e,
          id: i,
        })) || [{ id: 0, option: '' }],
      }
    : {
        options: [{ id: 0, option: '' }],
        textAudio: '',
        textAudioKey: '',
      },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const Reorder = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const form = {
        ...prevState.form,
        ...data,
      };
      onChange({
        ...form,
      });
      return { form };
    });

  const addOption = () => {
    const maxId = Math.max(...state.form.options.map((e) => e.id), 0);
    setFormData({
      options: [...state.form.options, { id: maxId + 1, option: '' }],
    });
  };
  const removeOption = (id) => {
    setFormData({
      options: state.form.options.filter((e) => e.id !== id),
    });
  };

  const onOptionChange = (value, id) => {
    setFormData({
      options: state.form.options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={state.form.textAudio}
        handleChange={(textAudio) => setFormData({ textAudio })}
        m={{ mt: 5 }}
        error={errors?.textAudio}
      />
      <MediaKeyInput
        label={`Audio Text file Key`}
        value={state.form.textAudioKey}
        handleChange={(textAudioKey) => {
          setFormData({ textAudioKey });
        }}
        m={{ mt: 5 }}
        error={errors?.textAudioKey}
      />

      <T.P mt={5}>
        <T.P mt={8} size="large" weight="bold">
          Steps
        </T.P>{' '}
        (put them in the correct order, we will randomise the order for the
        learner)
      </T.P>
      {errors?.options && typeof errors.options === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.options}
        </T.P>
      )}

      {state.form.options.map((e, i) => {
        return (
          <>
            <Textarea
              label={`Step ${i + 1}`}
              value={e.option}
              handleChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mt: 5 }}
              error={errors?.options && errors?.options[i]?.option}
            />

            {state.form.options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={state.form.options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Example
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string().required('required field'),
          isCorrect: boolean(),
        })
        .required()
    )
    .required('you must provide examples')
    .min(1, 'you must provide examples'),
  textAudio: string().required('required field'),
  textAudioKey: string().required('required field'),
};
export { validationSchema };

export default Reorder;
