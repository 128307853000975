import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 600px;
`;

export const ButtonsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

export const TipWrapper = styled.div`
  margin-top: 12px;
  margin-left: 0;
`;
