import axios from 'axios';
import handleError from './format-error';

const COACHES_BASE = '/coaches';

const getCoaches = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${COACHES_BASE}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

// handled by useAddCoaches in coach.queries
// const addCoaches = async ({ form, options } = {}) => {
//   try {
//     const { data } = await axios.post(`${COACHES_BASE}`, form);
//     return { data };
//   } catch (error) {
//     const err = handleError(error, options);
//     return { error: err };
//   }
// };

const signup = async ({ form, options } = {}) => {
  try {
    const { data } = await axios.post(`${COACHES_BASE}/signup`, form);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getCoachBySignupToken = async ({ token, options } = {}) => {
  try {
    const { data } = await axios.get(`${COACHES_BASE}/signup-token/${token}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getCoachStuckAtLearners = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${COACHES_BASE}/stuck-at-learners`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getCoachDetailsById = async ({ coachId, options } = {}) => {
  try {
    const { data } = await axios.get(`${COACHES_BASE}/${coachId}/details`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateCoach = async ({
  coachId,
  onboarded,
  bestDayTime,
  availabilityHoursPerWeek,
  completedTrainingAt,
  options,
}) => {
  try {
    const { data } = await axios.post(`${COACHES_BASE}/update/`, {
      coachId,
      onboarded,
      bestDayTime,
      availabilityHoursPerWeek,
      completedTrainingAt,
    });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getCoaches,
  // addCoaches,
  signup,
  getCoachBySignupToken,
  getCoachStuckAtLearners,
  getCoachDetailsById,
  updateCoach,
};
