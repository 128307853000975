import { useParams } from 'react-router-dom';
import {} from '../../../components/Button';
import Copy from '../../../components/Copy';
import { Col } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import { COACH } from '../../../constants/nav-routes';
import theme from '../../../theme';

const InviteLink = () => {
  const { token } = useParams();

  const uniqueUrl = `${window.location.origin}${COACH.SIGNUP.replace(
    ':signupToken',
    token
  )}`;

  return token ? (
    <>
      <Col w={[4, 9, 9]} mt="5">
        <Copy
          inviteToken={uniqueUrl}
          tokenStyle={{
            color: theme.colors.quaternaryMain,
            textDecoration: 'underline',
          }}
        />
      </Col>
    </>
  ) : (
    <T.P color="error">There was an error loading the coach's token</T.P>
  );
};

export default InviteLink;
