import styled from '@emotion/styled';
import { Checkbox, Button as AntdButton, Tag as AntdTag } from 'antd';
import theme from '../../../theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  margin: ${({ dashboard }) => (dashboard ? '0' : '-5%')};
  .ant-table table {
    font-size: 1rem;
    font-weight: 400;
  }

  .ant-picker-input > input::placeholder {
    font-size: 1rem;
  }
`;

export const NameColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  span {
    color: ${({ theme }) => theme.colors['neutralMain']};
    font-weight: bold;
    font-size: 1rem;
  }
`;

export const DBSCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.colors.neutralMain};
    border-color: ${theme.colors.neutralMain};
  }
`;

export const Button = styled(AntdButton)`
  padding: 0;
`;

export const ApprovedStatusColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  button {
    font-weight: bold;
    font-size: 1rem;
    margin-left: -0.5rem;
  }
`;

export const Tag = styled(AntdTag)`
  background-color: ${({ bgColor, theme }) => theme.colors[bgColor] ?? bgColor};
  color: ${({ fontColor, theme }) => theme.colors[fontColor] ?? fontColor};
  border-radius: 24px;
  font-size: 1rem;
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

export const TableTitleWrapper = styled.div`
  min-height: 72px;
  display: flex;
  align-items: center;
`;
