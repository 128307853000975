import React from 'react';
import * as S from './style';

import Graphics from '../../Graphics';
import Header from '../Header';
import { useMediaQuery } from 'react-responsive';

import theme from '../../../theme';
import { useAuth } from '../../../context/auth';

const FullScreenComprehension = ({
  children,
  image = 'secondaryColor',
  sideColor = 'neutralLight',
  headerProps = {},
  ...props
}) => {
  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.tablet})`,
  });
  const { user } = useAuth();
  return (
    <S.Wrapper {...props} bgColor={user.overlayColor}>
      {!isTablet && <Graphics image={image} bgColor={sideColor} />}
      <S.Content>
        <Header
          {...headerProps}
          bgColor={
            user?.overlayColor !== 'default' ? 'neutralMain' : 'primaryMain'
          }
        />
        <S.ContentBody headerType={headerProps.type}>{children}</S.ContentBody>
      </S.Content>
      {!isTablet && <Graphics image={image} bgColor={sideColor} />}
    </S.Wrapper>
  );
};

export default FullScreenComprehension;
