import { EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import * as T from '../../../components/Typography';
import * as S from './style';
import { navRoutes, coachStatusesQuery } from '../../../constants';

const NameColumn = ({ name, id }) => (
  <S.NameColumnWrapper>
    <T.P ml={3}>{name}</T.P>
    <T.Link
      to={navRoutes.FACILITATOR.COACH_PROFILE.replace(
        ':statusType',
        coachStatusesQuery.ALL
      ).replace(':coachId', id)}
    >
      <Button type="text" shape="round" icon={<EyeOutlined />}>
        View Profile
      </Button>
    </T.Link>
  </S.NameColumnWrapper>
);

export default NameColumn;
