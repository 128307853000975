import {
  useFetch,
  formatQueryError,
  usePatch,
  usePost,
} from '../utils/queries';
import { LEARNERS_BASE, EXERCISES_BASE } from '../constants';

function useGetLearnerStats({ learnerId }) {
  const context = useFetch(`${LEARNERS_BASE}/${learnerId}/stats`, undefined);
  return { ...context, stats: context.data, error: formatQueryError(context) };
}

function useGetLearnerInfo({ id }) {
  const context = useFetch(`${LEARNERS_BASE}/${id}/user-info`, undefined);
  return {
    ...context,
    userInfo: context.data,
    error: formatQueryError(context),
  };
}

function useGetLearners({ page, limit }) {
  const context = useFetch(`${LEARNERS_BASE}/`, { page, limit });
  return {
    ...context,
    learners: context.data,
    error: formatQueryError(context),
  };
}

function useUpdateLearnerContactInfo({
  userId,
  preferredWaysSession,
  contactNumber,
  contactEmail,
  preferredWaysContact,
}) {
  return usePatch(
    `${LEARNERS_BASE}/${userId}/intro-meeting-info`,
    {
      preferredWaysSession,
      contactNumber,
      contactEmail,
      preferredWaysContact,
    },
    {
      options: {
        invalidateAll: true,
      },
    }
  );
}

function useGetLearnersByCoachId({ coachId }) {
  const context = useFetch(`${LEARNERS_BASE}/coach-id/${coachId}`, undefined);
  return {
    ...context,
    learners: context.data,
    error: formatQueryError(context),
  };
}

function useCoachConfirmsLearner({ learnerId, confirm }) {
  return usePost(
    `${LEARNERS_BASE}/${learnerId}/confirm`,
    {
      confirm,
    },
    {
      options: {
        invalidateKey: [`${LEARNERS_BASE}/`],
      },
    }
  );
}

function useCoachHasIssue({
  learnerUniqueId,
  message,
  coachName,
  coachPhoneNumber,
  learnerName,
}) {
  return usePost(`${LEARNERS_BASE}/has-issue`, {
    message,
    coachName,
    coachPhoneNumber,
    learnerUniqueId,
    learnerName,
  });
}

const useCreateStuckAt = ({ learnerId, stepId, pageUrl }) => {
  return usePost(
    `${LEARNERS_BASE}/${learnerId}/stuck-at`,
    {
      learnerId,
      stepId,
      pageUrl,
    },
    {
      options: {
        invalidateKey: [`${EXERCISES_BASE}`],
      },
    }
  );
};

const useUpdateActiveStatus = ({
  learnerId,
  activeStatus,
  activeStatusDate,
}) => {
  return usePost(
    `${LEARNERS_BASE}/${learnerId}/active-status`,
    {
      learnerId,
      activeStatus,
      activeStatusDate,
    },
    {
      options: {
        invalidateAll: true,
      },
    }
  );
};

export {
  useGetLearnerStats,
  useGetLearners,
  useGetLearnerInfo,
  useUpdateLearnerContactInfo,
  useCoachConfirmsLearner,
  useCoachHasIssue,
  useGetLearnersByCoachId,
  useCreateStuckAt,
  useUpdateActiveStatus,
};
