import styled from '@emotion/styled';

export const Footer = styled.footer`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: left;
  bottom: 0;
  margin-top: 0;
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.neutralMain};
  border: none;
  z-index: 100;
  padding: ${({ theme }) => `${theme.spacings[6]} ${theme.spacings[5]}`};
  padding-left: 0;
`;

export const Container = styled.div`
  flex: 1;
`;

export const SidePlaceholder = styled.div`
  width: 30vw;
  max-width: 100%;
  min-height: auto;
  ${({ theme }) => theme.media.tablet} {
    display: none;
  }
`;

export const FooterContent = styled.div`
  max-width: 1200px;
  width: 100%;
  padding-left: ${({ theme: { constants } }) =>
    constants.layout.side.leftPadding.desktop};
  padding-right: ${({ theme: { constants } }) =>
    constants.layout.side.leftPadding.desktop};

  ${({ theme }) => theme.media.tablet} {
    align-items: stretch;

    padding-left: ${({ theme: { constants } }) =>
      constants.layout.side.leftPadding.tablet};
    padding-right: ${({ theme: { constants } }) =>
      constants.layout.side.leftPadding.tablet};
  }
  ${({ theme }) => theme.media.mobile} {
    padding-left: ${({ theme: { constants } }) =>
      constants.layout.side.leftPadding.mobile};
    padding-right: ${({ theme: { constants } }) =>
      constants.layout.side.leftPadding.mobile};
  }
`;
