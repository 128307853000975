import { useEffect, useState } from 'react';

import * as T from '../../../../components/Typography';
import { BasicButton } from '../../../../components/Button';
import { UseExerciseWithSelfLearning } from '../../../../Hooks';

import EmptySpace from '../../../../components/EmptySpace';
import Option from '../../../../components/Option';
import * as S from './style';
import { showMovingBlock } from './../../../../helpers';
import TryAgainModal from '../../../../components/Modal/TryAgainModal';
import ContinueSolving from './ContinueSolving';
import OtherCorrectAnswers from './OtherCorrectAnswers';
import StepFooterButtons from '../StepFooterButtons';

import { useGeneralState } from '../../../../context/general-state';

const WordBuild = ({
  data: { options: _options = [], correctOptions: _correctOptions = [] } = {},
  handleNext,
  stuckAtReview,
  disabled,
  enableNext,
  preview,
  selfLearning,
  cmsPreview,
}) => {
  const { setState } = useGeneralState();
  const { setSubProgress } = UseExerciseWithSelfLearning();

  const [isCompleted, setIsCompleted] = useState(false);
  const [disableCheck, setDisableCheck] = useState(false);
  const [correctOptions, setCorrectOptions] = useState(_correctOptions);
  const [activePosition, setActivePosition] = useState({ row: 0, column: 0 }); // [row, column]
  const sortedOptionsByLength = [..._correctOptions].sort(
    (a, b) => b?.option?.length - a?.option?.length
  );
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  const longestWordLength = sortedOptionsByLength[0]?.option?.length;
  const shortestWordLength =
    sortedOptionsByLength[sortedOptionsByLength.length - 1]?.option?.length;

  const options = _options.map((op, i) => ({ option: op.option, id: i }));

  const answerRow = new Array(Math.max(longestWordLength || 0, 3)).fill(null);

  const defaultEmptyAnswersArray = [
    answerRow,
    answerRow,
    answerRow,
    answerRow,
    answerRow,
  ];
  const [answers, setAnswers] = useState(defaultEmptyAnswersArray);

  const onClickAnswer = (row, column, answer) => {
    if (answer?.option) {
      setActivePosition({ row, column });
      showMovingBlock({
        OptionElId: `option-${answer.id}`,
        answerElId: `answer-${row}-${column}`,
        movingElId: 'moving-div',
        isBeingSelected: true,
      });
      setAnswers((_answers) =>
        _answers.map((_row, ir) =>
          _row.map((_column, ic) => {
            if (ir === row && ic === column) {
              return null;
            } else {
              return _column;
            }
          })
        )
      );
    } else {
      setActivePosition({ row, column });
    }
  };

  const onFocusAnswer = (row, column) => {
    setActivePosition({ row, column });
  };

  const onClickOption = (option) => {
    setDisableCheck(true);
    showMovingBlock({
      OptionElId: `option-${option.id}`,
      answerElId: `answer-${activePosition.row}-${activePosition.column}`,
      movingElId: 'moving-div',
      isBeingSelected: false,
      done: () => {
        setAnswers((_answers) =>
          _answers.map((row, ir) =>
            row.map((column, ic) => {
              if (ir === activePosition.row && ic === activePosition.column) {
                return option;
              } else {
                return column;
              }
            })
          )
        );
        setDisableCheck(false);
      },
    });

    setActivePosition((_activePosition) => ({
      row: _activePosition.row,
      column:
        _activePosition.column + 1 >= answerRow.length
          ? _activePosition.column
          : _activePosition.column + 1,
    }));
  };

  const concatRowAnswer = (row) => {
    return answers[row]
      .map((e) => e?.option || ' ')
      .join('')
      .trimEnd();
  };

  const validateRow = (row) => {
    const currentAnswer = concatRowAnswer(row);
    if (currentAnswer.includes(' ')) {
      return false;
    }

    return correctOptions.find((e) => e?.option === currentAnswer);
  };

  const onCheck = () => {
    const correctOption = validateRow(activePosition.row);
    if (correctOption) {
      setCorrectOptions((_correctOptions) =>
        _correctOptions.filter((e) => e.option !== correctOption.option)
      );

      if (isCompleted) {
        setShowCompleteModal(true);
      }

      if (activePosition.row + 1 === answers.length) {
        setIsCompleted(true);
        setShowCompleteModal(true);
      } else {
        setActivePosition((_activePosition) => ({
          row: _activePosition.row + 1,
          column: 0,
        }));
      }
    } else {
      setShowTryAgain(true);
    }
  };

  const onComplete = () => {
    setShowCorrectAnswers(true);
  };

  const onAddMore = () => {
    if (activePosition.row + 1 === answers.length) {
      setActivePosition({ row: 0, column: 0 });
      setAnswers(defaultEmptyAnswersArray);
    }
    setShowCompleteModal(false);
  };

  const currentAnswer = concatRowAnswer(activePosition.row);

  useEffect(() => {
    if (cmsPreview) return;
    const answeredWords = _correctOptions.length - correctOptions.length;
    setState({
      headerSteps: {
        show: true,
        requiredSteps: 5,
        completedSteps: answeredWords,
      },
    });
    setSubProgress((answeredWords >= 5 ? 5 : answeredWords) / 5);

    return () => {
      setState({
        headerSteps: {
          show: false,
          requiredSteps: null,
          completedSteps: null,
        },
      });
    };
  }, [
    _correctOptions.length,
    cmsPreview,
    correctOptions.length,
    setState,
    setSubProgress,
  ]);

  if (showCorrectAnswers) {
    return (
      <OtherCorrectAnswers
        disabled={disabled}
        enableNext={enableNext}
        handleNext={handleNext}
        data={{ correctOptions }}
      />
    );
  }

  return (
    <S.Wrapper>
      {/* keep this to animation */}
      <div style={{ display: 'none' }}>
        <Option id="moving-div"></Option>
      </div>
      <TryAgainModal
        isModalVisible={showTryAgain}
        setIsModalVisible={setShowTryAgain}
      />
      <ContinueSolving
        isModalVisible={showCompleteModal}
        setIsModalVisible={setShowCompleteModal}
        onComplete={onComplete}
        onAddMore={onAddMore}
      />

      <S.Grid>
        {answers.map((row, ir) => (
          <S.Row disabled={ir !== activePosition.row} key={ir}>
            {row.map((answer, ic) => (
              <EmptySpace
                isActive={
                  ir === activePosition.row &&
                  ic === activePosition.column &&
                  !cmsPreview
                }
                onClick={() => onClickAnswer(ir, ic, answer)}
                onFocus={() => onFocusAnswer(ir, ic, answer)}
                id={`answer-${ir}-${ic}`}
                bgColor={
                  answer?.id ? 'rgba(255, 255, 255, 0.5)' : 'neutralSurface'
                }
                key={`${ir}-${ic}`}
                disabled={ir !== activePosition.row}
              >
                {answer?.option}
              </EmptySpace>
            ))}
          </S.Row>
        ))}
      </S.Grid>

      <S.OptionsGridWrapper>
        <S.OptionsGrid>
          {options.map((op) => (
            <Option
              m={1}
              onClick={() => onClickOption(op)}
              id={`option-${op.id}`}
              key={op.id}
            >
              {op.option}
            </Option>
          ))}
        </S.OptionsGrid>
      </S.OptionsGridWrapper>

      {(!_options || !_correctOptions) && (
        <T.P color="error">Exercise missing required fields </T.P>
      )}
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={onCheck}
        preview={preview}
        disabled={currentAnswer.includes(' ') || disableCheck}
        enableNext={currentAnswer.length >= shortestWordLength}
        selfLearning={selfLearning}
        title="Check"
      />
      {isCompleted && (
        <BasicButton
          disabled={disabled}
          handleClick={setShowCorrectAnswers}
          icon="next"
          variant="primary"
          mt={4}
        >
          <T.P color="white" weight="semi">
            Next
          </T.P>
        </BasicButton>
      )}
    </S.Wrapper>
  );
};

export { OtherCorrectAnswers };
export default WordBuild;
