import * as S from './style';
import Checkbox from '../Checkbox';

const GroupCheckbox = ({
  state = [],
  setState,
  onClickToRedirect,
  avoidClick,
  largeDivider,
  ...props
}) => {
  if (!state.length) return null;
  const handleChange = (value, id) => {
    setState((_state) =>
      _state.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const Component = onClickToRedirect ? S.ItemWrapperLink : S.ItemWrapper;

  return (
    <S.Wrapper>
      {state.map((item, index) => (
        <Component to={item.to} key={item.id}>
          <Checkbox
            checked={item.checked}
            label={item.label}
            component={item?.component}
            value={item?.value}
            avoidClick={avoidClick}
            handleChange={(value) => {
              if (avoidClick) {
                return null;
              }
              return onClickToRedirect
                ? undefined
                : handleChange(value, item.id);
            }}
            {...props}
          />
          {index !== state.length - 1 && (
            <S.Divider largeDivider={largeDivider} />
          )}
        </Component>
      ))}
    </S.Wrapper>
  );
};

export default GroupCheckbox;
