import { Col, Row } from '../../../components/Grid';
import * as TipsCard from '../../../components/TipsCard';
import * as T from '../../../components/Typography';

const CardsSection = ({ onboarded }) => {
  return (
    <>
      <Row mt="5" ai="stretch">
        <Col w={[4, 12, 12]}>
          <T.H2>View advice on coaching</T.H2>
        </Col>
        {onboarded && (
          <Col w={[4, 8, 7]} mt="4">
            <TipsCard.TrainingChecklist />
          </Col>
        )}
        <Col w={[4, 8, 7]} mt="2">
          <TipsCard.Safeguarding />
        </Col>
        <Col w={[4, 8, 7]} mt="2">
          <TipsCard.Advice />
        </Col>
        <Col w={[4, 8, 7]} mt="2">
          <TipsCard.Qualities />
        </Col>
      </Row>
    </>
  );
};

export default CardsSection;
