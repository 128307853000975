import { useReducer, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { introMeeting as validate } from '../../../validation/schemas';
import { Inputs, Typography as T } from '../../../components';
import { BasicButton } from '../../../components/Button';
import { Row, Col } from '../../../components/Grid';
import { useParams } from 'react-router-dom';
import { COACH, LEARNER, EXTERNAL } from '../../../constants/nav-routes';
import Checkbox from '../../../components/Inputs/Checkbox/';

import * as S from './style';
import {
  sessionPreferencesDropDown,
  contactWays,
} from '../../../constants/data-types';
import {
  useGetLearnerInfo,
  useUpdateLearnerContactInfo,
} from '../../../api-calls/learners.queries';
const { Dropdown, BasicInput } = Inputs;

function reducer(state, newState) {
  return { ...state, ...newState };
}

const NewLearner = () => {
  const submitAttempt = useRef(false);
  const navigate = useNavigate();
  const { userId } = useParams();
  const { userInfo } = useGetLearnerInfo({ id: userId });

  const initialState = {
    preferredWaysSession: [],
    contactNumber: userInfo?.phoneNumber?.trim(),
    contactEmail: userInfo?.email,
    preferredWaysContact: [],
    giveConsent: false,
    validationErrs: {},
  };
  const [state, setState] = useReducer(reducer, initialState);
  const {
    preferredWaysSession,
    contactNumber,
    contactEmail,
    preferredWaysContact,
    validationErrs,
    giveConsent,
  } = state;

  const {
    mutate: addIntroMeetingInfo,
    error: addIntroMeetingInfoHttpError,
    isLoading,
  } = useUpdateLearnerContactInfo({
    userId,
    preferredWaysSession,
    contactNumber,
    contactEmail,
    preferredWaysContact,
  });
  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredWaysSession, contactNumber, contactEmail, preferredWaysContact]);

  useEffect(() => {
    if (userInfo && userInfo.email) {
      setState({ contactEmail: userInfo.email });
    }
    if (userInfo && userInfo.phoneNumber) {
      setState({ contactNumber: userInfo.phoneNumber?.trim() });
    }
    if (userInfo && userInfo.preferredContactWays) {
      setState({ preferredWaysContact: userInfo.preferredContactWays });
    }
    if (userInfo && userInfo.sessionPreferences) {
      setState({ preferredWaysSession: userInfo.sessionPreferences });
    }

    if (
      userInfo &&
      userInfo.preferredContactWays &&
      userInfo.phoneNumber &&
      userInfo.email &&
      userInfo.sessionPreferences
    ) {
      if (window.history.state && window.history.state.idx > 0) {
        navigate(-1);
      } else {
        navigate(LEARNER.SETUP_PROCESS.replace(':id', userId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);
  const createInfoMeeting = async () => {
    addIntroMeetingInfo(
      {
        userId,
        preferredWaysSession,
        contactNumber,
        contactEmail,
        preferredWaysContact,
      },
      {
        onSuccess: () => {
          navigate(LEARNER.SETUP_PROCESS.replace(':id', userId));
        },
      }
    );
  };

  const validateForm = () => {
    try {
      validate({
        preferredWaysSession,
        contactNumber,
        contactEmail,
        preferredWaysContact,
        giveConsent,
      });
      setState({ validationErrs: { hasError: false } });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      createInfoMeeting();
    }
  };

  return (
    <S.Wrapper>
      <T.P size="med" weight="bold" mt="3">
        Introductory meeting
      </T.P>
      <Row>
        <Col w={[4, 11, 9]} mt="6">
          <T.P weight="bold">1. Safeguarding and expectations</T.P>
          <T.P mt="3">
            Take time to run through important information on how the programme
            works, expectations and how to stay safe.{' '}
            {/* TODO: check the below link where is should go  */}
            <T.Link
              underline
              weight="bold"
              to={COACH.TRAINING_CHECKLIST}
              color="neutralMain"
            >
              Click here for a useful checklist
            </T.Link>
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 11, 9]} mt="5">
          <T.P weight="bold">2. How to meet and contact details</T.P>
        </Col>
      </Row>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col w={[4, 11, 9]} mt="3">
            <Dropdown
              weight="regular"
              label="Please add in your learner’s preferred way(s) of doing sessions together"
              placeholder="Select preference(s)..."
              options={sessionPreferencesDropDown}
              multi
              selected={preferredWaysSession.map((way) => {
                return sessionPreferencesDropDown.find(
                  (type) => type.value === way
                );
              })}
              handleChange={(preferredWaysSession) =>
                setState({ preferredWaysSession })
              }
              error={validationErrs.preferredWaysSession}
            />
            <T.P ml="2" mt="3">
              So you can contact them through the tool, please provide a phone
              number. This does not need to be their own, but a number through
              which they could be contacted to arrange an upcoming session if
              needed
            </T.P>
            <Row mt="3">
              <BasicInput
                label="Contact number"
                placeholder="Contact number"
                value={contactNumber}
                name="contactNumber"
                handleChange={(contactNumber) => setState({ contactNumber })}
                error={validationErrs.contactNumber}
              />
            </Row>
            <Row mt="4">
              <BasicInput
                label="Contact email"
                placeholder="Contact email"
                value={contactEmail}
                name="contactEmail"
                handleChange={(contactEmail) => setState({ contactEmail })}
                error={
                  validationErrs.contactEmail ||
                  addIntroMeetingInfoHttpError?.response?.data?.message
                }
                optional
              />
            </Row>
            <Row mt="3">
              <Dropdown
                label="Please select your learner’s preferred way(s) of being contacted"
                placeholder="Select preference(s)..."
                options={contactWays}
                multi
                selected={preferredWaysContact.map((way) => {
                  return contactWays.find((type) => type.value === way);
                })}
                handleChange={(preferredWaysContact) =>
                  setState({ preferredWaysContact })
                }
                error={validationErrs.preferredWaysContact}
              />
            </Row>
            <Row mt="4">
              <Checkbox
                plain
                font="regular"
                checked={giveConsent}
                handleChange={(giveConsent) => setState({ giveConsent })}
                ai="start"
                label={
                  <T.P color="neutralMain">
                    Please confirm the learner gives their consent to Turning
                    Pages using and keeping the information they provide as
                    described in our{' '}
                    <T.Link
                      to={EXTERNAL.PRIVACY_POLICY}
                      color="neutralMain"
                      external
                      underline
                    >
                      Privacy Policy
                    </T.Link>
                  </T.P>
                }
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 11, 9]} mt="5">
            {isLoading ? <T.P mb="2">...loading</T.P> : null}
            {addIntroMeetingInfoHttpError && (
              <T.P color="error" mb="3">
                {addIntroMeetingInfoHttpError?.response?.data?.message}
              </T.P>
            )}
            <BasicButton
              variant="primary"
              type="submit"
              id="into-meeting"
              loading={isLoading}
              disabled={!giveConsent}
            >
              <T.P color="white" weight="semi">
                Submit
              </T.P>
            </BasicButton>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 11, 9]} mt="3">
            <BasicButton variant="secondary" to={COACH.DASHBOARD}>
              <T.P color="white" weight="semi">
                Go back
              </T.P>
            </BasicButton>
          </Col>
        </Row>
      </form>
    </S.Wrapper>
  );
};

export default NewLearner;
