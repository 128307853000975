import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { LEARNER } from '../../../constants/nav-routes';
import { UseExerciseWithSelfLearning } from '../../../Hooks';
import { useGeneralState } from '../../../context/general-state';
import { types as Ty } from '../../../constants';

import ListenAndRepeat from '../../Exercises/ExerciseStep/ListenAndRepeat';
import ListenAndRepeatGrid from '../../Exercises/ExerciseStep/ListenAndRepeatGrid';
import { replaceMediaKeysWithUrls } from '../../../helpers';
import Comprehensions from '../../Exercises/ExerciseStep/Comprehensions';
import { useCreateCompletedProgressCheck } from '../../../api-calls/progressChecks.queries';

const initStep = {
  audioKey: '',
  correctOption: null,
  imageKey: '',
  options: null,
  subtitle: '',
  title: '',
};

const ProgressCheckStep = () => {
  const navigate = useNavigate();
  const {
    state: { steps },
  } = useLocation();
  const { state: generalState } = useGeneralState();

  const { stepId, milestoneId } = useParams();
  const [step, setStep] = useState(initStep);
  const { disabled } = UseExerciseWithSelfLearning();

  const index = stepId - 1;
  const { mutate: createCompletedProgressCheck } =
    useCreateCompletedProgressCheck({
      milestoneId,
    });
  const handleNext = () => {
    if (index === steps.length - 1) {
      createCompletedProgressCheck(
        { milestoneId },
        {
          onSuccess: () => {
            navigate(LEARNER.PROGRESS_CHECK_THANK_YOU);
          },
        }
      );
    } else {
      navigate(
        LEARNER.PROGRESS_CHECK_QUESTIONS.replace(
          ':stepId',
          Number(stepId) + 1
        ).replace(':milestoneId', milestoneId),
        {
          state: { steps },
        }
      );
    }
  };
  useEffect(() => {
    if (steps?.length)
      setStep({
        ...replaceMediaKeysWithUrls(steps[index], generalState?.preferredVoice),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, stepId, milestoneId, index]);

  switch (step.type) {
    case Ty.exerciseTypes.LISTEN_AND_REPEAT:
      return (
        <ListenAndRepeat
          {...step}
          handleNext={handleNext}
          disabled={disabled}
        />
      );

    case Ty.exerciseTypes.LISTEN_AND_REPEAT_GRID:
      return (
        <ListenAndRepeatGrid
          {...step}
          handleNext={handleNext}
          disabled={disabled}
        />
      );
    case Ty.exerciseTypes.TYPING:
      return (
        <Comprehensions
          isCheck="true"
          handleNext={handleNext}
          disabled={disabled}
          {...step}
        />
      );
    case Ty.exerciseTypes.MULTIPLE_CHOICE:
      return (
        <Comprehensions
          isCheck="true"
          handleNext={handleNext}
          disabled={disabled}
          {...step}
        />
      );
    default:
      return null;
  }
};

export default ProgressCheckStep;
