import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import { PlayButton } from '../../../components/Button';
import Tip from '../../../components/Tip';

import { getMediaUrl } from '../../../helpers';
import * as T from '../../../components/Typography';
import * as S from './style';
import { useGeneralState } from '../../../context/general-state';

const Content = ({
  beforeExercise,
  handleNext,
  disabled,
  review,
  showCoachNotes,
  toCoachNotes,
  loading,
  error,
}) => {
  const { state: generalState } = useGeneralState();

  return (
    <S.Wrapper>
      <Row jc="center">
        <Col w={[4, 8, 8]}>
          <PlayButton
            audioUrl={getMediaUrl(
              beforeExercise?.textAudioKey,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: '32px', height: '32px' }}
            padding="12px"
          >
            {beforeExercise?.text?.trim()}
          </PlayButton>
        </Col>
      </Row>
      {beforeExercise.tip && (
        <Row jc="center">
          <Col w={[4, 8, 8]} mt={4} mb={4} noWrap>
            {beforeExercise?.tipAudioKey && (
              <PlayButton
                audioUrl={getMediaUrl(
                  beforeExercise?.tipAudioKey,
                  true,
                  generalState?.preferredVoice
                )}
                mr={'12px'}
              />
            )}
            <Tip text={beforeExercise.tip?.trim()} />
          </Col>
        </Row>
      )}

      <Row jc="center" ai="flex-end" style={{ flex: 1 }}>
        {error && (
          <Col w={[4, 8, 8]}>
            <T.P color="error" mt={4}>
              {error}
            </T.P>
          </Col>
        )}
        <Col w={[4, 8, 8]}>
          <S.ButtonWrapper>
            {showCoachNotes && (
              <BasicButton
                variant="secondary"
                maxWidth="300px"
                to={toCoachNotes?.to}
                state={toCoachNotes?.state}
              >
                <T.P color="white" weight="semi">
                  View coach notes
                </T.P>
              </BasicButton>
            )}
            <BasicButton
              mt="3"
              variant="primary"
              icon="next"
              maxWidth="300px"
              disabled={disabled}
              onClick={() => handleNext({ isExplainer: true, review })}
              loading={loading}
            >
              <T.P color="white" weight="semi">
                Next
              </T.P>
            </BasicButton>
          </S.ButtonWrapper>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default Content;
