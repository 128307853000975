import { useReducer } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import { Checkbox } from 'antd';
import * as S from './style';
import MDEditor from '../../../../components/MDEditor';
import { Icon } from '../../../../components';
import { string, number, boolean, array, object } from 'yup';
import { MediaKeyInput } from '../../../Components';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data
    ? {
        ...data,
        options: data?.options?.map((e, i) => ({
          ...e,
          id: i,
        })) || [{ id: 0, option: '', isCorrect: false, imageKey: '' }],
      }
    : {
        options: [{ id: 0, option: '', isCorrect: false, imageKey: '' }],
        title: '',
        imageKey: '',
        audioKey: '',
      },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const MultipleChoice = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const form = {
        ...prevState.form,
        ...data,
      };
      onChange({
        ...form,
      });
      return { form };
    });

  const addOption = () => {
    const maxId = Math.max(...state.form.options.map((e) => e.id), 0);
    setFormData({
      options: [
        ...state.form.options,
        { id: maxId + 1, option: '', hide: false, imageKey: '' },
      ],
    });
  };
  const removeOption = (id) => {
    setFormData({
      options: state.form.options.filter((e) => e.id !== id),
    });
  };

  const onOptionChange = (value, id) => {
    setFormData({
      options: state.form.options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const onImageKeyChange = (value, id) => {
    setFormData({
      options: state.form.options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, imageKey: value };
      }),
    });
  };
  const onAudioKeyChange = (value, id) => {
    setFormData({
      options: state.form.options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, audioKey: value };
      }),
    });
  };

  const onIsCorrectChange = (value, id) => {
    setFormData({
      options: state.form.options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, isCorrect: value };
      }),
    });
  };

  return (
    <div>
      <MDEditor
        label={'Title'}
        value={state.form.title}
        onChange={(title) => setFormData({ title })}
        mode={'edit'}
        height={80}
        m={{ mt: 8 }}
        error={errors?.title}
      />
      <BasicInput
        label={`Audio Text`}
        value={state.form.textAudio}
        handleChange={(textAudio) => {
          setFormData({ textAudio });
        }}
        m={{ mt: 8 }}
        error={errors?.textAudio}
      />

      <MediaKeyInput
        label={`Audio Text file Key`}
        value={state.form.textAudioKey}
        handleChange={(textAudioKey) => {
          setFormData({ textAudioKey });
        }}
        m={{ mt: 5 }}
        error={errors?.textAudioKey}
      />

      <MediaKeyInput
        label={`Audio Key`}
        value={state.form.audioKey}
        handleChange={(audioKey) => {
          setFormData({ audioKey });
        }}
        m={{ mt: 8 }}
        error={errors?.audioKey}
        helper='for "Play sound" button'
      />
      <MediaKeyInput
        label={`Image Key`}
        value={state.form.imageKey}
        handleChange={(imageKey) => {
          setFormData({ imageKey });
        }}
        m={{ mt: 8 }}
        error={errors?.imageKey}
      />

      <T.P mt={8} size="large" weight="bold">
        Options
      </T.P>
      {errors?.options && typeof errors.options === 'string' && (
        <T.P mb={2} color="error">
          {errors.options}
        </T.P>
      )}
      {state.form.options.map((e, i) => {
        return (
          <>
            <MDEditor
              label={`Option ${i + 1} text`}
              value={e.option}
              onChange={(value) => {
                onOptionChange(value, e.id);
              }}
              mode={'edit'}
              height={80}
              m={{ mt: 8 }}
              error={errors?.options && errors?.options[i]?.option}
            />

            <Checkbox
              checked={e.isCorrect}
              onChange={(value) =>
                onIsCorrectChange(value.target.checked, e.id)
              }
              disabled={!e.option && !e.imageKey}
            >
              correct option?
            </Checkbox>

            <MediaKeyInput
              label={`Option ${i + 1} Image Key`}
              value={e.imageKey}
              handleChange={(value) => {
                onImageKeyChange(value, e.id);
              }}
              m={{ mt: 4 }}
              error={errors?.options?.[i]?.imageKey}
            />
            <MediaKeyInput
              label={`Option ${i + 1} Audio Key`}
              value={e.audioKey}
              handleChange={(value) => {
                onAudioKeyChange(value, e.id);
              }}
              m={{ mt: 4 }}
              error={errors?.options?.[i]?.audioKey}
            />
            {state.form.options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={state.form.options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string().test(
            'textOrImage',
            'Text or Image should be provided',
            (val, { parent }) => {
              return val || parent.imageKey;
            }
          ),
          imageKey: string().test(
            'textOrImage',
            'Text or Image should be provided',
            (val, { parent }) => {
              return val || parent.option;
            }
          ),
          audioKey: string().nullable(),
          isCorrect: boolean(),
        })
        .required()
    )
    .required('you must provide options')
    .min(1, 'you must provide options')
    .test('oneCorrect', 'should select one correct answer', (val) => {
      return val?.filter((e) => e.isCorrect)?.length === 1;
    }),

  title: string().optional().max(15, 'title must be less than 15 characters'),
  textAudio: string().nullable(),
  textAudioKey: string().when('textAudio', {
    is: (textAudio) => !!textAudio,
    then: string().required('required field'),
    otherwise: string().nullable(),
  }),
};
export { validationSchema };

export default MultipleChoice;
