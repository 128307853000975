import { coachApprovalStatuses } from '../../../constants';
import theme from '../../../theme';
export const dateFormat = 'YYYY-MM-DD HH:mm';
export const decideStatusTagProps = (status) => {
  let text;
  let bgColor;
  let fontColor = 'neutralSurface';
  switch (status) {
    case coachApprovalStatuses.REQUESTED:
      text = 'Requested';
      bgColor = theme.colors.primaryMain;
      break;
    case coachApprovalStatuses.APPROVED:
      text = 'Approved';
      bgColor = theme.colors.neutralMain;
      break;
    case coachApprovalStatuses.REJECTED:
      text = 'Rejected';
      bgColor = theme.colors.neutral40;
      fontColor = theme.colors.neutralMain;
      break;
    case coachApprovalStatuses.INVITED_TO_INTERVIEW:
      text = 'Invited to interview';
      bgColor = theme.colors.quaternaryMain;
      break;
    default:
      break;
  }
  return { text, bgColor, fontColor };
};

export const decideCoachTypeTagProps = (type) => {
  let bgColor;
  let fontColor;
  let text;
  switch (type) {
    case 'PEER_COACH':
      fontColor = theme.colors.primaryMain;
      bgColor = theme.colors.primary30;
      text = 'Peer coach';
      break;
    case 'COACH':
      fontColor = theme.colors.quaternaryMain;
      bgColor = theme.colors.quaternary30;
      text = 'Coach';
      break;
    case 'PROFESSIONAL_COACH':
      bgColor = theme.colors.primaryLight;
      fontColor = theme.colors.primaryMain;
      text = 'Professional coach';
      break;
    default:
      break;
  }
  return { text, bgColor, fontColor };
};
