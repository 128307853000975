import { useParams } from 'react-router-dom';

import * as T from '../../../../components/Typography';
import { PlayButton, BasicButton } from '../../../../components/Button';
import { Markdown } from '../../../../components';

import * as S from './style';
import { navRoutes } from '../../../../constants';

const CombineWordsPre = ({
  data: { textAudioUrl, textAudio, options = [] } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  selfLearning,
}) => {
  const { exerciseId } = useParams();

  return (
    <S.Wrapper display={'flex'}>
      <S.PlayBtnWrapper>
        <PlayButton
          audioUrl={textAudioUrl}
          iconProps={{ width: '32px', height: '32px' }}
          padding="12px"
          weight="regular"
          mt={1}
          mx="auto"
          mxM="0"
        >
          {textAudio}
        </PlayButton>
      </S.PlayBtnWrapper>

      {options?.length > 0 &&
        options.map(({ option }, index) => (
          <S.Row
            bgColor={index % 2 ? 'white' : 'neutralLight'}
            key={index}
            mt={index === 0 ? 5 : 0}
          >
            <S.MarkdownWrapper>
              <Markdown
                text={option.replaceAll('+ ', '+&nbsp;')}
                customStyles={{
                  p: {
                    size: 'large',
                    weight: 'regular',
                    mt: 1,
                    lh: '150%',
                    ta: 'left',
                  },
                }}
              />
            </S.MarkdownWrapper>
          </S.Row>
        ))}

      <S.ButtonWrapper>
        {stuckAtReview ? (
          <BasicButton handleClick={handleNext} variant="primary">
            <T.P color="white" weight="semi">
              Complete review
            </T.P>
          </BasicButton>
        ) : (
          <div style={{ width: '100%' }}>
            <BasicButton
              disabled={disabled || preview}
              handleClick={handleNext}
              icon="next"
              variant="primary"
            >
              <T.P color="white" weight="semi">
                Next
              </T.P>
            </BasicButton>
            {preview && (
              <BasicButton
                icon="back"
                variant="primary"
                to={(selfLearning
                  ? navRoutes.EXERCISES.EXERCISE_EXPLAINER_SL
                  : navRoutes.EXERCISES.EXERCISE_EXPLAINER
                ).replace(':exerciseId', exerciseId)}
                mt={5}
              >
                <T.P color="white" weight="semi">
                  Back to exercise
                </T.P>
              </BasicButton>
            )}
          </div>
        )}
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default CombineWordsPre;
