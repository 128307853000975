import * as S from './style';
import { badgesGroups } from '../../constants/badges';
import Badge from '../Badge';

const BadgesList = ({ badges }) => {
  const groupedBadges = {};

  badges?.length &&
    badges.forEach((badge) => {
      const group = badgesGroups[badge.type];
      if (group) {
        if (!groupedBadges[group])
          groupedBadges[group] = { title: group, levels: [] };
        groupedBadges[group].levels.push(badge.type);
      } else {
        groupedBadges[badge.type] = { title: badge.type };
      }
    });

  return Object.values(groupedBadges).map((group) => (
    <S.Wrapper mb="4" mt="3">
      <Badge type={group.title} levels={group.levels} />
    </S.Wrapper>
  ));
};

export default BadgesList;
