const FeedbackHigh = ({ width, height, color, ...props }) => (
  <svg
    width={width || '44'}
    height={height || '44'}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.189941 21.6282C0.189941 33.5322 9.84009 43.1824 21.7441 43.1824C33.6482 43.1824 43.2983 33.5322 43.2983 21.6282C43.2983 9.72412 33.6482 0.0739746 21.7441 0.0739746C9.84009 0.0739746 0.189941 9.72412 0.189941 21.6282ZM30.7536 26.3719C25.9855 32.3801 17.6247 32.3433 12.74 26.3845C12.3806 25.9461 12.4447 25.2994 12.8831 24.9401C13.3215 24.5807 13.9682 24.6447 14.3275 25.0831C18.3903 30.0391 25.1988 30.0691 29.1456 25.0958C29.498 24.6518 30.1436 24.5775 30.5877 24.9298C31.0317 25.2822 31.106 25.9278 30.7536 26.3719ZM18.5202 15.1284C17.9652 13.5207 16.2692 12.3906 14.5017 12.3906C12.7347 12.3906 11.0634 13.5223 10.51 15.137C10.3262 15.6732 10.612 16.2569 11.1482 16.4407C11.6844 16.6245 12.2681 16.3387 12.4519 15.8025C12.714 15.0379 13.592 14.4433 14.5017 14.4433C15.4183 14.4433 16.3193 15.0437 16.5798 15.7983C16.7648 16.3341 17.3492 16.6185 17.885 16.4335C18.4208 16.2485 18.7052 15.6642 18.5202 15.1284ZM28.8711 12.3906C30.6386 12.3906 32.3346 13.5207 32.8896 15.1284C33.0746 15.6642 32.7902 16.2485 32.2544 16.4335C31.7185 16.6185 31.1342 16.3341 30.9492 15.7983C30.6887 15.0437 29.7877 14.4433 28.8711 14.4433C27.9614 14.4433 27.0833 15.0379 26.8213 15.8025C26.6375 16.3387 26.0538 16.6245 25.5176 16.4407C24.9814 16.2569 24.6956 15.6732 24.8794 15.137C25.4328 13.5223 27.1041 12.3906 28.8711 12.3906Z"
      fill={color || '#051730'}
    />
  </svg>
);

export default FeedbackHigh;
