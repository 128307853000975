import { fields, createSchema, validate as _validate } from '..';

const {
  firstName,
  lastName,
  optionalEmail,
  requiredText,
  birthDate,
  requiredBoolean,
  optionalText,
  phoneNumber,
  email,
  shouldBeTrue,
  requiredRadio,
} = fields;

const preSchema = createSchema({
  firstName,
  lastName,
  hasDeviceInternetAccess: requiredText,
  involvedBefore: requiredText,
  preReleased: requiredBoolean,
  nomisId: optionalText,
  delius: optionalText,
  probationOfficerName: requiredText,
  probationOfficerEmail: email,
  email: optionalEmail,
  availability: optionalText,
  additionalInfo: requiredText,
  dateOfBirth: birthDate,
  //referrer
  referrerFirstName: requiredText,
  referrerLastName: requiredText,
  referrerEmail: email,
  referrerPhoneNumber: phoneNumber,
  relationship: requiredText,
  acceptPrivacyPolicy: shouldBeTrue,
  confirmedPermissionType: requiredRadio,
});
const postSchema = createSchema({
  firstName,
  lastName,
  hasDeviceInternetAccess: requiredText,
  involvedBefore: requiredText,
  preReleased: requiredBoolean,
  nomisId: optionalText,
  delius: optionalText,
  probationOfficerName: requiredText,
  probationOfficerEmail: email,
  email: optionalEmail,
  availability: optionalText,
  additionalInfo: requiredText,
  dateOfBirth: birthDate,
  //referrer
  referrerFirstName: requiredText,
  referrerLastName: requiredText,
  referrerEmail: email,
  referrerPhoneNumber: phoneNumber,
  relationship: requiredText,
  acceptPrivacyPolicy: shouldBeTrue,
  confirmedPermissionType: requiredRadio,
});

const validate = (data) => {
  return _validate(data.preReleased ? preSchema : postSchema, data);
};

export default validate;
