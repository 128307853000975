import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getMediaUrl } from '../../../helpers';
import { PlayButton, BasicButton } from '../../../components/Button';
import { useGeneralState } from '../../../context/general-state';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import * as S from './style';
import { feedbackScores, audio } from '../../../constants';
import * as Exercise from '../../../api-calls/exercises.queries';
import { navRoutes } from './../../../constants';
import { Badges } from '../../../api-calls';
import { UseExerciseWithSelfLearning } from '../../../Hooks';

const pickRandomELemFromArr = (arr) => {
  if (!arr?.length) return null;
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
};

const ExerciseFeedback = () => {
  const [feedbackRate, setFeedbackRate] = useState();
  const { state: generalState } = useGeneralState();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { data: badges } = Badges.useGetBadges({ type: 'new' });

  const { handleNext } = UseExerciseWithSelfLearning();

  const { data: isAllExercisesCompleted } =
    Exercise.useGetMilestoneAllExercisesCompleted({
      milestoneId: state?.milestoneId,
    });

  const { exerciseId } = useParams();
  const {
    mutateAsync: updateExerciseFeedback,
    error,
    isLoading,
  } = Exercise.useUpdateExerciseFeedback({
    exerciseId,
  });
  const handleClick = (val) => {
    setFeedbackRate(val);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateExerciseFeedback({ feedbackRate });

    const badge = badges?.length && pickRandomELemFromArr(badges);

    if (badge) {
      return navigate(
        navRoutes.EXERCISES.WON_NEW_BADGE.replace(
          ':exerciseId',
          exerciseId
        ).replace(':badgeType', badge.type),
        { state: { isAllExercisesCompleted, milestoneId: state?.milestoneId } }
      );
    }

    if (isAllExercisesCompleted) {
      navigate(
        navRoutes.LEARNER.SUCCESS_MILESTONE.replace(':id', state?.milestoneId)
      );
    } else {
      handleNext({ isExerciseCompletedPage: true });
    }
  };

  return (
    <S.Wrapper>
      <Row jc="center">
        <Col w={[4, 8, 8]} mt={6}>
          <PlayButton
            audioUrl={getMediaUrl(
              audio.greatWorkAnotherExerciseCompletedPleaseSelectAnEmojiToShowHowTheExerciseMadeYouFeel,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: '32px', height: '32px' }}
            padding="12px"
          >
            Please select an emoji to show how the exercise made you feel.
          </PlayButton>
        </Col>
      </Row>
      <Row jc="center">
        <Col w={[4, 8, 8]} mt={5} jc="space-between">
          <BasicButton
            width="31%"
            height="80px"
            icon="feedbackLow"
            variant="tertiary"
            handleClick={() => handleClick(feedbackScores.low)}
            selected={feedbackRate === feedbackScores.low}
            iconMR="0"
          />
          <BasicButton
            width="31%"
            height="80px"
            icon="feedbackMedium"
            variant="tertiary"
            handleClick={() => handleClick(feedbackScores.medium)}
            selected={feedbackRate === feedbackScores.medium}
            iconMR="0"
          />
          <BasicButton
            width="31%"
            height="80px"
            icon="feedbackHigh"
            variant="tertiary"
            handleClick={() => handleClick(feedbackScores.high)}
            selected={feedbackRate === feedbackScores.high}
            iconMR="0"
          />
        </Col>
      </Row>

      <Row jc="center" ai="flex-end" mb={6} style={{ flex: 1 }}>
        <Col w={[4, 8, 8]} mt={6}>
          {error?.message && <T.P>{error.message}</T.P>}
          <BasicButton
            mtT="4"
            mt="5"
            variant="primary"
            icon="next"
            handleClick={handleSubmit}
            loading={isLoading}
            disabled={!feedbackRate}
          >
            <T.P color="white" weight="semi">
              Next
            </T.P>
          </BasicButton>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default ExerciseFeedback;
