import ReadABookToMyChild from '../../../components/assets/goals/ReadABookToMyChild.png';
import ReadANewspaper from '../../../components/assets/goals/ReadANewspaper.png';
import FillInAForm from '../../../components/assets/goals/FillInAForm.jpg';
import ReadALetterFromMyFamily from '../../../components/assets/goals/ReadALetterFromMyFamily.png';
import ReadPaperwork from '../../../components/assets/goals/ReadPaperwork.png';
import BuildMyConfidence from '../../../components/assets/goals/BuildMyConfidence.png';
import GainAJob from '../../../components/assets/goals/GainAJob.jpg';
import ContinueLearning from '../../../components/assets/goals/ContinueLearning.png';
import WriteATextMessage from '../../../components/assets/goals/WriteATextMessage.png';

export const defaultGoals = {
  'Read a book to my child': {
    title: 'Read a book to my child',
    audio: 'readBookToMyChild',
    image: ReadABookToMyChild,
  },
  'Read a newspaper': {
    title: 'Read a newspaper',
    audio: 'readANewspaper',
    image: ReadANewspaper,
  },
  'Fill in a form': {
    title: 'Fill in a form',
    audio: 'fillInAForm',
    image: FillInAForm,
  },
  'Read a letter from my family': {
    title: 'Read a letter from my family',
    audio: 'readALetterFromMyFamily',
    image: ReadALetterFromMyFamily,
  },
  'Read paperwork': {
    title: 'Read paperwork',
    audio: 'readPaperwork',
    image: ReadPaperwork,
  },
  'Build my confidence': {
    title: 'Build my confidence',
    audio: 'buildMyConfidence',
    image: BuildMyConfidence,
  },
  'Gain a job': { title: 'Gain a job', audio: 'gainAJob', image: GainAJob },
  'Continue learning': {
    title: 'Continue learning',
    audio: 'continueLearning',
    image: ContinueLearning,
  },
  'Write a text message': {
    title: 'Write a text message',
    audio: 'writeATextMessage',
    image: WriteATextMessage,
  },
};

export const getGoalBg = ({ title }) => {
  if (defaultGoals[title]) {
    return defaultGoals[title].image;
  } else {
    return BuildMyConfidence;
  }
};

export const getGoalAudio = ({ title }) => {
  if (defaultGoals[title]) {
    return defaultGoals[title].audio;
  } else {
    return null;
  }
};
