import { useEffect, useState, useRef } from 'react';
import { BasicButton } from '../../../components/Button';
import { Textarea } from '../../../components/Inputs';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';

import * as S from './style';

import validate from '../../../validation/schemas/add-coach-note';
import { useFacilitatorAddCoachNote } from '../../../api-calls/facilitator.queries';
import NoteCard from '../../../components/NoteCard';
import { navRoutes } from '../../../constants';

const Notes = ({ coachId, notes, statusType }) => {
  const [showNoteInput, setShowNoteInput] = useState(false);
  const [note, setNote] = useState('');
  const [noteValidationError, setNoteValidationError] = useState(null);
  const {
    mutate: addNote,
    error: addNoteHttpError,
    isLoading,
  } = useFacilitatorAddCoachNote({
    coachId,
    note,
  });

  const submitAttempt = useRef(false);
  const validateNote = () => {
    try {
      validate({ note });
      setNoteValidationError(false);

      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setNoteValidationError(error?.inner?.note);
      }
      return false;
    }
  };

  const handleNoteSubmission = async (e) => {
    e.preventDefault();
    submitAttempt.current = true;
    const isValid = validateNote();
    if (isValid) {
      addNote(
        { coachId, note },
        {
          onSuccess: () => {
            setNote('');
            setShowNoteInput(false);
            setNoteValidationError(null);
          },
        }
      );
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  return (
    <Col w={[4, 8, 8]} mt="7" key={`column-notes`}>
      <S.ColWrapper>
        <T.H2 mb="3" weight="regular">
          Notes
        </T.H2>

        {notes.length
          ? notes
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
              .slice(0, 3)
              .map(({ note, id, updatedAt }) => (
                <Row mb={4}>
                  <NoteCard
                    createdAt={updatedAt}
                    note={note}
                    noteId={id}
                    coachId={coachId}
                  />
                </Row>
              ))
          : null}

        {showNoteInput ? (
          <Textarea
            label={`Add note`}
            value={note}
            handleChange={(note) => setNote(note)}
            m={{ mt: 5 }}
            error={noteValidationError}
          />
        ) : null}

        <BasicButton
          variant="primary"
          type="submit"
          mt="5"
          style={{ width: '300px' }}
          onClick={(e) => {
            if (!showNoteInput) {
              setShowNoteInput(true);
            } else {
              handleNoteSubmission(e);
            }
          }}
          loading={isLoading}
        >
          Add note
        </BasicButton>

        <BasicButton
          variant="secondary"
          mt="3"
          style={{ width: '300px' }}
          to={navRoutes.FACILITATOR.COACH_NOTES.replace(
            ':statusType',
            statusType
          ).replace(':coachId', coachId)}
          linkState={{ canGoBack: true }}
        >
          View all notes
        </BasicButton>
      </S.ColWrapper>
      {addNoteHttpError ? (
        <T.P color="error">{addNoteHttpError?.message}</T.P>
      ) : null}
    </Col>
  );
};

export default Notes;
