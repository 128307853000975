import styled from '@emotion/styled';

export const StatsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* margin: 0 auto; */
  padding-bottom: ${({ theme }) => theme.spacings[1]};
  max-width: 400px;

  @media (max-width: 376px) {
    width: 100vw;
    margin: 0 auto;
    margin-left: -41px;
  }
  ${({ theme }) => theme.media.mobile} {
    margin-top: -24px;
  }
`;
