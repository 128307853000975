import { useRef, useReducer, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { navRoutes } from '../../../constants';
import {
  Typography as T,
  Button,
  Inputs,
  Grid,
  Modal,
} from '../../../components';
import validate from '../../../validation/schemas/coach-send-issue';
import {
  useCoachHasIssue,
  useGetLearnerInfo,
} from '../../../api-calls/learners.queries';

import * as S from './style';

const { Row, Col } = Grid;

const initialState = {
  notes: '',
  validationErrs: {},
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const ConfirmLearner = () => {
  const { id: learnerId } = useParams();
  const { userInfo } = useGetLearnerInfo({ id: learnerId });
  const navigate = useNavigate();
  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const { notes, validationErrs } = state;
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    mutate: raiseIssue,
    error: raiseIssueHttpError,
    isLoading,
  } = useCoachHasIssue({
    message: notes,
  });
  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  const validateForm = () => {
    try {
      validate({
        notes,
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  const handleSendIssue = async () => {
    setState({ loading: true });

    raiseIssue(
      {
        message: notes,
        learnerName: userInfo?.fullName,
        learnerUniqueId: userInfo?.learnerUniqueId,
      },
      {
        onSuccess: () => {
          setIsSuccess(true);
        },
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      handleSendIssue();
    }
  };

  return (
    <>
      <T.H2 weight="bold" color="neutralMain" mt="4">
        No problem
      </T.H2>
      <T.P color="neutralMain" mt="4">
        We can help you resolve this. Please let us know why below
      </T.P>
      <S.Form onSubmit={handleSubmit}>
        <Row>
          <Col w={[4, 11, 6]}>
            <Inputs.Textarea
              rows={10}
              id="text"
              label="Add in your issue"
              placeholder="Notes..."
              type="text"
              value={notes}
              autoFocus
              handleChange={(input) => setState({ notes: input })}
              error={validationErrs.notes}
            />
          </Col>
        </Row>
        <S.ButtonsWrapper mt="6" mtT="6">
          <S.ButtonContainer w={[4, 11, 6]}>
            {isLoading ? <T.P mb="2">...loading</T.P> : null}
            {raiseIssueHttpError && (
              <T.P mb="2" color="error">
                {raiseIssueHttpError?.message}
              </T.P>
            )}
            <Button.BasicButton
              variant="primary"
              disabled={false}
              loading={isLoading}
              type="submit"
              id="send-issue-button"
            >
              Submit
            </Button.BasicButton>
          </S.ButtonContainer>
        </S.ButtonsWrapper>
      </S.Form>
      <Modal visible={isSuccess} setIsModalVisible={setIsSuccess}>
        <Row>
          <Col w={[4, 12, 12]}>
            <T.P size="med" color="white">
              Your issue has been submitted and one of the team will get in
              touch shortly
            </T.P>
          </Col>
        </Row>
        <Row>
          <Col w={[4, 12, 12]} mt="3">
            <Button.BasicButton
              variant="secondary"
              onClick={() => {
                navigate(navRoutes.COACH.DASHBOARD);
                setIsSuccess(false);
              }}
            >
              Okay, back to dashboard
            </Button.BasicButton>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default ConfirmLearner;
