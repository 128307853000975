import { types as Ty, comprehensionsKeys } from '../../../constants';

import ListenAndRepeat from './ListenAndRepeat';
import ListenAndRepeatMultiple from './ListenAndRepeatMultiple';
import ListenAndRepeatGrid from './ListenAndRepeatGrid';
import ListenAndRepeatGridHidden from './ListenAndRepeatGridHidden';
import MultipleChoice from './MultipleChoice';
import { TextFinderBasic, TextFinderComprehension } from './TextFinder';
import WordBuild from './WordBuild';
import SpotTheWord from './SpotTheWord';
import {
  DragAndDropV1,
  DragAndDropV2,
  DragAndDropOrderSounds,
} from './DragAndDrop';
import VowelBreak from './VowelBreak';
import TypeNewEndings from './TypeNewEndings';
import CombineWords, { CombineWordsPre } from './CombineWords';
import { WordJoin1, WordJoin2 } from './WordJoin';
import SentenceFill from './SentenceFill';

import Comprehensions from './Comprehensions';
import MultipleChoiceGrid from './MultipleChoiceGrid';

const ExerciseStep = ({
  exercise,
  handleNext,
  disabled,
  preview,
  step,
  selfLearning,
  stuckAtReview,
}) => {
  let Component = () => null;
  let layout = 'general';

  if (comprehensionsKeys.includes(exercise.key)) {
    Component = (
      <Comprehensions
        exKey={exercise.key}
        handleNext={handleNext}
        disabled={disabled}
        preview={preview}
        selfLearning={selfLearning}
        stuckAtReview={stuckAtReview}
        {...step}
      />
    );
    layout = 'fullScreenComprehension';

    return {
      Component,
      layout,
    };
  }

  switch (step.type) {
    case Ty.exerciseTypes.LISTEN_AND_REPEAT:
      Component = (
        <ListenAndRepeat
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.LISTEN_AND_REPEAT_MULTIPLE:
      Component = (
        <ListenAndRepeatMultiple
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.LISTEN_AND_REPEAT_GRID:
      Component = (
        <ListenAndRepeatGrid
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.LISTEN_AND_REPEAT_GRID_HIDDEN:
      Component = (
        <ListenAndRepeatGridHidden
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.MULTIPLE_CHOICE:
      Component = (
        <MultipleChoice
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.MULTIPLE_CHOICE_BUBBLE:
      Component = (
        <MultipleChoice
          bubble
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.TEXT_FINDER:
      Component = (
        <TextFinderBasic
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.COMPREHENSION_TEXT_FINDER:
      Component = (
        <TextFinderComprehension
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      layout = 'fullScreenComprehension';

      break;

    case Ty.exerciseTypes.WORD_BUILD:
      Component = (
        <WordBuild
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.SPOT_THE_WORD:
      Component = (
        <SpotTheWord
          {...step}
          preview={preview}
          handleNext={handleNext}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      layout = 'fullScreenEx';
      break;

    case Ty.exerciseTypes.DRAG_AND_DROP_1:
      Component = (
        <DragAndDropV1
          {...step}
          preview={preview}
          handleNext={handleNext}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;
    case Ty.exerciseTypes.DRAG_AND_DROP_2:
      Component = (
        <DragAndDropV2
          {...step}
          preview={preview}
          handleNext={handleNext}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;
    case Ty.exerciseTypes.DRAG_AND_DROP_ORDER_SOUNDS:
      Component = (
        <DragAndDropOrderSounds
          {...step}
          preview={preview}
          handleNext={handleNext}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.VOWEL_BREAK:
      Component = (
        <VowelBreak
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      layout = 'fullScreenEx';
      break;

    case Ty.exerciseTypes.WORD_JOIN_1:
      Component = (
        <WordJoin1
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.WORD_JOIN_2:
      Component = (
        <WordJoin2
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.SENTENCE_FILL:
      Component = (
        <SentenceFill
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      layout = 'fullScreenEx';
      break;

    case Ty.exerciseTypes.COMBINE_WORDS_PRE:
      Component = (
        <CombineWordsPre
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      layout = 'fullScreenEx';

      break;

    case Ty.exerciseTypes.COMBINE_WORDS:
      Component = (
        <CombineWords
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      layout = 'fullScreenEx';

      break;

    case Ty.exerciseTypes.TYPE_NEW_ENDINGS:
      Component = (
        <TypeNewEndings
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.MULTIPLE_CHOICE_GRID:
      Component = (
        <MultipleChoiceGrid
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          selfLearning={selfLearning}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    default:
      break;
  }

  return { Component, layout };
};

export default ExerciseStep;
