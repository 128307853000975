import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import { InfoCardWithButton } from '../../../components/InfoCard';
import { BasicButton } from '../../../components/Button';
import { Coaches, Learners } from '../../../api-calls';
import { GENERAL, COACH } from '../../../constants/nav-routes';
import * as S from './style';
const LearnersStuckAt = () => {
  const navigate = useNavigate();
  const [learnersStuckAt, setLearnersStuckAt] = useState([]);

  useEffect(() => {
    const getLearners = async () => {
      const { data, error } = await Coaches.getCoachStuckAtLearners();
      if (error) {
        return navigate(GENERAL.NOT_FOUND);
      }

      setLearnersStuckAt(data);
    };
    getLearners();
  }, [navigate]);
  return (
    <>
      <Row>
        <Col w={[4, 6, 6]} mb={4}>
          <T.H2 weight="bold">Learners who are stuck</T.H2>
        </Col>
      </Row>
      {learnersStuckAt.length !== 0 ? (
        learnersStuckAt.map((learner) => {
          return (
            <Row>
              <Col w={[4, 6, 6]}>
                <InfoCardWithButton
                  firstColumnLabel="Name"
                  firstColumnValue={learner.firstName}
                  secondColumnLabel="Stuck on"
                  secondColumnValue={learner.title}
                  handleClick={async () => {
                    await Learners.selectLearner({
                      learnerId: learner.learnerId,
                    });
                    navigate(
                      COACH.REVIEW_STUCK_AT.replace(
                        ':exerciseId',
                        learner.exerciseId
                      ).replace(':stepId', learner.stepId),
                      { type: learner.stepType }
                    );
                  }}
                />
              </Col>
            </Row>
          );
        })
      ) : (
        <Row>
          <Col w={[4, 6, 6]}>
            <InfoCardWithButton />
          </Col>
        </Row>
      )}

      <S.ButtonWrapper mt="6">
        <Col w={[4, 6, 6]} mt="6" mb="2">
          <BasicButton to={COACH.DASHBOARD} variant="primary">
            <T.P color="white" weight="semi">
              Return to dashboard
            </T.P>
          </BasicButton>
        </Col>
      </S.ButtonWrapper>
    </>
  );
};
export default LearnersStuckAt;
