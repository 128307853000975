import * as S from './style';
import * as T from '../Typography';
import Icon from '../Icon';
import theme from '../../theme';

const MilestoneCard = ({
  backgroundColor = 'transparent',
  title,
  id,
  ref,
  ...props
}) => {
  return (
    <S.MilestoneWrapper
      backgroundColor={backgroundColor}
      id={id}
      ref={ref}
      {...props}
    >
      <Icon
        icon="milestoneNew"
        color={theme.colors.white}
        width="100px"
        height="100px"
      />
      <T.P
        ta="center"
        size="xLarge"
        weight="bold"
        color={theme.colors.neutralMain}
        lineHeight="extraLarge"
      >
        {title}
      </T.P>
    </S.MilestoneWrapper>
  );
};

export default MilestoneCard;
