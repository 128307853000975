import { useState } from 'react';
import * as S from './style';

import { Row, Col } from '../../../../../../components/Grid';

import OptionsSection from './OptionsSection';
import ButtonsSection from './ButtonsSection';
import { randomizeArray } from './helpers';

const Reorder = ({
  data: { options = [] },
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  selfLearning,
  cmsPreview,
}) => {
  const [tempArr, setTempArr] = useState(
    cmsPreview
      ? options.map((e, i) => ({ ...e, id: i }))
      : randomizeArray(options.map((e, i) => ({ ...e, id: i })))
  );

  const correctAnswer = Array.from(Array(options.length).keys());

  return (
    <Row jc="center">
      <Col w={[4, 6, 6]}>
        <S.ButtonWrapper>
          {options?.length > 0 && tempArr?.length > 0 && (
            <OptionsSection
              tempArr={tempArr}
              setTempArr={setTempArr}
              cmsPreview={cmsPreview}
            />
          )}

          <ButtonsSection
            handleNext={handleNext}
            stuckAtReview={stuckAtReview}
            disabled={disabled}
            preview={preview}
            selfLearning={selfLearning}
            tempArr={tempArr}
            correctAnswer={correctAnswer}
          />
        </S.ButtonWrapper>
      </Col>
    </Row>
  );
};

export default Reorder;
