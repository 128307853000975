import { useRef, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import { BasicInput } from '../../../components/Inputs';
import { BasicButton } from '../../../components/Button';
import { addCoach as validate } from '../../../validation/schemas';
import { FACILITATOR } from '../../../constants/nav-routes';
import { useAddCoaches } from '../../../api-calls/coaches.queries';

const initialState = {
  form: {
    firstName: '',
    lastName: '',
  },
  validationErrs: { contactLinks: {} },
};

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}
const AddCoach = () => {
  const [state, setState] = useReducer(reducer, initialState);
  const submitAttempt = useRef(false);
  const navigate = useNavigate();
  const {
    form: { firstName, lastName },
    validationErrs,
  } = state;

  const {
    mutate,
    error: httpError,
    isLoading,
    isError,
    data: { data: { coachId = null } = {} } = {},
  } = useAddCoaches();

  const setFormData = (data) =>
    setState((prevState) => ({ form: { ...prevState.form, ...data } }));

  const validateForm = () => {
    try {
      validate(state.form);
      setState({ validationErrs: { hasError: false } });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      mutate({ ...state.form });
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName]);

  // redirect when we're getting back the coach id
  useEffect(() => {
    if (coachId) {
      navigate(FACILITATOR.COACH_PROFILE.replace(':coachId', coachId));
    }
    return () => {
      setState(initialState);
    };
  }, [coachId, navigate]);

  return (
    <>
      <Row mt="8">
        <Col w={[4, 12, 12]}>
          <T.H2 color="neutralMain" weight="bold" mt={1}>
            Onboard a new coach
          </T.H2>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 6]} mt="7">
          <BasicInput
            label="Enter their name "
            placeholder="First name..."
            value={firstName}
            name="firstName"
            handleChange={(firstName) => setFormData({ firstName })}
            error={validationErrs.firstName}
          />
        </Col>
        <Col w={[4, 6, 6]} mt="9">
          <BasicInput
            placeholder="Last name..."
            value={lastName}
            name="lastName"
            handleChange={(lastName) => setFormData({ lastName })}
            error={validationErrs.lastName}
          />
        </Col>
      </Row>
      <Row mt="7">
        {isError ? (
          <Col w={[4, 12, 12]}>
            <T.P color="error" mb={3}>
              {httpError?.message}
            </T.P>
          </Col>
        ) : null}
        {validationErrs.hasError && (
          <Col w={[4, 12, 12]}>
            <T.P color="error" mb={3}>
              Please fill all the required fields
            </T.P>
          </Col>
        )}
        <Col w={[4, 6, 6]}>
          <BasicButton
            variant="primary"
            disabled={validationErrs.hasError}
            loading={isLoading}
            handleClick={handleSubmit}
          >
            <T.P color="white" weight="semi">
              Onboard coach
            </T.P>
          </BasicButton>
        </Col>
      </Row>
    </>
  );
};

export default AddCoach;
