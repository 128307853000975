import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as T from '../../../../components/Typography';
import { PlayButton, BasicButton } from '../../../../components/Button';
import Markdown from '../../../../components/Markdown';
import { createH1 } from '../../../../utils/markdown';

import { navRoutes } from '../../../../constants';
import OptionButton from '../MultipleChoice/OptionButton';
import { Row, Col } from '../../../../components/Grid';

import * as S from './style';

const MultipleChoiceGrid = ({
  data: { textAudio, textAudioUrl, title, options = [] } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  selfLearning,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const { exerciseId } = useParams();
  const [selected, setSelected] = useState([]);

  const handleClick = (item) => {
    setSelected((old) => {
      const itemExists = old.find((opt) => opt.id === item.id);
      if (itemExists) {
        return old.filter((opt) => opt.id !== item.id);
      } else {
        return [...old, item];
      }
    });
  };

  useEffect(() => {
    const correctAnswers = formattedWordsArray.filter((opt) => opt.isCorrect);
    const allAnswersAreCorrect =
      correctAnswers.length === selected.length &&
      correctAnswers.every((opt) => selected.find((ans) => ans.id === opt.id));
    if (allAnswersAreCorrect) {
      setEnableNext(true);
    } else {
      setEnableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.length]);

  const formattedWordsArray = options.map((el, idx) => ({ ...el, id: idx }));

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {textAudio && textAudioUrl ? (
          <S.TextAudioWrapper mt={1} mb={5}>
            <PlayButton
              audioUrl={textAudioUrl}
              width="56px"
              height="56px"
              iconProps={{ width: '32px', height: '32px' }}
            />

            <Markdown
              text={textAudio}
              customStyles={{
                p: {
                  ml: 4,
                  weight: 'regular',
                  size: 'med',
                },
              }}
            />
          </S.TextAudioWrapper>
        ) : textAudio && !textAudioUrl ? (
          <Markdown
            text={createH1(textAudio)}
            customStyles={{
              h1: {
                size: 'Larger',
                ta: 'center',
                weight: 'bold',
                mb: 6,
              },
            }}
          />
        ) : null}
        {title ? (
          <Markdown
            text={createH1(title)}
            customStyles={{
              h1: {
                size: 'extraLarge',
                ta: 'center',
                weight: 'regular',
                mb: 2,
              },
            }}
          />
        ) : null}
        <Row>
          {formattedWordsArray.map((el, i) => (
            <Col w={[2, 3, 3]} key={`col-${el?.option}-${i}`}>
              <OptionButton
                mb={2}
                option={el?.option}
                handleClick={handleClick}
                selected={selected.find((opt) => opt.id === el.id) || {}}
                index={el?.id}
                isCorrect={el?.isCorrect}
                variant="small"
              />
            </Col>
          ))}
        </Row>
      </S.ContentWrapper>
      <S.ButtonsWrapper>
        <S.ButtonsContainer>
          <S.ButtonWrapper>
            {stuckAtReview ? (
              <BasicButton handleClick={handleNext} variant="primary">
                <T.P color="white" weight="semi">
                  Complete review
                </T.P>
              </BasicButton>
            ) : !preview ? (
              <BasicButton
                disabled={disabled || !enableNext}
                handleClick={handleNext}
                icon="next"
                variant="primary"
              >
                <T.P color="white" weight="semi">
                  Next
                </T.P>
              </BasicButton>
            ) : (
              <S.ButtonWrapper mt={5}>
                <BasicButton
                  icon="back"
                  variant="primary"
                  to={(selfLearning
                    ? navRoutes.EXERCISES.EXERCISE_EXPLAINER_SL
                    : navRoutes.EXERCISES.EXERCISE_EXPLAINER
                  ).replace(':exerciseId', exerciseId)}
                >
                  <T.P color="white" weight="semi">
                    Back to exercise
                  </T.P>
                </BasicButton>
              </S.ButtonWrapper>
            )}
          </S.ButtonWrapper>
        </S.ButtonsContainer>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default MultipleChoiceGrid;
