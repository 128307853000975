import { Route, Routes } from 'react-router-dom';
import { Route as CustomRoute } from '../../components';

import { SelfLearningProvider } from '../../context/self-learning';

import ExerciseStep from './ExerciseStep';
import ReviewCompleted from './ReviewCompleted';
import CoachNotes from './CoachNotes';

import ExerciseExplainer from './ExerciseExplainer';
import { EXERCISES } from './../../constants/nav-routes';
const { SL_BASE } = EXERCISES;

function SelfLearningRouter() {
  return (
    <>
      <SelfLearningProvider>
        <Routes>
          <Route
            path={EXERCISES.EXERCISE_EXPLAINER_SL.replace(SL_BASE, '')}
            element={
              <CustomRoute
                Component={ExerciseExplainer}
                layout="general"
                image="secondaryColor"
                fullWidth
                review
              />
            }
          />
          <Route
            path={EXERCISES.SINGLE_STEP_SL.replace(SL_BASE, '')}
            element={<CustomRoute Component={ExerciseStep} />}
          />
          <Route
            path={EXERCISES.REVIEW_COMPLETED_SL.replace(SL_BASE, '')}
            element={
              <CustomRoute
                Component={ReviewCompleted}
                layout="general"
                headerProps={{
                  type: 'progress',
                  progressPercent: 100,
                }}
                image="secondaryColor"
              />
            }
          />
          <Route
            path={EXERCISES.COACH_NOTES_SL.replace(SL_BASE, '')}
            element={
              <CustomRoute
                Component={CoachNotes}
                layout="general"
                image="secondaryColor"
              />
            }
          />
          <Route
            path={EXERCISES.SINGLE_STEP_PREVIEW_SL.replace(SL_BASE, '')}
            element={<CustomRoute Component={ExerciseStep} preview />}
          />
        </Routes>
      </SelfLearningProvider>
    </>
  );
}

export default SelfLearningRouter;
