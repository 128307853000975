import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { UseExerciseWithSelfLearning } from './../../../Hooks';

import { navRoutes as NR } from '../../../constants';
import Content from './Content';

const CoachNotes = () => {
  const { exerciseId } = useParams();
  const { exercise, selfLearning } = UseExerciseWithSelfLearning();
  const { key } = exercise;
  const { intro, steps } = exercise.coachNotes;
  const navigate = useNavigate();
  const { state } = useLocation();

  const previewExerciseLink = (
    selfLearning
      ? NR.EXERCISES.SINGLE_STEP_PREVIEW_SL
      : NR.EXERCISES.SINGLE_STEP_PREVIEW
  )
    .replace(':exerciseId', exerciseId)
    .replace(':stepId', exercise.steps && exercise.steps[0]?.id);

  const explainerPage = (
    selfLearning
      ? NR.EXERCISES.EXERCISE_EXPLAINER_SL
      : NR.EXERCISES.EXERCISE_EXPLAINER
  ).replace(':exerciseId', exerciseId);

  const onClose = async () => {
    if (state?.canGoBack) return navigate(-1);
    return navigate(explainerPage);
  };

  return (
    <Content
      exKey={key}
      intro={intro?.trim()}
      previewExerciseLink={previewExerciseLink}
      steps={steps?.trim()}
      onClose={onClose}
    />
  );
};

export { Content };
export default CoachNotes;
