import * as S from './style';

import * as T from '../Typography';
import Markdown from '../Markdown';

const Tip = ({
  text,
  ShowBoldTipPrefix,
  customPrefix,
  coachNotesStyle,
  ...props
}) => {
  if (text)
    return (
      <S.TipWrapper {...props}>
        <Markdown
          text={
            !ShowBoldTipPrefix ? `**${customPrefix || 'Tip'}!** ${text}` : text
          }
          coachNotesStyle={coachNotesStyle}
          customStyles={{
            p: {
              mt: 3,
              ml: 4,
              mr: 4,
              mb: 3,
              color: 'primaryMain',
            },
            li: {
              color: 'primaryMain',
            },
          }}
        />
      </S.TipWrapper>
    );
  return props?.children?.map((e, index) => (
    <S.TipWrapper {...props} key={index}>
      <T.P display="inline" color="primaryMain" mt={3} ml={4} mr={4} mb={3}>
        {ShowBoldTipPrefix && 'Tip!'} <S.Content>{e}</S.Content>
      </T.P>
    </S.TipWrapper>
  ));
};

export default Tip;
