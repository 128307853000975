import { useEffect } from 'react';
import { ThemeProvider } from '@emotion/react';
import { Global } from '@emotion/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import theme, { globalStyle } from './theme';
import { Route as CustomRoute } from './components';
import * as Pages from './pages';
import { navRoutes, userRoles } from './constants';
import { ScrollToTop } from './helpers';
import { AuthProvider } from './context/auth';
import { GeneralStateProvider } from './context/general-state';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ProgressCheckProvider } from './context/progress-check';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

import hotJarConfig from './hotJarConfig';
import 'antd/dist/antd.css';
import RouteChangeTracker from './RouteChangeTracker';
const isProduction =
  process.env.NODE_ENV === 'production' && !process.env.REACT_APP_CIRCLE_CI;

// const domainGroupId = process.env.REACT_APP_COOKIEBOT_DOMAIN_ID;
const muiTheme = createTheme(theme);

function App() {
  useEffect(() => {
    if (isProduction) {
      hotJarConfig(
        window,
        document,
        'https://static.hotjar.com/c/hotjar-',
        '.js?sv='
      );
    }
  }, []);

  useEffect(() => {
    localStorage.getItem('isLarge') === 'true'
      ? (document.getElementsByTagName('html')[0].style.fontSize = '1.1875rem')
      : (document.getElementsByTagName('html')[0].style.fontSize = '1rem');
  }, []);

  return (
    <HelmetProvider>
      <div className="app">
        {isProduction && (
          <Helmet>
            <script
              id="Cookiebot"
              src="https://consent.cookiebot.com/uc.js"
              data-cbid="0a12d463-1972-4860-9042-4d32d7533878"
              data-blockingmode="auto"
              type="text/javascript"
            ></script>
          </Helmet>
        )}
        <Global styles={globalStyle} />
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={muiTheme}>
            <AuthProvider>
              <GeneralStateProvider>
                <BrowserRouter>
                  <RouteChangeTracker />
                  <ScrollToTop />

                  <Routes>
                    <Route
                      path={navRoutes.GENERAL.LOGIN}
                      element={
                        <CustomRoute
                          Component={Pages.Login}
                          layout="side"
                          image="secondaryColor"
                          publicOnly
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.LOGIN}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.Login}
                          layout="side"
                          image="tertiaryColor"
                          publicOnly
                          showLearnerFooter
                          staticBg
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.MENU}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.Menu}
                          layout="general"
                          isPrivate
                          image="secondaryColor"
                          allowedRoles={[userRoles.LEARNER]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.DASHBOARD}
                      element={
                        <CustomRoute
                          Component={Pages.LearnerDashboard}
                          layout="general"
                          headerProps={{ showProfile: true }}
                          isPrivate
                          image="secondaryColor"
                          allowedRoles={[userRoles.LEARNER, userRoles.COACH]}
                          removePaddingTop
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.PROFILE}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.Profile}
                          layout="general"
                          isPrivate
                          allowedRoles={[userRoles.LEARNER, userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.SUCCESS_MILESTONE}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.MilestoneSuccess}
                          layout="general"
                          isPrivate
                          allowedRoles={[userRoles.LEARNER, userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.REVIEW_LEARNER_SELF_LEARNING}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.ReviewSelfLearning}
                          layout="side"
                          sideColor="neutralLight"
                          headerProps={{ showProfile: true }}
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.NEW_LEARNER}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.NewLearner}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.TRAINING_CHECKLIST}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.TrainingChecklist}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.INTRODUCTORY_MEETING}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.IntroMeeting}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.ONBOARDING_1}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.Onboarding1}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH, userRoles.LEARNER]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.ONBOARDING_4}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.Onboarding4}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH, userRoles.LEARNER]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.ONBOARDING_2}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.Onboarding2}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH, userRoles.LEARNER]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.PROGRESS_CHECK_QUESTIONS}
                      element={
                        <ProgressCheckProvider>
                          <CustomRoute
                            Component={Pages.Learner.ProgressCheckStep}
                            layout="general"
                            headerProps={{
                              type: 'progress',
                            }}
                            image="secondaryColor"
                            allowedRoles={[userRoles.COACH, userRoles.LEARNER]}
                          />
                        </ProgressCheckProvider>
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.PROGRESS_PROFILE_QUESTIONS}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.ProgressProfileQuestions}
                          layout="general"
                          image="secondaryColor"
                          isPrivate
                          allowedRoles={[userRoles.COACH, userRoles.LEARNER]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.PROGRESS_REPORT_QUESTIONS}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.Questions}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH, userRoles.LEARNER]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.PROGRESS_FIRST_PAGE}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.FirstPage}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH, userRoles.LEARNER]}
                        />
                      }
                    />
                    <Route
                      path={
                        navRoutes.LEARNER.LEARNING_BY_YOURSELF_OR_WITH_COACH
                      }
                      element={
                        <CustomRoute
                          Component={
                            Pages.Learner.LearningByYourselfOrWithCoach
                          }
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH, userRoles.LEARNER]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.LEARNER_ID}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.LearnerId}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH, userRoles.LEARNER]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.SETUP_PROCESS}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.SetupProcess}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH, userRoles.LEARNER]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.SOUND_BOARD}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.SoundBoard}
                          layout="general"
                          isPrivate
                          allowedRoles={[userRoles.LEARNER]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.ICONS}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.IconsPage}
                          layout="side"
                          isPrivate
                          allowedRoles={[userRoles.LEARNER]}
                        />
                      }
                    />
                    {[
                      navRoutes.LEARNER.COLOURED_OVERLAY,
                      navRoutes.COACH.COLOURED_OVERLAY,
                    ].map((path) => (
                      <Route
                        path={path}
                        element={
                          <CustomRoute
                            Component={Pages.OverlayColor}
                            layout="general"
                            image="secondaryColor"
                            isPrivate
                            allowedRoles={[userRoles.LEARNER, userRoles.COACH]}
                          />
                        }
                      />
                    ))}
                    <Route
                      path={navRoutes.LEARNER.GOALS}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.Goals}
                          layout="general"
                          image="secondaryColor"
                          isPrivate
                          allowedRoles={[userRoles.LEARNER]}
                          fullWidth
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.LEARNER_DASHBOARD}
                      element={
                        <CustomRoute
                          Component={Pages.LearnerDashboard}
                          layout="general"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                          image="secondaryColor"
                          headerProps={{
                            learnerDashboard: true,
                            showProfile: true,
                          }}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.LEARNER_PROFILE}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.LearnerProfile}
                          layout="general"
                          coachView
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.GENERAL.FORGET_PASSWORD}
                      element={
                        <CustomRoute
                          Component={Pages.ForgetPassword}
                          layout="side"
                          image="secondaryColor"
                          publicOnly
                        />
                      }
                    />
                    <Route
                      path={navRoutes.GENERAL.RESET_PASSWORD}
                      element={
                        <CustomRoute
                          Component={Pages.ResetPassword}
                          layout="side"
                          image="secondaryColor"
                          publicOnly
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.LEARNER_DASHBOARD}
                      element={
                        <CustomRoute
                          Component={Pages.LearnerDashboard}
                          layout="general"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                          image="secondaryColor"
                          headerProps={{
                            learnerDashboard: true,
                            showProfile: true,
                          }}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.LEARNER_PROFILE}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.LearnerProfile}
                          layout="general"
                          coachView
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.LEARNER_REACHED_MILESTONES}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.LearnerMilestonesReached}
                          layout="general"
                          coachView
                          isPrivate
                          allowedRoles={[
                            userRoles.COACH,
                            userRoles.LEARNER,
                            userRoles.FACILITATOR,
                          ]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.COACH_CODE}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.CoachCode}
                          layout="general"
                          image="secondaryColor"
                          allowedRoles={[userRoles.LEARNER]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.PROGRESS_CHECK_THANK_YOU}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.ProgressCheckThankYou}
                          layout="general"
                          image="secondaryColor"
                          allowedRoles={[userRoles.LEARNER]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.GENERAL.REFER_A_LEARNER}
                      element={
                        <CustomRoute
                          Component={Pages.ReferLearner}
                          layout="side"
                          publicOnly
                          sideChildren={<Pages.ReferLearner.SideDetails />}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.GENERAL.REFER_A_LEARNER}
                      element={
                        <CustomRoute
                          Component={Pages.ReferLearner}
                          layout="side"
                          publicOnly
                          sideChildren={<Pages.ReferLearner.SideDetails />}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.GENERAL.REFER_A_LEARNER_THANK_YOU}
                      element={
                        <CustomRoute
                          Component={Pages.ReferLearnerThankYou}
                          layout="side"
                          image="secondaryColor"
                          publicOnly
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.LEARNERS}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.Learners}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.LEARNERS_STUCK_AT}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.LearnersStuckAt}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.REVIEW_STUCK_AT}
                      element={
                        <CustomRoute
                          Component={Pages.StuckAtReview}
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.SIGNUP}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.CoachSignup}
                          layout="side"
                          sideColor="neutralLight"
                          publicOnly
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.PROFILE}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.Profile}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.CONFIRM_LEARNER}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.ConfirmLearner}
                          layout="side"
                          sideColor="neutralLight"
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.HAVE_ISSUE}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.HaveIssue}
                          layout="side"
                          sideColor="neutralLight"
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.GENERAL.INTEREST}
                      element={
                        <CustomRoute
                          Component={Pages.InterestForm}
                          layout="side"
                          sideColor="neutralLight"
                          publicOnly
                        />
                      }
                    />
                    <Route
                      path={navRoutes.GENERAL.INTEREST_THANK_YOU}
                      element={
                        <CustomRoute
                          Component={Pages.InterestFormThankYou}
                          layout="side"
                          sideColor="neutralLight"
                          publicOnly
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.DASHBOARD_ADVICE}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.DashboardSubPages.Advice}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.DASHBOARD_TRAINING_CHECKLIST}
                      element={
                        <CustomRoute
                          Component={
                            Pages.Coach.DashboardSubPages.TrainingChecklist
                          }
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.DASHBOARD_SAFEGUARDING}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.DashboardSubPages.Safeguarding}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.DASHBOARD_QUALITIES}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.DashboardSubPages.Qualities}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.COACH.DASHBOARD}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.Dashboard}
                          layout="side"
                          sideColor="neutralLight"
                          headerProps={{ showProfile: true }}
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.ONBOARDING_5}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.Onboarding5}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH, userRoles.LEARNER]}
                        />
                      }
                    />
                    <Route
                      path={navRoutes.LEARNER.PROGRESS_REPORT_COMPLETED}
                      element={
                        <CustomRoute
                          Component={Pages.Learner.ReportCompleted}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH, userRoles.LEARNER]}
                        />
                      }
                    />{' '}
                    <Route
                      path={navRoutes.COACH.ONBOARDING_1}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.Onboarding1}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />{' '}
                    <Route
                      path={navRoutes.COACH.ONBOARDING_2}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.Onboarding2}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />{' '}
                    <Route
                      path={navRoutes.COACH.ONBOARDING_3}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.Onboarding3}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />{' '}
                    <Route
                      path={navRoutes.COACH.ONBOARDING_4}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.Onboarding4}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />{' '}
                    <Route
                      path={navRoutes.COACH.ONBOARDING_5}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.Onboarding5}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />{' '}
                    <Route
                      path={navRoutes.COACH.ONBOARDING_6}
                      element={
                        <CustomRoute
                          Component={Pages.Coach.Onboarding6}
                          layout="side"
                          sideColor="neutralLight"
                          isPrivate
                          allowedRoles={[userRoles.COACH]}
                        />
                      }
                    />
                    <Route
                      path="/"
                      element={
                        <CustomRoute.Redirect
                          path="/"
                          to={navRoutes.LEARNER.LOGIN}
                        />
                      }
                    />
                    <Route
                      path={'*'}
                      element={
                        <CustomRoute
                          Component={Pages.PageNotFound}
                          layout="general"
                          sideColor="neutralLight"
                        />
                      }
                    />
                    <Route
                      path={`${navRoutes.FACILITATOR.BASE}/*`}
                      element={<Pages.Facilitator />}
                    />
                    <Route
                      path={`${navRoutes.EXERCISES.BASE}/*`}
                      element={<Pages.Exercises />}
                    />
                    <Route
                      path={`${navRoutes.CMS.BASE}/*`}
                      element={<Pages.CMS />}
                    />
                  </Routes>
                </BrowserRouter>
              </GeneralStateProvider>
            </AuthProvider>
          </MuiThemeProvider>
        </ThemeProvider>
      </div>
    </HelmetProvider>
  );
}

export default App;
