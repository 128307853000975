import axios from 'axios';
import handleError from './format-error';

const EXERCISES_BASE = '/exercises';

const getExerciseById = async ({ id, learnerId, options } = {}) => {
  try {
    const { data } = await axios.get(`${EXERCISES_BASE}/${id}`, {
      params: { learnerId },
    });

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createCompletedStep = async ({
  id,
  learnerId,
  withCoach,
  options,
} = {}) => {
  try {
    const { data } = await axios.post(
      `${EXERCISES_BASE}/steps/${id}/complete`,
      {
        learnerId,
        withCoach,
      }
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

// handled by useGetExercises in exercises.queries
const getExercises = async ({ cms, options } = {}) => {
  try {
    const { data } = await axios.get(`${EXERCISES_BASE}`, { params: { cms } });
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getStepById = async ({ exerciseId, stepId, options } = {}) => {
  try {
    const { data } = await axios.get(
      `${EXERCISES_BASE}/${exerciseId}/steps/${stepId}`
    );

    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const createExercise = async ({ data, options } = {}) => {
  try {
    const { data: _data } = await axios.post(`${EXERCISES_BASE}/`, data);

    return { data: _data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};
const updateExercise = async ({ id, data, options } = {}) => {
  try {
    const { data: _data } = await axios.patch(`${EXERCISES_BASE}/${id}`, data);

    return { data: _data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

// handled by useGetLetterSoundSteps in exercises.queries
// const getLetterSoundsSteps = async ({ options } = {}) => {
//   try {
//     const { data } = await axios.get(`${EXERCISES_BASE}/letter-sounds`);

//     return { data };
//   } catch (error) {
//     const err = handleError(error, options);
//     return { error: err };
//   }
// };

export {
  getExerciseById,
  createCompletedStep,
  getExercises,
  getStepById,
  createExercise,
  updateExercise,
  // getLetterSoundsSteps,
};
