import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SideWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  margin-top: 195px;
  padding: 0 4vw;
`;

export const NoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 600px;
  background-color: ${({ theme }) => theme.colors.neutralLight};
  border-radius: 16px;
  padding: 16px 24px;
  gap: 12px;
  margin-top: ${({ theme, idx }) => idx > 0 && theme.spacings[5]};
`;

export const DateWrapper = styled.div`
  display: flex;
`;

export const DatePickerWrapper = styled.div`
  width: 300px;
  margin-top: 1rem;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
