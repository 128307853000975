import { useParams } from 'react-router-dom';
import { UseExerciseWithSelfLearning } from '../../../Hooks';

import { navRoutes, userRoles } from '../../../constants';
import { useAuth } from '../../../context/auth';
import { LearningSession } from './../../../api-calls';
import { Loading } from './../../../components';

import Content from './Content';

const ExerciseExplainer = ({ review }) => {
  const { user } = useAuth();
  const { exerciseId } = useParams();

  const { exercise, withCoach, handleNext, disabled, selfLearning } =
    UseExerciseWithSelfLearning();

  const {
    mutateAsync,
    error: httpError,
    isLoading,
    isError,
  } = LearningSession.useCreateLearningSession();

  const onNext = async (...args) => {
    if (user.role === userRoles.COACH) {
      await mutateAsync({ selfLearning: false });
    }
    handleNext(...args);
  };
  if (!exercise?.id) {
    return <Loading />;
  }
  return (
    <Content
      beforeExercise={exercise?.beforeExercise}
      handleNext={onNext}
      disabled={disabled}
      review={review}
      showCoachNotes={user.role === userRoles.COACH || withCoach}
      toCoachNotes={{
        to: (selfLearning
          ? navRoutes.EXERCISES.COACH_NOTES_SL
          : navRoutes.EXERCISES.COACH_NOTES
        ).replace(':exerciseId', exerciseId),
        state: { canGoBack: true },
      }}
      loading={isLoading}
      error={isError && httpError?.message}
    />
  );
};

export { Content };
export default ExerciseExplainer;
