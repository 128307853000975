import React from 'react';

import * as S from './style';

const ModalNew = ({
  visible,
  setIsModalVisible,
  children,
  onCancel,
  bgColor,
  ...props
}) => {
  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
    if (typeof setIsModalVisible === 'function') {
      setIsModalVisible(false);
    }
  };

  return (
    <S.Modal
      open={visible}
      onClose={handleCancel}
      aria-label="dialog"
      aria-modal="true"
      role="dialog"
      BackdropProps={{
        style: { backgroundColor: 'rgba(5, 13, 48, 0.7)' },
      }}
      {...props}
    >
      <S.Container bgColor={bgColor}>{children}</S.Container>
    </S.Modal>
  );
};

export default ModalNew;
