import { useReducer } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, number, array, object } from 'yup';
import { MediaKeyInput } from '../../../Components';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data
    ? {
        ...data,
        correctOptions: data?.correctOptions?.map((e, i) => ({
          ...e,
          id: i,
        })) || [{ id: 0, option: '' }],
      }
    : {
        correctOptions: [{ id: 0, option: '' }],
        title: '',
        audioUrl: '',
      },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const TypeNewEndings = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const form = {
        ...prevState.form,
        ...data,
      };
      onChange({
        ...form,
      });
      return { form };
    });

  const addOption = () => {
    const maxId = Math.max(...state.form.correctOptions.map((e) => e.id), 0);
    setFormData({
      correctOptions: [
        ...state.form.correctOptions,
        { id: maxId + 1, option: '', hide: false },
      ],
    });
  };
  const removeOption = (id) => {
    setFormData({
      correctOptions: state.form.correctOptions.filter((e) => e.id !== id),
    });
  };

  const onOptionChange = (value, id) => {
    setFormData({
      correctOptions: state.form.correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={state.form.textAudio}
        handleChange={(textAudio) => setFormData({ textAudio })}
        m={{ mt: 8 }}
        error={errors?.textAudio}
      />
      <MediaKeyInput
        label={`Explainer Text file Key`}
        value={state.form.textAudioKey}
        handleChange={(textAudioKey) => {
          setFormData({ textAudioKey });
        }}
        m={{ mt: 5 }}
        error={errors?.textAudioKey}
      />

      <BasicInput
        label="Title"
        value={state.form.title}
        handleChange={(title) => setFormData({ title })}
        m={{ mt: 8 }}
        helper="e.g. 'Play'"
        error={errors.title}
      />

      <T.P mt={8} size="large" weight="bold">
        Endings{' '}
      </T.P>

      {errors?.correctOptions && typeof errors.correctOptions === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.correctOptions}
        </T.P>
      )}

      {state.form.correctOptions.map((e, i) => {
        return (
          <>
            <BasicInput
              label="Text"
              value={e.option}
              handleChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mt: 5 }}
              error={
                errors?.correctOptions && errors?.correctOptions[i]?.option
              }
              helper='Add "+" between the word and the ending (e.g. Play+ed)'
              placeholder="Play+ed"
            />

            {state.form.correctOptions.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={state.form.correctOptions.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add ending
      </S.Button>
    </div>
  );
};

const validationSchema = {
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string().required('required field'),
        })
        .required()
    )
    .required('you must provide endings')
    .min(1, 'you must provide endings'),
  title: string().required('required field'),
  textAudioKey: string().required('required field'),
  textAudio: string().required('required field'),
};
export { validationSchema };

export default TypeNewEndings;
