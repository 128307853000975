import * as T from '../../components/Typography';
import { facilitatorDetailsPublic } from '../../constants';

import * as S from './style';

const SideDetails = () => {
  // UNCOMMENT THIS CALL ONCE FACILITATOR DETAILS HAVE TO BE PULLED IN DYNAMICALLY
  // useEffect(() => {
  //   const getFacilitatorById = async () => {
  //     const { data } = await Facilitators.getFacilitatorById({
  //       facilitatorId: 'default',
  //     });

  //     if (data) {
  //       setFacilitator(data);
  //     }
  //   };
  //   getFacilitatorById();
  // }, []);

  return (
    <S.SideDetailsWrapper>
      <T.H2 color="neutralMain">Prefer to chat?</T.H2>
      <T.P mt={3} color="neutralMain">
        If you would like to have a chat first to talk through your needs and
        the programme, please give us a call using the contact details below
      </T.P>
      <T.P weight="bold" mb={1} mt={3} color="neutralMain">
        {facilitatorDetailsPublic.fullName}
      </T.P>
      <T.Link to="tel:020 3763 8118" color="neutralMain" external weight="bold">
        {facilitatorDetailsPublic.phoneNumber}
      </T.Link>
    </S.SideDetailsWrapper>
  );
};

export default SideDetails;
