import moment from 'moment';
import { Table } from 'antd';
import { Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import NameColumn from './NameColumn';

import * as S from './style';
import { coachStatusesQuery } from '../../../constants';
import { FACILITATOR } from '../../../constants/nav-routes';

import { useUpdateCoachDBS } from '../../../api-calls/facilitator.queries';

const CoachesTableAll = ({
  dataSource,
  statusType,
  embed = false,
  pageSize,
  dashboard,
  onChange,
  isLoading,
}) => {
  const { mutate, error: httpError } = useUpdateCoachDBS((update) => update);
  const pagination = dashboard
    ? false
    : {
        showSizeChanger: false,
        pageSize,
        total: dataSource?.[0]?.total || 0,
      };
  const handleDbsUpdate = (update) => mutate(update);

  const columnsAllCoaches = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (name, { id, approvalStatus, deleted }) =>
        !deleted ? (
          <NameColumn
            id={id}
            name={name}
            deleted={deleted}
            statusType={statusType}
            approvalStatus={approvalStatus}
          />
        ) : (
          <T.P>Deleted</T.P>
        ),
      align: 'left',
      responsive: ['sm'],
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      responsive: ['sm'],
      render: (email, { deleted }) =>
        !deleted ? <T.P>{email}</T.P> : <T.P>Deleted</T.P>,
    },
    {
      title: 'Name / Email',
      render: ({ fullName, id, email, deleted, approvalStatus }) =>
        !deleted ? (
          <>
            <NameColumn
              id={id}
              name={fullName}
              statusType={statusType}
              approvalStatus={approvalStatus}
            />
            <br />
            {email}
          </>
        ) : (
          <T.P>Deleted</T.P>
        ),

      responsive: ['xs'],
    },
    {
      title: 'DBS check',
      dataIndex: 'dbsCheck',
      key: 'dbsCheck',
      render: (dbsCheck, { id, deleted }) => (
        <S.DBSCheckbox
          key={id}
          checked={dbsCheck}
          onChange={(value) =>
            deleted
              ? undefined
              : handleDbsUpdate({
                  value: value.target.checked,
                  coachId: id,
                })
          }
        />
      ),
    },
    {
      title: 'Number of Learners',
      dataIndex: 'totalLearners',
      key: 'totalLearners',
    },
    {
      title: 'Hours available (week)',
      dataIndex: 'availabilityHoursPerWeek',
      key: 'availabilityHoursPerWeek',
      responsive: ['sm'],
    },
    {
      title: 'Hours available (month)',
      dataIndex: 'availabilityHoursPerMonth',
      key: 'availabilityHoursPerMonth',
      responsive: ['sm'],
    },
    {
      title: 'Hours available week / month',
      render: (record) => (
        <>
          {record.availabilityHoursPerMonth}
          <br />
          {record.availabilityHoursPerWeek}
        </>
      ),
      responsive: ['xs'],
    },
    {
      title: 'Date completed training',
      dataIndex: 'completedTrainingAt',
      key: 'completedTrainingAt',
      render: (date) => (date ? moment(date).format('DD MMMM YYYY') : 'TBC'),
    },
  ];

  return (
    <S.Wrapper dashboard={dashboard}>
      {!embed ? (
        <>
          <Row mt="8">
            <S.TableTitleWrapper>
              <T.H2 color="neutralMain" size="large">
                Coaches (approved)
              </T.H2>
            </S.TableTitleWrapper>
          </Row>
          <Row mb={5}>
            <T.Link
              to={FACILITATOR.COACHES.replace(
                ':statusType',
                coachStatusesQuery.REJECTED
              )}
            >
              View rejected coaches
            </T.Link>
          </Row>
        </>
      ) : null}
      <Table
        columns={columnsAllCoaches}
        dataSource={dataSource}
        pagination={pagination}
        onChange={onChange}
        loading={isLoading}
      />

      {httpError ? <T.P color="error">{httpError?.message}</T.P> : null}
    </S.Wrapper>
  );
};

export default CoachesTableAll;
