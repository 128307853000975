import React from 'react';
import * as S from './style';
import Icon from '../Icon';
import { calculatePercentage } from '../../helpers';

const ProgressBar = ({ progress }) => {
  const percentage = calculatePercentage(progress?.complete, progress?.total);
  return (
    <S.Wrapper>
      <S.BarOuter>
        <S.BarInner percentage={percentage} />
      </S.BarOuter>
      <Icon icon="milestoneNew" width="50px" height="50px" />
    </S.Wrapper>
  );
};

export default ProgressBar;
