import ReactGA from 'react-ga';

import { useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';

function RouteChangeTracker() {
  let location = useLocation();
  useLayoutEffect(() => {
    if (location?.pathname) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  }, [location]);

  return <div></div>;
}

export default RouteChangeTracker;
