import { Col, Row } from '../../../components/Grid';
import * as T from '../../../components/Typography';
import { Button } from '../../../components';
import * as S from './style';

const TableHeader = ({
  title,
  iconButtonText,
  iconButtonLink,
  basicButtonText,
  basicButtonLink,
}) => {
  return (
    <Row mt="8" jc="space-between" ai="center" key={`header-${title}`}>
      <Col w={[4, 5, 5]} mb="3" key={`column-${title}`}>
        <T.H2 color="neutralMain" size="medLarge">
          {title}
        </T.H2>
      </Col>
      <Col w={[4, 7, 7]} jc="flex-end" mb="3" key={`column-${iconButtonText}`}>
        <S.TableButtonsWrapper>
          <Button.WithText
            mr={5}
            icon="userWithArrow"
            text={iconButtonText}
            weight="regular"
            to={iconButtonLink}
            style={{ width: '250px' }}
          />
          {basicButtonLink && basicButtonText ? (
            <Button.BasicButton style={{ width: '190px' }} to={basicButtonLink}>
              {basicButtonText}
            </Button.BasicButton>
          ) : null}
        </S.TableButtonsWrapper>
      </Col>
    </Row>
  );
};

export default TableHeader;
