import moment from 'moment';
import { Table } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import * as T from '../../../components/Typography';
import * as S from './style';
import { navRoutes } from '../../../constants';

const badgesStyle = {
  REFERRAL: {
    text: 'Referral',
    bgColor: 'secondaryMain',
  },
  REJECTED: {
    text: 'Rejected',
    bgColor: 'neutralMain',
  },
  AWAITING_COACH: {
    text: 'Awaiting Coach',
    bgColor: 'secondaryMain',
  },
  TO_BE_ONBOARDED: {
    text: 'Still to onboard',
    bgColor: 'primaryMain',
  },
  ASSIGNED: {
    text: 'Assigned',
    bgColor: 'quaternaryMain',
  },
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const decideSingleStatus = (row) => {
  if (row.referralStatus === 'PENDING') return 'REFERRAL';
  if (row.referralStatus === 'REJECTED') return 'REJECTED';
  if (row.referralStatus === 'APPROVED' && !row.assignedToCoach)
    return 'AWAITING_COACH';
  if (!row.isReferral && !row.assignedToCoach) return 'AWAITING_COACH';
  if (row.assignedToCoach && !row.onboarded) return 'TO_BE_ONBOARDED';
  if (row.assignedToCoach && row.onboarded) return 'ASSIGNED';
};

const decideMultipleStatuses = (row) => {
  let statuses = [];
  if (row.isReferral) {
    statuses.push('REFERRAL');
  }
  if (row.referralStatus === 'REJECTED') {
    return ['REJECTED'];
  }
  if (!row.assignedToCoach) {
    statuses.push('AWAITING_COACH');
  }
  if (row.assignedToCoach && !row.onboarded) {
    statuses.push('TO_BE_ONBOARDED');
  }
  if (row.assignedToCoach && row.onboarded) {
    statuses.push('ASSIGNED');
  }

  return statuses;
};

const columns = (showSingleStatus) => [
  {
    title: 'Name',
    width: '15%',
    dataIndex: 'fullName',
    key: 'fullName',
    render: (fullName, record) => {
      const { referralStatus, id, deleted } = record;
      const needsReview = referralStatus === 'PENDING';

      return (
        <S.LearnerNameColumnWrapper>
          <T.P>{deleted ? 'Deleted' : fullName}</T.P>
          {!deleted ? (
            <T.Link
              to={
                needsReview
                  ? navRoutes.FACILITATOR.LEARNER_REFERRAL.replace(':id', id)
                  : navRoutes.FACILITATOR.LEARNER.replace(':userId', id)
              }
            >
              <S.Button type="text" icon={<EyeOutlined />}>
                {needsReview ? 'Review' : 'View more'}
              </S.Button>
            </T.Link>
          ) : null}
        </S.LearnerNameColumnWrapper>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_, row) => {
      if (showSingleStatus) {
        const status = decideSingleStatus(row);
        const { text, bgColor } = badgesStyle[status] || {};
        return <S.Tag bgColor={bgColor}>{text}</S.Tag>;
      } else {
        const statuses = decideMultipleStatuses(row);
        return statuses.map((status) => {
          const { text, bgColor } = badgesStyle[status] || {};
          return (
            <S.Tag bgColor={bgColor} key={status}>
              {text}
            </S.Tag>
          );
        });
      }
    },
  },
  {
    title: 'Control / Treatment',
    dataIndex: 'controlTreatmentType',
    key: 'controlTreatmentType',
    render: (text) => (text ? capitalizeFirstLetter(text) : 'N/A'),
  },
  {
    title: 'Assigned Coach',
    dataIndex: 'coach',
    key: 'coach',
    render: (coach) => (coach.deleted ? 'Deleted' : coach.fullName || 'N/A'),
  },
  {
    title: 'Date added to platform',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date) => (date ? moment(date).format('DD MMMM YYYY') : 'N/A'),
  },
  {
    title: 'Learner ID',
    dataIndex: 'learnerUniqueId',
    key: 'learnerUniqueId',
  },
];

const LearnersTable = ({
  dataSource,
  onChange,
  dashboard,
  pageSize,
  loading,
}) => {
  const showSingleStatus = dashboard;
  const pagination = dashboard
    ? false
    : {
        showSizeChanger: false,
        pageSize,
        total: dataSource?.[0]?.total || 0,
      };
  return (
    <S.TableWrapper>
      <Table
        columns={columns(showSingleStatus)}
        dataSource={dataSource}
        pagination={pagination}
        onChange={onChange}
        loading={loading}
      />
    </S.TableWrapper>
  );
};

export default LearnersTable;
