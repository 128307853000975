import moment from 'moment';

import { Row } from '../Grid';
import * as T from '../Typography';
import { navRoutes } from '../../constants';
import * as S from './style';

const NoteCard = ({ createdAt, note, noteId, coachId }) => {
  return (
    <S.NoteWrapper key={`note-${noteId}`}>
      <Row>
        <S.DateWrapper>
          <T.P>
            Date <br />
            {moment(createdAt).format('DD.MM.YY')}
          </T.P>
          <T.P ml="5">
            Time <br />
            {moment(createdAt).format('hh:mm')}
          </T.P>
        </S.DateWrapper>
      </Row>
      <Row>
        <T.P color="neutral90">Notes</T.P>
      </Row>
      <Row color="neutralMain" style={{ display: 'inline-block' }}>
        <T.P>{note}</T.P>
      </Row>
      <Row>
        <T.Link
          color="neutralMain"
          underline
          to={navRoutes.FACILITATOR.EDIT_NOTE}
          state={{
            noteId,
            note,
            coachId,
          }}
        >
          Edit Note
        </T.Link>
      </Row>
    </S.NoteWrapper>
  );
};
export default NoteCard;
