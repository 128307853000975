import { useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import Markdown from '../../../../components/Markdown';

import EmptySpace from '../../../../components/EmptySpace';
import Option from '../../../../components/Option';
import * as S from './style';
import { showMovingBlock } from '../../../../helpers';
import TryAgainModal from '../../../../components/Modal/TryAgainModal';
import StepFooterButtons from '../StepFooterButtons';

const SentenceFill = ({
  data: {
    options: _options = [],
    questionText = '',
    textAudioUrl,
    textAudio,
  } = {},
  handleNext,
  audioUrl,
  stuckAtReview,

  preview,
  selfLearning,
  cmsPreview,
}) => {
  const [disableCheck, setDisableCheck] = useState(false);
  const [activePosition, setActivePosition] = useState(0);
  const [showTryAgain, setShowTryAgain] = useState(false);

  const regex = /%(.*?)%/g;
  const replacedText = questionText.replace(regex, '%INPUT%');
  const correctAnswers = questionText
    .match(regex)
    ?.map((e) => e.replace(/%/g, ''));
  let i = 0;
  const contentObjects = replacedText.split('%').map((e) => {
    if (e === 'INPUT') {
      const elem = { type: 'input', id: i };
      i++;
      return elem;
    } else {
      return { type: 'text', content: e };
    }
  });

  const options = _options?.map((op, i) => ({
    option: op.option,
    id: i,
    audioUrl: op.audioUrl,
  }));

  const [answers, setAnswers] = useState(
    correctAnswers?.map((e) => null) || []
  );

  const onClickAnswer = (id) => {
    if (answers[id]) {
      setActivePosition(id);
      showMovingBlock({
        OptionElId: `option-${answers[id]}`,
        answerElId: `answer-${id}`,
        movingElId: 'moving-div',
        isBeingSelected: true,
      });
      setAnswers((_answers) =>
        _answers.map((answer, ir) => {
          if (ir === id) {
            return null;
          } else {
            return answer;
          }
        })
      );
    } else {
      setActivePosition(id);
    }
  };

  const onFocusAnswer = (id) => {
    setActivePosition(id);
  };
  const onClickOption = (option) => {
    setDisableCheck(true);

    showMovingBlock({
      OptionElId: `option-${option.option}`,
      answerElId: `answer-${activePosition}`,
      movingElId: 'moving-div',
      isBeingSelected: false,
      done: () => {
        setAnswers((_answers) => {
          const newAnswers = _answers.map((answer, ir) => {
            if (ir === activePosition) {
              return (
                <Markdown
                  text={option.option}
                  customStyles={{
                    p: {
                      ml: 2,
                      size: 'med',
                    },
                  }}
                />
              );
            } else {
              return answer;
            }
          });

          setActivePosition((_activePosition) => {
            const availablePositions = [...newAnswers, ...newAnswers].map(
              (answer, i) => !answer && i > _activePosition
            );

            const nextSpace = availablePositions.indexOf(true);

            return nextSpace % answers.length;
          });

          return newAnswers;
        });
        setDisableCheck(false);
      },
    });
  };

  const validateCell = (id) => {
    const currentAnswer = answers[id];
    return currentAnswer.props.text === correctAnswers[id];
  };

  const onNext = () => {
    if (stuckAtReview) return handleNext();

    const allCorrect = answers.every((e, i) => validateCell(i));

    if (allCorrect) {
      handleNext();
    } else {
      setShowTryAgain(true);
    }
  };

  return (
    <S.Wrapper>
      {/* keep this to animation */}
      <div style={{ display: 'none' }}>
        <Option id="moving-div"></Option>
      </div>
      <TryAgainModal
        isModalVisible={showTryAgain}
        setIsModalVisible={setShowTryAgain}
      />

      {textAudio && (
        <PlayButton
          audioUrl={textAudioUrl}
          padding="12px"
          mt={5}
          mx="auto"
          mxM="0"
          weight="regular"
        >
          {textAudio}
        </PlayButton>
      )}

      <S.Grid>
        <S.Row>
          <PlayButton audioUrl={audioUrl} mt={2} mr={3} />
          <S.SentenceWrapper size="medLarge">
            {contentObjects.map((item, ir) =>
              item.type === 'input' ? (
                <S.Item>
                  <EmptySpace
                    isActive={item.id === activePosition && !cmsPreview}
                    onClick={() => onClickAnswer(item.id)}
                    onFocus={() => onFocusAnswer(item.id)}
                    id={`answer-${item.id}`}
                    bgColor={
                      answers[item.id]
                        ? 'rgba(255, 255, 255, 0.5)'
                        : 'neutralSurface'
                    }
                    key={`${ir}`}
                    w="78px"
                    h="53px"
                    style={{ display: 'inline-block' }}
                    fontSize="20px"
                  >
                    {answers[item.id]}
                  </EmptySpace>
                </S.Item>
              ) : (
                item.content.split(' ').map((e, i) => (
                  <S.SingleWord>
                    <Markdown
                      text={e}
                      customRender={{
                        p: (props) => <span {...props} />,
                      }}
                    />
                    &nbsp;
                  </S.SingleWord>
                ))
              )
            )}
          </S.SentenceWrapper>
        </S.Row>
      </S.Grid>

      <S.OptionsGridWrapper>
        <S.OptionsFlexWrapper>
          {options.map((op) => (
            <S.OptionRow>
              <PlayButton audioUrl={op.audioUrl} mx={1} />
              <Option
                m={1}
                onClick={() => onClickOption(op)}
                id={`option-${op.option}`}
                key={op.id}
                style={{ minWidth: '74px', flex: 1 }}
                disabled={activePosition < 0}
                fontSize="20px"
              >
                <Markdown
                  text={op.option}
                  customRender={{
                    p: (props) => <span {...props} />,
                  }}
                />
              </Option>
            </S.OptionRow>
          ))}
        </S.OptionsFlexWrapper>
      </S.OptionsGridWrapper>

      {(!_options || !questionText) && (
        <T.P color="error">Exercise missing required fields </T.P>
      )}
      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={onNext}
        preview={preview}
        disabled={
          answers?.filter((e) => e).length < answers?.length || disableCheck
        }
        enableNext={
          answers.filter((e) => e).length >= answers.length || disableCheck
        }
        selfLearning={selfLearning}
      />
    </S.Wrapper>
  );
};

export default SentenceFill;
