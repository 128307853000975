import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../../../components/Layout';

import Loading from '../../../components/Loading';
import { UseExerciseWithSelfLearning } from '../../../Hooks';
import { useGeneralState } from '../../../context/general-state';
import { navRoutes as NR } from '../../../constants';
import { Exercises } from '../../../api-calls';

import { replaceMediaKeysWithUrls } from '../../../helpers';

import StuckAtReview from './StuckAtReview';

import Formats from './Formats';

const initStep = {
  audioKey: '',
  correctOption: null,
  imageKey: '',
  options: null,
  subtitle: '',
  title: '',
};

const ExerciseStepContent = ({ preview }) => {
  const navigate = useNavigate();
  const { state: generalState } = useGeneralState();

  const { stepId, exerciseId } = useParams();
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(initStep);
  const { exercise, handleNext, selfLearning, disabled } =
    UseExerciseWithSelfLearning();

  useEffect(() => {
    let mount = true;
    async function fetchData() {
      setLoading(true);

      const { data, error } = await Exercises.getStepById({
        stepId: stepId,
        exerciseId,
      });

      if (mount) {
        if (error?.statusCode === 404) {
          navigate(NR.GENERAL.NOT_FOUND);
        }
        setStep({
          ...replaceMediaKeysWithUrls(data, generalState?.preferredVoice),
        });
        setLoading(false);
      }
    }

    fetchData();

    return () => {
      mount = false;
    };
  }, [stepId, exerciseId, generalState?.preferredVoice, navigate]);

  if (loading) return { Component: <Loading />, layout: 'general' };
  return Formats({
    exercise,
    handleNext,
    disabled,
    preview,
    step,
    selfLearning,
  });
};

const ExerciseStep = (props) => {
  const { Component, layout } = ExerciseStepContent(props);

  return (
    <Layout
      layout={layout}
      headerProps={{
        type: 'progress',
      }}
      image="secondaryColor"
    >
      {Component}
    </Layout>
  );
};

export default ExerciseStep;
export { StuckAtReview };
