import { useState } from 'react';
import * as T from '../../../components/Typography';
import LearnersTable from './LearnersTable';
import { navRoutes } from '../../../constants';
import { useGetLearners } from '../../../api-calls/learners.queries';
import { Col, Row } from '../../../components/Grid';
import { Button } from '../../../components';
import useCsvDownload from './../../../Hooks/useCsvDownload';
import * as S from './style';

const pageSize = 20;
const AllLearners = () => {
  const [page, setPage] = useState(1);
  const {
    learners,
    error: getLearnersError,
    isLoading: loading,
  } = useGetLearners({ page, limit: pageSize });

  const [data, handleClick] = useCsvDownload('/csv/learners');

  // const { size, loading: csvLoading, error } = data;

  if (getLearnersError?.message) {
    return (
      <T.P mb={2} mt={2} color="error">
        {getLearnersError?.message}
      </T.P>
    );
  }

  return (
    <S.Wrapper>
      <Row mt="8" ai="center" mb={5}>
        <Col w={[4, 4, 4]}>
          <S.TableTitleWrapper>
            <T.H2 color="neutralMain" size="medLarge">
              Learners
            </T.H2>
          </S.TableTitleWrapper>
        </Col>
        <Col w={[4, 8, 8]} jc="flex-end">
          <Button.WithText
            mr={5}
            icon="userWithArrow"
            text="Onboard a new learner"
            weight="regular"
            to={navRoutes.FACILITATOR.ADD_LEARNER}
            style={{ width: '250px' }}
          />
          <Button.BasicButton
            style={{ width: '190px' }}
            handleClick={handleClick}
            loading={data.loading}
          >
            Export Learners
          </Button.BasicButton>
        </Col>
      </Row>
      <LearnersTable
        dataSource={learners}
        onChange={({ current }) => setPage(current)}
        pageSize={pageSize}
        loading={loading}
      />
    </S.Wrapper>
  );
};

export default AllLearners;
