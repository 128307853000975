import axios from 'axios';

import * as Users from './users';
import * as Learners from './learners';
import * as Coaches from './coaches';
import * as Facilitators from './facilitators';
import * as Exercises from './exercises';
import * as Milestones from './milestones';
import * as Steps from './steps';
import * as Media from './media';
import * as LearningSession from './learning-sessions';
import * as ProgressCheck from './progressChecks.queries';
import * as Badges from './badges.queries';

if (
  process.env.NODE_ENV === 'production' &&
  !process.env.REACT_APP_CIRCLE_CI &&
  process.env.REACT_APP_DOMAIN_NAME
) {
  axios.defaults.baseURL = `${process.env.REACT_APP_DOMAIN_NAME}/api`;
  axios.defaults.withCredentials = true;
} else {
  axios.defaults.baseURL = `${process.env.PUBLIC_URL}/api`;
}

export {
  Users,
  Learners,
  Coaches,
  Facilitators,
  Exercises,
  Milestones,
  Steps,
  Media,
  LearningSession,
  ProgressCheck,
  Badges,
};
