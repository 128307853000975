import { useEffect, useState } from 'react';
import { useAuth } from '../../../context/auth';
import { navRoutes } from './../../../constants';
import { Facilitators, Coaches, Learners } from '../../../api-calls';
import { Col, Row } from '../../../components/Grid';
import { BasicButton } from '../../../components/Button';
import TextWithIcon from '../../../components/TextWithIcon';
import StartCoaching from '../../../components/StartCoaching';
import Onboarding1 from '../Onboarding1';
import Modal from '../../../components/Modal';
import InfoCard from '../../../components/InfoCard';
import CoachTrainingChecklist from '../CoachTrainingChecklist';
import * as T from '../../../components/Typography';
import CardsSection from './CardsSection';

const Dashboard = () => {
  const [facilitator, setFacilitator] = useState({});
  const [learnersStuckAt, setLearnersStuckAt] = useState(false);
  const [learners, setLearners] = useState(false);
  const { user, logout } = useAuth();
  const [modalClicked, setModalClicked] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const storeModalIntoStorage = () => {
    localStorage.setItem('modal', JSON.stringify(true));
    setModalClicked(true);
  };

  useEffect(() => {
    const _modal = localStorage.getItem('modal');
    setModalClicked(_modal);
  }, [modalClicked]);

  useEffect(() => {
    const getLearner = async () => {
      const { data } = await Facilitators.getFacilitatorById({
        facilitatorId: user.facilitatorId,
      });

      const { data: stuckAtLearners } = await Coaches.getCoachStuckAtLearners();

      const { data: learnersList } = await Learners.getLearners();

      if (stuckAtLearners) setLearnersStuckAt(!!stuckAtLearners.length);

      if (learnersList) setLearners(!!learnersList.length);

      if (data) setFacilitator(data);
    };

    getLearner();
  }, [user.facilitatorId]);

  useEffect(() => {
    if (isModalVisible) {
      let root = document.getElementById('root');
      root?.setAttribute('aria-hidden', 'false');
    }
  }, [isModalVisible]);

  const handleCancel = () => {
    storeModalIntoStorage();
    setIsModalVisible(false);
  };

  const InfoTextCard =
    'Well done on completing your training 🎉 You’re now ready to take on a learner and we will be in touch soon.';

  if (user.id && !user.completedTrainingAt) return <Onboarding1 />;
  return (
    <>
      <Row>
        <Col w={[4, 8, 7]}>
          {' '}
          <T.H2 id="welcome-message" color="neutralMain">
            Welcome, {user.firstName}!
          </T.H2>
        </Col>
        {user.id && !user?.onboarded && (
          <Row>
            <Col w={[4, 8, 7]}>
              <CoachTrainingChecklist setIsModalVisible={setIsModalVisible} />
            </Col>
          </Row>
        )}
        {user.id && user?.onboarded && !learners && (
          <Row>
            <Col w={[4, 8, 7]}>
              <InfoCard mt={3} noIcon firstColumnLabel={InfoTextCard} />
            </Col>
          </Row>
        )}

        {learners && user?.onboarded && (
          <>
            <Col w={[4, 8, 7]} mt="4">
              <BasicButton
                variant="secondary"
                to={navRoutes.COACH.LEARNERS}
                id="view-learners"
              >
                <T.P color="white" weight="semi">
                  View your learners
                </T.P>
              </BasicButton>
            </Col>
            <Col w={[4, 8, 7]} mt="2">
              <BasicButton
                handleClick={() => {}}
                to={navRoutes.COACH.COLOURED_OVERLAY}
                variant="tertiary"
                iconMR="4px"
                ta="center"
              >
                Add a coloured overlay
              </BasicButton>
            </Col>
            <Col w={[4, 8, 7]} mt="6">
              <StartCoaching />
            </Col>
          </>
        )}
      </Row>

      <CardsSection onboarded={user?.onboarded} />

      <Row>
        <Col w={[4, 12, 12]} mt="6">
          <T.P weight="bold" color="neutralMain">
            Manager details
          </T.P>
        </Col>
        <Col w={[4, 12, 12]}>
          <T.P color="neutralMain" mt={2}>
            {facilitator.firstName} {facilitator.lastName}
          </T.P>
        </Col>
        <Col w={[4, 12, 12]}>
          <T.Link
            color="neutralMain"
            mt={1}
            to={`mailto:${facilitator.email}`}
            underline
            external
          >
            {facilitator.email}
          </T.Link>
        </Col>
      </Row>
      <Row mt="6">
        <Col w={[4, 4, 4]}>
          <TextWithIcon
            id="logout-button"
            icon="logout"
            text="Log out"
            weight="semi"
            handleClick={logout}
            iconProps={{ style: { marginRight: 4 } }}
            isButton
          />
        </Col>
      </Row>
      {modalClicked && learners && user?.onboarded && (
        <Modal visible={learnersStuckAt} setIsModalVisible={setLearnersStuckAt}>
          <Row>
            <Col w={[4, 12, 12]} mb={4}>
              <T.H2 color="neutralSurface" weight="bold">
                A learner is stuck
              </T.H2>
            </Col>
          </Row>

          <BasicButton
            to={navRoutes.COACH.LEARNERS_STUCK_AT}
            variant="secondary"
          >
            <T.P color="white" weight="bold">
              Check summary
            </T.P>
          </BasicButton>
        </Modal>
      )}

      <Modal
        visible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onCancel={handleCancel}
      >
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            <T.H2 color="neutralSurface" weight="bold" mb={3}>
              You’ve finished training! You’re now ready to take on a learner
              and we will be in touch soon.
            </T.H2>
            <T.P color="neutralSurface" weight="regular">
              This might take a while but if there’s anything else you need in
              the meantime you can always contact Shannon Trust at{' '}
              <T.Link
                to={'mailto:chloe.bradbury@shannontrust.com'}
                external
                color="white"
                hoverColor="neutralMain"
                underline
              >
                chloe.bradbury@shannontrust.com
              </T.Link>
            </T.P>
          </Col>
        </Row>

        <BasicButton
          handleClick={handleCancel}
          variant="secondary"
          id="back-to-dashboard"
          aria-label="back-to-dashboard"
        >
          <T.P color="white" weight="bold">
            Okay, got it!
          </T.P>
        </BasicButton>
      </Modal>
    </>
  );
};

export default Dashboard;
