import { fields, createSchema, validate as _validate } from '..';

const {
  requiredText,
  optionalText,
  interestsAtLeastOne,
  goalsArrayAtLeastOne,
  optionalArrayOfString,
} = fields;

const schema = createSchema({
  optimisticAboutFuture: requiredText,
  feelingUseful: requiredText,
  feelingRelaxed: requiredText,
  dealingWithProblems: requiredText,
  thinkingClearly: requiredText,
  feelingCloseToOtherPeople: requiredText,
  ableToMakeUpMind: requiredText,
  feelingConfident: requiredText,
  interestedInNewThings: requiredText,
  education: requiredText,
  employment: requiredText,
  confidentInReadingAbility: requiredText,
  ableToReadLetters: requiredText,
  communicateWellWithOthers: requiredText,
  firstLanguage: optionalText,
  ethnicity: optionalText,
  genders: optionalArrayOfString,
  interests: interestsAtLeastOne,
  personalGoals: goalsArrayAtLeastOne,
  hasDeviceInternetAccess: optionalText,
  usesDeviceType: optionalArrayOfString,
  confidentUsingTechnology: optionalText,
});

const validate = (data) => _validate(schema, data);

export default validate;
