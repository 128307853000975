import { useState } from 'react';
import { userRoles } from './../constants';
import { createContext, useReducer, useContext, useEffect } from 'react';
import { Users } from './../api-calls';
import { useAuth } from './auth';

const initialState = {
  headerSteps: {
    show: false,
    requiredSteps: null,
    completedSteps: null,
  },
  learnerUniqueId: '',
  selectedLearnerInfo: {},
  preferredVoice: 'MALE',
};

const GeneralStateContext = createContext({
  state: initialState,
  setState: () => {},
});

function reducer(state, newState) {
  return { ...state, ...newState };
}

const GeneralStateProvider = (props) => {
  const [state, setState] = useReducer(reducer, initialState);
  const [localStorageVoice, setLocalStorageVoice] = useState(
    localStorage.getItem('voice')
  );
  const { user } = useAuth();
  useEffect(() => {
    if (!localStorageVoice) {
      localStorage.setItem('voice', 'INIT');
      setLocalStorageVoice('INIT');
    }
  }, [localStorageVoice]);

  useEffect(() => {
    const getSelectedLearnerInfo = async () => {
      const { data } = await Users.getSelectedLearner();
      if (data) {
        setState({ selectedLearnerInfo: data });
      } else {
        setState({ selectedLearnerInfo: initialState });
      }
    };

    if (user?.id && user?.role === userRoles.COACH && user.selectedLearnerId) {
      getSelectedLearnerInfo();
    }
  }, [user.id, user.role, user.selectedLearnerId]);

  const value = {
    state: {
      ...state,
      preferredVoice:
        localStorageVoice === 'MALE' || localStorageVoice === 'FEMALE'
          ? localStorageVoice
          : state.selectedLearnerInfo?.preferredVoice ||
            user?.preferredVoice ||
            state.preferredVoice,
    },
    setState: setState,
  };

  return <GeneralStateContext.Provider value={value} {...props} />;
};

const useGeneralState = () => {
  const value = useContext(GeneralStateContext);
  return value;
};

export { GeneralStateProvider, useGeneralState };
export default GeneralStateContext;
