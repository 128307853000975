import { Modal, Grid, Icon } from '../../../../components';
import { BasicButton, PlayButton } from '../../../../components/Button';
import { audio } from '../../../../constants';
import { getMediaUrl } from '../../../../helpers';
import { useGeneralState } from '../../../../context/general-state';

const { Col, Row } = Grid;
const ContinueSolving = ({
  isModalVisible,
  setIsModalVisible,
  onComplete,
  onAddMore,
}) => {
  const { state: generalState } = useGeneralState();

  return (
    <Modal
      bgColor="primaryMain"
      visible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
    >
      <Row>
        <Col w={[4, 12, 12]} jc="center">
          <Icon icon="like" color="white" width="96" height="96" mt="5" />
        </Col>
        <Col w={[4, 12, 12]} jc="center" mt="2">
          <PlayButton
            audioUrl={getMediaUrl(
              audio.doYouWantToAddMoreWords,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: '32px', height: '32px' }}
            padding="12px"
            color="white"
          >
            That was great! Do you want to add more words or complete?
          </PlayButton>
        </Col>
        <Col w={[4, 6, 6]} mt={5}>
          <BasicButton
            handleClick={onAddMore}
            icon="plus"
            variant="tertiary"
            iconProps={{ width: '25', height: '25', color: 'neutralMain' }}
            bgColor="neutralSurface"
            customColor="neutralMain"
            style={{ paddingRight: '10px', paddingLeft: '10px' }}
          >
            Add more
          </BasicButton>
        </Col>
        <Col w={[4, 6, 6]} mt={5} mtM={2}>
          <BasicButton
            handleClick={onComplete}
            icon="next"
            variant="secondary"
            iconProps={{ width: '20', height: '20' }}
          >
            Complete
          </BasicButton>
        </Col>
      </Row>
    </Modal>
  );
};

export default ContinueSolving;
