import { useState } from 'react';
import { useParams } from 'react-router-dom';

import * as T from '../../../../components/Typography';
import { PlayButton, BasicButton } from '../../../../components/Button';
import { Markdown } from '../../../../components';
import { createH1 } from '../../../../utils/markdown';
import { navRoutes } from '../../../../constants';

import InputsWrapper from './InputsWrapper';
import * as S from './style';

const TypeEndings = ({
  data: { title, textAudio, textAudioUrl, correctOptions } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  selfLearning,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const { exerciseId } = useParams();
  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {textAudio && textAudioUrl ? (
          <S.TextAudioWrapper mt={1} mb={5}>
            <PlayButton
              audioUrl={textAudioUrl}
              width="56px"
              height="56px"
              iconProps={{ width: '32px', height: '32px' }}
            />
            <T.P ml={4} weight="regular" size="med">
              {textAudio}
            </T.P>
          </S.TextAudioWrapper>
        ) : null}
        {title ? (
          <Markdown
            text={createH1(title)}
            customStyles={{
              h1: {
                size: 'extraLarge',
                ta: 'center',
                weight: 'bold',
                mb: 0,
                color: 'neutralMain',
              },
            }}
          />
        ) : null}
        {correctOptions && (
          <InputsWrapper
            correctOptions={correctOptions}
            enableNext={enableNext}
            setEnableNext={setEnableNext}
          />
        )}
      </S.ContentWrapper>
      <S.ButtonsWrapper>
        <S.ButtonsContainer>
          <S.ButtonWrapper>
            {stuckAtReview ? (
              <BasicButton handleClick={handleNext} variant="primary">
                <T.P color="white" weight="semi">
                  Complete review
                </T.P>
              </BasicButton>
            ) : !preview ? (
              <BasicButton
                disabled={disabled || !enableNext}
                handleClick={handleNext}
                icon="next"
                variant="primary"
              >
                <T.P color="white" weight="semi">
                  Next
                </T.P>
              </BasicButton>
            ) : (
              <S.ButtonWrapper mt={5}>
                <BasicButton
                  icon="back"
                  variant="primary"
                  to={(selfLearning
                    ? navRoutes.EXERCISES.EXERCISE_EXPLAINER_SL
                    : navRoutes.EXERCISES.EXERCISE_EXPLAINER
                  ).replace(':exerciseId', exerciseId)}
                >
                  <T.P color="white" weight="semi">
                    Back to exercise
                  </T.P>
                </BasicButton>
              </S.ButtonWrapper>
            )}
          </S.ButtonWrapper>
        </S.ButtonsContainer>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default TypeEndings;
