import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InfoCardWithButton } from '../../../components/InfoCard';
import LearnerStats from '../../../components/LearnerStats';
// import Icon from '../../../components/Icon';
import { useAuth } from '../../../context/auth';
import { Badges } from '../../../api-calls';

import { Grid, Typography as T, Loading } from '../../../components';
import * as S from './style';
import { Learners } from '../../../api-calls';

import { navRoutes, navRoutes as R } from '../../../constants';
import BadgesList from 'components/BadgesList';

import StartCoachingButton from './StartCoachingButton';

const { Row, Col } = Grid;

const LearnerProfile = () => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});
  const [learnersStuckAt, setLearnersStuckAt] = useState([]);
  const [learner, setLearner] = useState({});
  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams();

  const { data: badges, isFetched: badgesLoaded } = Badges.useGetBadges({
    type: 'all',
  });

  useEffect(() => {
    let mount = true;
    async function fetchData() {
      setLoading(true);

      const { data } = await Learners.getLearnerStats();

      if (mount) {
        if (data) {
          setStats(data);
        }
        setLoading(false);
      }
    }

    const getLearnerStuckAt = async () => {
      setLoading(true);

      const { data, error } = await Learners.getLearnerStuckAt();
      if (error) {
        return navigate(R.GENERAL.NOT_FOUND);
      }

      if (mount) {
        if (data) {
          setLearnersStuckAt(data);
        }
        setLoading(false);
      }
    };

    const getLearner = async () => {
      setLoading(true);

      const { data, error } = await Learners.getLearnerUniqueId({
        learnerUserId: id || user.selectedLearnerId,
      });

      if (error) {
        return navigate(R.GENERAL.NOT_FOUND);
      }

      if (mount) {
        if (data) {
          setLearner(data);
        }
        setLoading(false);
      }
    };

    getLearner();
    getLearnerStuckAt();
    fetchData();

    return () => {
      mount = false;
    };
  }, [id, navigate, user.selectedLearnerId]);

  if (loading) return <Loading />;
  return (
    <S.Wrapper>
      <S.StatsWrapper>
        <LearnerStats stats={stats} learnerId={user.selectedLearnerId} />
      </S.StatsWrapper>
      <Row mt="6" ai="center">
        <Col w={[3, 9, 9]}>
          <T.H2>Exercises {learner.firstName} is finding difficult</T.H2>
        </Col>
      </Row>
      {learnersStuckAt.length ? (
        learnersStuckAt.map((learner) => {
          return (
            <Row key={learner.stepId}>
              <Col w={[4, 6, 8]} mt="6" mb="2">
                <InfoCardWithButton
                  firstColumnLabel="Stuck on"
                  firstColumnValue={
                    learner?.stepData?.title?.toUpperCase() +
                    ', ' +
                    learner.title
                  }
                  handleClick={async () => {
                    navigate(
                      R.COACH.REVIEW_STUCK_AT.replace(
                        ':exerciseId',
                        learner.exerciseId
                      ).replace(':stepId', learner.stepId),
                      {
                        state: {
                          type: learner.stepType,
                          to: navRoutes.COACH.LEARNER_PROFILE.replace(
                            ':id',
                            id || user.selectedLearnerId
                          ),
                        },
                      }
                    );
                  }}
                />
              </Col>
            </Row>
          );
        })
      ) : (
        <Row>
          <Col w={[4, 6, 6]} mt={5}>
            <InfoCardWithButton />
          </Col>
        </Row>
      )}

      {badgesLoaded && (
        <>
          {/* badges */}
          <Row mt="6" mb="3" ai="center">
            <Col w={[3, 9, 9]}>
              <T.H2>Badges</T.H2>
            </Col>
          </Row>

          <BadgesList badges={badges} />
        </>
      )}
      {/* <Row mt="6" ai="center">
        <Col w={[4, 6, 8]}>
          <T.H2>Call learner</T.H2>
          <T.P>
            To protect everyone’s identity your phone number and the learner’s
            won’t be revealed by making this call{' '}
          </T.P>
        </Col>
        <Col w={[3, 9, 9]}>
          <S.CallLearnerWrapper>
            <S.IconWrapper>
              <Icon icon={'phone'} />
            </S.IconWrapper>
            <T.P weight="bold" ml={3} color="neutralMain">
              Call learner
            </T.P>
          </S.CallLearnerWrapper>
        </Col>
      </Row> */}
      <Row mt="6" ai="center">
        <Col w={[4, 6, 8]}>
          <StartCoachingButton
            id={id}
            user={user}
            learner={learner}
            learnerUniqueId={learner.learnerUniqueId}
            margin={'32px'}
          />
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default LearnerProfile;
