import WithText from '../../../components/Button/WithText';
import * as S from './style';

const AssignColumn = ({ handleClick }) => (
  <S.NameColumnWrapper>
    <WithText
      text="Assign"
      icon="userWithArrow"
      width={'30px'}
      height={'30px'}
      handleClick={handleClick}
    />
  </S.NameColumnWrapper>
);

export default AssignColumn;
