import { useReducer } from 'react';

import * as T from '../../../../components/Typography';
import { BasicInput } from '../../../../components/Inputs';
import * as S from './style';
import { Icon } from '../../../../components';
import { string, number, array, object } from 'yup';

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const getInitialState = (data) => ({
  form: data
    ? {
        ...data,
        options: data?.options?.map((e, i) => ({ ...e, id: i })) || [
          { id: 0, option: '' },
        ],
        correctOptions: data?.correctOptions?.map((e, i) => ({
          ...e,
          id: i,
        })) || [{ id: 0, option: '' }],
      }
    : {
        options: [{ id: 0, option: '' }],
        audioUrl: '',
        correctOptions: [{ id: 0, option: '' }],
      },
  httpError: '',
  validationErrs: {},
  loading: false,
  submitLoading: false,
});

const WordJoin1 = ({ data, onChange, errors }) => {
  const [state, setState] = useReducer(reducer, getInitialState(data));

  const setFormData = (data) =>
    setState((prevState) => {
      const _correctOptions =
        data?.correctOptions || prevState?.form?.correctOptions;
      const form = {
        ...prevState.form,
        ...data,
      };
      onChange({
        ...form,
        correctOptions: _correctOptions,
      });
      return { form };
    });

  const addOption = () => {
    const maxId = Math.max(...state.form.options.map((e) => e.id), 0);
    setFormData({
      options: [...state.form.options, { id: maxId + 1, option: '' }],
    });
  };
  const removeOption = (id) => {
    setFormData({
      options: state.form.options.filter((e) => e.id !== id),
    });
  };

  const addCorrectOptions = () => {
    const maxId = Math.max(...state.form.correctOptions.map((e) => e.id), 0);
    setFormData({
      correctOptions: [
        ...state.form.correctOptions,
        { id: maxId + 1, option: '' },
      ],
    });
  };
  const removeCorrectOptions = (id) => {
    setFormData({
      correctOptions: state.form.correctOptions.filter((e) => e.id !== id),
    });
  };
  const onOptionChange = (value, id) => {
    setFormData({
      options: state.form.options.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  const onCorrectOptionsTextChange = (value, id) => {
    setFormData({
      correctOptions: state.form.correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, option: value };
      }),
    });
  };

  return (
    <div>
      <T.P mt={8} size="large" weight="bold">
        Options
      </T.P>
      {errors?.options && typeof errors.options === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.options}
        </T.P>
      )}
      {state.form.options.map((e, i) => {
        return (
          <>
            <BasicInput
              label={`Option ${i + 1}`}
              value={e.option}
              handleChange={(value) => {
                onOptionChange(value, e.id);
              }}
              m={{ mt: 5 }}
              helper='single word e.g. "stand" / "up" ...'
              error={errors?.options && errors?.options[i]?.option}
            />

            {state.form.options.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        disabled={state.form.options.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Option
      </S.Button>
      <T.P mt={8} size="large" weight="bold">
        Correct words
      </T.P>

      {errors?.correctOptions && typeof errors.correctOptions === 'string' && (
        <T.P mb={2} mt={2} color="error">
          {errors.correctOptions}
        </T.P>
      )}

      {state.form.correctOptions.map((e, i) => {
        return (
          <>
            <BasicInput
              label={`Word ${i + 1}`}
              value={e.option}
              handleChange={(value) => {
                onCorrectOptionsTextChange(value, e.id);
              }}
              m={{ mb: 2, mt: 5 }}
              helper='split words with "-" e.g. stand-up'
              error={
                errors?.correctOptions && errors?.correctOptions[i]?.option
              }
            />

            {state.form.correctOptions.length === 1 && i === 0 ? null : (
              <S.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeCorrectOptions(e.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </S.Button>
            )}
          </>
        );
      })}
      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addCorrectOptions}
        disabled={state.form.correctOptions.find((e) => !e.option)}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add correct word
      </S.Button>
    </div>
  );
};

const validationSchema = {
  options: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string().required('required field'),
        })
        .required()
    )
    .required('You must provide options')
    .min(1, 'You must provide options'),
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().required(),
          option: string()
            .required('required field')
            .test('shouldIncludeDash', 'Should be split by "-"', (val) => {
              return val?.split('-')?.length === 2;
            }),
        })
        .required()
    )
    .required('You must provide correct words')
    .min(1, 'You must provide correct words'),
};
export { validationSchema };

export default WordJoin1;
