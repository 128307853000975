import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import { Loading } from '../../../components';
import * as T from '../../../components/Typography';
import Modal from './../../../components/Modal';
import * as S from './style';
import Notes from './Notes';
import theme from '../../../theme';
import { FACILITATOR } from '../../../constants/nav-routes';
import {
  coachStatusesQuery,
  coachApprovalStatuses,
  userRoles,
} from '../../../constants';
import { ProfileStats } from '../../../components';
import {
  useGetFacilitatorCoachNotes,
  useUpdateCoachStatus,
} from '../../../api-calls/facilitator.queries';
import { useGetCoachDetails } from '../../../api-calls/coaches.queries';
import { useDeleteUser } from '../../../api-calls/users.queries';

import CoachLearnersSection from './CoachLearnersSection';

const ApproveModal = ({
  isModalVisible,
  setIsModalVisible,
  handleApproval,
}) => (
  <Modal visible={isModalVisible} setIsModalVisible={setIsModalVisible}>
    <Row>
      <Col w={[4, 12, 12]} jc="center">
        <T.P size="med" weight="bold" color="white">
          Are you sure that you want to approve this application?
        </T.P>
      </Col>
      <>
        <Col w={[4, 12, 12]} mt={4}>
          <BasicButton onClick={handleApproval} variant="secondary">
            Yes, let's approve it
          </BasicButton>
        </Col>
      </>
    </Row>
  </Modal>
);
const CoachProfile = () => {
  const navigate = useNavigate();
  const { coachId, statusType } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false);
  const [deleteSuccessModalVisible, setDeleteSuccessModalVisible] =
    useState(false);

  const {
    coach,
    error: getCoachError,
    isLoading: getCoachLoading,
  } = useGetCoachDetails({
    coachId,
  });

  const {
    notes = [],
    error: getCoachNotesError,
    isLoading: getCoachNotesLoading,
  } = useGetFacilitatorCoachNotes({ coachId });

  const { mutate: approveCoach, error: approveCoachHttpError } =
    useUpdateCoachStatus({
      coachId,
      status: 'approved',
    });

  const {
    mutateAsync: deleteUser,
    isLoading: deleteUserLoading,
    error: deleteUserError,
  } = useDeleteUser({
    id: coachId,
    role: userRoles.COACH,
  });

  const handleDeleteUser = () => {
    deleteUser(
      {
        id: coachId,
        role: userRoles.COACH,
      },
      {
        onSuccess: () => {
          setDeleteSuccessModalVisible(true);
        },
      }
    );
  };

  if (getCoachLoading || getCoachNotesLoading) {
    return <Loading />;
  }

  if (getCoachError || getCoachNotesError || !coach) {
    return (
      <T.P mb={2} mt={2} color="error">
        {getCoachError || getCoachNotesError}
      </T.P>
    );
  }

  if (coach?.deleted) {
    return (
      <T.H1 color="neutralMain" weight="bold" mt={1}>
        This user was deleted.
      </T.H1>
    );
  }

  const handleApproval = async () => {
    approveCoach(
      {
        coachId,
        status: coachApprovalStatuses.APPROVED,
      },
      {
        onSuccess: ({ data }) => {
          navigate(
            FACILITATOR.APPROVE_COACH.replace(':statusType', statusType)
              .replace(':coachId', coachId)
              .replace(':token', data?.token)
          );
        },
      }
    );
  };

  const handleDetailsClick = () => {
    navigate(
      FACILITATOR.COACH_DETAILS.replace(':statusType', statusType).replace(
        ':coachId',
        coachId
      )
    );
  };

  const isApprovedCoach =
    coach?.approvalStatus === coachApprovalStatuses.APPROVED;
  const isRejectedCoach =
    coach?.approvalStatus === coachApprovalStatuses.REJECTED;

  return (
    <S.Wrapper>
      <ApproveModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleApproval={handleApproval}
      />
      <Row key={'coach-details'}>
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            <T.H1>{coach?.firstName}'s profile</T.H1>
          </Col>
        </Row>
        {statusType === coachStatusesQuery.ALL ? (
          <>
            <ProfileStats type="noOfLearners" learners={coach.totalLearners} />
            <ProfileStats
              type="coachingOnTheApp"
              hours={coach.totalCoachingTime.hours}
              days={coach.totalCoachingTime.days}
              months={coach.totalCoachingTime.months}
            />
            <ProfileStats
              type="dateOfLastSessionWithCoach"
              date={coach.createdAt}
              backgroundColor={theme.colors.quaternaryMain85}
              text="date they signed up"
            />
            <ProfileStats
              type="dateOfLastSessionWithCoach"
              date={coach.completedTrainingAt}
              backgroundColor={theme.colors.primaryMain}
              text="date they have completed training"
            />
            <Row>
              <Col w={[4, 12, 12]} mt={7}>
                <T.H2>{coach?.firstName}'s learners</T.H2>
              </Col>
            </Row>
            <Row>
              <CoachLearnersSection coachId={coachId} />
            </Row>
          </>
        ) : null}
        <Col w={[4, 12, 12]} mt="7" key={`column-details`}>
          <S.ColWrapper>
            <T.H2>Details</T.H2>
            <T.P mt="2">Here you can view the coach’s submission form.</T.P>
            <BasicButton
              variant="tertiary"
              mt="5"
              style={{ width: '300px' }}
              customColor={'primaryMain'}
              handleClick={handleDetailsClick}
              to={''}
            >
              View Details
            </BasicButton>
          </S.ColWrapper>
        </Col>
        {/* Manage coach section for new coaches */}
        <Col w={[4, 8, 8]} mt="7" key={`column-notes`}>
          <S.ColWrapper>
            {/* if coach was approved but has not signed up yet -> render message */}
            {isApprovedCoach && coach?.token ? (
              <>
                <T.H2 weight="regular">Application Status</T.H2>
                <T.P mt="3">
                  The coach was approved but has not signed up yet.
                </T.P>
              </>
            ) : null}
            {/* if coach has not yet been approved / rejected -> all options */}
            {!isApprovedCoach && !isRejectedCoach ? (
              <>
                <T.H2 weight="regular">Manage Application Status</T.H2>
                {/* Interview section */}
                {coach?.approvalStatus ===
                coachApprovalStatuses.INVITED_TO_INTERVIEW ? (
                  <>
                    <T.P mt="3">
                      <strong>
                        Coach invited to interview: <br />
                      </strong>
                    </T.P>
                    {coach?.interviewTime ? (
                      <T.P mt="3">
                        {moment(coach.interviewTime).format('YYYY-MM-DD')}
                      </T.P>
                    ) : (
                      <T.P>
                        Please remember to change the table with the date of the
                        interview once it’s booked in by the applicant.
                      </T.P>
                    )}
                  </>
                ) : (
                  <BasicButton
                    variant="primary"
                    mt="5"
                    style={{ width: '300px' }}
                    to={FACILITATOR.MANAGE_COACH_APPLICATION.replace(
                      ':coachId',
                      coachId
                    ).replace(':action', 'interview')}
                  >
                    Offer an Interview
                  </BasicButton>
                )}
                {/* Approve button */}
                <BasicButton
                  variant="primary"
                  mt="5"
                  style={{ width: '300px' }}
                  onClick={() => setIsModalVisible(true)}
                >
                  Approve application
                </BasicButton>

                {/* Reject button */}
                <BasicButton
                  variant="primary"
                  mt="5"
                  style={{ width: '300px' }}
                  to={FACILITATOR.MANAGE_COACH_APPLICATION.replace(
                    ':statusType',
                    statusType
                  )
                    .replace(':coachId', coachId)
                    .replace(':action', 'reject')}
                >
                  Reject application
                </BasicButton>
              </>
            ) : null}
          </S.ColWrapper>
        </Col>
        <Notes coachId={coachId} notes={notes} statusType={statusType} />
        {approveCoachHttpError ? (
          <T.P color="error">{approveCoachHttpError?.message}</T.P>
        ) : null}
      </Row>

      {/* DELETE ACCOUNT */}
      <Row>
        <Col w={[4, 12, 12]} mt={7} display="block">
          <T.H2 color="neutralMain" weight="bold">
            Delete account
          </T.H2>
          <T.P color="neutralMain" mt={3}>
            This will delete all personal data related to this user. Please note
            this cannot be undone.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 6]} mt={5}>
          {!isApprovedCoach && !isRejectedCoach ? (
            <T.P>
              To delete their personal data please manage their application
              status first (reject / approve)
            </T.P>
          ) : (
            <BasicButton
              variant="primary"
              onClick={() => setDeleteUserModalVisible(true)}
            >
              <T.P color="white" weight="bold">
                Delete
              </T.P>
            </BasicButton>
          )}
        </Col>
      </Row>
      <Modal visible={deleteUserModalVisible}>
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            {!deleteUserError ? (
              <T.H3 color="neutralSurface">
                Are you sure you want to delete all personal data related to
                this user?
              </T.H3>
            ) : (
              <T.H3 color="error">
                There was an error deleting this account. Please try again
                later.
              </T.H3>
            )}
          </Col>
        </Row>
        <BasicButton
          variant="secondary"
          loading={deleteUserLoading}
          disabled={deleteUserLoading || deleteUserError}
          handleClick={handleDeleteUser}
        >
          <T.P color="white" weight="bold">
            Yes, I'm sure
          </T.P>
        </BasicButton>
        <BasicButton
          mt={3}
          to={'#'}
          variant="tertiary"
          loading={deleteUserLoading}
          handleClick={() => setDeleteSuccessModalVisible(false)}
        >
          No, go back
        </BasicButton>
      </Modal>
      {/* SUCCESS DELETED MODAL */}
      <Modal visible={deleteSuccessModalVisible}>
        <S.ModalContent>
          <T.H1 mb={3} color="neutralSurface">
            Done! 🎉
          </T.H1>
          <T.P mb={3} color="neutralSurface">
            All personal data related to this user was deleted.
          </T.P>
          <BasicButton
            to={FACILITATOR.DASHBOARD}
            linkState={{ refetch: userRoles.COACH }}
            variant="secondary"
          >
            <T.P color="white" weight="bold">
              Return to dashboard
            </T.P>
          </BasicButton>
        </S.ModalContent>
      </Modal>
    </S.Wrapper>
  );
};

export default CoachProfile;
