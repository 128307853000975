import {
  ethnicityTypesCategorised,
  genderTypes,
} from '../../../constants/data-types';
import Films from '../../../components/assets/Films.jpg';
import Music from '../../../components/assets/Music.jpg';
import Sports from '../../../components/assets/Sports.jpg';
import Food from '../../../components/assets/Food.png';
import LifeStories from '../../../components/assets/LifeStories.jpg';
import History from '../../../components/assets/History.jpg';
import GoalOptions1 from '../../../components/assets/GoalOptions1.png';
import GoalOptions2 from '../../../components/assets/GoalOptions2.jpg';
import GoalOptions3 from '../../../components/assets/GoalOptions3.png';
import GoalOptions4 from '../../../components/assets/GoalOptions4.png';
import GoalOptions5 from '../../../components/assets/GoalOptions5.png';
import GoalOptions6 from '../../../components/assets/GoalOptions6.png';
import GoalOptions7 from '../../../components/assets/GoalOptions7.png';
import GoalOptions8 from '../../../components/assets/GoalOptions8.png';

export const questionsArray = [
  {
    question: 'I’ve been feeling optimistic about the future',
    key: 'optimisticAboutFuture',
    options: [
      { label: 'None of the time', value: 1 },
      { label: 'Rarely', value: 2 },
      { label: 'Some of the time', value: 3 },
      { label: 'Often', value: 4 },
      { label: 'All of the time', value: 5 },
    ],
    type: 'select',
    showOnProgressReport: true,
  },
  {
    question: 'I’ve been feeling useful',
    key: 'feelingUseful',
    options: [
      { label: 'None of the time', value: 1 },
      { label: 'Rarely', value: 2 },
      { label: 'Some of the time', value: 3 },
      { label: 'Often', value: 4 },
      { label: 'All of the time', value: 5 },
    ],
    type: 'select',
    showOnProgressReport: true,
  },
  {
    question: 'I’ve been feeling relaxed',
    key: 'feelingRelaxed',
    options: [
      { label: 'None of the time', value: 1 },
      { label: 'Rarely', value: 2 },
      { label: 'Some of the time', value: 3 },
      { label: 'Often', value: 4 },
      { label: 'All of the time', value: 5 },
    ],
    type: 'select',
    showOnProgressReport: true,
  },
  {
    question: 'I’ve been dealing with problems well',
    key: 'dealingWithProblems',
    options: [
      { label: 'None of the time', value: 1 },
      { label: 'Rarely', value: 2 },
      { label: 'Some of the time', value: 3 },
      { label: 'Often', value: 4 },
      { label: 'All of the time', value: 5 },
    ],
    type: 'select',
    showOnProgressReport: true,
  },
  {
    question: 'I’ve been thinking clearly',
    key: 'thinkingClearly',
    options: [
      { label: 'None of the time', value: 1 },
      { label: 'Rarely', value: 2 },
      { label: 'Some of the time', value: 3 },
      { label: 'Often', value: 4 },
      { label: 'All of the time', value: 5 },
    ],
    type: 'select',
    showOnProgressReport: true,
  },
  {
    question: 'I’ve been feeling close to other people',
    key: 'feelingCloseToOtherPeople',
    options: [
      { label: 'None of the time', value: 1 },
      { label: 'Rarely', value: 2 },
      { label: 'Some of the time', value: 3 },
      { label: 'Often', value: 4 },
      { label: 'All of the time', value: 5 },
    ],
    type: 'select',
    showOnProgressReport: true,
  },
  {
    question: 'I’ve been able to make up my own mind about things',
    key: 'ableToMakeUpMind',
    options: [
      { label: 'None of the time', value: 1 },
      { label: 'Rarely', value: 2 },
      { label: 'Some of the time', value: 3 },
      { label: 'Often', value: 4 },
      { label: 'All of the time', value: 5 },
    ],
    type: 'select',
    showOnProgressReport: true,
  },
  {
    question: 'I’ve been feeling confident',
    key: 'feelingConfident',
    options: [
      { label: 'None of the time', value: 1 },
      { label: 'Rarely', value: 2 },
      { label: 'Some of the time', value: 3 },
      { label: 'Often', value: 4 },
      { label: 'All of the time', value: 5 },
    ],
    type: 'select',
    showOnProgressReport: true,
  },
  {
    question: 'I’ve been interested in new things',
    key: 'interestedInNewThings',
    options: [
      { label: 'None of the time', value: 1 },
      { label: 'Rarely', value: 2 },
      { label: 'Some of the time', value: 3 },
      { label: 'Often', value: 4 },
      { label: 'All of the time', value: 5 },
    ],
    type: 'select',
    showOnProgressReport: true,
  },
  {
    question: 'How confident do you feel in your reading ability? ',
    key: 'confidentInReadingAbility',
    type: 'slider',
    note: 'On a scale of 1 to 10, with 1 being the lowest and 10 being the highest',
    showOnProgressReport: true,
  },
  {
    question:
      'How well do you feel you are able to read letters and complete forms? ',
    key: 'ableToReadLetters',
    type: 'slider',
    note: 'On a scale of 1 to 10, with 1 being the lowest and 10 being the highest',
    showOnProgressReport: true,
  },
  {
    question: 'How well do you think you communicate with others? ',
    key: 'communicateWellWithOthers',
    type: 'slider',
    note: 'On a scale of 1 to 10, with 1 being the lowest and 10 being the highest',
    showOnProgressReport: true,
  },
  {
    question:
      'How happy are you with the progress you’ve made so far with Turning Pages?',
    key: 'happyWithProgress',
    type: 'slider',
    note: 'On a scale of 1 to 10, with 1 being the lowest and 10 being the highest',
    showOnProgressReport: true,
  },
  {
    question: 'Are you currently engaged in any other education or training? ',
    key: 'education',
    options: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'Not yet but plan to be', value: 'NOT_YET_BUT_PLAN_TO_BE' },
    ],
    type: 'yesNo',
    showOnProgressReport: true,
  },
  {
    question: 'Are you currently in employment?  ',
    key: 'employment',
    options: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'Not yet but plan to be', value: 'NOT_YET_BUT_PLAN_TO_BE' },
    ],
    type: 'yesNo',
    showOnProgressReport: true,
  },
  {
    question: 'Is English your first language?',
    key: 'firstLanguage',
    options: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
    ],
    type: 'yesNo',
    skip: 'true',
    secQuestion: 'If no, what is your first language?',
    placeholder: 'First language...',
    showOnProgressReport: false,
  },
  {
    question:
      'Choose one option that best describes your ethnic group or background',
    key: 'ethnicity',
    options: ethnicityTypesCategorised,
    type: 'dropdown',
    label: 'Ethnic background',
    skip: 'true',
    secQuestion: 'Any other ethnic group?',
    placeholder: 'Ethnic background...',
    showOnProgressReport: false,
  },
  {
    question: 'Choose the options that best describe your gender',
    key: 'genders',
    options: Object.values(genderTypes).map((e) => ({ label: e, value: e })),
    type: 'multiDropdown',
    // label: 'Gender',
    skip: 'true',
    showOnProgressReport: false,
  },
  {
    question:
      'Do you have access to an internet enabled device? (eg. mobile phone, computer or tablet)',
    key: 'hasDeviceInternetAccess',
    options: [
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' },
      { label: 'I don’t know', value: 'I_DONT_KNOW' },
    ],
    type: 'radio',
    skip: 'true',
    showOnProgressReport: false,
  },
  {
    question:
      'What device will you be using Turning Pages on? (please select all that apply)',
    key: 'usesDeviceType',
    options: [
      'My phone',
      'My computer',
      'My tablet',
      'A public computer',
      'My friend or family member’s phone',
      'My friend or family member’s computer or tablet',
      'My coach’s device',
      'I don’t know',
      'I would prefer not to say',
      'Other – please specify',
    ].map((e) => ({ label: e, value: e })),
    type: 'multiDropdown',
    skip: 'true',
    showOnProgressReport: false,
  },
  {
    question: 'How confident are you using technology?',
    key: 'confidentUsingTechnology',
    options: [
      'Not confident at all',
      'Not very confident',
      'Neither',
      'Fairly confident',
      'Very confident',
      'I would prefer not to say',
    ].map((e) => ({ label: e, value: e })),
    type: 'dropdown',
    skip: 'true',
    showOnProgressReport: false,
  },
  {
    question: 'What personal goals would you like to set?',
    key: 'personalGoals',
    options: [
      {
        label: 'Read a book to my child',
        value: 'Read a book to my child',
        imageUrl: GoalOptions1,
      },
      {
        label: 'Read a newspaper',
        value: 'Read a newspaper',
        imageUrl: GoalOptions2,
      },
      {
        label: 'Fill in a form',
        value: 'Fill in a form',
        imageUrl: GoalOptions3,
      },
      {
        label: 'Read a letter from my family',
        value: 'Read a letter from my family',
        imageUrl: GoalOptions4,
      },
      {
        label: 'Read paperwork',
        value: 'Read paperwork',
        imageUrl: GoalOptions5,
      },
      {
        label: 'Build my confidence',
        value: 'Build my confidence',
        imageUrl: GoalOptions6,
      },
      {
        label: 'Gain a job',
        value: 'Gain a job',
        imageUrl: GoalOptions7,
      },
      {
        label: 'Continue learning',
        value: 'Continue learning',
        imageUrl: GoalOptions7,
      },
      {
        label: 'Write a text message',
        value: 'Write a text message',
        imageUrl: GoalOptions8,
      },
    ],
    type: 'imageWithText',
    secQuestion: 'Something else? Please write it down',
    placeholder: 'A personal goal...',
    showOnProgressReport: false,
  },
  {
    question:
      'As you progress, books will be recommended. What are your interests?',
    key: 'interests',
    options: [
      {
        label: 'Films',
        value: 'FILMS',
        imageUrl: Films,
      },
      {
        label: 'Music',
        value: 'MUSIC',
        imageUrl: Music,
      },
      {
        label: 'Sports',
        value: 'SPORTS',
        imageUrl: Sports,
      },
      {
        label: 'Food',
        value: 'FOOD',
        imageUrl: Food,
      },
      {
        label: 'Life stories',
        value: 'LIFE_STORIES',
        imageUrl: LifeStories,
      },
      {
        label: 'History',
        value: 'HISTORY',
        imageUrl: History,
      },
    ],
    type: 'imageSelect',
    showOnProgressReport: false,
  },
];

export const options = [
  {
    label: 'Read a book to my child',
    value: 'Read a book to my child',
    imageUrl: GoalOptions1,
  },
  {
    label: 'Read a newspaper',
    value: 'Read a newspaper',
    imageUrl: GoalOptions2,
  },
  {
    label: 'Fill in a form',
    value: 'Fill in a form',
    imageUrl: GoalOptions3,
  },
  {
    label: 'Read a letter from my family',
    value: 'Read a letter from my family',
    imageUrl: GoalOptions4,
  },
  {
    label: 'Read paperwork',
    value: 'Read paperwork',
    imageUrl: GoalOptions5,
  },
  {
    label: 'Build my confidence',
    value: 'Build my confidence',
    imageUrl: GoalOptions6,
  },
  {
    label: 'Gain a job',
    value: 'Gain a job',
    imageUrl: GoalOptions7,
  },
  {
    label: 'Continue learning',
    value: 'Continue learning',
    imageUrl: GoalOptions7,
  },
  {
    label: 'Write a text message',
    value: 'Write a text message',
    imageUrl: GoalOptions8,
  },
];
