import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { navRoutes } from '../../../constants';
import { useAuth } from '../../../context/auth';
import { Facilitators } from '../../../api-calls';
import {
  Typography as T,
  Button,
  IconWithParagraph,
  Grid,
} from '../../../components';
import { useCoachConfirmsLearner } from '../../../api-calls/learners.queries';
import * as S from './style';

const { Row, Col } = Grid;

const ConfirmLearner = () => {
  const [facilitator, setFacilitator] = useState({});

  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();

  const {
    mutate: confirmLearner,
    error: confirmLearnerHttpError,
    isLoading,
  } = useCoachConfirmsLearner({
    learnerId: id,
    confirm: true,
  });

  const handleConfirm = () => {
    confirmLearner(
      {
        learnerId: id,
        confirm: true,
      },
      {
        onSuccess: () => {
          navigate(navRoutes.COACH.DASHBOARD);
        },
      }
    );
  };

  useEffect(() => {
    const getFacilitator = async () => {
      const { data } = await Facilitators.getFacilitatorById({
        facilitatorId: user.facilitatorId,
      });

      if (data) setFacilitator(data);
    };

    getFacilitator();
  }, [user.facilitatorId]);
  return (
    <>
      <Row>
        <Col w={[4, 11, 8]}>
          <T.H2 weight="bold" color="neutralMain">
            Thank you
          </T.H2>
        </Col>
        <Col w={[4, 11, 8]}>
          <T.P color="neutralMain" mt="22px">
            Your coaching is making a huge difference.
          </T.P>
        </Col>
        <Col w={[4, 11, 8]}>
          <T.P weight="bold" color="neutralMain" mt="22px">
            Here are your next steps:
          </T.P>
        </Col>
        <Col w={[4, 11, 8]}>
          <IconWithParagraph icon="smallTick" ai="flex-start" mt="4">
            <T.P>
              Set up an introductory meeting to get to know your learner. Here
              are your manager’s details to help you set this up:
            </T.P>
            <T.P weight="bold" color="neutralMain" mt="4">
              Manager details
            </T.P>
            <T.P color="neutralMain" mt="2">
              {facilitator.firstName} {facilitator.lastName}
            </T.P>
            <T.P color="neutral90" mt="2">
              {facilitator.email}
            </T.P>
          </IconWithParagraph>
        </Col>
      </Row>{' '}
      <Row mt="4">
        <Col w={[4, 11, 6]}>
          <S.GeneralTip>
            {[
              'Remember not to share any personal contact details with your learner',
            ]}
          </S.GeneralTip>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 11, 6]}>
          <Button.BasicButton
            variant="primary"
            disabled={false}
            onClick={handleConfirm}
            type="click"
            mt="7"
            loading={isLoading}
          >
            I understand
          </Button.BasicButton>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 11, 6]} jc="center">
          <T.Link
            underline
            to={navRoutes.COACH.HAVE_ISSUE.replace(':id', id)}
            color="neutralMain"
            ta="center"
            mt="4"
          >
            I have an issue
          </T.Link>
        </Col>
      </Row>
      {confirmLearnerHttpError && (
        <Row>
          <Col w={[4, 11, 6]} mt={4}>
            <T.P color="error">{confirmLearnerHttpError?.message}</T.P>
          </Col>
        </Row>
      )}
    </>
  );
};
export default ConfirmLearner;
