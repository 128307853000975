import { useState, useEffect } from 'react';
import * as S from './style';
import { getMediaUrl } from '../../../helpers';
import { audio } from '../../../constants';
import { useGeneralState } from '../../../context/general-state';

import { Typography as T, Button, Icon } from '../../../components';

import GoalProgress from './GoalProgress';
import CardButtonsSection from './CardButtonsSection';
import Task from './Task';

import { getGoalAudio, getGoalBg } from './helpers';
import {
  useUpdateLearnerGoal,
  useDeleteLearnerGoal,
} from '../../../api-calls/learnersGoals.queries';

const handleProgress = ({ tasks = [], completed }) => {
  if (completed) {
    return 100;
  } else if (!tasks || tasks.length === 0) {
    return 0;
  } else {
    const completedTasked = tasks.filter((task) => task?.completed);
    return parseFloat((completedTasked.length / tasks.length).toFixed(2)) * 100;
  }
};

const GoalCard = ({
  goalId,
  title,
  tasks: _tasks,
  handleTaskCheck,
  handleDeleteGoal,
  completed,
  setGoals,
  setOpenModal,
  refetch,
}) => {
  const [tasks, setTasks] = useState([]);
  const { state: generalState } = useGeneralState();
  const { mutate: updateGoal } = useUpdateLearnerGoal({
    personalGoalId: goalId,
    title,
    completed,
  });
  const { mutateAsync: deleteGoal } = useDeleteLearnerGoal({
    personalGoalId: goalId,
  });

  useEffect(() => {
    setTasks(completed ? [] : _tasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_tasks]);

  const goalAudioKey = getGoalAudio({ title });

  return (
    <S.CardWrapper bgImage={getGoalBg({ title })} completed={completed}>
      <S.IconButton
        type="button"
        onClick={() =>
          handleDeleteGoal({
            goalId,
            completed,
            cb: () => deleteGoal({ personalGoalId: goalId }),
          })
        }
      >
        <Icon icon="trash" color="red" />
      </S.IconButton>
      {goalAudioKey && (
        <Button.PlayButton
          audioUrl={getMediaUrl(
            audio[goalAudioKey],
            true,
            generalState?.preferredVoice
          )}
        />
      )}
      <T.H2 color="white" mt="3" mb="2">
        {title}
      </T.H2>
      <GoalProgress progress={handleProgress({ tasks, completed })} />

      {!completed && (!tasks || tasks.length === 0) ? (
        <Task
          mt={3}
          goalId={goalId}
          checked={false}
          handleTaskCheck={() => {}}
          newTask
          setGoals={setGoals}
          refetch={refetch}
        />
      ) : (
        tasks.map((t, idx) => (
          <Task
            key={t.id + t.title}
            mt={idx === 0 ? 3 : 2}
            goalId={goalId}
            id={t.id}
            title={t.title}
            completed={t.completed}
            goalTitle={title}
            handleTaskCheck={handleTaskCheck}
            updateGoal={updateGoal}
            refetch={refetch}
          />
        ))
      )}
      {!completed && (
        <CardButtonsSection
          goalId={goalId}
          title={title}
          completed={completed}
          setGoals={setGoals}
          setOpenModal={setOpenModal}
          tasks={tasks}
          refetch={refetch}
        />
      )}
    </S.CardWrapper>
  );
};

export default GoalCard;
