import { fields, createSchema, validate as _validate } from '..';

const { requiredText } = fields;

const schema = createSchema({
  optimisticAboutFuture: requiredText,
  feelingUseful: requiredText,
  feelingRelaxed: requiredText,
  dealingWithProblems: requiredText,
  thinkingClearly: requiredText,
  feelingCloseToOtherPeople: requiredText,
  ableToMakeUpMind: requiredText,
  feelingConfident: requiredText,
  interestedInNewThings: requiredText,
  education: requiredText,
  employment: requiredText,
  confidentInReadingAbility: requiredText,
  ableToReadLetters: requiredText,
  communicateWellWithOthers: requiredText,
  happyWithProgress: requiredText,
});

const validate = (data) => _validate(schema, data);

export default validate;
