import { Col, Row } from '../../components/Grid';
import {
  BasicInput,
  DatePicker,
  Radio,
  Textarea,
} from '../../components/Inputs';

const Form = ({ validationErrs, setFormData, state }) => {
  return (
    <>
      <Row>
        <Col w={[4, 6, 6]} mt="6">
          <BasicInput
            label="First name"
            placeholder="First name..."
            value={state.firstName}
            name="firstName"
            handleChange={(firstName) => setFormData({ firstName })}
            error={validationErrs.firstName}
          />
        </Col>
        <Col w={[4, 6, 6]} mt="6">
          <BasicInput
            label="Last name"
            placeholder="Last name..."
            value={state.lastName}
            name="lastName"
            handleChange={(lastName) => setFormData({ lastName })}
            error={validationErrs.lastName}
          />
        </Col>
        <Col w={[4, 12, 12]} mt="6">
          <Radio
            label="Will they have access to a device that can be connected to the internet?"
            options={[
              { label: 'Yes', value: 'YES' },
              { label: 'No', value: 'NO' },
              { label: 'I don’t know', value: 'I_DONT_KNOW' },
            ]}
            value={state.hasDeviceInternetAccess}
            onChange={(hasDeviceInternetAccess) => {
              setFormData({ hasDeviceInternetAccess });
            }}
            error={validationErrs.hasDeviceInternetAccess}
            row
          />
        </Col>
        <Col w={[4, 12, 12]} mt="6">
          <Radio
            label="Have they been involved in Turning Pages before?"
            options={[
              { label: 'Yes', value: 'YES' },
              { label: 'No', value: 'NO' },
              { label: 'I don’t know', value: 'I_DONT_KNOW' },
            ]}
            value={state.involvedBefore}
            onChange={(involvedBefore) => {
              setFormData({ involvedBefore });
            }}
            error={validationErrs.involvedBefore}
            row
          />
        </Col>
        <Col w={[4, 12, 12]} mt="6">
          <Radio
            label="Are they pre or post release?"
            options={[
              { label: 'Pre', value: true },
              { label: 'Post', value: false },
            ]}
            value={state.preReleased}
            onChange={(preReleased) => {
              setFormData({ preReleased: preReleased === 'true' });
            }}
            error={validationErrs.preReleased}
            row
          />
        </Col>
        <Col w={[4, 6, 6]} mt="6">
          <BasicInput
            label="Enter a NOMIS ID"
            placeholder="e.g. A1111AA"
            value={state.nomisId}
            name="nomisId"
            optional
            handleChange={(nomisId) => setFormData({ nomisId })}
            error={validationErrs.nomisId}
          />
        </Col>
        <Col w={[4, 6, 6]} mt="6">
          <BasicInput
            label="Enter nDelius CRN"
            placeholder="e.g. 235790"
            value={state.delius}
            name="delius"
            optional
            handleChange={(delius) => setFormData({ delius })}
            error={validationErrs.delius}
          />
        </Col>

        <Col w={[4, 6, 6]} mt="6">
          <BasicInput
            label="Probation Practitioner’s name"
            placeholder="Name..."
            value={state.probationOfficerName}
            name="probationOfficer"
            handleChange={(probationOfficerName) =>
              setFormData({ probationOfficerName })
            }
            error={validationErrs.probationOfficerName}
          />
        </Col>
        <Col w={[4, 6, 6]} mt="6">
          <BasicInput
            label="Probation Practitioner’s email address"
            placeholder="Email..."
            value={state.probationOfficerEmail}
            name="email"
            handleChange={(probationOfficerEmail) =>
              setFormData({ probationOfficerEmail })
            }
            error={validationErrs.probationOfficerEmail}
          />
        </Col>

        <Col w={[4, 6, 6]} mt="6">
          <DatePicker
            label="Learner's Date of Birth"
            placeholder="DD/MM/YYYY"
            value={state.dateOfBirth}
            name="dateOfBirth"
            handleChange={(dateOfBirth) =>
              setFormData({
                dateOfBirth,
              })
            }
            error={validationErrs.dateOfBirth}
          />
        </Col>
        <Col w={[4, 6, 6]} mt="6">
          <BasicInput
            label="Learner's email address"
            placeholder="Email..."
            value={state.email}
            name="email"
            optional
            handleChange={(email) => setFormData({ email })}
            error={validationErrs.email}
          />
        </Col>
      </Row>

      <Row>
        <Col w={[4, 12, 12]} mt="6">
          <Textarea
            mb="-8px"
            label="Availability of the learner"
            placeholder="e.g. Are they only available on weekends or certain weekdays/times?"
            value={state.availability}
            optional
            handleChange={(availability) => {
              setFormData({ availability });
            }}
            error={validationErrs.availability}
          />
        </Col>
        <Col w={[4, 12, 12]} mt="6">
          <Textarea
            label="Any useful additional information for us to know? i.e. Risk information"
            placeholder="Additional information..."
            value={state.additionalInfo}
            handleChange={(additionalInfo) => {
              setFormData({ additionalInfo });
            }}
            error={validationErrs.additionalInfo}
          />
        </Col>
      </Row>
    </>
  );
};

export default Form;
