import styled from '@emotion/styled';
import setMargin from '../../../helpers/set-margin';
import * as CS from '../style';

const InputCommonStyle = ({ theme, error, color, disabled }) => `
  width: 100%;
  padding: ${theme.spacings[3]};
  padding-right: ${error ? '45px' : theme.spacings[3]};
  height: 60px;
  line-height: 24px;
  font-size: 1rem;
  color: ${color ? theme.colors[color] || color : theme.colors.neutralDark};
  cursor: ${disabled && 'not-allowed'};
  border-radius: 16px;

  :hover {
    border: solid 2px ${
      error ? theme.colors.error : theme.colors.primaryMain
    } !important;
  }

  :focus {
    outline: none;
    border: solid 2px ${theme.colors.neutralMain};
    box-shadow :
        0 0 0 2px white,
        0 0 0 5px ${theme.colors.primaryMain};
        }
`;

export const Input = styled.input`
  ${setMargin};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  ${CS.commonBorderStyle};
  ${InputCommonStyle};
  ${CS.placeholderStyle};
`;

export const BasicInputRelativeDiv = styled.div`
  position: relative;
`;

export const AlertWrapper = styled.div`
  position: absolute;
  top: 5%;
  transform: translate(0, 50%);
  right: 12px;
`;

export const InfoWrapper = styled.button`
  margin-bottom: ${({ theme }) => theme.spacings[2]};
  margin-left: ${({ theme }) => theme.spacings[2]};
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
`;
