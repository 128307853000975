import { useParams } from 'react-router-dom';
import * as S from './style';
import { BasicButton } from '../../../../../../components/Button';
import { Row, Col } from '../../../../../../components/Grid';

import { navRoutes } from '../../../../../../constants';
import * as T from '../../../../../../components/Typography';

const SelectRecipes = ({
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  selfLearning,
  enableNext,
}) => {
  const { exerciseId } = useParams();

  return (
    <Row jc="center">
      <Col w={[4, 6, 6]}>
        <S.ButtonWrapper>
          {stuckAtReview ? (
            <BasicButton
              handleClick={handleNext}
              variant="primary"
              disabled={disabled || preview || !enableNext}
            >
              <T.P color="white" weight="semi">
                Complete review
              </T.P>
            </BasicButton>
          ) : (
            <div style={{ width: '100%' }}>
              <BasicButton
                disabled={disabled || preview || !enableNext}
                handleClick={handleNext}
                icon="next"
                variant="primary"
              >
                <T.P color="white" weight="semi">
                  Next
                </T.P>
              </BasicButton>
              {preview && (
                <BasicButton
                  icon="back"
                  variant="primary"
                  disabled={disabled || preview || !enableNext}
                  to={(selfLearning
                    ? navRoutes.EXERCISES.EXERCISE_EXPLAINER_SL
                    : navRoutes.EXERCISES.EXERCISE_EXPLAINER
                  ).replace(':exerciseId', exerciseId)}
                  mt={5}
                >
                  <T.P color="white" weight="semi">
                    Back to exercise
                  </T.P>
                </BasicButton>
              )}
            </div>
          )}
        </S.ButtonWrapper>
      </Col>
    </Row>
  );
};

export default SelectRecipes;
