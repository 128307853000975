import { useAuth } from './auth';

import { navRoutes } from '../constants';
import { useEffect, createContext, useState, useContext } from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { Exercises } from '../api-calls';
import Modal from '../components/Modal';
import Icon from '../components/Icon';
import { Row, Col } from '../components/Grid';
import { BasicButton, PlayButton } from '../components/Button';

import { userRoles, audio } from './../constants';
import * as utils from './utils';
import { useCreateStuckAt } from '../api-calls/learners.queries';
import { useCreateCompletedStep } from '../api-calls/exercises.queries';
import { getMediaUrl } from '../helpers';
import { useGeneralState } from '../context/general-state';

// TODO: set with coach in local storage
// TODO: set learner id in local storage
// TODO: create a function to handle completed steps / stuck at steps

const successMessages = [
  'Nice!',
  'Great!',
  'Good work',
  'Cool, keep it going',
  `Great, let's continue`,
  'Correct!',
];
const successAudio = [
  audio.nice,
  audio.great,
  audio.goodWork,
  audio.coolKeepItGoing,
  audio.greatLetSContinue,
  audio.correct,
];

const selectSuccessMessage = (preferredVoice) => {
  const randomIndex = Math.floor(Math.random() * 4);
  return {
    text: successMessages[randomIndex],
    audioUrl: getMediaUrl(successAudio[randomIndex], true, preferredVoice),
  };
};

const initialExerciseState = {
  id: null,
  title: '',
  titleAudioKey: '',
  type: '',
  beforeExercise: {
    text: '',
    textAudioKey: '',
    tip: '',
  },
  coachNotes: {
    title: '',
    titleAudioKey: '',
    type: '',
  },
  steps: [{ id: null, stuck: false, completed: false }],
};

const ExerciseContext = createContext({
  exercise: initialExerciseState,
  setExercise: () => {},
});

const getWithCoachFromStorage = () => {
  const withCoach = JSON.parse(localStorage.getItem('withCoach'));
  return withCoach;
};

const storeWithCoachIntoStorage = (withCoach) => {
  localStorage.setItem('withCoach', JSON.stringify(withCoach));
};

const ExerciseProvider = (props) => {
  const [exercise, setExercise] = useState(initialExerciseState);
  const [successCounter, setSuccessCounter] = useState(0);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [parkingModalVisible, setParkingModalVisible] = useState(false);
  const [subProgress, setSubProgress] = useState(0);
  const [progressPercent, setProgressPercent] = useState(0);
  const [withCoach, setWithCoach] = useState(getWithCoachFromStorage);
  const { user } = useAuth();
  const { state: generalState } = useGeneralState();
  const location = useLocation();

  const learnerId =
    user.role === userRoles.LEARNER ? user.id : user.selectedLearnerId;
  const match = matchPath(
    { path: navRoutes.EXERCISES.SINGLE_STEP },
    location.pathname
  );
  const { exerciseId: _exerciseId } = useParams();
  const { stepId: _stepId } = match?.params || {};

  const navigate = useNavigate();
  const exerciseId = Number(_exerciseId);
  const stepId = Number(_stepId);

  // we only store last completed step/each exercise
  const currentStepIndex = exercise.steps.findIndex((s) => s.id === stepId);
  const _progressPercent = currentStepIndex / exercise.steps.length;
  const nextStep = utils.getNextStep(exercise.steps);
  const lastStep = exercise.steps[exercise.steps.length - 1];
  const { mutate: createStuckAt } = useCreateStuckAt({
    stepId,
    learnerId,
  });
  const { mutateAsync: createCompletedStep } = useCreateCompletedStep({
    id: stepId,
    learnerId,
    withCoach: withCoach,
  });

  useEffect(() => {
    setProgressPercent(_progressPercent);
    setSubProgress(0);
  }, [_progressPercent]);

  useEffect(() => {
    const getExerciseById = async () => {
      const { data } = await Exercises.getExerciseById({
        learnerId,
        id: exerciseId,
      });
      if (data) {
        const incompleteExercise = data?.steps?.find((e) => !e.completed);
        if (incompleteExercise) {
          setExercise({ ...exercise, ...data });
        } else {
          setExercise({
            ...exercise,
            ...data,
            steps: data?.steps?.map((e) => ({ ...e, completed: false })),
          });
        }
      } else {
        setExercise(initialExerciseState);
      }
    };

    getExerciseById(exerciseId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseId]);
  const markActiveStepAsCompleted = async () => {
    try {
      await createCompletedStep({
        id: stepId,
        learnerId,
        withCoach: withCoach,
      });
      const _steps = exercise.steps.map((step) => {
        return step.id === stepId
          ? { ...step, completed: true, stuck: false }
          : { ...step, completed: false };
      });

      const nextStep = utils.getNextStep(_steps, stepId);

      setExercise({ ...exercise, steps: _steps });
      return nextStep;
    } catch (error) {
      throw error;
    }
  };
  const pushToExerciseComplete = () => {
    navigate(
      navRoutes.EXERCISES.EXERCISE_COMPLETED.replace(
        ':exerciseId',
        exerciseId
      ).replace(':milestoneId', exercise.milestoneId)
    );
  };

  const pushToNextStep = ({ nextStep }) => {
    if (nextStep) {
      navigate(
        navRoutes.EXERCISES.SINGLE_STEP.replace(
          ':exerciseId',
          exerciseId
        ).replace(':stepId', nextStep.id)
      );
    } else {
      pushToExerciseComplete();
    }
  };

  const pushToDashboard = (nextStep) => {
    if (user.role === userRoles.COACH) {
      return navigate(
        navRoutes.COACH.LEARNER_DASHBOARD.replace(':id', learnerId)
      );
    }
    navigate(navRoutes.LEARNER.DASHBOARD);
  };

  const showModal = (isExplainer) => {
    if (lastStep?.id === stepId) {
      return;
    }

    if (successCounter >= 5 && !isExplainer) {
      setSuccessCounter(0);
      setSuccessModalVisible(
        selectSuccessMessage(generalState?.preferredVoice)
      );

      return true;
    }

    if (successCounter < 3) {
      setSuccessCounter((_counter) => _counter + 1);
    } else {
      if (isExplainer) {
        setSuccessCounter(0);
        return;
      }
      const res = Math.random();
      if (res < 1 / 3) {
        setSuccessCounter((_counter) => _counter + 1);
      } else {
        setSuccessCounter(0);
        setSuccessModalVisible(
          selectSuccessMessage(generalState?.preferredVoice)
        );
        return true;
      }
    }
  };

  const handleNext = async ({ isExplainer, isExerciseCompletedPage } = {}) => {
    let _nextStep = nextStep;

    if (isExerciseCompletedPage) {
      return pushToDashboard();
    }

    if (!isExplainer) {
      _nextStep = await markActiveStepAsCompleted();
    }
    if (isExplainer) {
      _nextStep = utils.getFirstStep(exercise.steps);
    }

    const isModalVisible = showModal(isExplainer);

    if (!isModalVisible) {
      pushToNextStep({ nextStep: _nextStep });
    }
  };

  useEffect(() => {
    storeWithCoachIntoStorage(withCoach);
  }, [withCoach]);

  const markActiveStepAsSaved = async () => {
    createStuckAt(
      {
        stepId,
        learnerId,
      },
      {
        onSuccess: () => {
          const _steps = exercise.steps.map((step) => {
            return step.id === stepId ? { ...step, stuck: true } : step;
          });
          setExercise({ ...exercise, steps: _steps });
        },
      }
    );
  };

  const handleSaveStep = async () => {
    await markActiveStepAsSaved();
    setParkingModalVisible(true);
  };

  const value = {
    exercise,
    setExercise,
    handleNext: handleNext, // => // modal /redirect / backend / update context, ,
    progressPercent: progressPercent + subProgress / exercise.steps.length,
    setSubProgress,
    nextStep,
    withCoach,
    setWithCoach,
    handleSaveStep,
  };

  return (
    <>
      <Modal visible={successModalVisible}>
        <Row>
          <Col w={[4, 12, 12]} jc="center">
            <Icon icon="like" color="white" width="80" height="80" />
          </Col>

          <Col w={[4, 12, 12]} jc="center" mt={3}>
            <PlayButton
              color="neutralSurface"
              audioUrl={successModalVisible && successModalVisible.audioUrl}
            >
              {successModalVisible && successModalVisible.text}
            </PlayButton>
          </Col>

          <Col w={[4, 12, 12]} mt={4}>
            <BasicButton
              handleClick={() => {
                pushToNextStep({ nextStep });
                setSuccessModalVisible(false);
              }}
              icon="tick"
              variant="secondary"
              iconProps={{ width: '13', height: '11.5' }}
              focusOutlineColor="neutralSurface"
            />
          </Col>
        </Row>
      </Modal>

      <Modal visible={parkingModalVisible}>
        <Row>
          <Col w={[4, 12, 12]} jc="center">
            <Icon icon="bookmarkSaved" color="white" width="70" height="70" />
          </Col>

          <Col w={[4, 12, 12]} jc="center" mt={3}>
            <PlayButton
              color="neutralSurface"
              audioUrl={getMediaUrl(
                audio.parkingPageSaved,
                true,
                generalState?.preferredVoice
              )}
            >
              Parking page saved
            </PlayButton>
          </Col>

          <Col w={[4, 12, 12]} mt={4}>
            <BasicButton
              handleClick={() => {
                pushToDashboard();
                setParkingModalVisible(false);
              }}
              icon="tick"
              variant="secondary"
              iconProps={{ width: '13', height: '11.5' }}
              focusOutlineColor="neutralSurface"
            />
          </Col>
        </Row>
      </Modal>

      <ExerciseContext.Provider value={value} {...props} />
    </>
  );
};

const useExercise = () => {
  const value = useContext(ExerciseContext);
  return value;
};

export { ExerciseProvider, useExercise };
export default ExerciseContext;
