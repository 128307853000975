import { useNavigate } from 'react-router';
import * as T from '../../../components/Typography';
import { Row, Col } from '../../../components/Grid';
import InfoCard, { InfoCardWithButton } from '../../../components/InfoCard';
import { Learners as AllLearners } from '../../../api-calls';
import { COACH } from '../../../constants/nav-routes';
import StartCoaching from '../../../components/StartCoaching';
import Loading from '../../../components/Loading';
import { useGetLearners } from '../../../api-calls/learners.queries';
import * as S from './style';

const Learners = () => {
  const navigate = useNavigate();
  const {
    learners = [],
    isLoading: getLearnersLoading,
    error: getLearnersError,
  } = useGetLearners({});
  const onboarded = learners?.filter((e) => e.onboarded);
  const newLearners = learners?.filter((e) => !e.onboarded);
  if (getLearnersLoading) {
    return <Loading />;
  }
  return (
    <S.Wrapper>
      <Row>
        <Col w={[4, 7, 9]} mb="1">
          <T.H2 weight="bold">Assigned new learners</T.H2>
        </Col>
      </Row>

      <Row>
        {newLearners.length !== 0 ? (
          newLearners?.map((learner) => {
            return (
              <Col w={[4, 7, 9]} mt="2" key={learner.id}>
                <InfoCard
                  firstColumnLabel="Learner ID"
                  firstColumnValue={learner.learnerUniqueId}
                  secondColumnLabel="Name"
                  secondColumnValue={learner.firstName}
                  buttonText={learner.confirmed ? 'Onboard' : 'Confirm'}
                  to={
                    learner.confirmed
                      ? COACH.NEW_LEARNER.replace(
                          ':firstName',
                          learner.firstName
                        ).replace(':userId', learner.id)
                      : COACH.CONFIRM_LEARNER.replace(':id', learner.id)
                  }
                />
              </Col>
            );
          })
        ) : (
          <Col w={[4, 7, 9]} mt="3">
            <InfoCardWithButton />
          </Col>
        )}
      </Row>

      <Row>
        <Col w={[4, 7, 9]} mt="6" mb="1">
          <T.H2 weight="bold">Current Learners</T.H2>
        </Col>
      </Row>
      <Row mb="2">
        {onboarded.length !== 0 ? (
          onboarded?.map((learner) => {
            return (
              <Col w={[4, 7, 9]} mt="2" key={learner.id}>
                <InfoCard
                  firstColumnLabel="Learner ID"
                  firstColumnValue={learner.learnerUniqueId}
                  secondColumnLabel="Name"
                  secondColumnValue={learner.firstName}
                  onClick={async () => {
                    await AllLearners.selectLearner({
                      learnerId: learner.id,
                    });
                    navigate(COACH.LEARNER_PROFILE.replace(':id', learner.id));
                  }}
                />
              </Col>
            );
          })
        ) : (
          <Col w={[4, 7, 9]} mt="2">
            <InfoCardWithButton />
          </Col>
        )}
      </Row>
      <Row>
        <Col w={[4, 7, 9]} mt="5">
          <StartCoaching hideForgetLink />
        </Col>
      </Row>
      {getLearnersError ? (
        <T.P color="error">{getLearnersError?.message}</T.P>
      ) : null}
    </S.Wrapper>
  );
};
export default Learners;
