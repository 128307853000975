import { navRoutes } from '../constants';
import { useEffect, createContext, useState, useContext } from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { Exercises } from '../api-calls';
import Modal from '../components/Modal';
import Icon from '../components/Icon';
import { Row, Col } from '../components/Grid';
import { BasicButton, PlayButton } from '../components/Button';
import { useAuth } from './auth';
import { audio } from '../constants';
import { getMediaUrl } from '../helpers';
import { useGeneralState } from '../context/general-state';

// TODO: set with coach in local storage
// TODO: set learner id in local storage
// TODO: create a function to handle completed steps / stuck at steps

const initialSelfLearningState = {
  id: null,
  title: '',
  titleAudioKey: '',
  type: '',
  beforeExercise: {
    text: '',
    textAudioKey: '',
    tip: '',
  },
  coachNotes: {
    title: '',
    titleAudioKey: '',
    type: '',
  },
  steps: [{ id: null, reviewed: false }],
};

const SelfLearningContext = createContext({
  exercise: initialSelfLearningState,
  setExercise: () => {},
});

const SelfLearningProvider = (props) => {
  const { user } = useAuth();
  const [exercise, setExercise] = useState(initialSelfLearningState);
  const { state: generalState } = useGeneralState();
  const [progressPercent, setProgressPercent] = useState(0);
  const [subProgress, setSubProgress] = useState(0);

  const [parkingModalVisible, setParkingModalVisible] = useState(false);
  const { pathname } = useLocation();
  const review = pathname.includes('review');

  const match = matchPath(
    { path: navRoutes.EXERCISES.SINGLE_STEP_SL },
    pathname
  );
  const { exerciseId: _exerciseId } = useParams();

  const { stepId: _stepId } = match?.params || {};

  const navigate = useNavigate();
  const exerciseId = Number(_exerciseId);
  const stepId = Number(_stepId);

  const getNextStep = (steps, stepId) => {
    const nextStep = steps.find((step) => {
      return !step.reviewed && step.id !== stepId;
    });
    return nextStep;
  };

  const currentStepIndex = exercise.steps.findIndex((s) => s.id === stepId);
  const _progressPercent = currentStepIndex / exercise.steps.length;

  const nextStep = getNextStep(exercise.steps, stepId);
  const lastStep = exercise.steps[exercise.steps.length - 1];

  useEffect(() => {
    setProgressPercent(_progressPercent);
    setSubProgress(0);
  }, [_progressPercent]);

  useEffect(() => {
    const getExerciseById = async () => {
      const { data } = await Exercises.getExerciseById({
        learnerId: user.selectedLearnerId,
        id: exerciseId,
      });

      if (data) {
        setExercise({
          ...exercise,
          ...data,
          steps: data.steps.map((s) => ({ ...s, reviewed: false })) || [],
        });
      } else {
        setExercise(initialSelfLearningState);
      }
    };

    if (review) {
      getExerciseById(exerciseId);
    }

    getExerciseById(exerciseId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseId, review]);

  const markActiveStepAsReviewed = () => {
    const _steps = exercise.steps.map((step) => {
      return step.id === stepId ? { ...step, reviewed: true } : step;
    });
    const nextStep = getNextStep(_steps, stepId);
    setExercise({ ...exercise, steps: _steps });
    return nextStep;
  };

  const pushToNextStep = (nextStep) => {
    navigate(
      navRoutes.EXERCISES.SINGLE_STEP_SL.replace(
        ':exerciseId',
        exerciseId
      ).replace(':stepId', nextStep?.id)
    );
  };

  const pushToSelfLearning = () => {
    navigate(
      navRoutes.COACH.REVIEW_LEARNER_SELF_LEARNING.replace(
        ':id',
        user.selectedLearnerId
      )
    );
  };

  const pushToReviewCompleted = () => {
    navigate(
      navRoutes.EXERCISES.REVIEW_COMPLETED_SL.replace(':exerciseId', exerciseId)
    );
  };

  const showModal = () => {
    if (lastStep?.id === stepId) {
      pushToReviewCompleted();

      return true;
    }
  };

  const handleNext = async ({ isExplainer, isReviewCompleted } = {}) => {
    if (isReviewCompleted) {
      return pushToSelfLearning();
    }

    let _nextStep = nextStep;

    if (!isExplainer) {
      _nextStep = markActiveStepAsReviewed();
    }

    const stop = showModal();
    if (stop) {
      return;
    }
    pushToNextStep(_nextStep);
  };

  const value = {
    exercise,
    setExercise,
    handleNext: handleNext, // => // modal /redirect / backend / update context, ,
    progressPercent: progressPercent + subProgress / exercise.steps.length,
    setSubProgress,
    nextStep,
    disabled: !nextStep?.id && lastStep.id !== stepId,
  };

  return (
    <>
      <Modal visible={parkingModalVisible}>
        <Row>
          <Col w={[4, 12, 12]} jc="center">
            <Icon icon="bookmarkSaved" color="white" width="70" height="70" />
          </Col>

          <Col w={[4, 12, 12]} jc="center" mt={3}>
            <PlayButton
              color="neutralSurface"
              audioUrl={getMediaUrl(
                audio.parkingPageSaved,
                true,
                generalState?.preferredVoice
              )}
            >
              Parking page saved
            </PlayButton>
          </Col>

          <Col w={[4, 12, 12]} mt={4}>
            <BasicButton
              handleClick={() => {
                pushToNextStep(nextStep);
                setParkingModalVisible(false);
              }}
              icon="tick"
              variant="secondary"
              iconProps={{ width: '13', height: '11.5' }}
              focusOutlineColor="neutralSurface"
            />
          </Col>
        </Row>
      </Modal>

      <SelfLearningContext.Provider value={value} {...props} />
    </>
  );
};

const useSelfLearning = () => {
  const value = useContext(SelfLearningContext);
  return value;
};

export { SelfLearningProvider, useSelfLearning };
export default SelfLearningContext;
