import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetFacilitatorCoaches } from '../../../api-calls/facilitator.queries';
import { coachStatusesQuery } from '../../../constants';
import { Table } from 'antd';
import { Row, Col } from '../../../components/Grid';
import { Loading } from '../../../components';
import moment from 'moment';
import NameColumn from './NameColumn';
import Modal from '../../../components/Modal';
import BasicButton from '../../../components/Button/Basic';
import AssignColumn from './AssignColumn';
import * as T from '../../../components/Typography';
import * as S from './style';
import * as Learners from '../../../api-calls/learners';
import { navRoutes } from '../../../constants';

const AssignCoach = () => {
  const [coach, setCoach] = useState();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('');
  const [learner, setLearner] = useState();
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const { id: learnerUserId } = useParams();

  const updateLearnerCoach = async () => {
    setLoading(true);
    const { error } = await Learners.updateLearnerCoach({
      learnerUserId,
      coachId: coach.id,
    });

    setLoading(false);
    if (error) {
      setError(error.message);
    } else {
      setIsModalVisible1(false);
      setIsModalVisible2(true);
    }
  };

  const getLearner = async () => {
    setLoading(true);
    const { data, error } = await Learners.getLearnerUniqueId({
      learnerUserId,
    });
    setLoading(false);
    if (data) {
      setLearner(data);
    } else {
      setError(error.message);
    }
  };

  useEffect(() => {
    getLearner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnerUserId]);

  const {
    coaches,
    error: getCoachesError,
    isLoading: getCoachesLoading,
  } = useGetFacilitatorCoaches({ statusType: coachStatusesQuery.ONBOARDED });

  if (getCoachesLoading) {
    return <Loading />;
  }
  if (getCoachesError) {
    return (
      <T.P mb={2} mt={2} color="error">
        {getCoachesError}
      </T.P>
    );
  }

  const columns = [
    {
      title: (
        <T.P ml={3} weight="bold" color="neutralMain">
          Name
        </T.P>
      ),
      dataIndex: 'fullName',
      key: 'fullName',
      render: (fullName, { id }) => {
        return <NameColumn name={fullName} id={id} />;
      },
    },
    {
      title: (
        <T.P weight="bold" color="neutralMain">
          Email
        </T.P>
      ),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: (
        <T.P weight="bold" color="neutralMain">
          Learners
        </T.P>
      ),
      dataIndex: 'totalLearners',
      key: 'totalLearners',
    },
    {
      title: (
        <T.P weight="bold" color="neutralMain">
          Hours available
        </T.P>
      ),
      dataIndex: 'availabilityHoursPerWeek',
      key: 'availabilityHoursPerWeek',
    },
    {
      title: (
        <T.P weight="bold" color="neutralMain">
          Completed training
        </T.P>
      ),
      render: (date) => (date ? moment(date).format('DD MMMM YYYY') : '-'),
      dataIndex: 'completedTrainingAt',
      key: 'completedTrainingAt',
    },
    {
      title: (
        <T.P weight="bold" color="neutralMain">
          Assign a coach
        </T.P>
      ),
      render: (id) => {
        return (
          <AssignColumn
            handleClick={() => {
              setIsModalVisible1(true);
              const coachObj = coaches.find((v) => v.id === id);
              setCoach(coachObj);
            }}
          />
        );
      },
      dataIndex: 'id',
      key: 'id',
    },
  ];

  return (
    <S.Wrapper>
      <Row mt="8" mb="6">
        <T.H2 color="neutralMain" size="large">
          Assign a coach
        </T.H2>
      </Row>
      <Row>
        <Table columns={columns} dataSource={coaches} />
      </Row>
      <Modal
        visible={isModalVisible1}
        setIsModalVisible={(e) => {
          setError('');
          setIsModalVisible1(e);
        }}
      >
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            {error ? (
              <>
                <T.H3 color="neutralSurface">Error:</T.H3>
                <T.H3 color="neutralSurface"> {error}</T.H3>
              </>
            ) : (
              <T.H3 color="neutralSurface">
                Are you sure you want to assign {coach?.fullName} as{' '}
                {learner?.fullName}'s coach
              </T.H3>
            )}
          </Col>
        </Row>

        <BasicButton
          to={'#'}
          variant="secondary"
          loading={loading}
          handleClick={() => {
            updateLearnerCoach();
          }}
        >
          <T.P color="white" weight="bold">
            Yes, I'm sure
          </T.P>
        </BasicButton>
        <BasicButton
          mt={3}
          to={'#'}
          variant="tertiary"
          loading={loading}
          handleClick={() => {
            setIsModalVisible1(false);
            setCoach(null);
          }}
        >
          No, go back
        </BasicButton>
      </Modal>

      <Modal visible={isModalVisible2} setIsModalVisible={setIsModalVisible2}>
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            <T.H3 color="neutralSurface">
              Thanks, the coach will be notified
            </T.H3>
          </Col>
        </Row>

        <BasicButton
          to={navRoutes.FACILITATOR.DASHBOARD}
          variant="secondary"
          loading={loading}
          handleClick={() => {
            setIsModalVisible2(false);
          }}
        >
          <T.P color="white" weight="bold">
            Return to dashboard
          </T.P>
        </BasicButton>
      </Modal>
    </S.Wrapper>
  );
};

export default AssignCoach;
