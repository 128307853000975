import { useNavigate } from 'react-router-dom';
import progressCheck from '../assets/progress-check.png';
import { navRoutes, userRoles } from '../../constants';
import TextWithIcon from '../TextWithIcon';

import * as S from './style';
import { Typography as T, Button } from '../';
import { navRoutes as R, audio } from '../../constants';
import { getMediaUrl } from '../../helpers';
import { useGeneralState } from '../../context/general-state';

const ProgressCheck = ({
  userRole,
  code,
  milestoneId,
  progressCheckFilled,
}) => {
  const navigate = useNavigate();
  const { state: generalState } = useGeneralState();

  return (
    <S.Wrapper
      disabled={!code || progressCheckFilled}
      image={progressCheck}
      success={progressCheckFilled}
      onClick={() =>
        userRole === userRoles.LEARNER
          ? navigate(R.LEARNER.COACH_CODE, { state: { milestoneId, code } })
          : navigate(
              navRoutes.LEARNER.PROGRESS_PROFILE_QUESTIONS.replace(
                ':id',
                1
              ).replace(':milestoneId', milestoneId)
            )
      }
    >
      {progressCheckFilled ? (
        <S.CompletedWrapper>
          <TextWithIcon
            text="Progress check"
            icon="tick"
            size="large"
            weight="bold"
            color="neutralSurface"
            isText={true}
            iconProps={{
              color: 'neutralSurface',
              width: '24.23px',
              height: '21px',
            }}
          />
        </S.CompletedWrapper>
      ) : (
        <S.ButtonsWrapper>
          <Button.PlayButton
            audioUrl={getMediaUrl(
              !code ? audio.exerciseLocked : audio.progressCheck,
              true,
              generalState?.preferredVoice
            )}
            iconProps={{ width: code ? 27.2 : 32 }}
            icon={!code ? 'lock' : 'speaker'}
            disabled={!code}
          />
          <S.PlayButtonWrapper disabled={!code}>
            <T.H1 color="neutralSurface">Progress check</T.H1>
          </S.PlayButtonWrapper>
        </S.ButtonsWrapper>
      )}
    </S.Wrapper>
  );
};
export default ProgressCheck;
