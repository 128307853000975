import * as S from './style';

// plays YouTube video embeds
const backUpTemplate = 'https://www.youtube.com/embed/LfsXExFeBg0';
const Video = ({
  src = backUpTemplate,
  width = '100%',
  height = '236px',
  ...props
}) => {
  return (
    <S.ResponsiveYoutube>
      <iframe
        width="560"
        height="315"
        src={src}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        {...props}
      ></iframe>
    </S.ResponsiveYoutube>
  );
};

export default Video;
