import styled from '@emotion/styled';

export const StyledIconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacings[3]};
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
`;
