import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BasicButton } from '../../../components/Button';
import { Col, Row } from '../../../components/Grid';
import { Loading } from '../../../components';
import * as T from '../../../components/Typography';
import * as S from './style';
import NoteCard from '../../../components/NoteCard';
import { navRoutes } from '../../../constants';

import { useGetFacilitatorCoachNotes } from '../../../api-calls/facilitator.queries';

const ViewAllNotes = () => {
  const { coachId, statusType } = useParams();
  const [loadMore, setLoadMore] = useState(6);
  const handleLoadMore = async () => {
    setLoadMore((old) => old + 6);
  };
  const {
    notes = [],
    error: getCoachNotesError,
    isLoading: getCoachNotesLoading,
  } = useGetFacilitatorCoachNotes({ coachId });

  if (getCoachNotesLoading) {
    return <Loading />;
  }
  return (
    <S.Wrapper>
      <Row>
        <Col w={[4, 12, 6]} mb={4}>
          <T.H1>Notes</T.H1>
        </Col>
      </Row>
      <Row>
        {notes?.length ? (
          notes
            ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            ?.slice(0, loadMore)
            .map(({ updatedAt, note, id }) => (
              <Col w={[4, 12, 6]} mb={4}>
                <NoteCard
                  createdAt={updatedAt}
                  note={note}
                  noteId={id}
                  coachId={coachId}
                />
              </Col>
            ))
        ) : (
          <Row>
            <Col w={[4, 12, 6]} mb={7} mt={5}>
              <T.P>There is no notes yet</T.P>
            </Col>
          </Row>
        )}
      </Row>
      {loadMore < notes?.length ? (
        <Row>
          <Col w={[4, 12, 4]} mb={3} mt={2}>
            <BasicButton
              variant="primary"
              type="submit"
              onClick={handleLoadMore}
              loading={getCoachNotesLoading}
            >
              Load more
            </BasicButton>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col w={[4, 12, 4]} mb={5}>
          <BasicButton
            variant="secondary"
            to={navRoutes.FACILITATOR.COACH_PROFILE.replace(
              ':statusType',
              statusType
            ).replace(':coachId', coachId)}
          >
            Back to coach profile
          </BasicButton>
        </Col>
      </Row>
      {getCoachNotesError ? (
        <T.P color="error">{getCoachNotesError?.message}</T.P>
      ) : null}
    </S.Wrapper>
  );
};

export default ViewAllNotes;
