import { Spin } from 'antd';
import styled from '@emotion/styled';
import setMargin from './../../../helpers/set-margin';

export const decideBackgroundBasic = (
  variant,
  theme,
  bgColor,
  opacity = '',
  overlayColor
) => {
  if (overlayColor && (bgColor === 'primaryMain' || variant === 'primary')) {
    return overlayColor !== 'default'
      ? `${theme.colors.neutralMain}${opacity}`
      : `${theme.colors.primaryMain}${opacity}`;
  }
  if (bgColor) {
    return `${theme.colors[bgColor]}${opacity}` || `${bgColor}${opacity}`;
  }
  switch (variant) {
    case 'primary':
      return `${theme.colors.primaryMain}${opacity}`;
    case 'secondary':
      return `${theme.colors.neutralMain}${opacity}`;
    case 'tertiary':
      return `${theme.colors.neutralLight}${opacity}`;
    default:
      return 'transparent';
  }
};

export const decideBackgroundHoverBasic = (
  variant,
  theme,
  opacity = '',
  disabled
) => {
  if (disabled) {
    return null;
  }
  switch (variant) {
    case 'primary':
      return `${theme.colors.secondaryMain}${opacity}`;
    case 'secondary':
      return `${theme.colors.neutralMain80}${opacity}`;
    case 'tertiary':
      return `${theme.colors.neutralLight}${opacity}`;

    default:
      return 'transparent';
  }
};

export const BasicButton = styled.button`
  ${setMargin};
  height: 3.8rem;
  display: flex;
  justify-content: ${({ left }) => (left ? 'left' : 'center')};
  align-items: center;
  text-align: ${({ ta }) => ta || 'left'} !important;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  color: ${({ variant, theme, customColor }) => {
    if (customColor) return theme.colors[customColor] || customColor;
    return variant === 'tertiary'
      ? theme.colors.neutralMain
      : theme.colors.white;
  }};
  position: relative;
  background: ${({ variant, theme, bgColor, opacity, overlayColor }) =>
    decideBackgroundBasic(variant, theme, bgColor, opacity, overlayColor)};
  border: none;
  border-radius: ${({ borderRadius }) => borderRadius || '16px'};
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.04rem;
  padding: ${({ theme, padding }) => padding || `20px ${theme.spacings[5]}`};
  box-shadow: ${({ theme }) => theme.shadows.elevation1};
  transition: background-color 300ms linear, color 300ms linear;

  /* selected */
  outline-style: ${({ selected }) => (selected ? 'solid' : 'none')};
  outline-width: ${({ selected }) => (selected ? '2px' : 'none')};
  outline-color: ${({ theme, focusOutlineColor = 'primaryMain', selected }) =>
    selected ? theme.colors[focusOutlineColor] : 'none'};

  /* clicking style */
  :active {
    opacity: 0.9;
    // transform: translateY(1px) scale(0.99);
  }

  :hover {
    color: ${({ variant, theme, customColor }) => {
      if (customColor) return theme.colors[customColor] || customColor;
      return variant === 'tertiary'
        ? theme.colors.neutralMain
        : theme.colors.white;
    }};
    background: ${({ variant, theme, opacity, disabled }) =>
      decideBackgroundHoverBasic(variant, theme, opacity, disabled)};
  }

  :focus {
    box-shadow: ${({ noOutline, theme }) =>
      noOutline
        ? theme.shadows.elevation1
        : 'inset 0px 0px 0px 2px rgba(5, 23, 48, 1)'};
    outline-style: ${({ noOutline }) => (noOutline ? 'none' : 'solid')};
    outline-width: ${({ noOutline }) => (noOutline ? 'none' : '3px')};
    outline-color: ${({
      theme,
      focusOutlineColor = 'primaryMain',
      noOutline,
    }) => (noOutline ? 'none' : theme.colors[focusOutlineColor])};
    outline-offset: 3px;
  }

  /* for disabled style */
  opacity: ${({ disabled, loading }) => (disabled || loading ? 0.5 : 1)};

  cursor: ${({ disabled, loading }) =>
    disabled || loading ? 'not-allowed' : 'pointer'};
`;

export const InnerTextAlign = styled.span`
  padding-top: 1px;
`;

export const Loading = styled(Spin)`
  span {
    line-height: 24px;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.white};
  }
`;
